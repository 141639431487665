import { css } from '@emotion/core';
import { phytosanitaryWeb, migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from '../Colors';
const themes = {
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.geyser
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.prussianBlue
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.prussianBlue
  },
  tdCarWeb: {
    alpha: tdCarWeb.prussianBlue
  }
};
export default (({
  theme = 'migratoryWebCitizen'
}) => css`
  border-bottom: 1px solid ${themes[theme].alpha};
  margin-bottom: 25px;
  width: 100%;
`);