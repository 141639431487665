import { useQuery } from 'react-query'
import qs from 'qs'
import { coreClient, client } from '../utils/api-client'
import {
  BUSINESSES_KEYS,
  MANUFACTURERTYPES_KEYS,
  MEASUREMENTUNITS_KEYS,
  PRESENTATION_TYPES_KEYS,
  COSMETICSHAPES_KEYS,
  COSMETICUSES_KEYS,
  PERSONS_KEYS,
  PROFESSIONALS_KEYS,
  COUNTRIES_KEYS,
  LEGALREPRESENTATIVES_KEYS,
  MANUFACTURERS_KEYS,
  CATALOGS_KEYS,
  ALLOWEDCHANGEDEFINITION_KEYS,
  PROFESSIONS_KEYS,
} from '../utils/queryKeyFactory'
const CONFIG = {
  refetchOnWindowsFocus: false,
}
const { REACT_APP_APPLICATION_ID, REACT_APP_APPLICATION_CODE } = process.env
export function useIdentificationsTypes({ scope = 'procedure', config, api }) {
  const key = {
    scope,
    applicationId: REACT_APP_APPLICATION_ID,
  }

  if (REACT_APP_APPLICATION_CODE) {
    key.applicationCode = REACT_APP_APPLICATION_CODE
    delete key.applicationId
  }

  const query = qs.stringify(key, {
    addQueryPrefix: true,
  })
  return useQuery({
    queryKey: ['identificationtypes', key],
    queryFn: async () => {
      const response = await coreClient(
        `/identificationtypes${query}`,
        {},
        api
      ).catch(error => {
        return Promise.reject(error)
      })
      return response.data
    },
    config: { ...CONFIG, ...config },
  })
}
export function useOperatorRoles({ options = {}, query, api } = {}) {
  const queryParams = qs.stringify(query, {
    addQueryPrefix: true,
  })
  return useQuery({
    queryKey: ['roles'],

    async queryFn() {
      const response = await coreClient(`/roles${queryParams}`, {}, api).catch(
        () => []
      )

      if (response && response.statusCode !== 200) {
        return []
      }

      return response.data
    },

    config: { ...CONFIG, ...options },
  })
}
export function useCountries({ options = {}, api } = {}) {
  return useQuery({
    queryKey: COUNTRIES_KEYS.list(),

    async queryFn() {
      const response = await coreClient('/countries?limit=300', {}, api).catch(
        () => []
      )

      if (response && response.statusCode !== 200) {
        return []
      }

      return response.data
    },

    config: { ...CONFIG, ...options },
  })
}
export async function getStateByName(name) {
  const response = await coreClient(`/places/states?name=${name}`, {}).catch(
    () => {
      return {
        data: [],
      }
    }
  )

  if (response && response.statusCode !== 200) {
    return {
      data: [],
    }
  }

  return response.data
}
export async function getCityByName({ name, stateId }) {
  const response = await coreClient(
    `/places/cities?name=${name}&stateId=${stateId}`,
    {}
  ).catch(() => {
    return {
      data: [],
    }
  })

  if (response && response.statusCode !== 200) {
    return {
      data: [],
    }
  }

  return response.data
}
export function useMeasurementUnits() {
  return useQuery({
    queryKey: MEASUREMENTUNITS_KEYS.list(),
    queryFn: async () => {
      const response = await client(`/measurementunit`, {
        method: 'GET',
      }).catch(error => Promise.reject(error))
      return response.data
    },
  })
}
export function usePresentationTypes() {
  return useQuery({
    queryKey: PRESENTATION_TYPES_KEYS.list(),
    queryFn: async () => {
      const response = await client(`/packagingtype`, {
        method: 'GET',
      }).catch(error => Promise.reject(error))
      return response.data
    },
  })
}
export function useCosmeticShapes() {
  return useQuery({
    queryKey: COSMETICSHAPES_KEYS.list(),
    queryFn: async () => {
      const response = await client(`/cosmeticshape`, {
        method: 'GET',
      }).catch(error => Promise.reject(error))
      return response.data
    },
  })
}
export function useCosmeticUses() {
  return useQuery({
    queryKey: COSMETICUSES_KEYS.list(),
    queryFn: async () => {
      const response = await client(`/cosmeticuse`, {
        method: 'GET',
      }).catch(error => Promise.reject(error))
      return response.data
    },
  })
}
export function useBusinesses({ query = {}, options = {} } = {}) {
  return useQuery({
    queryKey: BUSINESSES_KEYS.list(query),
    queryFn: async () => {
      const queryParams = qs.stringify(query, {
        addQueryPrefix: true,
      })
      const response = await client(`/business${queryParams}`, {
        method: 'GET',
      }).catch(error => Promise.reject(error))
      return response
    },
    config: { ...options },
  })
}
export function useBusinessesTypes({
  query = {},
  pagination = {},
  options = {},
} = {}) {
  const querys = qs.stringify(query, {
    addQueryPrefix: true,
  })
  return useQuery({
    queryKey: ['businessesTypes', query, pagination],
    queryFn: async () => {
      const response = await client(`/businessType${querys}`, {
        method: 'GET',
      }).catch(error => Promise.reject(error))
      return response.data
    },
    config: {
      refetchOnWindowFocus: false,
      ...options,
    },
  })
}
export function usePersons({ query = {}, options = {} } = {}) {
  return useQuery({
    queryKey: PERSONS_KEYS.list(query),
    queryFn: async () => {
      const queryParams = qs.stringify(query, {
        addQueryPrefix: true,
      })
      const response = await client(`/person${queryParams}`, {
        method: 'GET',
      }).catch(error => Promise.reject(error))
      return response
    },
    config: { ...options },
  })
}
export function useProfessions({ options = {} } = {}) {
  return useQuery({
    queryKey: PROFESSIONS_KEYS.list(),
    queryFn: async () => {
      const response = await client('/profession').catch(error =>
        Promise.reject(error)
      )
      return response.data
    },
    config: { ...options },
  })
}
export function useProfessionals({ query = {}, options = {} } = {}) {
  return useQuery({
    queryKey: PROFESSIONALS_KEYS.list(query),
    queryFn: async () => {
      const queryParams = qs.stringify(query, {
        addQueryPrefix: true,
      })
      const response = await client(`/professional${queryParams}`, {
        method: 'GET',
      }).catch(error => Promise.reject(error))
      return response
    },
    config: { ...options },
  })
}
export function useHolders({ query = {}, pagination = {}, options = {} } = {}) {
  const querys = qs.stringify(query, {
    addQueryPrefix: true,
  })
  return useQuery({
    queryKey: ['holders', query, pagination],
    queryFn: async () => {
      const response = await client(`/headline${querys}`, {
        method: 'GET',
      }).catch(error => Promise.reject(error))
      return response
    },
    config: {
      refetchOnWindowFocus: false,
      ...options,
    },
  })
}
export function useManufacturerTypes({ manipulation, config = {} } = {}) {
  return useQuery({
    queryKey: MANUFACTURERTYPES_KEYS.list(),
    queryFn: async () => {
      const response = await client(`/manufacturerType`, {
        method: 'GET',
      }).catch(error => Promise.reject(error))

      if (manipulation && typeof manipulation === 'function') {
        return manipulation(response.data)
      }

      return response.data
    },
    config: { ...CONFIG, ...config },
  })
}
export function useManufacturers({ query = {}, options = {} } = {}) {
  return useQuery({
    queryKey: MANUFACTURERS_KEYS.list(query),
    queryFn: async () => {
      const queryParams = qs.stringify(query, {
        addQueryPrefix: true,
      })
      const response = await client(`/manufacturer${queryParams}`, {
        method: 'GET',
      }).catch(error => Promise.reject(error))
      return response
    },
    config: { ...options },
  })
}
export function usePostRegistersSections(query = {}) {
  return useQuery({
    queryKey: ALLOWEDCHANGEDEFINITION_KEYS.list(query),
    queryFn: async () => {
      const queryParams = qs.stringify(query, {
        addQueryPrefix: true,
      })
      const response = await client(`/allowedchangedefinition${queryParams}`, {
        method: 'GET',
      }).catch(error => Promise.reject(error))
      return response.data
    },
  })
}
export function useLegalRepresentatives({ query = {}, options = {} } = {}) {
  return useQuery({
    queryKey: LEGALREPRESENTATIVES_KEYS.list(query),
    queryFn: async () => {
      const queryParams = qs.stringify(query, {
        addQueryPrefix: true,
      })
      const response = await client(`/legalrepresentatives${queryParams}`, {
        method: 'GET',
      }).catch(error => Promise.reject(error))
      return response.data
    },
    config: { ...options },
  })
}
export function useFindPerson({ options = {}, id } = {}) {
  return useQuery({
    queryKey: CATALOGS_KEYS.searchPerson(id),

    async queryFn() {
      try {
        const response = await coreClient(`/tse/persons/${id}`, {}, 'core')

        if (
          (response === null || response === void 0
            ? void 0
            : response.statusCode) >= 400
        ) {
          throw response
        }

        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },

    config: {
      retry: false,
      ...options,
    },
  })
}
export async function useMicroEnterprise(query) {
  const queryParams = qs.stringify(query, {
    addQueryPrefix: true,
  })
  const response = await client(`/microbusiness${queryParams}`, {
    method: 'GET',
  }).catch(error => {
    return Promise.reject(error)
  })
  return response
}
