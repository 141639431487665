function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { RadioButton } from '../../../core';
import { migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from '../../Colors';
import { DivRow, DivColumn } from '../../components/InputsUI';
const themes = {
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.aliceBlue,
    beta: migratoryWebCitizen.prussianBlue
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.aliceBlue,
    beta: sanitarianRegistersWeb.prussianBlue
  },
  tdCarWeb: {
    alpha: tdCarWeb.aliceBlue,
    beta: tdCarWeb.prussianBlue
  }
};
/**
 * This component represent a legal information card container.
 * @param {*} children What can be display on the card.
 * @param {JSON} style Custom style for the card div.
 */

export function LegalInfo({
  children,
  style
}) {
  const {
    theme = 'migratoryWebCitizen'
  } = useTheme();
  return jsx("div", {
    css: styles(theme),
    style: { ...style
    }
  }, children);
}
/**
 * This component represent a generic information card title.
 * @param {*} children What can be display on the card title.
 * @param {JSON} style Custom style for the card title.
 */

export function LegalInfoTitle({
  children,
  style
}) {
  return jsx("h1", {
    className: "legal-info-card-title",
    style: { ...style
    }
  }, children);
}
/**
 * This component represent a generic information card subtitle.
 * @param {*} children What can be display on the card title.
 * @param {JSON} style Custom style for the card title.
 */

export function LegalInfoSubTitle({
  children,
  style
}) {
  return jsx("h1", {
    className: "legal-info-card-subtitle",
    style: { ...style
    }
  }, children);
}
/**
 * This component represent a generic information card radio button.
 * @param {*} children What can be display on the card title.
 * @param {JSON} style Custom style for the card title.
 */

export function LegalInfoRadio({
  name = 'radio',
  value = '',
  onChange,
  ...props
}) {
  return jsx(RadioButton, _extends({
    type: "radio",
    value: value,
    name: name,
    onChange: ({
      target: {
        value: tempValue
      }
    }) => {
      onChange(tempValue);
    },
    labelStyle: {
      marginBottom: 0
    },
    spanStyle: {
      transform: 'scale(.9)'
    }
  }, props));
}
/**
 * This component represent a legal information card "card".
 * @param {*} children What can be display on the card title.
 * @param {JSON} style Custom style for the card title.
 */

export function LegalInfoCard({
  children,
  style
}) {
  return jsx("div", {
    className: "legal-info-card",
    style: { ...style
    }
  }, jsx(DivRow, {
    containerStyle: {
      marginBottom: 0,
      height: '100%'
    }
  }, jsx(DivColumn, {
    containerStyle: {
      justifyContent: 'center'
    }
  }, children[0]), jsx(DivColumn, {
    containerStyle: {
      justifyContent: 'center',
      flexBasis: '70%'
    }
  }, children[1])));
}

const styles = theme => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  list-style-type: none;

  .legal-info-card {
    height: 75px;
    padding: 0 22px;
    background: ${themes[theme].alpha};
    border-radius: 10px;
  }

  .legal-info-card-title,
  .legal-info-card-subtitle {
    margin: 0;
    color: ${themes[theme].beta};
  }

  .legal-info-card-title {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
  }

  .legal-info-card-subtitle {
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .legal-info-card-subtitle:last-of-type {
    margin-bottom: 0;
  }
`;