import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import apm from '@migratory/ui/hooks/use-apm'
import { ScrollToTop } from '@migratory/ui/core'

const Home = React.lazy(() => import('./screens/home'))
const Signup = React.lazy(() => import('./screens/auth/signup'))
const Instituciones = React.lazy(() => import('./screens/Instituciones'))
const Consulta = React.lazy(() => import('./screens/Consulta'))
const Ayuda = React.lazy(() => import('./screens/Ayuda'))
const Contacto = React.lazy(() => import('./screens/Contacto'))
const Verification = React.lazy(() => import('./screens/verification'))
const ResetPassword = React.lazy(() => import('./screens/auth/ResetPassword'))
const HelpInstructions = React.lazy(() => import('./screens/HelpInstructions'))
const NotFound = React.lazy(() => import('./screens/404'))

const { REACT_APP_APPLICATION_CODE } = process.env
const showSignup = parseInt(process.env.REACT_APP_SHOW_SIGNUP, 10) === 1
const showConsultation =
  parseInt(process.env.REACT_APP_SHOW_PUBLIC_CONSULTATION, 10) === 1

function App() {
  apm({ user: {} })

  if (REACT_APP_APPLICATION_CODE === 'tdcar') {
    return (
      <React.Suspense fallback={<div />}>
        <BrowserRouter>
          <Route component={ScrollToTop} />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route
              path="/signup"
              exact
              component={showSignup ? Signup : Home}
            />
            <Route path="/auth/verification" exact component={Verification} />
            <Route path="/resetPassword" exact component={ResetPassword} />
            <Route
              path="/resetPassword/:email"
              exact
              component={ResetPassword}
            />
            <Route path="/ayuda/:code" exact component={HelpInstructions} />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </React.Suspense>
    )
  }

  if (REACT_APP_APPLICATION_CODE === 'registrelo') {
    return (
      <React.Suspense fallback={<div />}>
        <BrowserRouter>
          <Route component={ScrollToTop} />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route
              path="/signup"
              exact
              component={showSignup ? Signup : Home}
            />
            <Route path="/auth/verification" exact component={Verification} />
            <Route path="/resetPassword" exact component={ResetPassword} />
            <Route
              path="/resetPassword/:email"
              exact
              component={ResetPassword}
            />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </React.Suspense>
    )
  }

  return (
    <React.Suspense fallback={<div />}>
      <BrowserRouter>
        <Route component={ScrollToTop} />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/signup" exact component={showSignup ? Signup : Home} />
          <Route path="/ayuda" exact component={Ayuda} />
          <Route path="/instituciones" exact component={Instituciones} />
          <Route
            path="/consultaPublica"
            exact
            component={showConsultation ? Consulta : Home}
          />
          <Route
            path="/consultaPublica/tramite"
            exact
            component={showConsultation ? Consulta : Home}
          />
          <Route
            path="/consultaPublica/notarios"
            exact
            component={showConsultation ? Consulta : Home}
          />
          <Route
            path="/consultaPublica/sociedades"
            exact
            component={showConsultation ? Consulta : Home}
          />
          <Route path="/contacto" exact component={Contacto} />
          <Route path="/auth/verification" exact component={Verification} />
          <Route path="/resetPassword" exact component={ResetPassword} />
          <Route path="/resetPassword/:email" exact component={ResetPassword} />
          <Route path="/ayuda/:code" exact component={HelpInstructions} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </React.Suspense>
  )
}

export default App
