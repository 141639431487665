const errorsApi = require('./errors-api.json')
const translationApi = require('./translations-api')
const translationSQL = require('./translations-sql.json')

const homeCommon = require('../common/en/UI/home.json')
const dgmeCommon = require('../common/en/UI/dgme.json')
const operatorCommon = require('../common/en/UI/operator.json')
const sanitarianRegistersCommon = require('../common/en/UI/sanitarianRegisters.json')
const tdcarCommon = require('../common/en/UI/tdcar.json')

exports.sql = {
  'translations-sql': translationSQL,
}

exports.api = {
  errors: errorsApi,
  ...translationApi,
}

exports.web = {
  home: { 'translations-web': homeCommon },
  dgme: { 'translations-web': dgmeCommon },
  operator: {
    'translations-web': { ...operatorCommon, ...sanitarianRegistersCommon },
  },
  sanitarianRegisters: { 'translations-web': sanitarianRegistersCommon },
  tdcar: { 'translations-web': tdcarCommon },
}
