import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '21',
  height: '13',
  viewBox: '0 0 21 13',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M9.69001 1.09999C9.69001 0.652657 10.0527 0.289993 10.5 0.289993H19.68C20.1273 0.289993 20.49 0.652657 20.49 1.09999C20.49 1.54733 20.1273 1.90999 19.68 1.90999H10.5C10.0527 1.90999 9.69001 1.54733 9.69001 1.09999ZM0.51001 6.49999C0.51001 6.05266 0.872658 5.68999 1.32001 5.68999H19.68C20.1273 5.68999 20.49 6.05266 20.49 6.49999C20.49 6.94733 20.1273 7.30999 19.68 7.30999H1.32001C0.872658 7.30999 0.51001 6.94733 0.51001 6.49999ZM1.32001 11.315C0.872658 11.315 0.51001 11.6776 0.51001 12.125C0.51001 12.5723 0.872658 12.935 1.32001 12.935H10.5C10.9473 12.935 11.31 12.5723 11.31 12.125C11.31 11.6776 10.9473 11.315 10.5 11.315H1.32001Z',
      fill: '#F4FBFE',
    })
  ),
  name: 'Bars',
})
