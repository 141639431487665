import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M9.68403 4.28976C9.18122 4.06249 8.63269 3.78976 8.12988 3.60794L8.90695 1.69885C9.45547 1.92613 10.0497 2.19885 10.5982 2.47158L9.68403 4.28976Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M11.6037 16.6534C11.3294 16.1534 11.0094 15.6988 10.6895 15.2443L12.3807 14.017C12.7921 14.5625 13.1121 15.1079 13.432 15.6988L11.6037 16.6534Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M9.36411 13.6989C8.95272 13.2898 8.54133 12.9261 8.08423 12.6079L9.3184 10.9261C9.86692 11.2898 10.3697 11.7443 10.8268 12.1989L9.36411 13.6989Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M6.34735 11.5625C5.84454 11.3352 5.29602 11.108 4.79321 10.9261L5.43315 8.9716C6.07309 9.19888 6.66733 9.42615 7.26156 9.69887L6.34735 11.5625Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M2.82774 10.4716C2.27922 10.3807 1.68499 10.3352 1.13647 10.3352V8.28978C1.82212 8.28978 2.46206 8.33523 3.102 8.42614L2.82774 10.4716Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M15.8093 15.7443C15.5808 15.2443 15.3065 14.7443 14.9866 14.2898L16.7693 13.1989C17.0892 13.7443 17.4092 14.2898 17.6835 14.8807L15.8093 15.7443Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M13.8434 12.6079C13.4778 12.1534 13.1121 11.7443 12.7007 11.3352L14.1634 9.88068C14.6205 10.3352 15.0319 10.7898 15.4433 11.2898L13.8434 12.6079Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M11.1923 10.017C10.7352 9.6534 10.2781 9.33522 9.82104 9.06249L10.9181 7.33522C11.4666 7.6534 11.9694 8.06249 12.4722 8.42613L11.1923 10.017Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M8.03851 8.0625C7.5357 7.83522 7.03289 7.60795 6.48438 7.42613L7.17002 5.47159C7.76425 5.69886 8.35848 5.92613 8.907 6.19886L8.03851 8.0625Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M4.5189 6.83524C3.97038 6.69888 3.42187 6.60797 2.82764 6.56251L3.05619 4.51706C3.69612 4.60797 4.33606 4.69888 4.93029 4.83524L4.5189 6.83524Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M19.7858 14.8352C19.5572 14.3352 19.3287 13.7898 19.0544 13.3352L20.8828 12.3352C21.1571 12.8807 21.4314 13.4261 21.7056 14.017L19.7858 14.8352Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M18.0032 11.608C17.6832 11.1534 17.3632 10.6989 16.9976 10.2443L18.6431 8.9716C19.0088 9.4716 19.3745 9.9716 19.7401 10.4716L18.0032 11.608Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M15.6722 8.69886C15.2608 8.28977 14.8494 7.88068 14.438 7.51704L15.8093 5.97159C16.2664 6.38068 16.7235 6.83522 17.1349 7.24431L15.6722 8.69886Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M12.8838 6.24433C12.4267 5.92615 11.9696 5.60797 11.4668 5.28979L12.5638 3.51706C13.0666 3.83524 13.6152 4.19888 14.118 4.56251L12.8838 6.24433Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M6.16435 2.92613C5.61583 2.74432 5.06732 2.65341 4.5188 2.51704L4.93019 0.517044C5.52441 0.653408 6.16435 0.789771 6.75858 0.971589L6.16435 2.92613Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M2.50743 2.19886C1.95891 2.15341 1.36468 2.10795 0.816162 2.0625L0.861871 0.0170441C1.4561 0.0170441 2.09604 0.0624986 2.73598 0.153408L2.50743 2.19886Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M12.4266 20.0625C12.3809 19.5171 12.2895 18.9261 12.1523 18.3807L14.1636 17.9261C14.3007 18.5625 14.4378 19.2443 14.4836 19.8807L12.4266 20.0625Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M16.5405 22.7898C16.6319 22.2443 16.6777 21.6534 16.6777 21.108L18.7803 21.1989C18.7803 21.8352 18.6889 22.4716 18.5975 23.108L16.5405 22.7898Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M16.6321 19.108C16.5864 18.5625 16.495 17.9716 16.4036 17.4261L18.4605 17.017C18.5976 17.6534 18.6891 18.2443 18.7348 18.8807L16.6321 19.108Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M20.9287 21.7898C20.9287 21.2443 20.9287 20.6534 20.9287 20.108L22.9857 20.017C23.0314 20.6534 23.0314 21.2443 23.0314 21.8807L20.9287 21.7898Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M20.7001 18.0625C20.6087 17.517 20.5173 16.9716 20.3801 16.3807L22.3914 15.9261C22.5285 16.517 22.6656 17.1534 22.7571 17.7443L20.7001 18.0625Z',
      fill: 'white',
    })
  ),
  name: 'Brand',
})
