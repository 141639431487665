import { css } from '@emotion/core';
export default (() => css`
  line-height: 45px;

  .radio {
    display: flex;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    justify-content: flex-start;
    align-items: center;
  }

  .radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .radio .check {
    position: relative;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    /*background-color: #eee;*/
    border: 2px solid #ccc;
    border-radius: 50%;
  }

  .check {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
  }

  .radio input:checked ~ .check {
    background-color: white;
    border: none;
    border: 2px solid #d33e43;
  }

  .radio .check:after {
    content: '';
    position: absolute;
    display: none;
  }

  .radio input:checked ~ .check:after {
    display: block;
  }

  .radio .check:after {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #d33e43;
  }

  .font {
    font-family: Rubik;
    font-style: normal;
    font-size: 14px;
  }

  .font-label {
    font-family: Rubik;
    font-style: normal;
    font-size: 14px;
  }
`);