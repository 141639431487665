import { css } from '@emotion/core';
import { migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../../Colors';
const themes = {
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.blackPearl,
    beta: migratoryWebCitizen.pacificBlue,
    gamma: migratoryWebCitizen.AzureishWhite,
    delta: migratoryWebCitizen.white,
    epsilon: migratoryWebCitizen.botticelli,
    dseta: migratoryWebCitizen.cerulean,
    eta: migratoryWebCitizen.fireEngineRed
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.blackPearl,
    beta: sanitarianRegistersWeb.pacificBlue,
    gamma: sanitarianRegistersWeb.AzureishWhite,
    delta: sanitarianRegistersWeb.white,
    epsilon: sanitarianRegistersWeb.botticelli,
    dseta: sanitarianRegistersWeb.cerulean,
    eta: sanitarianRegistersWeb.fireEngineRed
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.sherpaBlue,
    beta: phytosanitaryWeb.mantis,
    gamma: phytosanitaryWeb.white,
    delta: phytosanitaryWeb.whiteSmoke,
    epsilon: phytosanitaryWeb.mantis,
    eta: phytosanitaryWeb.burntSienna
  },
  tdCarWeb: {
    alpha: tdCarWeb.blackPearl,
    beta: tdCarWeb.pacificBlue,
    gamma: tdCarWeb.AzureishWhite,
    delta: tdCarWeb.white,
    epsilon: tdCarWeb.botticelli,
    dseta: tdCarWeb.cerulean,
    eta: tdCarWeb.fireEngineRed
  }
};
export default (({
  theme = 'migratoryWebCitizen'
}) => css`
  position: relative;

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-icon {
    margin-top: 10px;
  }

  .dropdown-content {
    position: absolute;
    right: 0;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    background: #ffffff;
    border-radius: 20px;
    width: 397px;
    height: 578px;
    width: 397px;
    flex-direction: column;
    padding-bottom: 10px;
  }

  .title {
    font-weight: 600;
    font-size: 25px;
    padding-top: 33px;
    padding-left: 20px;
    color: ${themes[theme].blackPearl};
  }

  .notifications-count {
    height: 25px;
    width: 25px;
    background-color: ${themes[theme].eta};
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.01em;
    color: ${themes[theme].delta};
    z-index: 10;
    position: absolute;
    right: 0;
  }

  .notifications {
    height: 100%;
    overflow-y: auto;
  }

  .notification-date {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${themes[theme].dseta};
    padding-top: 33px;
    padding-left: 20px;
    padding-bottom: 13px;
  }

  .notification-info {
    border-top: 1px solid #0f7cb5;
    cursor: pointer;
    margin-bottom: 15px;
  }

  .notification-header {
    margin-top: 30px;
    margin-left: 15px;
  }

  .header-title {
    font-weight: 600;
    font-size: 14px;
    color: #333333;
  }

  .header-hour {
    font-weight: 600;
    font-size: 13px;
    color: ${themes[theme].dseta};
    mix-blend-mode: normal;
  }

  .notification-message {
    font-size: 16px;
    color: #333333;
  }

  .column {
    float: left;
    padding: 10px;
  }

  .row:after {
    content: '';
    display: table;
    clear: both;
  }
`);