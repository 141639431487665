import React from 'react'

function useOnDidUpdate() {
  const onDidUpdate = React.useRef(null)
  React.useLayoutEffect(() => {
    if (onDidUpdate.current) {
      onDidUpdate.current()
      onDidUpdate.current = null
    }
  })
  return fn => {
    onDidUpdate.current = fn
  }
}

export default useOnDidUpdate
