/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '13',
  height: '12',
  viewBox: '0 0 13 12',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M9.40419 3.60575e-05C9.68289 0.00223841 9.95346 0.105036 10.1579 0.309458L11.7399 1.89087C12.1772 2.32819 12.1472 3.06499 11.6743 3.5391C11.2001 4.01324 10.4621 4.04143 10.026 3.60533L8.44461 2.02392C8.0073 1.5878 8.03788 0.848637 8.51084 0.375687C8.76212 0.123779 9.08836 -0.00245149 9.40419 3.60575e-05ZM6.79761 2.08953L9.96103 5.25235L4.82009 10.3921L1.65666 7.22867L6.79761 2.08953ZM0.835546 7.82764L4.17234 11.1645L0 12L0.835546 7.82764Z',
    fill: '#0672AB',
  }),
  name: 'EditAlt',
})
