/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import Rodal from 'rodal';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { Formik, useFormikContext } from 'formik';
import useMediaQuery from '../../../hooks/use-media-query';
import { Button, Colors, SpinnerSmall } from '../../../core';
import styles from './modalStyle';
import modalCustomStyle from './utils';
import { DivRow, DivColumn } from '../InputsUI';
import { InputField, GenericDropDown } from '../../advanced/procedure/sections/shared/components';
import { GridContainer } from '../filters/utils';
import ScrollWraper from '../../ScrollWrapper';
import { useVehicleMakes, useVehicleDeposit, useVehicleCategories } from '../../../queries';
import { useCreateVehicleForm, useEditVehicle } from '../../../mutations';
import validationSchema from '../../advanced/procedure/sections/tdcar/vehicle-data/form-schema-ipn';
const {
  tdCarWeb: colors
} = Colors;
/**
 * This component represents a modal for cancel a procedure.
 */

export function FormModalIpn({
  isVisible,
  setIsVisible,
  isEdit = false,
  onCloseEdit,
  isLoading
}) {
  const {
    t
  } = useTranslation();
  const {
    isMobile
  } = useMediaQuery();
  const formik = useFormikContext();

  const handleOnBlur = (fieldName, maxLen) => {
    const parsedNumber = parseFloat(formik.values[fieldName]); // Convert string to float
    // Check if the parsed number is not NaN

    if (!Number.isNaN(parsedNumber)) {
      // Convert the number to a string with two decimal places
      const numberString = parsedNumber.toFixed(2); // Determine the total length of the string

      const totalLength = numberString.length; // Check if the total length exceeds the maximum allowed length

      if (totalLength <= maxLen) {
        // Convert the number to a string with exactly two decimal places
        formik.setFieldValue(fieldName, numberString);
      }
    }
  };

  return jsx(Rodal, {
    visible: isVisible,
    showCloseButton: false,
    width: 1112,
    height: 650,
    closeOnEsc: true,
    onClose: () => {},
    customStyles: modalCustomStyle({
      isMobile,
      width: 1112,
      height: 650,
      borderRadius: 20
    })
  }, jsx("div", {
    css: [styles, {
      padding: '21px 16px 16px 16px',
      height: '650px'
    }]
  }, jsx(DivRow, {
    style: {
      marginBottom: '0px!important',
      display: 'flex'
    }
  }, jsx(DivColumn, {
    containerStyle: {
      flexBasis: '80%'
    }
  }, jsx("h1", {
    className: "modals-title",
    style: {
      fontSize: 24,
      marginTop: 10,
      marginBottom: 30,
      color: colors.deepCerulean,
      marginLeft: 30
    }
  }, t(isEdit ? 'Modificar vehículo' : 'Agregar nuevo vehículo')))), jsx(ScrollWraper, {
    containerStyle: {
      maxHeight: '100%'
    }
  }, jsx(GridContainer, {
    containerStyle: {
      display: 'grid',
      columnGap: 16,
      rowGap: 15,
      gridTemplateColumns: '420px 420px',
      marginLeft: 30
    }
  }, jsx(InputField, {
    label: t('Número de VIN'),
    name: "vin",
    containerStyle: {
      height: '50px'
    },
    maxLength: "25",
    disabled: isEdit
  }), jsx(InputField, {
    label: t('Número de chasis'),
    name: "chasis",
    containerStyle: {
      height: '50px'
    },
    maxLength: "20",
    disabled: isEdit
  }), jsx(GenericDropDown, {
    label: `${t('Marca de vehículo')}:`,
    name: "make",
    query: () => useVehicleMakes({
      manipulation: makes => makes.map(item => ({
        id: item === null || item === void 0 ? void 0 : item.code,
        name: `${item === null || item === void 0 ? void 0 : item.code} - ${item === null || item === void 0 ? void 0 : item.name}`
      }))
    }),
    containerStyle: {
      height: '50px'
    },
    maxLength: "255"
  }), jsx(InputField, {
    label: `${t('Modelo')}:`,
    name: "model",
    containerStyle: {
      height: '50px'
    },
    maxLength: "20"
  }), jsx(InputField, {
    label: `${t('Año de modelo')}:`,
    name: "year",
    containerStyle: {
      height: '50px'
    },
    maxLength: "4"
  }), jsx(GenericDropDown, {
    label: `${t('Categoría')}:`,
    name: "category",
    query: () => useVehicleCategories({
      manipulation: category => category.map(item => ({
        id: item === null || item === void 0 ? void 0 : item.code,
        name: `${item === null || item === void 0 ? void 0 : item.code} - ${item === null || item === void 0 ? void 0 : item.name}`
      }))
    }),
    containerStyle: {
      height: '50px'
    },
    maxLength: "255"
  }), jsx(InputField, {
    label: `${t('PMA (Peso Bruto) kg')}:`,
    name: "rawWeight",
    placeholderText: t('000000.00'),
    containerStyle: {
      height: '50px'
    },
    maxLength: "9",
    onBlur: () => {
      handleOnBlur('rawWeight', 9);
    },
    mask: {
      regex: '^[0-9.]*$'
    }
  }), jsx(GenericDropDown, {
    label: `${t('Almacén o déposito')}:`,
    name: "deposit",
    query: () => useVehicleDeposit({
      manipulation: deposit => deposit.map(item => ({
        id: item === null || item === void 0 ? void 0 : item.code,
        name: `${item === null || item === void 0 ? void 0 : item.code} - ${item === null || item === void 0 ? void 0 : item.name}`
      }))
    }),
    containerStyle: {
      height: '50px'
    },
    maxLength: "255"
  }))), jsx("div", {
    className: "button-container",
    css: {
      justifyContent: 'flex-end!important',
      alignItems: 'center',
      paddingLeft: 80
    }
  }, jsx("div", {
    css: {
      display: 'flex'
    }
  }, jsx(Button, {
    size: "md-icon",
    outline: true,
    className: "button blue",
    style: {
      color: colors.cerulean,
      border: `2px solid ${colors.cerulean}`,
      width: 210,
      height: 48,
      marginRight: 10
    },
    onClick: () => {
      setIsVisible(false);

      if (isEdit) {
        onCloseEdit();
      }
    },
    disabled: isLoading
  }, t('Cancelar')), jsx(Button, {
    size: "md-icon",
    className: "button red",
    style: {
      background: colors.fireEngineRed,
      color: colors.white,
      width: 184,
      height: 48,
      marginRight: 20
    },
    onClick: () => {
      formik.handleSubmit();
    },
    disabled: isLoading
  }, t('Guardar'), isLoading ? jsx(SpinnerSmall, {
    style: {
      marginLeft: '10px'
    }
  }) : '')))));
}
export default function FormDataIpn({
  isVisible,
  setIsVisible,
  procedureId,
  refetch,
  vehicleData,
  onCloseEdit,
  customsName,
  importerName
}) {
  const {
    t
  } = useTranslation();
  const initialValues = {
    vin: vehicleData ? vehicleData.vin : '',
    chasis: vehicleData ? vehicleData.chasis : '',
    make: vehicleData ? vehicleData.make : '',
    model: vehicleData ? vehicleData.model : '',
    year: vehicleData ? vehicleData.year : '',
    category: vehicleData ? vehicleData.category : '',
    rawWeight: vehicleData ? vehicleData.rawWeight : '',
    deposit: vehicleData ? vehicleData.deposit : '',
    procedureId,
    identification: vehicleData ? vehicleData.identification : '',
    identificationType: vehicleData ? vehicleData.identificationType : '',
    isNew: false,
    condition: 1,
    customsName,
    importerName
  };
  let reset;
  const {
    addToast
  } = useToasts();
  const [mutateCreateVehicle, {
    isLoading: isLoadingCreate
  }] = useCreateVehicleForm({
    config: {
      onSuccess: () => {
        setIsVisible(false);

        if (reset) {
          reset();
        }

        refetch();
      },
      onError: error => {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true
        });
      }
    }
  });
  const [mutateEditVehicle, {
    isLoading: isLoadingEdit
  }] = useEditVehicle({
    config: {
      onSuccess: () => {
        setIsVisible(false);
        onCloseEdit();
        refetch();
      },
      onError: error => {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true
        });
      }
    }
  });
  return jsx(Formik, {
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema(t),
    onSubmit: (values, {
      resetForm
    }) => {
      let updatedValues;

      if (values.vin) {
        updatedValues = { ...values,
          identification: values.vin.replace(/[\s\t]+/g, '').trim(),
          vin: values.vin.replace(/[\s\t]+/g, '').trim(),
          chasis: values.chasis.replace(/[\s\t]+/g, '').trim(),
          identificationType: 1
        };
      } else {
        updatedValues = { ...values,
          identification: values.chasis.replace(/[\s\t]+/g, '').trim(),
          chasis: values.chasis.replace(/[\s\t]+/g, '').trim(),
          vin: values.vin.replace(/[\s\t]+/g, '').trim(),
          identificationType: 3
        };
      }

      if (vehicleData) {
        mutateEditVehicle({
          id: vehicleData === null || vehicleData === void 0 ? void 0 : vehicleData.id,
          ...updatedValues
        });
      } else {
        reset = resetForm;
        mutateCreateVehicle({
          body: updatedValues
        });
      }
    }
  }, jsx(FormModalIpn, {
    isVisible: isVisible,
    setIsVisible: setIsVisible,
    isEdit: !!vehicleData,
    onCloseEdit: onCloseEdit,
    isLoading: isLoadingCreate || isLoadingEdit
  }));
}