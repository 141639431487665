/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '14',
  height: '14',
  viewBox: '0 0 14 14',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M7 0C3.60197 0 0.770105 2.42148 0.134155 5.63281H2.07727C2.67598 3.47382 4.65037 1.88672 7 1.88672C8.41247 1.88672 9.68886 2.46066 10.6128 3.38721L8.36719 5.63281H14V0L11.9492 2.05078C10.6826 0.783778 8.93314 0 7 0ZM0 8.36719V14L2.05078 11.9492C3.31737 13.2162 5.06686 14 7 14C10.398 14 13.2299 11.5785 13.8658 8.36719H11.9227C11.324 10.5262 9.34962 12.1133 7 12.1133C5.58753 12.1133 4.31114 11.5393 3.38721 10.6128L5.63281 8.36719H0Z',
    fill: 'white',
  }),
  name: 'RefreshAlt',
})
