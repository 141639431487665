/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '20',
  height: '20',
  viewBox: '0 0 20 20',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M10.0003 1.66699C5.40033 1.66699 1.66699 5.40033 1.66699 10.0003C1.66699 14.6003 5.40033 18.3337 10.0003 18.3337C14.6003 18.3337 18.3337 14.6003 18.3337 10.0003C18.3337 5.40033 14.6003 1.66699 10.0003 1.66699ZM10.0003 16.667C6.32533 16.667 3.33366 13.6753 3.33366 10.0003C3.33366 6.32533 6.32533 3.33366 10.0003 3.33366C13.6753 3.33366 16.667 6.32533 16.667 10.0003C16.667 13.6753 13.6753 16.667 10.0003 16.667ZM13.4753 12.3003L12.5587 11.3837C13.1503 10.2753 13.0003 8.87533 12.067 7.94199C11.7965 7.66924 11.4745 7.45292 11.1197 7.30558C10.765 7.15824 10.3845 7.08281 10.0003 7.08366C9.97533 7.08366 9.95033 7.09199 9.92533 7.09199L10.8337 8.00033L9.95033 8.88366L7.59199 6.52533L9.95033 4.16699L10.8337 5.05033L10.0337 5.85033C11.092 5.85866 12.142 6.25033 12.9503 7.05033C14.367 8.47533 14.542 10.6837 13.4753 12.3003V12.3003ZM12.4087 13.4753L10.0503 15.8337L9.16699 14.9503L9.95866 14.1587C8.86963 14.1502 7.82777 13.7131 7.05866 12.942C6.38036 12.263 5.95694 11.3708 5.85978 10.416C5.76263 9.46117 5.99769 8.50204 6.52533 7.70033L7.44199 8.61699C6.85033 9.72533 7.00033 11.1253 7.93366 12.0587C8.51699 12.642 9.29199 12.9253 10.067 12.9003L9.16699 12.0003L10.0503 11.117L12.4087 13.4753V13.4753Z',
    fill: 'white',
  }),
  name: 'ArrowsRotate',
})
