import React, { createContext, useState } from 'react'

export const PublicContext = createContext()

export function PublicContextProvider(props) {
  const [applicationId, setApplicationId] = useState(1)
  const { children } = props
  return (
    <PublicContext.Provider value={{ applicationId, setApplicationId }}>
      {children}
    </PublicContext.Provider>
  )
}
