/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '35',
  height: '35',
  viewBox: '0 0 35 35',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M17.5 0C7.84 0 0 7.84 0 17.5C0 27.16 7.84 35 17.5 35C27.16 35 35 27.16 35 17.5C35 7.84 27.16 0 17.5 0ZM24.5 19.25H19.25V24.5C19.25 25.4625 18.4625 26.25 17.5 26.25C16.5375 26.25 15.75 25.4625 15.75 24.5V19.25H10.5C9.5375 19.25 8.75 18.4625 8.75 17.5C8.75 16.5375 9.5375 15.75 10.5 15.75H15.75V10.5C15.75 9.5375 16.5375 8.75 17.5 8.75C18.4625 8.75 19.25 9.5375 19.25 10.5V15.75H24.5C25.4625 15.75 26.25 16.5375 26.25 17.5C26.25 18.4625 25.4625 19.25 24.5 19.25Z',
    fill: 'white',
  }),
  name: 'PlusCircle',
})
