import { css } from '@emotion/core';
import { migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../../Colors';
const themes = {
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.botticelli,
    beta: migratoryWebCitizen.botticelli,
    gamma: migratoryWebCitizen.valhalla,
    delta: migratoryWebCitizen.morningGlory,
    epsilon: migratoryWebCitizen.white,
    dseta: migratoryWebCitizen.mabel,
    eta: migratoryWebCitizen.blackPearl
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.botticelli,
    beta: sanitarianRegistersWeb.AzureishWhite,
    gamma: sanitarianRegistersWeb.prussianBlue,
    delta: sanitarianRegistersWeb.prussianBlue,
    epsilon: sanitarianRegistersWeb.fireEngineRed,
    dseta: sanitarianRegistersWeb.prussianBlue,
    eta: sanitarianRegistersWeb.blackPearl
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.sherpaBlue,
    beta: phytosanitaryWeb.azure2,
    gamma: phytosanitaryWeb.sherpaBlue,
    delta: phytosanitaryWeb.sherpaBlue,
    epsilon: phytosanitaryWeb.lightSeaGreen,
    dseta: phytosanitaryWeb.sherpaBlue,
    eta: phytosanitaryWeb.blackPearl
  },
  tdCarWeb: {
    alpha: tdCarWeb.botticelli,
    beta: tdCarWeb.botticelli,
    gamma: tdCarWeb.valhalla,
    delta: tdCarWeb.morningGlory,
    epsilon: tdCarWeb.white,
    dseta: tdCarWeb.mabel,
    eta: tdCarWeb.blackPearl
  }
};
export default (({
  theme = 'migratoryWebCitizen'
}) => css`
  font-size: 12px;
  position: relative;

  .dropdown {
    display: flex;
    position: relative;
    justify-content: flex-end;
    padding: 0 23px;

    div {
      margin-right: 21px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    span {
      text-transform: uppercase;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: ${themes[theme].alpha};
    }

    span:last-of-type {
      font-weight: normal;
      font-size: 12px;
    }

    svg {
      transition: all 0.35s;
      width: 11.45px;
      height: 7.68px;
      position: relative;
      top: 12px;

      path {
        fill: ${themes[theme].alpha};
      }
    }
  }

  .menu-content {
    flex-direction: column;
    transition: all 0.35s;
    background: ${themes[theme].beta};
    position: absolute;
    transform: translate(0, 11px);
    overflow: hidden;
    width: 100%;
    z-index: 10;
    border-radius: 20px;
  }

  .menu-options-title {
    color: ${themes[theme].eta};
  }

  .menu-options {
    padding: 10px 0;
    cursor: pointer;
    display: flex;
    position: relative;

    div {
      margin-left: 10px;
      width: 90%;
    }

    span {
      font-style: normal;
      font-weight: bold;
      line-height: 14px;
      color: ${themes[theme].gamma};
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    span:last-of-type {
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
    }
  }

  .menu-options:after {
    position: absolute;
    left: -15%;
    content: ' ';
    display: block;
    height: 35px;
    border-bottom: 1px solid ${themes[theme].delta};
    width: 140%;
  }

  .menu-content div:last-of-type:after {
    border-bottom: none;
  }

  .check-icon {
    width: 23px;
    height: 23px;
    color: ${themes[theme].epsilon};
  }

  .rol-icon {
    width: 23px;
    height: 23px;
    color: ${themes[theme].dseta};
  }
`);