/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '19',
  height: '19',
  viewBox: '0 0 19 19',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M9.5 0.15625C4.34791 0.15625 0.15625 4.34791 0.15625 9.5C0.15625 14.6521 4.34791 18.8438 9.5 18.8438C14.6521 18.8438 18.8438 14.6521 18.8438 9.5C18.8438 4.34791 14.6521 0.15625 9.5 0.15625ZM9.5 15.25C8.56113 15.25 7.81453 14.852 7.71436 14.0111C7.7117 13.9859 7.71438 13.9605 7.72221 13.9365C7.73005 13.9125 7.74286 13.8904 7.75982 13.8716C7.77678 13.8529 7.7975 13.8379 7.82063 13.8277C7.84375 13.8176 7.86877 13.8124 7.89404 13.8125H11.1037C11.1292 13.8123 11.1545 13.8176 11.1778 13.8279C11.2012 13.8383 11.222 13.8535 11.239 13.8725C11.256 13.8916 11.2688 13.914 11.2764 13.9383C11.2841 13.9627 11.2865 13.9884 11.2834 14.0138C11.1688 14.839 10.429 15.25 9.5 15.25ZM13.9023 13.0938H5.09766C4.56578 13.0938 4.28906 12.4199 4.59498 12.0605C5.32855 11.1918 5.84695 10.8265 5.84695 8.43086C5.84695 6.23553 7.00324 5.45658 7.95828 5.07654C8.024 5.04915 8.08326 5.0083 8.13224 4.95662C8.18121 4.90493 8.21882 4.84357 8.24264 4.77646C8.4084 4.22617 8.87738 3.75 9.5 3.75C10.1226 3.75 10.5907 4.22617 10.7578 4.77691C10.7818 4.844 10.8195 4.90535 10.8685 4.95702C10.9176 5.0087 10.9769 5.04956 11.0426 5.07699C11.9954 5.45613 13.1539 6.23643 13.1539 8.43131C13.1539 10.827 13.6719 11.1922 14.4055 12.061C14.7082 12.4199 14.4311 13.0938 13.9023 13.0938Z',
    fill: '#FFF',
  }),
  name: 'NotificationsCircle',
})
