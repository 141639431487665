/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M12.0394 11.3143H12.8571L17.9897 16.4571L16.4571 17.9897L11.3143 12.8571V12.0446L11.0314 11.7617C9.864 12.7646 8.34686 13.3714 6.68571 13.3714C2.99314 13.3714 0 10.3783 0 6.68571C0 2.99314 2.99314 0 6.68571 0C10.3783 0 13.3714 2.99314 13.3714 6.68571C13.3714 8.34686 12.7646 9.864 11.7566 11.0314L12.0394 11.3143ZM2.05714 6.68571C2.05714 9.24171 4.12971 11.3143 6.68571 11.3143C9.24171 11.3143 11.3143 9.24171 11.3143 6.68571C11.3143 4.12971 9.24171 2.05714 6.68571 2.05714C4.12971 2.05714 2.05714 4.12971 2.05714 6.68571Z',
    fill: 'white',
  }),
  name: 'Search',
})
