import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  css: css({
    path: {
      stroke: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z',
      stroke: '#0672AB',
      strokeWidth: '2',
      strokeMiterlimit: '10',
    }),
    jsx('path', {
      d: 'M20.5 14.5L16 19.5L11.5 14.5',
      stroke: '#0672AB',
      strokeWidth: '2',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    })
  ),
  name: 'ChevronInfo',
})
