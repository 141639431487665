function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

/** @jsxRuntime classic */

/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useEffect, useRef } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { json } from '@codemirror/lang-json';
import { xml } from '@codemirror/lang-xml';
import { githubLight } from '@uiw/codemirror-theme-github';
import createTheme from '@uiw/codemirror-themes';
import { tags } from '@lezer/highlight';
import { migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../../Colors';
import { getSkeletonStyle } from '../../../utils/emotion-css';
/**
 * This component represent a text area.
 * @param {*} children What will be shown below the text area.
 * @param {number} width Width of the container of the text area.
 * @param {boolean} capitalLetter Determine if capitalize the text or not.
 * @param {*} rest All other supported props.
 */

export default function TextArea({
  children = null,
  width = 524,
  capitalLetter = false,
  ...rest
}) {
  const [count, setCount] = React.useState(0);
  const textAreaRef = React.useRef(null);
  const {
    showCount = false,
    error,
    touched,
    onChange,
    showCountStyle
  } = rest; // eslint-disable-next-line no-param-reassign

  delete rest.onChange; // eslint-disable-next-line no-param-reassign

  delete rest.showCount; // eslint-disable-next-line no-param-reassign

  delete rest.touched; // eslint-disable-next-line no-param-reassign

  delete rest.showCountStyle;

  const countWords = ({
    target: {
      value
    }
  }) => {
    setCount(value.length);
  };

  const RenderCount = () => {
    const {
      maxLength
    } = rest;
    return jsx("span", {
      className: "character-counter",
      css: { ...showCountStyle
      }
    }, count, maxLength ? `/${maxLength}` : '');
  };

  const onTextAreaChange = e => {
    const {
      value
    } = e.target;

    if (showCount) {
      countWords(e);
    }

    if (onChange) {
      if (onChange.name.length > 0) {
        onChange(capitalLetter ? value.toUpperCase() : value);
        return;
      } // eslint-disable-next-line no-param-reassign


      e.target.value = capitalLetter ? value.toUpperCase() : value;
      onChange(e);
    }
  };

  React.useEffect(() => {
    const {
      value = ''
    } = rest;

    if (count === 0 && value.length > 0) {
      setCount(value.length);
    }
  }, [rest]);
  return jsx("div", {
    css: styles,
    style: {
      display: 'flex',
      flexDirection: 'column'
    }
  }, jsx("div", {
    style: {
      position: 'relative',
      width
    }
  }, jsx("textarea", _extends({
    ref: textAreaRef,
    onChange: onTextAreaChange,
    type: "text",
    className: error && error.length > 0 && touched ? 'error' : ''
  }, rest)), children || '', showCount && jsx(RenderCount, null)));
}
const themes = {
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.fireEngineRed,
    beta: migratoryWebCitizen.pacificBlue,
    gamma: migratoryWebCitizen.cello,
    delta: migratoryWebCitizen.cadetBlue,
    epsilon: migratoryWebCitizen.white,
    dseta: migratoryWebCitizen.valhalla,
    cita: migratoryWebCitizen.cadetBlue
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.fireEngineRed,
    beta: sanitarianRegistersWeb.pacificBlue,
    gamma: sanitarianRegistersWeb.cello,
    delta: sanitarianRegistersWeb.cadetBlue,
    epsilon: sanitarianRegistersWeb.white,
    dseta: sanitarianRegistersWeb.valhalla,
    cita: sanitarianRegistersWeb.cadetBlue
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.fireEngineRed,
    beta: phytosanitaryWeb.pacificBlue,
    gamma: phytosanitaryWeb.cello,
    delta: phytosanitaryWeb.cadetBlue,
    epsilon: phytosanitaryWeb.white,
    dseta: phytosanitaryWeb.valhalla,
    cita: phytosanitaryWeb.cadetBlue
  },
  tdCarWeb: {
    alpha: tdCarWeb.fireEngineRed,
    beta: tdCarWeb.pacificBlue,
    gamma: tdCarWeb.cello,
    delta: tdCarWeb.botticelli,
    epsilon: tdCarWeb.white,
    dseta: tdCarWeb.valhalla,
    cita: tdCarWeb.cadetBlue
  }
};
export const TextAreaCodeHighlight = ({
  name,
  formik,
  t,
  isLoading,
  placeholder,
  containerStyle = {},
  textAreaCss = css``,
  theme,
  customTheme = null,
  customExtension = 'javascript',
  staticValue = null,
  ...props
}) => {
  const parsingValue = useRef(true);
  const inputValue = useRef('');
  const customCss = css`
    .cm-activeLine,
    .cm-activeLineGutter {
      background-color: ${themes[theme].zeta};
    }

    .cm-editor {
      border-radius: 10px;
    }

    .cm-gutters,
    .cm-lineNumbers {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    ${(props === null || props === void 0 ? void 0 : props.disabled) && !customTheme && `
    .cm-activeLine,
    .cm-activeLineGutter{
      background-color: ${themes[theme].delta};
      cursor: text;
      color: ${themes[theme].gamma}
    }
    .cm-activeLine.cm-line{
      width: fit-content;
      background-color: ${themes[theme].cita};
    }
    .cm-line,
    .cm-gutterElement{
      cursor: text;
    }
  `}
  `;

  const validatedValue = examineValue => {
    let valueToExamine = examineValue;

    if (!valueToExamine) {
      return '';
    }

    if (typeof valueToExamine === 'string' && customExtension === 'json') {
      try {
        valueToExamine = JSON.stringify(JSON.parse(valueToExamine), null, 2);
      } catch {
        return valueToExamine;
      }
    }

    if (typeof valueToExamine === 'object') {
      try {
        valueToExamine = JSON.stringify(valueToExamine, null, 2);
      } catch {
        formik.setFieldValue(name, '');
        return '';
      }
    }

    if (typeof valueToExamine !== 'string') {
      try {
        valueToExamine = valueToExamine.toString();
      } catch {
        formik.setFieldValue(name, '');
        return '';
      }
    }

    return valueToExamine;
  };

  useEffect(() => {
    const newValue = validatedValue(formik.values[name]);
    formik.setFieldValue(name, newValue);
    inputValue.current = newValue;
    parsingValue.current = false;
  }, [formik.values[name]]);
  const basicStyle = {
    borderRadius: '10px',
    width: '600px',
    border: `${(props === null || props === void 0 ? void 0 : props.disabled) ? '2px' : '1px'} solid ${(props === null || props === void 0 ? void 0 : props.disabled) ? themes[theme].delta : themes[theme].beta}`,
    padding: '3px 0'
  };

  if (isLoading) {
    return jsx("div", {
      style: { ...basicStyle,
        height: '48px'
      },
      css: getSkeletonStyle()
    });
  }

  let extension = [];

  if (customExtension === 'javascript') {
    extension = [javascript({
      jsx: true
    })];
  } else if (customExtension === 'json') {
    extension = [json()];
  } else if (customExtension === 'xml') {
    extension = [xml()];
  }

  let themeToUse = githubLight;

  if (customTheme) {
    themeToUse = customTheme;
  } else if (props === null || props === void 0 ? void 0 : props.disabled) {
    themeToUse = createTheme({
      settings: {
        foreground: themes[theme].gamma,
        gutterForeground: themes[theme].gamma,
        gutterBackground: themes[theme].delta,
        lineHighlight: themes[theme].cita,
        background: themes[theme].delta,
        gutterActiveForeground: themes[theme].delta,
        selection: themes[theme].cita,
        selectionMatch: themes[theme].cita
      },
      styles: [{
        tag: tags.comment,
        color: themes[theme].gamma
      }, {
        tag: tags.variableName,
        color: themes[theme].gamma
      }, {
        tag: [tags.string, tags.special(tags.brace)],
        color: themes[theme].gamma
      }, {
        tag: tags.number,
        color: themes[theme].gamma
      }, {
        tag: tags.bool,
        color: themes[theme].gamma
      }, {
        tag: tags.null,
        color: themes[theme].gamma
      }, {
        tag: tags.keyword,
        color: themes[theme].gamma
      }, {
        tag: tags.operator,
        color: themes[theme].gamma
      }, {
        tag: tags.className,
        color: themes[theme].gamma
      }, {
        tag: tags.definition(tags.typeName),
        color: themes[theme].gamma
      }, {
        tag: tags.typeName,
        color: themes[theme].gamma
      }, {
        tag: tags.angleBracket,
        color: themes[theme].gamma
      }, {
        tag: tags.tagName,
        color: themes[theme].gamma
      }, {
        tag: tags.attributeName,
        color: themes[theme].gamma
      }, {
        tag: tags.content,
        color: themes[theme].gamma
      }]
    });
  }

  if (!parsingValue.current) {
    return jsx("div", {
      css: [customCss, textAreaCss],
      style: { ...basicStyle,
        ...containerStyle
      }
    }, jsx(CodeMirror, _extends({
      value: staticValue && (props === null || props === void 0 ? void 0 : props.disabled) ? staticValue : inputValue.current,
      onChange: value => {
        inputValue.current = value;
      },
      onBlur: () => {
        setTimeout(() => {
          if (inputValue.current !== formik.values[name]) {
            formik.setFieldValue(name, validatedValue(inputValue.current));
          }
        }, 0);
      },
      placeholder: t(placeholder),
      theme: themeToUse,
      extensions: extension,
      style: {
        cursor: 'text'
      },
      readOnly: (props === null || props === void 0 ? void 0 : props.disabled) || false,
      editable: !(props === null || props === void 0 ? void 0 : props.disabled) && true
    }, props)));
  }

  return jsx(React.Fragment, null);
};

function styles({
  theme = 'migratoryWebCitizen'
}) {
  return css`
    .error {
      border: 1px solid ${themes[theme].alpha} !important;
    }

    input:focus,
    input:hover,
    textarea:hover,
    textarea:focus {
      border: 1px solid ${themes[theme].beta} !important;
      color: ${themes[theme].beta} !important;
      box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15) !important;
    }

    input[readonly],
    input:disabled,
    textarea[readonly],
    textarea:disabled {
      cursor: not-allowed;
      border: none !important;
      color: ${themes[theme].gamma} !important;
      background: ${themes[theme].delta} !important;
    }

    input[readonly]::placeholder,
    input:disabled::placeholder,
    textarea[readonly]::placeholder,
    textarea:disabled::placeholder {
      opacity: 1;
    }

    input[readonly]::-webkit-input-placeholder,
    input:disabled::-webkit-input-placeholder,
    textarea[readonly]::-webkit-input-placeholder,
    textarea:disabled::-webkit-input-placeholder {
      opacity: 1;
    }

    input[readonly]::-moz-input-placeholder,
    input:disabled::-moz-input-placeholder,
    textarea[readonly]::-moz-input-placeholder,
    textarea:disabled::-moz-input-placeholder {
      opacity: 1;
    }

    .character-counter {
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-size: calc(10px + (12 - 10) * ((100vw - 768px) / (1600 - 768)));
      color: ${themes[theme].beta};
    }

    input[readonly] + span,
    input:disabled + span,
    textarea[readonly] + span,
    textarea:disabled + span {
      color: ${themes[theme].gamma};
    }

    textarea {
      background-color: ${themes[theme].epsilon};
      border: 1px solid ${themes[theme].dseta};
      box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
      border-radius: 6px;
      width: 100%;
      height: 50px;
      padding: 14px 16px;
      font-size: 15px;
      color: ${themes[theme].dseta};
      box-sizing: border-box;
      resize: none;
      font-family: 'Rubik';
    }
  `;
}