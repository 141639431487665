/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import { motion } from 'framer-motion';
import { useTheme } from 'emotion-theming';
import { useAccordion } from '../hooks/use-accordion';
import { ChevronRightIcon } from '../icon';
import { migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from './Colors';
const themes = {
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.brightGray,
    beta: migratoryWebCitizen.cerulean
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.brightGray,
    beta: sanitarianRegistersWeb.cerulean
  },
  tdCarWeb: {
    alpha: tdCarWeb.brightGray,
    beta: tdCarWeb.cerulean
  }
};
/**
 * This component represents is the accordion mobile version, only display in modal size screens.
 * @param {string} tittle Accordion title.
 * @param {*} children Accordion content.
 */

export default function AccordionMobile({
  children,
  title
}) {
  const {
    openIndexes,
    toggleIndex
  } = useAccordion();
  const {
    theme
  } = useTheme();
  const isOpen = openIndexes.includes(0);
  return jsx("div", {
    style: {
      backgroundColor: themes[theme].alpha,
      borderRadius: 6,
      marginBottom: 16
    }
  }, jsx("div", {
    css: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '23px 17px',
      boxSizing: 'border-box'
    },
    onClick: () => toggleIndex(0),
    role: "presentation"
  }, jsx("h1", {
    css: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
      color: themes[theme].beta
    }
  }, title), jsx(ChevronRightIcon, {
    css: {
      width: 15,
      height: 9,
      transform: `rotate(${isOpen ? '90deg' : '-90deg'})`,
      color: themes[theme].beta
    }
  })), jsx(motion.div, {
    key: "filters",
    initial: false,
    animate: isOpen ? {
      opacity: 1,
      height: 'auto',
      visibility: 'visible'
    } : {
      opacity: 0,
      height: 0,
      transitionEnd: {
        visibility: 'hidden'
      }
    },
    transition: {
      duration: 0.4,
      ease: [0.04, 0.62, 0.23, 0.98]
    }
  }, children));
}