import { css } from '@emotion/core'

export default css`
  ul.pagination {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  ul.pagination li {
    margin: 0 3px;
    padding: 5px 10px;
    display: inline-block;
    cursor: pointer;
    float: left;
    display: block;
  }

  ul.pagination li.empty {
    border: none;
    cursor: default;
  }

  ul.pagination li.next,
  ul.pagination li.prev {
    border: none;
    float: left;
    display: block;
  }

  ul.pagination li.next {
    padding: 5px 0 5px 10px;
    margin: 0 0 0 3px;
  }

  ul.pagination li.prev {
    padding: 5px 10px 5px 0;
    margin: 0 3px 0 0;
  }

  ul.pagination li.active {
    background: #ccc;
  }

  .pageBtn:active {
    background: #000;
  }
`
