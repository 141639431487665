/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M15.8686 1.54883H12.7081V0.0488281H0.0664062V12.7988C0.0664062 14.0415 1.12764 15.0488 2.43673 15.0488H13.4982C14.8073 15.0488 15.8686 14.0415 15.8686 12.7988V1.54883ZM12.708 12.799V3.049H14.2882V12.799C14.2882 13.2132 13.9345 13.549 13.4981 13.549C13.0618 13.549 12.708 13.2132 12.708 12.799ZM1.64648 1.54882V12.7988C1.64648 13.213 2.00023 13.5488 2.43659 13.5488H11.2621C11.1725 13.3079 11.1271 13.0543 11.1278 12.7988V1.54882H1.64648ZM9.54645 7.54879H3.22559V9.04879H9.54645V7.54879ZM3.22559 10.549H9.54645V12.049H3.22559V10.549ZM9.54645 3.049H3.22559V6.049H9.54645V3.049Z',
    fill: '#fff',
  }),
  name: 'FileAlt',
})
