/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { mediaQuery } from '../../../../../utils';
import { migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../../../../Colors';
export default function AlertRequiredFields({
  customText = null,
  style
}) {
  const {
    t
  } = useTranslation();
  return jsx("div", {
    css: styles,
    style: { ...style
    }
  }, customText || t('Al completar los datos tome en cuenta que no se permiten acentos[ext]'));
}

const styles = ({
  theme = 'migratoryWebCititzen'
}) => {
  const customCSSByApp = {
    migratoryWebCitizen: css`
      background: ${migratoryWebCitizen.brightGray};
      color: ${migratoryWebCitizen.prussianBlue};
      font-size: 14px;
    `,
    sanitarianRegistersWeb: css`
      background: ${sanitarianRegistersWeb.brightGray};
      color: ${sanitarianRegistersWeb.prussianBlue};
      font-size: 14px;
    `,
    phytosanitaryWeb: css`
      background: ${phytosanitaryWeb.darkGreen};
      color: ${phytosanitaryWeb.darkGray};
    `,
    tdCarWeb: css`
      background: ${tdCarWeb.brightGray};
      color: ${tdCarWeb.prussianBlue};
      font-size: 14px;
    `
  };
  return [css`
      border-radius: 8px;
      font-style: italic;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      width: 660px;
      margin: -6px 0 27px 0;
      padding: 16px;
      ${mediaQuery[0]} {
        width: 85%;
      }
    `, customCSSByApp[theme]];
};