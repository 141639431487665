import React from 'react'
import isEqual from 'react-fast-compare'
import { isPrimitive } from '../utils'
/**
 * Esta función wrapea a useEffect recibe un callback y el array de
 * dependencias, esta compara los valores nuevos vs los que tiene memoizados
 * Si no son iguales actualiza la referencia memoizada, y por la tanto ejecuta
 * el useEffect de React.
 * Esta funcion es de especial ayuda cuando se usa en ocasiones en las que
 * quiero evitar llamados innecesarios en los useEffect y que por lo tanto
 * causan re-renders.
 * Por qué? Porque suceden cosas como estas:
 * const a1 = { a: 1 }
 * const b1 = { a: 1 }
 * console.log(a1 === b1) el resultado del console.log es false
 * const a2 = [1]
 * const b2 = [1]
 * console.log(a2 === b2) el resultado del console.log es false
 * Esto se da porque aunque lo que se esta comparando tienen los mismos
 * valores, tienen distinto constructor por lo tanto no son completamente
 * iguales.
 * Que sucede, que el useEffect siempre se va a ejecutar aunque las
 * dependecias sean las mismas, como la del ejemplo de arriba, y en
 * realidad no se debería ejecutar porque son los mismos valores.
 * Con useDeepCompareEffect se soluciona este problema.
 * @note solo se debe usar para comparar dependencias que incluyan funciones,
 * arrays, objetos, o valores primitivos junto con array o objetos. Si las
 * depedencias son solo valores primitivos usar lo que ofrece React mejor
 * @param {*} value
 * @returns
 */

function checkDeps(deps) {
  if (!deps || !deps.length) {
    throw new Error(
      'useDeepCompareEffect no debería se usado si la función no tienen dependencias. Use React.useEffect en su lugar.'
    )
  }

  if (deps.every(isPrimitive)) {
    throw new Error(
      'useDeepEffect no debería ser usado con dependecias que son todas primitivas. Use React.useEffect en su lugar.'
    )
  }
}

export function useDeepCompare(value) {
  const ref = React.useRef()

  if (!isEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}
export default function useDeepCompareEffect(callback, dependencies) {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies)
  }

  React.useEffect(callback, useDeepCompare(dependencies))
}
/**
 * Esta function solo debe ser usada cuando las props pueden cambiar entre solo
 * primitivas a objetos y arrays
 *
 * @param {*} callback
 * @param {*} dependencies
 */

export function useDeepCompareEffectNoCheck(callback, dependencies) {
  React.useEffect(callback, useDeepCompare(dependencies))
}
