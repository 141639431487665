import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  css: css({
    path: {
      stroke: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('circle', {
      cx: '8',
      cy: '8',
      r: '7',
      stroke: '#CE1625',
      strokeWidth: '2',
    }),
    jsx('path', {
      d: 'M8 5L8 8',
      stroke: '#CE1625',
      strokeWidth: '2',
      strokeLinecap: 'round',
    }),
    jsx('circle', {
      cx: '8',
      cy: '11',
      r: '1',
      fill: '#CE1625',
    })
  ),
  name: 'ExclamationCircleAlert',
})
