import { css } from '@emotion/core';
import { migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../Colors';
const themes = {
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.blackPearl,
    beta: migratoryWebCitizen.pacificBlue,
    gamma: migratoryWebCitizen.AzureishWhite,
    delta: migratoryWebCitizen.white,
    epsilon: migratoryWebCitizen.botticelli
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.blackPearl,
    beta: sanitarianRegistersWeb.pacificBlue,
    gamma: sanitarianRegistersWeb.AzureishWhite,
    delta: sanitarianRegistersWeb.white,
    epsilon: sanitarianRegistersWeb.botticelli
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.sherpaBlue,
    beta: phytosanitaryWeb.mantis,
    gamma: phytosanitaryWeb.white,
    delta: phytosanitaryWeb.sherpaBlue,
    epsilon: phytosanitaryWeb.mantis
  },
  tdCarWeb: {
    alpha: tdCarWeb.blackPearl,
    beta: tdCarWeb.pacificBlue,
    gamma: tdCarWeb.AzureishWhite,
    delta: tdCarWeb.white,
    epsilon: tdCarWeb.botticelli
  }
};
export default (({
  theme = 'migratoryWebCitizen'
}) => css`
  .side-menu {
    width: 0;
    top: 0;
    right: 0;
    height: 100vh;
    position: absolute;
    background-color: ${themes[theme].alpha};
    border-radius: 2px;
    color: ${themes[theme].gamma};
    z-index: 100;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    transition: 0.3s;
    overflow-x: hidden;
  }

  .side-menu-is-active {
    width: 100%;
  }

  .side-menu-inner {
    padding: 50px 24px 0 37px;
  }

  .side-menu-item {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 27px;
    cursor: pointer;
    line-height: 19px;
    white-space: nowrap;

    &.big {
      font-size: 26px;
      font-weight: bold;
    }

    &.small {
      font-weight: normal;
      font-size: 13px;
    }

    &.link {
      cursor: pointer;
      color: #076c7e;
    }
  }

  .menu-item-icon {
    width: 18px;
    height: 18px;
    position: relative;
    top: 3px;
  }

  .close-menu-div {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 62px;
    height: 33px;
  }

  .close-icon {
    width: 33px;
    height: 26;
    cursor: pointer;
  }

  .side-disclosure {
    display: block;
    margin-right: 0;
    margin-left: auto;
  }

  .side-menu-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 6px;
    text-align: center;
    background-color: ${themes[theme].beta};
    color: ${themes[theme].delta};
  }

  .side-menu-footer-divider {
    width: 100%;
    border-top: 1px solid #076c7e;
    border-radius: 20px;
    box-sizing: border-box;
  }

  .logout {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    padding: 10px 25px;
    display: block;
    cursor: pointer;
  }

  .hamburger {
    padding: 5px;
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: ${themes[theme].epsilon} !important;
    width: 22px;
    height: 2px;
  }

  .hamburger-box {
    width: 22px;
    height: 22px;
  }

  @media screen and (min-width: 768px) {
    .side-menu-is-active {
      width: 385px;
    }
  }
`);