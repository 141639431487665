/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import { useTheme } from 'emotion-theming';
import { migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from '../Colors';
import { reducer, mediaQuery } from '../../utils';
import { SpinnerSmall as Spinner } from '../../core';
import { SearchIcon } from '../../icon';
import DatePicker from '../forms/inputs/DatePicker';
import DropDownCombo from '../forms/inputs/DropDownCombo';
const themes = {
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.cerulean,
    beta: migratoryWebCitizen.fireEngineRed,
    gama: migratoryWebCitizen.white,
    delta: migratoryWebCitizen.brightGray,
    epsilon: migratoryWebCitizen.fireEngineRed,
    eta: migratoryWebCitizen.fireEngineRed,
    zeta: migratoryWebCitizen.cerulean,
    iota: migratoryWebCitizen.prussianBlue
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.cerulean,
    beta: sanitarianRegistersWeb.fireEngineRed,
    gama: sanitarianRegistersWeb.white,
    delta: sanitarianRegistersWeb.brightGray,
    epsilon: sanitarianRegistersWeb.fireEngineRed,
    eta: sanitarianRegistersWeb.fireEngineRed,
    zeta: sanitarianRegistersWeb.cerulean,
    iota: sanitarianRegistersWeb.prussianBlue
  },
  tdCarWeb: {
    alpha: tdCarWeb.cerulean,
    beta: tdCarWeb.fireEngineRed,
    gama: tdCarWeb.white,
    delta: tdCarWeb.brightGray,
    epsilon: tdCarWeb.fireEngineRed,
    eta: tdCarWeb.fireEngineRed,
    zeta: tdCarWeb.cerulean,
    iota: tdCarWeb.prussianBlue
  }
};
export function Filter({
  t,
  permits,
  states,
  onSubmit,
  isLoading = false
}) {
  const {
    theme
  } = useTheme();
  const [{
    permit,
    state,
    createdAt,
    name
  }, dispatch] = React.useReducer(reducer, {
    permit: '',
    state: '',
    createdAt: '',
    name: ''
  });

  function onChangeName(evt) {
    dispatch({
      name: evt.target.value
    });
  }

  const onChangeCreatedAt = React.useCallback(date => {
    dispatch({
      createdAt: date
    });
  }, []);

  function handleSubmit() {
    onSubmit({
      permit,
      state,
      createdAt,
      name
    });
  }

  return jsx("div", {
    className: "search-section",
    css: filterStyle
  }, jsx("div", {
    className: "filter"
  }, jsx("div", {
    className: "filter-wrapper combo"
  }, jsx("label", null, "Permiso:"), jsx(DropDownCombo, {
    items: permits,
    placeholder: "Seleccionar",
    value: permit,
    style: {
      margin: 0,
      width: '100%'
    },
    onChange: ({
      id
    }) => {
      if (id) {
        dispatch({
          permit: id
        });
      } else {
        dispatch({
          permit: ''
        });
      }
    }
  }))), jsx("div", {
    className: "filter"
  }, jsx("div", {
    className: "filter-wrapper combo"
  }, jsx("label", null, "Estado del usuario:"), jsx(DropDownCombo, {
    items: states,
    placeholder: "Seleccionar",
    value: state,
    style: {
      margin: 0,
      width: '100%'
    },
    onChange: ({
      id
    }) => {
      if (id) {
        dispatch({
          state: id
        });
      } else {
        dispatch({
          state: ''
        });
      }
    }
  }))), jsx("div", {
    className: "filter"
  }, jsx("div", null, jsx("label", null, "Fecha de creaci\xF3n:"), jsx(DatePicker, {
    onChange: onChangeCreatedAt,
    showMonthDropdown: true,
    showYearDropdown: true,
    showClearDate: true,
    placeholder: "Ingresar",
    selectsEnd: true,
    value: createdAt
  }))), jsx("div", {
    className: "filter"
  }, jsx("div", {
    className: "filter-wrapper"
  }, jsx("label", null, "Nombre del usuario: "), jsx("div", {
    className: "search"
  }, jsx("input", {
    type: "text",
    placeholder: t('Buscar'),
    value: name,
    onChange: onChangeName,
    maxLength: 255
  }), jsx(SearchIcon, {
    className: "icon",
    css: {
      color: themes[theme].alpha
    }
  }))), jsx("button", {
    type: "button",
    onClick: handleSubmit
  }, isLoading ? jsx(Spinner, {
    style: {
      position: 'relative',
      top: 3,
      marginRight: 10
    }
  }) : null, t('Buscar'))));
}
export function ListItem() {
  return jsx("div", null);
}

const filterStyle = ({
  theme = 'migratoryWebCitizen'
}) => css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  gap: 12px;
  ${mediaQuery[0]} {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
    overflow: auto;
  }

  .filter {
    .filter-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      ${mediaQuery[0]} {
        width: 100%;
      }

      .search {
        width: 100%;
        position: relative;
        input {
          background: #ffffff;
          border: 1px solid #0f7cb5;
          box-sizing: border-box;
          border-radius: 4px;
          height: 34px;
          padding: 0 30px;
          ${mediaQuery[0]} {
            height: 46px;
          }
        }

        .icon {
          width: 18px;
          height: 18px;
          color: #0f7cb5;
          position: absolute;
          top: 8px;
          left: 8px;
          ${mediaQuery[0]} {
            top: 14px;
          }
        }
      }
    }

    div {
      label {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #282828;
        padding-left: 10px;
      }
    }

    .combo {
      input {
        height: 34px;
        border: 1px solid #0f7cb5;
        ${mediaQuery[0]} {
          height: 46px;
        }
      }
    }
    .react-datepicker-wrapper {
      input {
        background-color: #fff;
        border: 1px solid #0f7cb5;
        box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
        border-radius: 6px;
        height: 34px;
        padding: 14px 16px;
        font-size: 15px;
        color: #0f7cb5;
        width: 100%;
        ${mediaQuery[0]} {
          height: 46px;
        }
      }
    }
    :last-of-type {
      grid-column: span 3;
      display: flex;
      align-items: flex-end;
      ${mediaQuery[0]} {
        grid-column: unset;
        flex-wrap: wrap;
        overflow: auto;
      }

      div {
        width: 80%;
        display: flex;
        flex-direction: column;
        ${mediaQuery[0]} {
          width: 100%;
          height: 62px;
        }
      }

      button {
        width: 20%;
        margin-left: 20px;
        background: ${themes[theme].beta};
        border-radius: 6px;
        border: ${themes[theme].beta};
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-transform: uppercase;
        color: ${themes[theme].gama};
        min-height: 34px;
        ${mediaQuery[0]} {
          width: 100%;
          margin-left: 0px;
          margin-top: 23px;
          height: 38px;
          position: relative;
        }
      }
    }
  }
`;