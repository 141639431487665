import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '30',
  height: '30',
  viewBox: '0 0 30 30',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M26.25 10H16.25C15.9185 10 15.6005 9.8683 15.3661 9.63388C15.1317 9.39946 15 9.08152 15 8.75C15 8.41848 15.1317 8.10054 15.3661 7.86612C15.6005 7.6317 15.9185 7.5 16.25 7.5H26.25C26.5815 7.5 26.8995 7.6317 27.1339 7.86612C27.3683 8.10054 27.5 8.41848 27.5 8.75C27.5 9.08152 27.3683 9.39946 27.1339 9.63388C26.8995 9.8683 26.5815 10 26.25 10ZM26.25 15H16.25C15.9185 15 15.6005 14.8683 15.3661 14.6339C15.1317 14.3995 15 14.0815 15 13.75C15 13.4185 15.1317 13.1005 15.3661 12.8661C15.6005 12.6317 15.9185 12.5 16.25 12.5H26.25C26.5815 12.5 26.8995 12.6317 27.1339 12.8661C27.3683 13.1005 27.5 13.4185 27.5 13.75C27.5 14.0815 27.3683 14.3995 27.1339 14.6339C26.8995 14.8683 26.5815 15 26.25 15Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M11.25 5H3.75C3.05964 5 2.5 5.55964 2.5 6.25V13.75C2.5 14.4404 3.05964 15 3.75 15H11.25C11.9404 15 12.5 14.4404 12.5 13.75V6.25C12.5 5.55964 11.9404 5 11.25 5Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M26.25 20H3.75C3.41848 20 3.10054 19.8683 2.86612 19.6339C2.6317 19.3995 2.5 19.0815 2.5 18.75C2.5 18.4185 2.6317 18.1005 2.86612 17.8661C3.10054 17.6317 3.41848 17.5 3.75 17.5H26.25C26.5815 17.5 26.8995 17.6317 27.1339 17.8661C27.3683 18.1005 27.5 18.4185 27.5 18.75C27.5 19.0815 27.3683 19.3995 27.1339 19.6339C26.8995 19.8683 26.5815 20 26.25 20ZM16.25 25H3.75C3.41848 25 3.10054 24.8683 2.86612 24.6339C2.6317 24.3995 2.5 24.0815 2.5 23.75C2.5 23.4185 2.6317 23.1005 2.86612 22.8661C3.10054 22.6317 3.41848 22.5 3.75 22.5H16.25C16.5815 22.5 16.8995 22.6317 17.1339 22.8661C17.3683 23.1005 17.5 23.4185 17.5 23.75C17.5 24.0815 17.3683 24.3995 17.1339 24.6339C16.8995 24.8683 16.5815 25 16.25 25Z',
      fill: 'white',
    })
  ),
  name: 'DocumentLayoutLeft',
})
