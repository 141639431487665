import { css } from '@emotion/core';
import { migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../../Colors';
const themes = {
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.fireEngineRed,
    beta: migratoryWebCitizen.white,
    gamma: migratoryWebCitizen.fireEngineRed
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.fireEngineRed,
    beta: sanitarianRegistersWeb.white,
    gamma: sanitarianRegistersWeb.fireEngineRed
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.mantis,
    beta: phytosanitaryWeb.sherpaBlue,
    gamma: phytosanitaryWeb.sherpaBlue
  },
  tdCarWeb: {
    alpha: tdCarWeb.fireEngineRed,
    beta: tdCarWeb.white,
    gamma: tdCarWeb.fireEngineRed
  }
};
export default (({
  theme = 'migratoryWebCitizen'
}) => css`
  display: flex;
  flex-direction: row;

  .toogle-buttom {
    border: 1px solid ${themes[theme].alpha};
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;

    span {
      color: ${themes[theme].gamma};
      margin: 0 10px;
    }

    svg {
      width: 21px;
      height: 21px;

      path {
        fill: ${themes[theme].gamma};
      }
    }
  }

  .toggle-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }

  .current-toggle {
    background-color: ${themes[theme].alpha};

    span {
      color: ${themes[theme].beta};
    }

    svg {
      path {
        fill: ${themes[theme].beta};
      }
    }
  }
`);