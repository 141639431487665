import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '27',
  height: '37',
  viewBox: '0 0 27 37',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M26.5622 12.5536L14.3757 0.440476C14.0838 0.146825 13.7189 0 13.3541 0H3.21081C1.38649 0 0 1.24802 0 2.93651V34.0635C0 35.6786 1.31351 37 2.91892 37H24.0811C25.6865 37 27 35.6786 27 34.0635V13.5813C27 13.2143 26.8541 12.8472 26.5622 12.5536ZM24.0811 34.0635H2.91892V3.00992C2.91892 3.00992 2.99189 2.93651 3.21081 2.93651H12.0405V12.1131C12.0405 13.7282 13.3541 15.0496 14.9595 15.0496H24.0811V34.0635Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M10.9459 20.8492C10.3621 20.2619 9.48647 20.2619 8.90268 20.8492C8.3189 21.4365 8.3189 22.3174 8.90268 22.9047L11.3108 25.3273L8.90268 27.75C8.3189 28.3373 8.3189 29.2182 8.90268 29.8055C9.19457 30.0992 9.55944 30.246 9.9243 30.246C10.2892 30.246 10.654 30.0992 10.9459 29.8055L13.354 27.3829L15.7621 29.8055C16.054 30.0992 16.4189 30.246 16.7838 30.246C17.1486 30.246 17.5135 30.0992 17.8054 29.8055C18.3892 29.2182 18.3892 28.3373 17.8054 27.75L15.3973 25.3273L17.8054 22.9047C18.3892 22.3174 18.3892 21.4365 17.8054 20.8492C17.2216 20.2619 16.3459 20.2619 15.7621 20.8492L13.354 23.2718L10.9459 20.8492Z',
      fill: 'white',
    })
  ),
  name: 'FileRemove',
})
