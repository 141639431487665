import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '19',
  height: '19',
  viewBox: '0 0 19 19',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx(
      'g',
      null,
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M18.4107 9.3564L9.96352 0.915389C9.9027 0.854448 9.83046 0.8061 9.75093 0.773112C9.6714 0.740124 9.58615 0.723145 9.50005 0.723145C9.41395 0.723145 9.32869 0.740124 9.24916 0.773112C9.16964 0.8061 9.09739 0.854448 9.03657 0.915389L0.589404 9.3564C0.343311 9.6025 0.203857 9.93678 0.203857 10.2854C0.203857 11.0093 0.792432 11.5979 1.51636 11.5979H2.4064V17.6211C2.4064 17.984 2.69966 18.2773 3.06265 18.2773H8.18755V13.6836H10.4844V18.2773H15.9375C16.3004 18.2773 16.5937 17.984 16.5937 17.6211V11.5979H17.4837C17.8324 11.5979 18.1666 11.4605 18.4127 11.2124C18.9234 10.6997 18.9234 9.8691 18.4107 9.3564Z',
        fill: '#fff',
      })
    )
  ),
  name: 'Home',
})
