import { css } from '@emotion/core';
import { mediaQuery } from '../../../utils';
export default css`
  padding: 54px 19px 19px 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
  ${mediaQuery[0]} {
    padding: 20px;
  }

  .modals-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 42px;

    ${mediaQuery[0]} {
      display: flex !important;
      flex-direction: column !important;
      margin-bottom: 20px !important;
    }
  }

  .modals-column,
  column-profile {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;

    ${mediaQuery[0]} {
      align-items: flex-start !important;
    }
  }

  .modals-column {
    :last-of-type {
      flex-basis: 65% !important;
      align-items: unset;
      justify-content: unset;
    }
  }

  .modals-column-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 15px;
    border-bottom: 1px solid #0672ab;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    ${mediaQuery[0]} {
      border: none;
    }
    span {
      font-weight: 600;
    }
  }

  .icon {
    width: 63px;
    height: 63px;
    color: #0f7cb5;
    ${mediaQuery[0]} {
      width: 65px !important;
      height: 58px !important;
    }
  }

  .signature-logo {
    width: 98px;
    height: 98px;
  }

  .shield-logo {
    width: 104px;
    height: 104px;
  }

  .traffic-cone {
    width: 176px;
    height: 140px;
    color: #0f7cb5;
    ${mediaQuery[0]} {
      width: 65px !important;
      height: 58px !important;
    }
  }

  .modals-title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    margin-top: 10px;
    color: #08415c;
    text-transform: uppercase;
  }

  .modals-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #0672ab;
    text-transform: uppercase;
  }

  .modal-p {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #333333;
  }

  .code-modal-p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #333333;
  }

  .modals-description {
    color: #333333;
    font-size: 14px;

    ${mediaQuery[0]} {
      font-size: 14px !important;
      line-height: 20px !important;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    ${mediaQuery[0]} {
      flex-wrap: wrap !important;
    }

    .button {
      width: 238px;
      height: 45px;
      color: #ffffff;
      border-radius: 6px;
      padding: 12px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase !important;
      ${mediaQuery[0]} {
        width: 100% !important;
      }

      span {
        font-size: 14px;

        ${mediaQuery[0]} {
          font-size: 12px;
        }
      }
    }

    .red {
      width: 238px;
      height: 45px;
      border: none;
      ${mediaQuery[0]} {
        margin-bottom: 10px !important;
      }
    }

    .blue {
      width: 238px;
      height: 45px;
      background: transparent;
      margin-right: 9px;
      color: #0f7cb5;
      border-radius: 6px;
      ${mediaQuery[0]} {
        margin-right: 0 !important;
        order: 2 !important;
      }
    }

    .red2 {
      width: auto;
      padding: 0 10px;
      height: 37px;
      background: transparent;
      margin-right: 9px;
      color: #ce1625;
      border-radius: 6px;
      ${mediaQuery[0]} {
        margin-right: 0 !important;
        order: 2 !important;
        height: 45px;
      }
    }

    .blue2 {
      width: 214px;
      height: 37px;
      border: none;
      ${mediaQuery[0]} {
        margin-bottom: 10px !important;
      }
    }

    .upper {
      text-transform: uppercase;
    }

    .spinner {
      margin-left: 5px;
      width: 20px;
      height: 20px;
    }
  }

  .observations-modal {
    .scrollable {
      background-color: #ebf1f4;
      border-radius: 12px;
      margin-bottom: 18px;
    }

    .observation-footer-note {
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      color: #333333;
      margin-bottom: 12px;
    }
  }

  .disclaimers-modal {
    .title-span {
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      text-transform: uppercase;
      color: #2b2d42;
    }

    .plain-text {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #333333;
    }

    .modals-row-disclaimer,
    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 10px;
      ${mediaQuery[0]} {
        flex-direction: column;
      }
    }

    .modals-column-disclaimer,
    .column {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .row {
      margin-bottom: 22px;
    }

    .column {
      :last-of-type {
        flex-basis: 65%;
      }
    }

    .modals-column-disclaimer.file {
      ${mediaQuery[0]} {
        min-height: 130px;
        overflow: auto;
        height: 100px;
        flex: unset;
      }
    }

    .modals-row-disclaimer.two-columns {
      ${mediaQuery[0]} {
        flex-direction: row;
      }

      .modals-column-disclaimer {
        :last-of-type {
          flex-basis: 65%;
        }

        ${mediaQuery[0]} {
          :last-of-type {
            flex-basis: 0;
          }
        }
      }
    }

    textarea {
      background: #ffffff;
      box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
      border-radius: 6px;
      border: none;
      min-height: 100px;
      ${mediaQuery[0]} {
        min-height: 50px;
      }
    }
  }

  .button-modal {
    width: 238px;
    height: 45px;
    color: #ffffff;
    border-radius: 6px;
    padding: 12px 0;
    display: flex;
    margin-left: 5px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase !important;
    ${mediaQuery[0]} {
      width: 100% !important;
    }

    span {
      font-size: 14px;

      ${mediaQuery[0]} {
        font-size: 12px;
      }
    }
  }

  .red-modal {
    width: 238px;
    height: 49px;
    border: none;
    ${mediaQuery[0]} {
      margin-bottom: 10px !important;
    }
  }
`;