import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '52',
  height: '52',
  viewBox: '0 0 52 52',
  css: css({}),
  paths: jsx(
    React.Fragment,
    null,
    jsx(
      'g',
      null,
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M34.1998 24.7998H19.7998V28.2998H34.1998V24.7998Z',
        fill: '#08415C',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M34.1998 31.7998H19.7998V35.2998H34.1998V31.7998Z',
        fill: '#08415C',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M26.9998 17.7998H19.7998V21.2998H26.9998V17.7998Z',
        fill: '#08415C',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M26 0C11.6 0 0 11.6 0 26C0 40.4 11.6 52 26 52C40.4 52 52 40.4 52 26C52 11.6 40.4 0 26 0ZM37.8 38.8C37.8 39.8 37 40.6 36 40.6H18C17 40.6 16.2 39.8 16.2 38.8V14.2C16.2 13.2 17 12.4 18 12.4H30.6C31.1 12.4 31.5 12.6 31.9 12.9L37.3 18.1C37.6 18.4 37.8 18.9 37.8 19.3V38.8V38.8Z',
        fill: '#08415C',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M31.9002 13C31.6002 12.7 31.1002 12.5 30.6002 12.5H18.0002C17.0002 12.5 16.2002 13.3 16.2002 14.3V38.8C16.2002 39.8 17.0002 40.6 18.0002 40.6H36.0002C37.0002 40.6 37.8002 39.8 37.8002 38.8V19.5C37.8002 19 37.6002 18.6 37.3002 18.3L31.9002 13ZM19.8002 17.8H27.0002V21.3H19.8002V17.8ZM34.2002 35.2H19.8002V31.7H34.2002V35.2ZM34.2002 28.2H19.8002V24.7H34.2002V28.2Z',
        fill: '#fff',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M19.8002 28.2H34.2002V24.7H19.8002V28.2ZM19.8002 35.2H34.2002V31.7H19.8002V35.2ZM19.8002 21.2H27.0002V17.7H19.8002V21.2ZM37.3002 18.3L31.9002 13C31.6002 12.7 31.1002 12.5 30.6002 12.5H18.0002C17.0002 12.5 16.2002 13.3 16.2002 14.3V38.8C16.2002 39.8 17.0002 40.6 18.0002 40.6H36.0002C37.0002 40.6 37.8002 39.8 37.8002 38.8V19.5C37.8002 19 37.6002 18.6 37.3002 18.3Z',
        fill: '#fff',
      })
    )
  ),
  name: 'FileAlternative',
})
