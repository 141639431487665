/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M19 6C19.552 6 20 6.448 20 7V19C20 19.552 19.552 20 19 20H5C4.448 20 4 19.552 4 19V7C4 6.448 4.448 6 5 6H7V4H10V6H14V4H17V6H19ZM18 18V9H6V18H18ZM8 11H10V13H8V11ZM11 11H13V13H11V11Z',
    fill: 'white',
  }),
  name: 'Calendar',
})
