/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M21.75 1.5H2.25C1.00734 1.5 0 2.50734 0 3.75V20.25C0 21.4927 1.00734 22.5 2.25 22.5H21.75C22.9927 22.5 24 21.4927 24 20.25V3.75C24 2.50734 22.9927 1.5 21.75 1.5ZM21.4688 20.25H2.53125C2.45666 20.25 2.38512 20.2204 2.33238 20.1676C2.27963 20.1149 2.25 20.0433 2.25 19.9688V4.03125C2.25 3.95666 2.27963 3.88512 2.33238 3.83238C2.38512 3.77963 2.45666 3.75 2.53125 3.75H21.4688C21.5433 3.75 21.6149 3.77963 21.6676 3.83238C21.7204 3.88512 21.75 3.95666 21.75 4.03125V19.9688C21.75 20.0433 21.7204 20.1149 21.6676 20.1676C21.6149 20.2204 21.5433 20.25 21.4688 20.25ZM19.5 15.9375V17.0625C19.5 17.3731 19.2481 17.625 18.9375 17.625H9.5625C9.25186 17.625 9 17.3731 9 17.0625V15.9375C9 15.6269 9.25186 15.375 9.5625 15.375H18.9375C19.2481 15.375 19.5 15.6269 19.5 15.9375ZM19.5 11.4375V12.5625C19.5 12.8731 19.2481 13.125 18.9375 13.125H9.5625C9.25186 13.125 9 12.8731 9 12.5625V11.4375C9 11.1269 9.25186 10.875 9.5625 10.875H18.9375C19.2481 10.875 19.5 11.1269 19.5 11.4375ZM19.5 6.9375V8.0625C19.5 8.37314 19.2481 8.625 18.9375 8.625H9.5625C9.25186 8.625 9 8.37314 9 8.0625V6.9375C9 6.62686 9.25186 6.375 9.5625 6.375H18.9375C19.2481 6.375 19.5 6.62686 19.5 6.9375ZM7.6875 7.5C7.6875 8.43197 6.93197 9.1875 6 9.1875C5.06803 9.1875 4.3125 8.43197 4.3125 7.5C4.3125 6.56803 5.06803 5.8125 6 5.8125C6.93197 5.8125 7.6875 6.56803 7.6875 7.5ZM7.6875 12C7.6875 12.932 6.93197 13.6875 6 13.6875C5.06803 13.6875 4.3125 12.932 4.3125 12C4.3125 11.068 5.06803 10.3125 6 10.3125C6.93197 10.3125 7.6875 11.068 7.6875 12ZM7.6875 16.5C7.6875 17.432 6.93197 18.1875 6 18.1875C5.06803 18.1875 4.3125 17.432 4.3125 16.5C4.3125 15.568 5.06803 14.8125 6 14.8125C6.93197 14.8125 7.6875 15.568 7.6875 16.5Z',
    fill: 'white',
  }),
  name: 'ListAlt',
})
