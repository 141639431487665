import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '18',
  height: '16',
  viewBox: '0 0 18 16',
  css: css({
    path: {
      stroke: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M1 13.8781V2.62516C1 2.45936 1.06587 2.30035 1.18311 2.18311C1.30035 2.06587 1.45936 2 1.62516 2H5.7929C5.92817 2 6.05978 2.04387 6.168 2.12503L8.33522 3.75045C8.44343 3.83161 8.57505 3.87548 8.71032 3.87548H14.1284C14.2942 3.87548 14.4532 3.94135 14.5704 4.05859C14.6877 4.17583 14.7535 4.33484 14.7535 4.50064V6.37613',
      stroke: '#08415C',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    }),
    jsx('path', {
      d: 'M1 13.8781L3.35463 7.1085C3.39728 6.98589 3.47705 6.87959 3.58286 6.80436C3.68867 6.72914 3.81527 6.68872 3.94509 6.68872H16.3748C16.4746 6.68872 16.5729 6.71259 16.6615 6.75833C16.7502 6.80407 16.8266 6.87035 16.8844 6.95164C16.9422 7.03294 16.9797 7.12688 16.9937 7.22562C17.0078 7.32437 16.9981 7.42505 16.9653 7.51926L14.7535 13.8781H1Z',
      stroke: '#08415C',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    })
  ),
  name: 'FolderOpen',
})
