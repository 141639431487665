/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../Colors';
import { QuestionIcon, InfoCircleIcon } from '../../icon';
import Button from '../../core/Button';
/**
 * This component represent help badge.
 * @param {function} onClickProfile Function triggered when the user click profile.
 * @param {function} onClickHelp Function triggered when the user click help badge.
 * @param {Object} user User representation.
 * @param {function} t Translate.
 */

export default function Help({
  onClickProfile,
  onClickHelp,
  user,
  t
}) {
  var _user$currentRole, _user$currentRole2;

  return jsx("div", {
    style: {
      display: 'flex'
    }
  }, jsx(Button, {
    size: "md-icon",
    style: {
      borderRadius: 6,
      color: '#0F7CB5',
      background: 'transparent',
      border: '2px solid #0F7CB5',
      padding: '10px 0',
      height: 45,
      width: 130
    },
    onClick: onClickHelp
  }, t ? t('Ayuda') : 'Ayuda', jsx("div", {
    css: style
  }, jsx(QuestionIcon, {
    css: {
      color: '#ffffff',
      width: 10,
      height: 10
    }
  }))), jsx(Button, {
    size: "md-icon",
    style: {
      borderRadius: 6,
      color: '#fff',
      background: '#CE1625',
      border: 'none',
      padding: '10px 0',
      height: 45,
      width: 130,
      marginLeft: 15,
      opacity: user.digitalRecord.userId !== user.id && !['user', 'legalRepresentative'].includes(user === null || user === void 0 ? void 0 : (_user$currentRole = user.currentRole) === null || _user$currentRole === void 0 ? void 0 : _user$currentRole.code) ? '0.6' : '1'
    },
    onClick: onClickProfile,
    disabled: user.digitalRecord.userId !== user.id && !['user', 'legalRepresentative'].includes(user === null || user === void 0 ? void 0 : (_user$currentRole2 = user.currentRole) === null || _user$currentRole2 === void 0 ? void 0 : _user$currentRole2.code)
  }, t ? t('Ver perfil') : 'Ver perfil', jsx(InfoCircleIcon, {
    className: "icon",
    css: {
      color: '#ffffff',
      marginLeft: 10,
      width: 20,
      height: 20
    }
  })));
}
const themes = {
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.grey,
    beta: phytosanitaryWeb.white
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.cerulean,
    beta: migratoryWebCitizen.white
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.cerulean,
    beta: sanitarianRegistersWeb.white
  },
  tdCarWeb: {
    alpha: tdCarWeb.cerulean,
    beta: tdCarWeb.white
  }
};

function style({
  theme = 'migratoryWebCitizen'
}) {
  return css`
    background-color: ${themes[theme].alpha};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    margin-left: 10px;

    .icon {
      width: 10px;
      height: 10px;
    }

    span {
      color: ${themes[theme].beta};
      text-transform: uppercase;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      letter-spacing: 0.01em;
      margin-top: 5px;
    }
  `;
}