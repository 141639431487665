import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '70',
  height: '70',
  viewBox: '0 0 70 70',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx(
      'g',
      null,
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70ZM35.5875 14.5251C31.2859 14.5251 27.7988 18.0143 27.7988 22.3184V28.352H48.6721C50.8813 28.352 52.6721 30.1429 52.6721 32.352V51.0001C52.6721 53.2092 50.8813 55.0001 48.6721 55.0001H22C19.7909 55.0001 18 53.2092 18 51.0001V32.352C18 30.1429 19.7909 28.352 22 28.352H23.2764V22.3184C23.2764 15.5152 28.7882 10 35.5875 10C42.3867 10 47.8986 15.5152 47.8986 22.3184V22.5698C47.8986 23.8194 46.8862 24.8324 45.6374 24.8324C44.3885 24.8324 43.3761 23.8194 43.3761 22.5698V22.3184C43.3761 18.0143 39.889 14.5251 35.5875 14.5251Z',
        fill: '#0F7CB5',
      })
    )
  ),
  name: 'CircleUnlock',
})
