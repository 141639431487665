import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '22',
  height: '22',
  viewBox: '0 0 22 22',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('circle', {
      cx: '11',
      cy: '11',
      r: '11',
      fill: 'url(#paint0_linear_1556_42556)',
    }),
    jsx('path', {
      d: 'M16.0121 7.76901H11.1889L9.63336 6.19329C9.54896 6.10729 9.44856 6.0391 9.33797 5.99268C9.22738 5.94626 9.10879 5.92253 8.98908 5.92285H5.98839C5.74679 5.9231 5.51515 6.02043 5.34431 6.19348C5.17347 6.36654 5.07739 6.60119 5.07715 6.84593V15.1892C5.07747 15.4246 5.16993 15.6502 5.33426 15.8166C5.49859 15.9829 5.72135 16.0765 5.95369 16.0767H16.0628C16.2909 16.0764 16.5096 15.9844 16.6709 15.821C16.8322 15.6576 16.923 15.4361 16.9233 15.205V8.69208C16.9231 8.44734 16.827 8.2127 16.6561 8.03964C16.4853 7.86658 16.2537 7.76925 16.0121 7.76901ZM5.98839 6.84593H8.98908L9.90033 7.76901H5.98839V6.84593Z',
      fill: '#F4FBFE',
    }),
    jsx(
      'defs',
      null,
      jsx(
        'linearGradient',
        {
          id: 'paint0_linear_1556_42556',
          x1: '10.34',
          y1: '11.66',
          x2: '17.6',
          y2: '21.12',
          gradientUnits: 'userSpaceOnUse',
        },
        jsx('stop', {
          stopColor: '#F93545',
        }),
        jsx('stop', {
          offset: '1',
          stopColor: '#CE1625',
        })
      )
    )
  ),
  name: 'FolderAlt',
})
