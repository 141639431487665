/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '22',
  height: '22',
  viewBox: '0 0 22 22',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M8.66667 0.5H1.66667C1.35725 0.5 1.0605 0.622916 0.841709 0.841709C0.622916 1.0605 0.5 1.35725 0.5 1.66667V8.66667C0.5 8.97609 0.622916 9.27283 0.841709 9.49162C1.0605 9.71042 1.35725 9.83333 1.66667 9.83333H8.66667C8.97609 9.83333 9.27283 9.71042 9.49162 9.49162C9.71042 9.27283 9.83333 8.97609 9.83333 8.66667V1.66667C9.83333 1.35725 9.71042 1.0605 9.49162 0.841709C9.27283 0.622916 8.97609 0.5 8.66667 0.5ZM7.5 7.5H2.83333V2.83333H7.5V7.5ZM13.3333 9.83333H20.3333C20.6428 9.83333 20.9395 9.71042 21.1583 9.49162C21.3771 9.27283 21.5 8.97609 21.5 8.66667V1.66667C21.5 1.35725 21.3771 1.0605 21.1583 0.841709C20.9395 0.622916 20.6428 0.5 20.3333 0.5H13.3333C13.0239 0.5 12.7272 0.622916 12.5084 0.841709C12.2896 1.0605 12.1667 1.35725 12.1667 1.66667V8.66667C12.1667 8.97609 12.2896 9.27283 12.5084 9.49162C12.7272 9.71042 13.0239 9.83333 13.3333 9.83333ZM14.5 2.83333H19.1667V7.5H14.5V2.83333ZM0.5 20.3333C0.5 20.6428 0.622916 20.9395 0.841709 21.1583C1.0605 21.3771 1.35725 21.5 1.66667 21.5H8.66667C8.97609 21.5 9.27283 21.3771 9.49162 21.1583C9.71042 20.9395 9.83333 20.6428 9.83333 20.3333V13.3333C9.83333 13.0239 9.71042 12.7272 9.49162 12.5084C9.27283 12.2896 8.97609 12.1667 8.66667 12.1667H1.66667C1.35725 12.1667 1.0605 12.2896 0.841709 12.5084C0.622916 12.7272 0.5 13.0239 0.5 13.3333V20.3333ZM2.83333 14.5H7.5V19.1667H2.83333V14.5ZM12.1667 20.3333C12.1667 20.6428 12.2896 20.9395 12.5084 21.1583C12.7272 21.3771 13.0239 21.5 13.3333 21.5H20.3333C20.6428 21.5 20.9395 21.3771 21.1583 21.1583C21.3771 20.9395 21.5 20.6428 21.5 20.3333V13.3333C21.5 13.0239 21.3771 12.7272 21.1583 12.5084C20.9395 12.2896 20.6428 12.1667 20.3333 12.1667H13.3333C13.0239 12.1667 12.7272 12.2896 12.5084 12.5084C12.2896 12.7272 12.1667 13.0239 12.1667 13.3333V20.3333ZM14.5 14.5H19.1667V19.1667H14.5V14.5Z',
    fill: 'white',
  }),
  name: 'Grip',
})
