import { css } from '@emotion/core';
import { migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../Colors';
import { mediaQuery } from '../../utils';
const themes = {
  home: {
    alpha: migratoryWebCitizen.blackPearl,
    beta: migratoryWebCitizen.cloudBurst
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.blackPearl,
    beta: migratoryWebCitizen.cloudBurst
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.blackPearl,
    beta: sanitarianRegistersWeb.cloudBurst
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.white,
    beta: phytosanitaryWeb.white
  },
  tdCarWeb: {
    alpha: tdCarWeb.blackPearl,
    beta: tdCarWeb.cloudBurst
  }
};
export default (({
  theme = 'migratoryWebCitizen'
}) => css`
  background-color: ${themes[theme].alpha};
  ${mediaQuery[0]} {
    display: none;
  }

  figure {
    padding: 0;
    margin: 0;
  }

  .nav {
    width: 100%;
    height: ${theme === 'phytosanitaryWeb' ? '71' : '60'}px;
    padding: 8px 40px;
    box-sizing: border-box;
    ${theme === 'phytosanitaryWeb' ? 'box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05)' : ''}
    @media print {
      display: none;
    }
  }

  .nav-group {
    display: flex;
    align-items: center;
    height: 50px;
    float: left;
  }

  .nav-link {
    margin-right: 50px;
    height: 60%;
  }

  .link {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    color: #93b7be;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    line-height: 14px;
    letter-spacing: 0.01em;
    text-decoration: none;
    cursor: pointer;
  }

  .nav-link:nth-last-of-type(2) {
    margin-right: 0;
  }

  .nav-link-divider {
    width: 1px;
    height: 120%;
    background-color: ${themes[theme].beta};
    position: relative;
    top: -3px;
    margin: 0 25px;
  }

  .nav-icon {
    position: relative;
    top: -4px;
    margin: 0 42px;
    cursor: pointer;
  }

  .nav-icon:last-of-type {
    margin-left: 0;
  }

  .right {
    float: right;
  }

  .left {
    float: left;
  }
`);