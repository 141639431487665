import { css } from '@emotion/core';
import { mediaQuery } from '../../utils';
import { userWeb, phytosanitaryWeb, migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from '../Colors';
const themes = {
  home: {
    alpha: userWeb.aquaHaze,
    beta: userWeb.eastern,
    gamma: userWeb.valhalla,
    delta: userWeb.geyser,
    epsilon: userWeb.fireEngineRed,
    dzeta: userWeb.ziggurat,
    eta: userWeb.valhalla
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.antiFlashWhite,
    beta: phytosanitaryWeb.sherpaBlue,
    gamma: phytosanitaryWeb.sherpaBlue,
    delta: phytosanitaryWeb.lightSeaGreen,
    epsilon: phytosanitaryWeb.salem,
    dzeta: phytosanitaryWeb.morningGlory,
    eta: phytosanitaryWeb.valhalla
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.brightGray,
    beta: migratoryWebCitizen.cerulean,
    gamma: migratoryWebCitizen.valhalla,
    delta: migratoryWebCitizen.geyser,
    epsilon: migratoryWebCitizen.fireEngineRed,
    dzeta: migratoryWebCitizen.AzureishWhite,
    eta: migratoryWebCitizen.valhalla
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.brightGray,
    beta: sanitarianRegistersWeb.cerulean,
    gamma: sanitarianRegistersWeb.valhalla,
    delta: sanitarianRegistersWeb.geyser,
    epsilon: sanitarianRegistersWeb.fireEngineRed,
    dzeta: sanitarianRegistersWeb.AzureishWhite,
    eta: sanitarianRegistersWeb.valhalla
  },
  td: {
    alpha: tdCarWeb.brightGray,
    beta: tdCarWeb.cerulean,
    gamma: tdCarWeb.valhalla,
    delta: tdCarWeb.geyser,
    epsilon: tdCarWeb.fireEngineRed,
    dzeta: tdCarWeb.AzureishWhite,
    eta: tdCarWeb.valhalla
  }
};
export default (({
  theme = 'home'
}) => css`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: ${themes[theme].gamma};
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 10px;
  }

  .paragraph {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: ${themes[theme].eta};
  }

  .back {
    color: ${themes[theme].epsilon};
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 28px;

    .back-icon {
      color: ${themes[theme].epsilon};
      width: 28px;
      height: 24px;
      margin-right: 8px;
    }
  }

  .containerTab {
    min-height: 53px;
    background: ${themes[theme].alpha};

    .logo-container {
      padding: 23px 55px;
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        width: 162px;
        height: 80px;
      }
    }

    .options {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0;
      color: ${themes[theme].beta};

      ${mediaQuery[0]} {
        flex-direction: column;
        padding: 20px 0;
      }
    }

    .option {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      padding: 5px 32px;
      float: left;
      margin-right: calc(10px + (84 - 10) * ((100vw - 768px) / (1600 - 768)));
      border-radius: 20px;

      ${mediaQuery[0]} {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    .option:last-of-type {
      margin-right: 0;
      margin-bottom: 0;
    }

    .active {
      color: #fff;
      background-color: ${themes[theme].epsilon};
    }
  }

  .display {
    background-color: #fff;
    width: 100%;
    padding: 28px 52px;

    .hide {
      display: none;
    }
  }
`);