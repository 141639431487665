import { useState, useEffect, useRef } from 'react';
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon, FlagEnIcon, FlagEsIcon } from '../icon';
import { languageMenu, languageMenuNav, languageMenuMobile, languageMenuVertical } from '../styles/languageSelector';
/**
 * This component represents a Language Selector.
 * @param {string} chevronColor ChevronColor, default: '#FFFFFF'
 */

export const LanguageSelector = ({
  chevronColor = '#FFFFFF',
  i18n
}) => {
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  const menuOpenRef = useRef(null);
  menuOpenRef.current = isOpen;

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target) && (typeof event.target.className === 'object' || !event.target.className.includes('language-selector'))) {
      if (menuOpenRef.current) {
        setOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const currentLanguage = {
    es: {
      lng: '(ES)',
      flag: 'flag-es'
    },
    'es-CR': {
      lng: '(ES)',
      flag: 'flag-es'
    },
    en: {
      lng: '(EN)',
      flag: 'flag-eng'
    },
    'en-US': {
      lng: '(EN)',
      flag: 'flag-eng'
    }
  }[i18n.language] || {
    lng: '(ES)',
    flag: 'flag-es'
  };

  const toggleLanguagePicker = () => {
    setOpen(!isOpen);
  };

  const changeLanguage = language => {
    i18n.changeLanguage(language);
    toggleLanguagePicker();
  };

  return jsx("div", {
    css: languageMenu(isOpen),
    ref: wrapperRef
  }, jsx("div", {
    role: "presentation",
    onClick: toggleLanguagePicker,
    style: {
      cursor: 'pointer'
    },
    className: "language-selector"
  }, currentLanguage.flag === 'flag-es' ? jsx(FlagEsIcon, {
    css: {
      width: 23,
      height: 23
    }
  }) : jsx(FlagEnIcon, {
    css: {
      width: 23,
      height: 23
    }
  }), jsx(ChevronRightIcon, {
    css: {
      color: chevronColor
    },
    className: "menu-icon"
  }), jsx("span", {
    style: {
      display: 'block'
    }
  }, currentLanguage.lng)), jsx("div", {
    className: "menu-content"
  }, jsx("div", {
    role: "presentation",
    className: "menu-options",
    onClick: () => changeLanguage('es-CR')
  }, jsx(FlagEsIcon, {
    css: {
      width: 23,
      height: 23
    }
  }), " ", jsx("span", null, "Espa\xF1ol")), jsx("div", {
    role: "presentation",
    className: "menu-options",
    onClick: () => changeLanguage('en-US')
  }, jsx(FlagEnIcon, {
    css: {
      width: 23,
      height: 23
    }
  }), " ", jsx("span", null, "English"))));
};
/**
 * This component represents a Language Selector vertical.
 * @param {string} chevronColor ChevronColor, default: '#FFFFFF'
 */

export const LanguageSelectorVertical = ({
  chevronColor = '#FFFFFF'
}) => {
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  const menuOpenRef = useRef(null);
  menuOpenRef.current = isOpen;
  const {
    i18n
  } = useTranslation();

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target) && (typeof event.target.className === 'object' || !event.target.className.includes('language-selector'))) {
      if (menuOpenRef.current) {
        setOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const currentLanguage = {
    es: {
      lng: '(ES)',
      flag: 'flag-es'
    },
    'es-CR': {
      lng: '(ES)',
      flag: 'flag-es'
    },
    en: {
      lng: '(EN)',
      flag: 'flag-eng'
    },
    'en-US': {
      lng: '(EN)',
      flag: 'flag-eng'
    }
  }[i18n.language] || {
    lng: '(ES)',
    flag: 'flag-es'
  };

  const toggleLanguagePicker = () => {
    setOpen(!isOpen);
  };

  const changeLanguage = language => {
    i18n.changeLanguage(language);
    toggleLanguagePicker();
  };

  return jsx("div", {
    css: languageMenuVertical(isOpen),
    ref: wrapperRef
  }, jsx("div", {
    role: "presentation",
    onClick: toggleLanguagePicker,
    style: {
      cursor: 'pointer'
    },
    className: "language-selector"
  }, currentLanguage.flag === 'flag-es' ? jsx(FlagEsIcon, {
    css: {
      width: 23,
      height: 23
    }
  }) : jsx(FlagEnIcon, {
    css: {
      width: 23,
      height: 23
    }
  }), jsx("span", null, currentLanguage.lng), jsx(ChevronRightIcon, {
    css: {
      color: chevronColor,
      transform: 'rotate(90deg)'
    },
    className: "menu-icon"
  })), jsx("div", {
    className: "menu-content"
  }, jsx("div", {
    role: "presentation",
    className: "menu-options",
    onClick: () => changeLanguage('es-CR')
  }, jsx(FlagEsIcon, {
    css: {
      width: 23,
      height: 23
    }
  }), " ", jsx("span", null, "Espa\xF1ol")), jsx("div", {
    role: "presentation",
    className: "menu-options",
    onClick: () => changeLanguage('en-US')
  }, jsx(FlagEnIcon, {
    css: {
      width: 23,
      height: 23
    }
  }), " ", jsx("span", null, "English"))));
};
/**
 * This component represents a Language Selector nav.
 * @param {string} containerStyle Custom style for the nav.
 */

export const LanguageSelectorNav = ({
  containerStyle
}) => {
  const [isOpen, setOpen] = useState(false);
  const {
    i18n
  } = useTranslation();
  const currentLanguage = {
    es: {
      lng: '(ES)',
      flag: 'flag-es',
      text: 'Español'
    },
    'es-CR': {
      lng: '(ES)',
      flag: 'flag-es',
      text: 'Español'
    },
    en: {
      lng: '(EN)',
      flag: 'flag-eng',
      text: 'English'
    },
    'en-US': {
      lng: '(EN)',
      flag: 'flag-eng',
      text: 'English'
    }
  }[i18n.language] || {
    lng: '(ES)',
    flag: 'flag-es',
    text: 'Español'
  };

  const toggleLanguagePicker = () => {
    setOpen(!isOpen);
  };

  const changeLanguage = language => {
    i18n.changeLanguage(language);
    toggleLanguagePicker();
  };

  return jsx("div", {
    css: languageMenuNav(isOpen)
  }, jsx("div", {
    role: "presentation",
    onClick: toggleLanguagePicker,
    className: "menu"
  }, currentLanguage.flag === 'flag-es' ? jsx(FlagEsIcon, {
    css: {
      width: 23,
      height: 23
    }
  }) : jsx(FlagEnIcon, {
    css: {
      width: 23,
      height: 23
    }
  }), jsx("span", {
    className: "text-label"
  }, currentLanguage.text), jsx(ChevronRightIcon, {
    className: "menu-icon"
  })), jsx("div", {
    className: "menu-content",
    style: { ...containerStyle
    }
  }, jsx("div", {
    role: "presentation",
    className: "menu-options",
    onClick: () => changeLanguage('es-CR')
  }, jsx(FlagEsIcon, {
    css: {
      width: 23,
      height: 23
    }
  }), " ", jsx("span", null, "Espa\xF1ol")), jsx("div", {
    role: "presentation",
    className: "menu-options",
    onClick: () => changeLanguage('en-US')
  }, jsx(FlagEnIcon, {
    css: {
      width: 23,
      height: 23
    }
  }), " ", jsx("span", null, "English"))));
};
/**
 * This component represents a Language Selector Mobile.
 * @param {object} parentRef Parent refence.
 */

export const LanguageSelectorMobile = ({
  parentRef,
  i18n
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  const menuOpenRef = useRef(null);
  menuOpenRef.current = isOpen;

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target) && (typeof event.target.className === 'object' || !event.target.className.includes('language-selector'))) {
      if (menuOpenRef.current) {
        setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleLanguagePicker = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = language => {
    i18n.changeLanguage(language);
    toggleLanguagePicker();
    setIsOpen(false);
    parentRef.current.toggleMenu();
  };

  const currentLanguage = {
    'es-CR': {
      lng: 'Español',
      flag: 'flag-es'
    },
    'en-US': {
      lng: 'English',
      flag: 'flag-eng'
    }
  }[i18n.language] || {
    lng: '(ES)',
    flag: 'flag-es'
  };
  return jsx("div", {
    css: languageMenuMobile(isOpen),
    ref: wrapperRef
  }, jsx("div", {
    onClick: toggleLanguagePicker,
    role: "presentation",
    className: "language-selector"
  }, currentLanguage.flag === 'flag-es' ? jsx(FlagEsIcon, {
    css: {
      width: 23,
      height: 23
    }
  }) : jsx(FlagEnIcon, {
    css: {
      width: 23,
      height: 23
    }
  }), jsx("div", null, jsx("span", {
    className: "lng-span"
  }, currentLanguage.lng), jsx(ChevronRightIcon, {
    className: "menu-icon"
  }))), jsx("ul", {
    className: "menu-content"
  }, jsx("li", {
    onClick: () => changeLanguage('es-CR')
  }, jsx(FlagEsIcon, {
    css: {
      marginRight: 10,
      width: 23,
      height: 23
    }
  }), ' ', "Espa\xF1ol"), jsx("li", {
    onClick: () => changeLanguage('en-US')
  }, jsx(FlagEnIcon, {
    css: {
      marginRight: 10,
      width: 23,
      height: 23
    }
  }), ' ', "English")));
};