/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M4.18667 15.9599L7.95733 19.7292C7.71134 19.9839 7.41709 20.1871 7.09174 20.3268C6.7664 20.4666 6.41648 20.5402 6.0624 20.5432C5.70833 20.5463 5.35718 20.4788 5.02946 20.3448C4.70173 20.2107 4.40399 20.0127 4.15361 19.7623C3.90323 19.5119 3.70522 19.2142 3.57114 18.8864C3.43706 18.5587 3.36959 18.2076 3.37267 17.8535C3.37574 17.4994 3.44931 17.1495 3.58906 16.8241C3.72882 16.4988 3.93197 16.2046 4.18667 15.9586V15.9599ZM16.0093 23.0586L15.0667 23.9999L0 8.93323L0.941333 7.99056L2.41067 8.19989C3.416 8.34389 4.664 8.03723 5.18 7.5199L10.36 2.34123C11.8633 0.866184 13.8882 0.0442928 15.9943 0.0542894C18.1004 0.0642859 20.1174 0.905362 21.6066 2.39461C23.0959 3.88386 23.9369 5.90085 23.9469 8.00694C23.9569 10.113 23.135 12.1379 21.66 13.6412L16.48 18.8226C15.9627 19.3399 15.656 20.5866 15.8 21.5919L16.0093 23.0586Z',
    fill: 'white',
  }),
  name: 'NotificationsTilted',
})
