function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import ScrollBar from 'react-scrollbars-custom';
import { useTheme } from 'emotion-theming';
import { phytosanitaryWeb, migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from '../Colors';
import useMediaQuery from '../../hooks/use-media-query';
import { mediaQuery } from '../../utils';
import styles from './BoxList.style';
const themes = {
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.fireEngineRed,
    beta: migratoryWebCitizen.fairPink
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.fireEngineRed,
    beta: sanitarianRegistersWeb.fairPink
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.sherpaBlue,
    beta: phytosanitaryWeb.riceFlower
  },
  tdCarWeb: {
    alpha: tdCarWeb.fireEngineRed,
    beta: tdCarWeb.fairPink
  }
};
/**
 * This component represent the box li
 * @param {*} children Components that will be display in the li tag.
 * @param {*} props All other supported properties.
 */

export function BoxLi({
  children,
  ...props
}) {
  return jsx("li", props, children);
}
/**
 * This component represent the box list
 * @param {*} children Components that will be display inside the box list.
 * @param {*} scrollBarStyle Custom style for the ScrollBar.
 * @param {*} dataTestId Id for test data.
 */

export function BoxList({
  children,
  scrollBarStyle,
  dataTestId = ''
}) {
  const {
    theme
  } = useTheme();
  const {
    isMobile
  } = useMediaQuery();

  if (isMobile) {
    return jsx("ul", {
      css: styles
    }, children);
  }

  return jsx(ScrollBar, {
    style: {
      minHeight: '400px',
      ...scrollBarStyle
    },
    noScrollX: true,
    wrapperProps: {
      renderer: props => {
        const {
          elementRef,
          style,
          ...restProps
        } = props;
        return jsx("div", _extends({}, restProps, {
          ref: elementRef,
          css: { ...style,
            marginRight: 15,
            [mediaQuery[0]]: {
              marginRight: 0
            }
          }
        }));
      }
    },
    trackYProps: {
      renderer: props => {
        const {
          elementRef,
          style,
          ...restProps
        } = props;
        return jsx("span", _extends({}, restProps, {
          style: { ...style,
            backgroundColor: themes[theme].beta
          },
          ref: elementRef
        }));
      }
    },
    thumbYProps: {
      renderer: props => {
        const {
          elementRef,
          ...restProps
        } = props;
        return jsx("div", _extends({}, restProps, {
          style: {
            backgroundColor: themes[theme].alpha
          },
          ref: elementRef
        }));
      }
    }
  }, jsx("ul", {
    "data-testid": dataTestId,
    css: styles
  }, children));
}
export function BoxListWithoutScroll({
  children,
  scrollBarStyle,
  dataTestId = '',
  ...ulProps
}) {
  const {
    isMobile
  } = useMediaQuery();

  if (isMobile) {
    return jsx("ul", {
      css: styles
    }, children);
  }

  return jsx("ul", _extends({
    "data-testid": dataTestId,
    css: styles
  }, ulProps), children);
}
/**
 * This component represent the box list Skeleton
 * @param {*} style custom style assigned to the ul tag.
 */

export function BoxListSkeleton({
  style,
  liCss,
  liQuantity = 2
}) {
  const lis = [];

  for (let i = 0; i < liQuantity; i++) {
    lis.push(jsx("li", {
      key: i,
      css: { ...liCss
      }
    }, jsx("div", {
      className: "skeleton"
    })));
  }

  return jsx("ul", {
    css: styles,
    style: { ...style
    }
  }, lis);
}