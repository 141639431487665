import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '59',
  height: '42',
  viewBox: '0 0 59 42',
  css: css({
    path: {
      stroke: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('rect', {
      x: '5.61212',
      y: '8.33146',
      width: '47.4461',
      height: '32.5396',
      rx: '1.5',
      transform: 'rotate(-9.40891 5.61212 8.33146)',
      stroke: '#08415C',
    }),
    jsx('path', {
      d: 'M44.7195 7.46102H24.8454L18.382 2.61533C17.7376 2.13406 16.9554 1.8731 16.1511 1.87109H3.72662C2.73856 1.87207 1.79124 2.26501 1.09258 2.96367C0.393913 3.66234 0.000974885 4.60965 0 5.59771V37.274C0.0009755 38.2621 0.393914 39.2094 1.09258 39.908C1.79125 40.6067 2.73856 40.9996 3.72662 41.0006H44.9269C45.8598 40.9993 46.7542 40.6281 47.4139 39.9684C48.0736 39.3087 48.4448 38.4144 48.4461 37.4814V11.1876C48.4451 10.1996 48.0522 9.25227 47.3535 8.5536C46.6548 7.85494 45.7075 7.462 44.7195 7.46102Z',
      fill: '#08415C',
    })
  ),
  name: 'FolderFile',
})
