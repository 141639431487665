/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '40',
  height: '40',
  viewBox: '0 0 40 40',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M34.2857 17.4286C37.4416 17.4286 40 14.8702 40 11.7143C40 8.55837 37.4416 6 34.2857 6C31.1298 6 28.5714 8.55837 28.5714 11.7143C28.5714 14.8702 31.1298 17.4286 34.2857 17.4286ZM3 9.42857H26.617C26.4015 10.1528 26.2857 10.92 26.2857 11.7143C26.2857 13.5442 26.9001 15.2307 27.9339 16.5786L19.4287 22.2487L5.66557 13.0733C4.99597 12.6269 4.09128 12.8078 3.64488 13.4774C3.19848 14.147 3.37942 15.0517 4.04902 15.4981L18.0816 24.8531C18.8973 25.397 19.9601 25.397 20.7759 24.8531L30.1815 18.5827C31.3815 19.3013 32.7854 19.7143 34.2857 19.7143C35.9854 19.7143 37.5613 19.1842 38.8571 18.2804V30.7143C38.8571 32.3711 37.514 33.7143 35.8571 33.7143H3C1.34314 33.7143 0 32.3711 0 30.7143V12.4286C0 10.7717 1.34315 9.42857 3 9.42857Z',
    fill: 'white',
  }),
  name: 'NewMail',
})
