import { css, keyframes } from '@emotion/core';
import { phytosanitaryWeb, migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from '../../Colors';
const themes = {
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.antiFlashWhite,
    beta: phytosanitaryWeb.allports,
    gama: phytosanitaryWeb.sherpaBlue,
    delta: phytosanitaryWeb.flamingo
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.brightGray,
    beta: migratoryWebCitizen.cerulean,
    gama: migratoryWebCitizen.blackPearl,
    delta: migratoryWebCitizen.fireEngineRed
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.brightGray,
    beta: sanitarianRegistersWeb.cerulean,
    gama: sanitarianRegistersWeb.blackPearl,
    delta: sanitarianRegistersWeb.fireEngineRed
  },
  tdCarWeb: {
    alpha: tdCarWeb.brightGray,
    beta: tdCarWeb.cerulean,
    gama: tdCarWeb.blackPearl,
    delta: tdCarWeb.fireEngineRed
  }
}; // TODO agregar los colores para usar temas

const shimmer = keyframes`
  100% {
    transform: translateX(100%)
  }
`;
export default (({
  theme = 'migratoryWebCitizen'
}) => css`
  background-color: ${themes[theme].alpha};
  border-radius: 20px;
  margin-bottom: 16px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  text-align: -moz-center;
  text-align: -webkit-center;

  .procedure-container {
    display: flex;
    flex-direction: column;
  }

  .procedure-id {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    margin-bottom: 10px;
    color: #0f7cb5;
  }

  .procedure-id-div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .procedure-name {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 10px;
    color: ${themes[theme].gama};
  }

  .container-detail {
    display: flex;
    flex-direction: column;
    margin-bottom: 13px;
    justify-content: center;
  }

  .procedure-detail-item {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #333333;
  }

  .container-state {
    display: inline-block;
  }

  .procedure-state-item {
    font-style: normal;
    font-weight: 500px;
    font-size: 16px;
    line-height: 20px;
  }

  .skeleton {
    display: inline-block;
    height: 20px;
    position: relative;
    background-color: #d4e4ed;
    overflow: hidden;
    width: 100%;
  }

  .skeleton {
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%
      );
      animation: ${shimmer} 2s infinite;
      content: '';
    }
  }

  .procedure-info-div {
    span {
      display: block;
      color: ${themes[theme].beta};
    }
  }

  .button-procedure-container {
    flex-direction: column;
    justify-content: space-evenly;

    button {
      margin-bottom: 10px;

      :last-of-type {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .procedure-info-div {
      span {
        display: unset;
      }
    }

    .procedure-name {
      font-size: 25px;
    }

    .button-procedure-container {
      flex-direction: row;
    }
  }
`);