/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { queryCache, useMutation, useQuery } from 'react-query';
import SpinnerSmall from '../../SpinnerSmall';
import { FileUploaderProvider, Uploader } from './FileUploader';
import style from './fileUploader.style';
import { DeleteIcon, FileIcon } from '../../../icon';
import useMediaQuery from '../../../hooks/use-media-query';
import { mediaQuery } from '../../../utils';
import { getToken, getCSRFToken } from '../../../utils/api-client';
const {
  REACT_APP_CDN_URL
} = process.env;
/**
 * This function is used to delete a file.
 * @param {function} deleteItemUploaded Function that deletes the file.
 * @param {File} file File.
 * @param {boolean} isPendingDeleteItem Used to represent the moment a file is being delete.
 */

export const DeleteFile = ({
  deleteItemUploaded,
  file,
  isPendingDeleteItem
}) => {
  const {
    name,
    id
  } = file;
  let fileName = name;

  if (fileName.length > 23) {
    fileName = `${fileName.substring(0, 20)}...`;
  }

  return jsx("div", {
    css: style,
    style: {
      marginBottom: 10
    }
  }, jsx("div", {
    className: "done"
  }, jsx("div", {
    className: "row",
    css: {
      padding: '22px 28px',
      [mediaQuery[0]]: {
        flexDirection: 'row !important'
      }
    }
  }, jsx("div", {
    className: "column",
    style: {
      flexDirection: 'row'
    }
  }, jsx(FileIcon, {
    className: "file-icon"
  }), jsx("span", {
    className: "file-selected"
  }, fileName)), jsx("div", {
    className: "column",
    style: {
      flexDirection: 'row'
    }
  }, !isPendingDeleteItem ? jsx("button", {
    type: "button",
    onClick: () => deleteItemUploaded(id),
    style: {
      justifyContent: 'flex-end'
    }
  }, jsx(DeleteIcon, {
    className: "delete-icon"
  })) : jsx("div", {
    style: {
      justifyContent: 'flex-end',
      display: 'flex',
      width: '100%'
    }
  }, jsx(SpinnerSmall, {
    style: {
      marginLeft: 5,
      width: 20,
      height: 20
    }
  }))))));
};
/**
 * This component represent a uploader of multiple files.
 * @param {Array} extensions Array of accepted extensions.
 * @param {function} t Translate.
 * @param {boolean} isVisible Determine if the files are visible or not.
 */

export default function MultiFileUploader({
  extensions,
  t,
  isVisible
}) {
  const {
    data: files
  } = useQuery('files', () => {
    return [];
  });
  const {
    isMobile
  } = useMediaQuery();
  React.useEffect(() => {
    if (!isVisible) {
      queryCache.setQueryData('files', []);
    }
  }, [isVisible]);
  const uploadItem = React.useCallback(({
    fileName,
    path
  }) => {
    const newFile = {
      id: path.replace(/^.*[\\\\/]/, ''),
      name: fileName.split('.')[0],
      path
    };
    queryCache.setQueryData('files', old => {
      if (old === null || old === void 0 ? void 0 : old.length) {
        return [...old, newFile];
      }

      return [newFile];
    });
  }, []);
  const [deleteItemUploaded, {
    isLoading: isPendingDeleteItem
  }] = useMutation(async id => {
    const token = getToken();
    const csrfToken = await getCSRFToken();
    const headers = {
      'X-CSRF-Token': csrfToken
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    return window.fetch(`${REACT_APP_CDN_URL}/${id}`, {
      method: 'DELETE',
      headers,
      credentials: 'include'
    }).then(r => r.json());
  }, {
    onMutate: removedFileId => {
      const previousFiles = queryCache.getQueryData('files');
      queryCache.setQueryData('files', old => old.filter(file => file.id !== removedFileId));
      return () => queryCache.setQueryData('files', previousFiles);
    }
  });
  return jsx("div", null, jsx(FileUploaderProvider, {
    onFinish: uploadItem,
    serverPath: `${process.env.REACT_APP_CDN_URL}/upload`,
    clearOnFinish: true,
    t: t
  }, jsx(Uploader, {
    accept: extensions.join(','),
    text: t('Subir un archivo'),
    containerStyle: {
      width: isMobile ? '100%' : 194,
      height: isMobile ? 60 : 81
    }
  })), jsx("div", {
    css: {
      marginTop: 29,
      width: '70%',
      [mediaQuery[0]]: {
        width: '100%',
        marginTop: 10
      }
    }
  }, files === null || files === void 0 ? void 0 : files.map(file => jsx(DeleteFile, {
    key: file.id,
    deleteItemUploaded: deleteItemUploaded,
    file: file,
    isPendingDeleteItem: isPendingDeleteItem
  }))));
}