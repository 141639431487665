import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M15.2726 2.142C15.1629 1.818 14.858 1.5996 14.5146 1.5996C14.4834 1.5996 11.3251 1.582 8.36057 0.0852C8.13406 -0.0284 7.86594 -0.0284 7.63943 0.0852C4.68927 1.5748 1.5158 1.5996 1.48458 1.5996C1.14202 1.6004 0.837083 1.818 0.727433 2.142C-0.366674 5.3668 -0.224208 8.402 1.13882 10.9188C2.45703 13.3516 4.94699 15.2412 7.80111 15.974C7.86674 15.9908 7.93317 15.9996 8.0004 15.9996C8.06683 15.9996 8.13406 15.9908 8.19889 15.974C11.053 15.2412 13.543 13.3516 14.8612 10.9188C16.2242 8.402 16.3667 5.3668 15.2726 2.142ZM9.83522 5.8337L7.1996 8.4681L6.16472 7.4337C5.85178 7.1209 5.34594 7.1209 5.033 7.4337C4.72005 7.7465 4.72005 8.2521 5.033 8.5649L6.63374 10.1649C6.78981 10.3209 6.99471 10.3993 7.1996 10.3993C7.40449 10.3993 7.60939 10.3209 7.76546 10.1649L10.9669 6.9649C11.2799 6.6521 11.2799 6.1465 10.9669 5.8337C10.654 5.5209 10.1482 5.5209 9.83522 5.8337ZM13.4533 10.1571C12.3744 12.1491 10.3463 13.7123 8.00038 14.3715C5.65369 13.7123 3.62555 12.1491 2.54665 10.1571C1.47175 8.17394 1.31328 5.76914 2.07843 3.17554C3.16694 3.10514 5.58486 2.82034 8.00038 1.68914C10.4151 2.82034 12.833 3.10514 13.9215 3.17554C14.6875 5.76914 14.5282 8.17394 13.4533 10.1571Z',
      fill: '#08415C',
    })
  ),
  name: 'ShieldCheck',
})
