import { css, keyframes } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { phytosanitaryWeb, migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from '../Colors';
import { mediaQuery } from '../../utils';
const shimmer = keyframes`
  100% {
    transform: translateX(100%)
  }
`;
const themes = {
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.allports
    /** #156472 */
    ,
    beta: phytosanitaryWeb.white
    /** #FFFFFF */
    ,
    gamma: phytosanitaryWeb.darkCharcoal
    /** #313131 */
    ,
    delta: phytosanitaryWeb.lightSeaGreen
    /** #38B4A6 */

  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.cerulean
    /** #0F7CB5 */
    ,
    beta: migratoryWebCitizen.white
    /** #FFFFFF */
    ,
    gamma: migratoryWebCitizen.darkCharcoal
    /** #313131 */
    ,
    delta: migratoryWebCitizen.cerulean
    /** #0F7CB5 */

  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.cerulean
    /** #0F7CB5 */
    ,
    beta: sanitarianRegistersWeb.white
    /** #FFFFFF */
    ,
    gamma: sanitarianRegistersWeb.darkCharcoal
    /** #313131 */
    ,
    delta: sanitarianRegistersWeb.cerulean
    /** #0F7CB5 */

  },
  tdCarWeb: {
    alpha: tdCarWeb.cerulean
    /** #0F7CB5 */
    ,
    beta: tdCarWeb.white
    /** #FFFFFF */
    ,
    gamma: tdCarWeb.darkCharcoal
    /** #313131 */
    ,
    delta: tdCarWeb.cerulean
    /** #0F7CB5 */

  }
};

const widths = () => {
  const styles = {};

  for (let i = 0; i < 100; i++) {
    styles[`.w-${i}`] = {
      width: `${i}%`
    };
  }

  return styles;
};

export default (() => {
  const {
    theme = 'migratoryWebCitizen'
  } = useTheme();
  return css`
    width: 100%;

    .thead {
      width: 100%;
      padding: 13px 35px;
      display: flex;
      box-sizing: border-box;
      font-size: 14px;
      color: ${themes[theme].alpha};
      ${mediaQuery[0]} {
        font-size: 11px;
      }
    }

    .tbody {
      width: 100%;
    }

    .trow {
      background-color: ${themes[theme].beta};
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 20px 35px;
      margin-bottom: 14px;
      font-size: 16px;
      color: ${themes[theme].gamma};
      ${mediaQuery[0]} {
        font-size: 12px;
      }

      :last-of-type {
        border-bottom: 0;
      }
    }

    .tcell,
    .tcell-icon {
      font-style: normal;
      font-weight: normal;
      overflow-wrap: break-word;
      white-space: pre-line;
    }

    .tcell-icon {
      display: flex;
    }

    .tcell-icon-inner {
      justify-content: center;
      align-items: center;
    }

    .tcell-link {
      font-style: normal;
      font-weight: normal;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 14px;
      color: ${themes[theme].delta};
      ${mediaQuery[0]} {
        font-size: 11px;
      }

      span {
        display: inline-flex;
        flex-direction: column;
        .underline {
          border-top: 1px solid ${themes[theme].delta};
          width: 100%;
          display: inline-block;
        }
      }
    }

    .tcell-skeleton {
      display: inline-block;
      height: 20px;
      position: relative;
      background-color: #d4e4ed;
      overflow: hidden;
      width: 70%;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 0.2) 20%,
          rgba(255, 255, 255, 0.5) 60%
        );
        animation: ${shimmer} 2s infinite;
        content: '';
      }
    }

    .tbody-empty {
      text-align: center;
      margin-top: 10px;
    }

    .pointer {
      cursor: pointer;
    }

    ${widths()}
  `;
});