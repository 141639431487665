import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: 'null',
  height: 'null',
  viewBox: '0 0 22 22',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx(
      'g',
      null,
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M7.92138 6.92737C7.92138 5.20573 9.31706 3.81006 11.0387 3.81006C12.7604 3.81006 14.156 5.20573 14.156 6.92737V9.34079H7.92138V6.92737ZM6.11133 9.34079V6.92737C6.11133 4.20606 8.31739 2 11.0387 2C13.76 2 15.9661 4.20606 15.9661 6.92737V9.34079H16.8771C17.4294 9.34079 17.8771 9.78851 17.8771 10.3408V19C17.8771 19.5523 17.4294 20 16.8771 20H5C4.44771 20 4 19.5523 4 19V10.3408C4 9.78851 4.44772 9.34079 5 9.34079H6.11133Z',
        fill: 'white',
      })
    )
  ),
  name: 'Lock',
})
