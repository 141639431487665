import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '15',
  height: '17',
  viewBox: '0 0 15 17',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx(
      'g',
      null,
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M13.875 5.875H10.375V0.625H5.125V5.875H1.625L7.75 12.875L13.875 5.875ZM0.75 14.625H14.75V16.375H0.75V14.625Z',
        fill: 'white',
      })
    )
  ),
  name: 'Download',
})
