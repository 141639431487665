/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import Rodal from 'rodal';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'emotion-theming';
import { home, migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../../Colors';
import useMediaQuery from '../../../hooks/use-media-query';
import { ExclamationTriangleIcon } from '../../../icon';
import { Button } from '../../../core';
import { DivRow, DivColumn } from '../InputsUI';
import styles from './modalStyle';
import modalCustomStyle from './utils';
const themes = {
  home: {
    alpha: home.cerulean,
    beta: home.prussianBlue,
    gamma: home.cerulean,
    delta: home.fireEngineRed,
    epsilon: home.white
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.cerulean,
    beta: migratoryWebCitizen.prussianBlue,
    gamma: migratoryWebCitizen.cerulean,
    delta: migratoryWebCitizen.fireEngineRed,
    epsilon: migratoryWebCitizen.white
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.cerulean,
    beta: sanitarianRegistersWeb.prussianBlue,
    gamma: sanitarianRegistersWeb.deepCerulean,
    delta: sanitarianRegistersWeb.fireEngineRed,
    epsilon: sanitarianRegistersWeb.white
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.lightSeaGreen,
    beta: phytosanitaryWeb.allports,
    gamma: phytosanitaryWeb.mantis,
    delta: phytosanitaryWeb.mantis,
    epsilon: phytosanitaryWeb.sherpaBlue
  },
  tdCarWeb: {
    alpha: tdCarWeb.cerulean,
    beta: tdCarWeb.prussianBlue,
    gamma: tdCarWeb.cerulean,
    delta: tdCarWeb.fireEngineRed,
    epsilon: tdCarWeb.white
  }
};
/**
 * this component represents modal when a presentation is going to be deleted
 * @param {boolean} isVisible Determines if the modal is visible or not.
 * @param {function} setIsVisible Function triggered when close the modal to hide it.
 * @param {function} removePresentation remove a presentation from the list
 */

export function DeletePresentation({
  isVisible,
  setIsVisible,
  removePresentation
}) {
  const {
    theme
  } = useTheme();
  const {
    t
  } = useTranslation();
  const {
    isMobile
  } = useMediaQuery();
  return jsx(Rodal, {
    visible: isVisible,
    showCloseButton: false,
    width: 781,
    height: 255,
    closeOnEsc: true,
    onClose: () => {},
    customStyles: (modalCustomStyle({
      isMobile,
      width: 781,
      height: 255
    }), {
      borderRadius: 10
    })
  }, jsx("div", {
    css: [styles, {
      paddingTop: 48
    }]
  }, jsx(DivRow, {
    className: "modals-row"
  }, jsx(DivColumn, {
    className: "modals-column"
  }, jsx(ExclamationTriangleIcon, {
    className: "icon",
    css: {
      color: themes[theme].alpha
    }
  })), jsx(DivColumn, {
    className: "modals-column"
  }, jsx("h1", {
    className: "modals-title",
    style: {
      color: themes[theme].beta
    }
  }, t('Eliminar presentación')), jsx("p", {
    className: "modals-description"
  }, t('Está a punto de eliminar una cantidad de su trámite ¿Está seguro que desea eliminarla?')))), jsx("div", {
    className: "button-container"
  }, jsx(Button, {
    size: "md-icon",
    outline: true,
    className: "button blue",
    style: {
      color: themes[theme].gamma,
      border: `2px solid ${themes[theme].gamma}`,
      width: 150
    },
    onClick: setIsVisible
  }, t('Cancelar')), jsx(Button, {
    size: "md-icon",
    className: "button red",
    style: {
      background: themes[theme].delta,
      color: themes[theme].epsilon
    },
    onClick: removePresentation
  }, t('Eliminar')))));
}
/**
 * this component represents modal when presentation with different unit of measure is added
 * @param {boolean} isVisible Determines if the modal is visible or not.
 * @param {function} setIsVisible Function triggered when close the modal to hide it.
 */

export function UseSamePresentation({
  isVisible,
  setIsVisible
}) {
  const {
    theme
  } = useTheme();
  const {
    t
  } = useTranslation();
  const {
    isMobile
  } = useMediaQuery();
  return jsx(Rodal, {
    visible: isVisible,
    showCloseButton: false,
    width: 781,
    height: 255,
    closeOnEsc: true,
    onClose: () => {},
    customStyles: (modalCustomStyle({
      isMobile,
      width: 781,
      height: 255
    }), {
      borderRadius: 10
    })
  }, jsx("div", {
    css: [styles, {
      paddingTop: 48
    }]
  }, jsx(DivRow, {
    className: "modals-row"
  }, jsx(DivColumn, {
    className: "modals-column"
  }, jsx(ExclamationTriangleIcon, {
    className: "icon",
    css: {
      color: themes[theme].alpha
    }
  })), jsx(DivColumn, {
    className: "modals-column"
  }, jsx("h1", {
    className: "modals-title",
    style: {
      color: themes[theme].beta
    }
  }, t('Utilice la misma unidad de medida')), jsx("p", {
    className: "modals-description"
  }, t('Si ya agregó una presentación a la lista, debe utilizar la misma unidad de medida para agregar una nueva')))), jsx("div", {
    className: "button-container"
  }, jsx(Button, {
    size: "md-icon",
    className: "button red",
    style: {
      background: themes[theme].delta,
      color: themes[theme].epsilon
    },
    onClick: setIsVisible
  }, t('Aceptar')))));
}