import { css } from '@emotion/core';
import { phytosanitaryWeb, migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from '../../Colors';
import { mediaQuery } from '../../../utils';
const themes = {
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.antiFlashWhite,
    beta: phytosanitaryWeb.sherpaBlue,
    gamma: phytosanitaryWeb.sherpaBlue,
    delta: phytosanitaryWeb.sherpaBlue,
    epsilon: phytosanitaryWeb.sherpaBlue,
    dseta: phytosanitaryWeb.prussianBlue
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.brightGray,
    beta: migratoryWebCitizen.pacificBlue,
    gamma: migratoryWebCitizen.blackPearl,
    delta: migratoryWebCitizen.blackPearl,
    epsilon: migratoryWebCitizen.blackPearl,
    dseta: migratoryWebCitizen.prussianBlue
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.brightGray,
    beta: sanitarianRegistersWeb.pacificBlue,
    gamma: sanitarianRegistersWeb.blackPearl,
    delta: sanitarianRegistersWeb.blackPearl,
    epsilon: sanitarianRegistersWeb.blackPearl,
    dseta: sanitarianRegistersWeb.prussianBlue
  },
  tdCarWeb: {
    alpha: tdCarWeb.brightGray,
    beta: tdCarWeb.pacificBlue,
    gamma: tdCarWeb.blackPearl,
    delta: tdCarWeb.blackPearl,
    epsilon: tdCarWeb.blackPearl,
    dseta: tdCarWeb.prussianBlue
  }
};
export default function ({
  theme = 'migratoryWebCitizen'
}) {
  return css`
    background-color: ${themes[theme].alpha};
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 11px 50px 23px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    ${mediaQuery[0]} {
      padding: 15px 7px !important;
    }

    .container {
      margin-left: 35px;
      display: flex;
      flex-direction: column;
      ${mediaQuery[0]} {
        margin-left: 8px;
      }

      .role {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: ${themes[theme].beta};
        text-transform: uppercase;
      }

      .name {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        color: ${themes[theme].dseta};
      }

      .id {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: ${themes[theme].epsilon};
      }

      .item {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: ${themes[theme].delta};
      }
    }

    @media screen and (min-width: 768px) {
      .container {
        .role {
          font-size: 20px;
        }

        .name {
          font-size: 35px;
        }

        .id {
          font-size: 14px;
        }

        .item {
          font-size: 14px;
        }
      }
    }
  `;
}