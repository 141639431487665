import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M7.9375 9.75C8.40163 9.75 8.84675 9.56563 9.17494 9.23744C9.50313 8.90925 9.6875 8.46413 9.6875 8C9.6875 7.94875 9.68516 7.89797 9.68078 7.84782L7.78531 9.74328C7.83547 9.74766 7.88609 9.75 7.9375 9.75ZM13.73 2.58688L13.0625 1.92C13.0391 1.89658 13.0073 1.88342 12.9741 1.88342C12.941 1.88342 12.9092 1.89658 12.8858 1.92L11.1777 3.6286C10.2354 3.14703 9.17615 2.90625 8 2.90625C4.99687 2.90625 2.75625 4.47032 1.27812 7.59844C1.21871 7.72425 1.1879 7.86165 1.1879 8.00078C1.1879 8.13992 1.21871 8.27732 1.27812 8.40313C1.86875 9.64719 2.57995 10.6443 3.41172 11.3945L1.75875 13.0469C1.73532 13.0703 1.72217 13.1021 1.72217 13.1352C1.72217 13.1684 1.73532 13.2002 1.75875 13.2236L2.42578 13.8906C2.44922 13.9141 2.481 13.9272 2.51414 13.9272C2.54728 13.9272 2.57906 13.9141 2.6025 13.8906L13.73 2.76375C13.7416 2.75214 13.7508 2.73836 13.7571 2.72318C13.7634 2.70801 13.7667 2.69174 13.7667 2.67532C13.7667 2.65889 13.7634 2.64262 13.7571 2.62745C13.7508 2.61227 13.7416 2.59849 13.73 2.58688ZM5.1875 8C5.18746 7.52501 5.31044 7.05811 5.54448 6.64478C5.77852 6.23144 6.11562 5.88578 6.52296 5.64145C6.93029 5.39713 7.39397 5.26248 7.86881 5.25061C8.34365 5.23875 8.81347 5.35008 9.2325 5.57375L8.47281 6.33344C8.16644 6.23534 7.83896 6.22353 7.52631 6.29929C7.21367 6.37506 6.92793 6.53548 6.70045 6.76296C6.47298 6.99043 6.31256 7.27617 6.23679 7.58882C6.16102 7.90146 6.17284 8.22894 6.27094 8.53532L5.51125 9.295C5.29806 8.89669 5.18684 8.45178 5.1875 8V8Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M14.7219 7.59688C14.1719 6.43854 13.5172 5.49401 12.758 4.76328L10.5058 7.01563C10.6956 7.5119 10.7377 8.05253 10.6269 8.5722C10.5162 9.09188 10.2573 9.56835 9.88157 9.94407C9.50585 10.3198 9.02938 10.5787 8.5097 10.6894C7.99003 10.8002 7.4494 10.7581 6.95313 10.5683L5.04282 12.4786C5.92907 12.8887 6.9148 13.0938 8 13.0938C11.0031 13.0938 13.2438 11.5297 14.7219 8.40157C14.7813 8.27576 14.8121 8.13836 14.8121 7.99922C14.8121 7.86009 14.7813 7.72268 14.7219 7.59688V7.59688Z',
      fill: 'white',
    })
  ),
  name: 'EyeSlash',
})
