/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '21',
  height: '21',
  viewBox: '0 0 21 21',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M9.32563 16.6658C9.78557 17.1257 9.79225 17.8647 9.33347 18.3235C8.87786 18.7791 8.1449 18.7848 7.67873 18.3187L0.346737 10.9866C-0.111542 10.5284 -0.119434 9.79322 0.346737 9.32705L7.67873 1.99501C8.13701 1.53673 8.87468 1.53139 9.33347 1.99018C9.78908 2.44579 9.79064 3.18292 9.32563 3.64793L3.98342 8.99018L19.8304 8.99017C20.4764 8.99017 21.0001 9.50802 21.0001 10.1568C21.0001 10.8012 20.4721 11.3235 19.8304 11.3235L3.98342 11.3235L9.32563 16.6658Z',
    fill: 'white',
  }),
  name: 'ArrowLeft',
})
