import React from 'react'
export default function useMediaQuery() {
  const [width, setWidth] = React.useState(window.innerWidth)
  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth)
    })
    return () => {
      window.removeEventListener('resize', () => {})
    }
  }, [])
  return {
    isMobile: width <= 768,
  }
}
