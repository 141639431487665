/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '23',
  height: '23',
  viewBox: '0 0 23 23',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M18.4 0H4.6V5.75H18.4V0ZM0 18.4V6.9H23V18.4H18.4V23H4.6V18.4H0ZM6.9 13.8V20.7H16.1V13.8H6.9ZM2.3 9.2V11.5H4.6V9.2H2.3ZM6.9 11.5V9.2H9.2V11.5H6.9Z',
    fill: 'white',
  }),
  name: 'Print',
})
