/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M15.1215 2.87884C13.5506 1.30801 11.3948 0.333008 9.00065 0.333008C6.70211 0.333008 4.49771 1.2461 2.87239 2.87142C1.24708 4.49673 0.333984 6.70113 0.333984 8.99967C0.333984 11.2982 1.24708 13.5026 2.87239 15.1279C4.49771 16.7532 6.70211 17.6663 9.00065 17.6663C13.0415 17.6663 16.4106 14.9038 17.3748 11.1663H15.1215C14.2331 13.6905 11.8282 15.4997 9.00065 15.4997C7.27674 15.4997 5.62344 14.8149 4.40446 13.5959C3.18547 12.3769 2.50065 10.7236 2.50065 8.99967C2.50065 7.27577 3.18547 5.62247 4.40446 4.40348C5.62344 3.18449 7.27674 2.49967 9.00065 2.49967C10.799 2.49967 12.4023 3.24717 13.5723 4.42801L10.084 7.91634H17.6673V0.333008L15.1215 2.87884Z',
    fill: 'white',
  }),
  name: 'Refresh',
})
