import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '16',
  height: '20',
  viewBox: '0 0 16 20',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx(
      'g',
      null,
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M13.4546 18.7272V7.81813C13.4546 7.16358 13.0182 6.72722 12.3636 6.72722H9.09092V0.181763L2.54547 2.36358V18.7272H0.363647V19.8181H15.6364V18.7272H13.4546ZM6.9091 18.7272H4.72728V4.5454H6.9091V18.7272Z',
        fill: 'white',
      })
    )
  ),
  name: 'Institution',
})
