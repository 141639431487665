import { css } from '@emotion/core'

export const languageMenu = isOpen => css`
  font-size: 12px;
  position: relative;

  .menu-icon {
    transform: ${isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
    transition: all 0.35s;
    width: 10px;
    height: 15px;
    position: relative;
    left: 9px;
  }

  .menu-content {
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    max-height: ${isOpen ? '100vh' : 0};
    transition: all 0.35s;
    background: #d4e4ed;
    position: absolute;
    padding: 32px 29px;
    z-index: 100;
    border-radius: 20px;
    top: -10px;
    right: -165px;
  }

  .menu-content .menu-options:last-of-type {
    margin-bottom: 0;
  }

  .menu-options {
    cursor: pointer;
    display: flex;
    margin-bottom: 22px;

    span {
      color: #2b2d42;
      position: relative;
      top: 4px;
      margin-left: 15px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
  }

  @media screen and (min-width: 768px) {
    .menu-content {
      top: -30px;
    }
  }
`

export const languageMenuVertical = isOpen => css`
  font-size: 12px;
  position: relative;

  .language-selector {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin: 0 10px;
    }
  }

  .menu-icon {
    transform: ${isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
    transition: all 0.35s;
    width: 15px;
    height: 10px;
    position: relative;
  }

  .menu-content {
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    max-height: ${isOpen ? '100vh' : 0};
    transition: all 0.35s;
    background: #d4e4ed;
    position: absolute;
    padding: 32px 29px;
    z-index: 100;
    border-radius: 20px;
    top: 30px;
    right: 0;
  }

  .menu-content .menu-options:last-of-type {
    margin-bottom: 0;
  }

  .menu-options {
    cursor: pointer;
    display: flex;
    margin-bottom: 22px;

    span {
      color: #2b2d42;
      position: relative;
      top: 4px;
      margin-left: 15px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
  }

  @media screen and (min-width: 768px) {
    .menu-content {
      top: -30px;
    }
  }
`

export const languageMenuNav = isOpen => css`
  font-size: 12px;
  position: relative;

  .menu {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    outline: none;
  }

  .text-label {
    color: #93b7be;
    font-weight: bold;
  }

  .menu-icon {
    transform: ${isOpen ? 'rotate(270deg)' : 'rotate(90deg)'};
    transition: all 0.35s;
    width: 15px;
    height: 10px;
    position: relative;
    color: #93b7be;
  }

  .menu-content {
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    max-height: ${isOpen ? '100vh' : 0};
    transition: all 0.35s;
    background: #d4e4ed;
    position: absolute;
    padding: 32px 29px;
    transform: translate(0, 16px);
    z-index: 100;
    width: 100%;
    box-sizing: border-box;
  }

  .menu-content .menu-options:last-of-type {
    margin-bottom: 0;
  }

  .menu-options {
    cursor: pointer;
    display: flex;
    margin-bottom: 22px;

    span {
      color: #2b2d42;
      position: relative;
      top: 4px;
      margin-left: 15px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
  }
`

export const languageMenuMobile = isOpen => css`
  margin-bottom: 31px;
  width: 146px;

  .language-selector {
    border: 1px solid #aacde0;
    border-radius: 10px;
    padding: 11px 19px;
    height: 45px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lng-span {
    color: #93b7be;
    fontweight: 500;
    fontsize: 14px;
  }

  .menu-icon {
    transform: ${isOpen ? 'rotate(90deg)' : 'rotate(0deg)'};
    transition: all 0.35s;
    width: 10px;
    height: 15px;
    position: relative;
    left: 10px;
  }

  .menu-content {
    list-style: none;
    margin: 0;
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    max-height: ${isOpen ? '100vh' : 0};
    transition: all 0.35s;
    background: #d4e4ed;
    color: #0f7cb5;
    border-radius: 20px;
    padding: 11px 19px;
    margin-top: 10px;

    li {
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 22px;

      :last-of-type {
        margin-bottom: 0;
      }
    }
  }
`
