/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'emotion-theming';
import { Colors } from '../core';
const themes = {
  migratoryWebCitizen: {
    alpha: Colors.migratoryWebCitizen.deepCerulean
  },
  sanitarianRegistersWeb: {
    alpha: Colors.sanitarianRegistersWeb.deepCerulean
  },
  tdcar: {
    alpha: Colors.tdCarWeb.deepCerulean
  },
  tdCarWeb: {
    alpha: Colors.tdCarWeb.deepCerulean
  }
};
export default function Legends({
  total,
  skip,
  limit,
  style = {}
}) {
  const {
    theme
  } = useTheme();
  const {
    t
  } = useTranslation();
  const start = 1 + skip;
  const calc = start + limit - 1;
  const end = calc > total ? total : calc;

  if (total <= limit) {
    return '';
  }

  return jsx("span", {
    style: {
      fontSize: 14,
      fontWeight: 500,
      color: themes[theme].alpha,
      ...style
    }
  }, t('Mostrando registros del al', {
    start,
    end,
    total
  }));
}