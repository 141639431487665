/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import { migratoryWebCitizen } from '../Colors';
import { HEX2RGB, mediaQuery, currencyFormat } from '../../utils';
/**
 * This component represent a sub total on costs breakdown.
 * @param {string} currency Currency.
 * @param {*} total Total amoung.
 * @param {*} t Translate.
 */

function SubTotal({
  currency,
  total,
  t
}) {
  return jsx(React.Fragment, null, jsx("span", {
    className: "subtotal"
  }, t('Subtotal'), ": ", currency, " ", currencyFormat(total)), jsx("div", {
    className: "divider"
  }));
}
/**
 * This component represent a total on costs breakdown.
 * @param {string} currency Currency.
 * @param {string} otherCurrency Other currency.
 * @param {*} total Total amoung.
 * @param {*} totalOtherCurrency Total amoung in the other currency.
 * @param {*} t Translate.
 */


function Total({
  currency = '',
  otherCurrency = '',
  total = 0,
  totalOtherCurrency = 0,
  t
}) {
  return jsx(React.Fragment, null, jsx("span", {
    className: "total"
  }, jsx("span", {
    style: {
      display: 'block',
      marginBottom: '5px'
    }
  }, t('Total a pagar'), ":"), currency, currencyFormat(total)), jsx("span", {
    className: "total-other-currency"
  }, "* ", t('Total a pagar en dólares'), ": ", otherCurrency, currencyFormat(totalOtherCurrency)));
}
/**
 * This component represent a total on costs breakdown.
 * @param {JSON} paymentItems List of the payment items.
 * @param {string} currencySymbol Currency symbol.
 * @param {function} t Translate.
 */


export default function CostsBreakdown({
  paymentItems: payments,
  currencySymbol = '₡',
  t,
  costsBreakDownToZero
}) {
  var _currencyFormat, _payments$breakdowns$, _payments$breakdowns$2, _payments$breakdowns$3, _paymentItems$breakdo, _paymentItems$total, _paymentItems$otherCu, _paymentItems$total2;

  const paymentItems = costsBreakDownToZero ? {
    breakdowns: [{
      amount: 0,
      label: 'Gastos Operativos',
      code: 'rubro_GASTFILIAC'
    }, {
      amount: 0,
      label: 'Impuesto sobre el valor agregado',
      code: 'rubro_IVAFILIAC'
    }, {
      amount: (_currencyFormat = currencyFormat(payments === null || payments === void 0 ? void 0 : (_payments$breakdowns$ = payments.breakdowns[2]) === null || _payments$breakdowns$ === void 0 ? void 0 : _payments$breakdowns$.amount)) !== null && _currencyFormat !== void 0 ? _currencyFormat : '---',
      label: (_payments$breakdowns$2 = payments === null || payments === void 0 ? void 0 : (_payments$breakdowns$3 = payments.breakdowns[2]) === null || _payments$breakdowns$3 === void 0 ? void 0 : _payments$breakdowns$3.label) !== null && _payments$breakdowns$2 !== void 0 ? _payments$breakdowns$2 : 'Tipo de Cambio',
      code: 'tipo_cambio'
    }]
  } : payments;
  return jsx("div", {
    css: style
  }, jsx("ul", {
    className: "costs-list"
  }, paymentItems === null || paymentItems === void 0 ? void 0 : (_paymentItems$breakdo = paymentItems.breakdowns) === null || _paymentItems$breakdo === void 0 ? void 0 : _paymentItems$breakdo.map(payment => {
    return jsx("li", {
      className: "cost-item",
      key: payment.code
    }, jsx("div", {
      className: "item-container"
    }, jsx("span", {
      className: "label"
    }, payment.label), jsx("div", {
      className: "dots"
    }), jsx("span", {
      className: "value"
    }, currencySymbol, " ", currencyFormat(payment.amount))));
  })), jsx(SubTotal, {
    t: t,
    currency: currencySymbol,
    total: (_paymentItems$total = paymentItems === null || paymentItems === void 0 ? void 0 : paymentItems.total) !== null && _paymentItems$total !== void 0 ? _paymentItems$total : 0
  }), jsx(Total, {
    t: t,
    currency: currencySymbol,
    otherCurrency: (_paymentItems$otherCu = paymentItems === null || paymentItems === void 0 ? void 0 : paymentItems.otherCurrencySymbol) !== null && _paymentItems$otherCu !== void 0 ? _paymentItems$otherCu : '',
    total: (_paymentItems$total2 = paymentItems === null || paymentItems === void 0 ? void 0 : paymentItems.total) !== null && _paymentItems$total2 !== void 0 ? _paymentItems$total2 : 0,
    totalOtherCurrency: paymentItems === null || paymentItems === void 0 ? void 0 : paymentItems.totalOtherCurrency
  }));
}
const style = css`
  .costs-list {
    list-style: none;
    padding: 0 40px;
    margin-top: 40px;
    ${mediaQuery[0]} {
      padding: 0;
      margin-top: 0;
    }

    .cost-item {
      margin-bottom: 10px;
      .item-container {
        display: flex;

        .label {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          display: flex;
          align-items: center;
          letter-spacing: 0.01em;
          color: ${migratoryWebCitizen.valhalla};
          margin-right: 20px;
          ${mediaQuery[0]} {
            margin-left: 0px;
          }
        }

        .dots {
          flex-grow: 2;
          background-image: linear-gradient(
            to right,
            ${migratoryWebCitizen.valhalla} 33%,
            rgba(${HEX2RGB(migratoryWebCitizen.valhalla).join(',')}, 0) 0%
          );
          background-position: bottom;
          background-size: 8px 2px;
          background-repeat: repeat-x;
          margin-bottom: 4px;
        }

        .value {
          text-align: right;
          width: 20%;
        }
      }
    }
  }

  .total {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: right;
    color: ${migratoryWebCitizen.valhalla};
    display: block;
    padding: 0px 40px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
    text-align: right;
    color: #08415c;
    ${mediaQuery[0]} {
      padding: 0;
    }
  }

  .total-other-currency {
    text-align: right;
    padding: 0px 40px;
    display: block;
    margin-bottom: 27px;
    ${mediaQuery[0]} {
      padding: 0;
    }
  }

  .subtotal {
    padding-right: 40px;
    display: block;
    text-align: right;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    text-align: right;
    letter-spacing: 0.01em;
    color: #2b2d42;

    + .divider {
      border-bottom: 1px solid #afccd1;
      margin: 16px 0;
    }

    ${mediaQuery[0]} {
      padding-right: 0;
    }
  }
`;