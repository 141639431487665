import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: 'null',
  height: 'null',
  viewBox: '0 0 22 22',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx(
      'g',
      null,
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M7.92179 6.92737C7.92179 5.20573 9.31746 3.81006 11.0391 3.81006C12.7608 3.81006 14.1564 5.20573 14.1564 6.92737V7.02793C14.1564 7.52777 14.5616 7.93296 15.0615 7.93296C15.5613 7.93296 15.9665 7.52777 15.9665 7.02793V6.92737C15.9665 4.20606 13.7604 2 11.0391 2C8.31779 2 6.11173 4.20606 6.11173 6.92737V9.34078H5C4.44772 9.34078 4 9.7885 4 10.3408V19C4 19.5523 4.44771 20 5 20H16.8771C17.4294 20 17.8771 19.5523 17.8771 19V10.3408C17.8771 9.7885 17.4294 9.34078 16.8771 9.34078H7.92179V6.92737Z',
        fill: 'white',
      })
    )
  ),
  name: 'LockOpen',
})
