/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import Rodal from 'rodal';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { Formik, useFormikContext } from 'formik';
import { useQuery } from 'react-query';
import useMediaQuery from '../../../hooks/use-media-query';
import { Button, Colors, SpinnerSmall } from '../../../core';
import styles from './modalStyle';
import modalCustomStyle from './utils';
import { DivRow, DivColumn } from '../InputsUI';
import { preprocessVehicleFormFields } from '../../../utils';
import { InputField, GenericDropDown } from '../../advanced/procedure/sections/shared/components';
import { GridContainer, CustomDatePicker } from '../filters/utils';
import ScrollWraper from '../../ScrollWrapper';
import { useVehicleCategories, useVehicleFrames, useColors, useTractions, useVehicleMakes, useFuels, useCabinTypes, useRoofTypes } from '../../../queries';
import { useCreateVehicleForm, useEditVehicle } from '../../../mutations';
import validationSchema from '../../advanced/procedure/sections/tdcar/vehicle-data/form-schema-new';
const {
  tdCarWeb: colors
} = Colors;
/**
 * This component represents a modal for cancel a procedure.
 */

export function FormModalNew({
  isVisible,
  setIsVisible,
  isEdit = false,
  onCloseEdit,
  isLoading
}) {
  const {
    t
  } = useTranslation();
  const {
    isMobile
  } = useMediaQuery();
  const formik = useFormikContext();
  const vehicleStates = [{
    id: 'Pendiente',
    name: t('Pendiente')
  }, {
    id: 'Inspeccionado',
    name: t('Inspeccionado')
  }];

  const vehicleStatesQuery = () => {
    return useQuery({
      queryKey: ['vehicleStates'],
      queryFn: () => vehicleStates
    });
  };

  const exoneration = [{
    id: true,
    name: t('Sí')
  }, {
    id: false,
    name: t('No')
  }];

  const exonerationQuery = () => {
    return useQuery({
      queryKey: ['hasExoneration'],
      queryFn: () => exoneration
    });
  };

  const typeOfExoneration = [{
    id: 'Parcial',
    name: t('Parcial')
  }, {
    id: 'Total',
    name: t('Total')
  }];

  const exonerationTypeQuery = () => {
    return useQuery({
      queryKey: ['exonerationType'],
      queryFn: () => typeOfExoneration
    });
  };

  const doors = Array.from({
    length: 10
  }, (_, index) => ({
    id: index,
    name: t(index)
  }));

  const doorsQuery = () => {
    return useQuery({
      queryKey: ['doors'],
      queryFn: () => doors
    });
  };

  const passengersNumb = Array.from({
    length: 201
  }, (_, index) => ({
    id: index,
    name: t(index)
  }));

  const passengersQuery = () => {
    return useQuery({
      queryKey: ['passengers'],
      queryFn: () => passengersNumb
    });
  };

  const wheelsLines = Array.from({
    length: 10
  }, (_, index) => ({
    id: index,
    name: t(index)
  }));

  const wheelLinesQuery = () => {
    return useQuery({
      queryKey: ['wheelLines'],
      queryFn: () => wheelsLines
    });
  };

  const steeringWheels = [{
    id: true,
    name: t('Sí')
  }, {
    id: false,
    name: t('No')
  }];

  const steeringWheelQuery = () => {
    return useQuery({
      queryKey: ['steeringWheel'],
      queryFn: () => steeringWheels
    });
  };

  const handleOnBlur = (fieldName, maxLen) => {
    const parsedNumber = parseFloat(formik.values[fieldName]); // Convert string to float
    // Check if the parsed number is not NaN

    if (!Number.isNaN(parsedNumber)) {
      // Convert the number to a string with two decimal places
      const numberString = parsedNumber.toFixed(2); // Determine the total length of the string

      const totalLength = numberString.length; // Check if the total length exceeds the maximum allowed length

      if (totalLength <= maxLen) {
        // Convert the number to a string with exactly two decimal places
        formik.setFieldValue(fieldName, numberString);
      }
    }
  };

  return jsx(Rodal, {
    visible: isVisible,
    showCloseButton: false,
    width: 1112,
    height: 650,
    closeOnEsc: true,
    onClose: () => {},
    customStyles: modalCustomStyle({
      isMobile,
      width: 1112,
      height: 650,
      borderRadius: 20
    })
  }, jsx("div", {
    css: [styles, {
      padding: '21px 16px 16px 16px',
      height: '645px'
    }]
  }, jsx(DivRow, {
    style: {
      marginBottom: '0px!important',
      display: 'flex'
    }
  }, jsx(DivColumn, {
    containerStyle: {
      flexBasis: '80%'
    }
  }, jsx("h1", {
    className: "modals-title",
    style: {
      fontSize: 24,
      marginTop: 10,
      marginBottom: 30,
      color: colors.deepCerulean,
      marginLeft: 30
    }
  }, t(isEdit ? 'Modificar vehículo' : 'Agregar nuevo vehículo')))), jsx(ScrollWraper, {
    containerStyle: {
      maxHeight: '100%'
    }
  }, jsx(GridContainer, {
    containerStyle: {
      display: 'grid',
      columnGap: 10,
      rowGap: 15,
      height: '100%',
      marginLeft: 30,
      gridTemplateColumns: '245px 245px 245px 245px'
    }
  }, jsx(InputField, {
    label: t('Número de VIN'),
    name: "vin",
    containerStyle: {
      height: '50px'
    },
    maxLength: "25",
    disabled: isEdit
  }), jsx(InputField, {
    label: t('Número de chasis'),
    name: "chasis",
    containerStyle: {
      height: '50px'
    },
    maxLength: "20",
    disabled: isEdit
  }), jsx(InputField, {
    label: t('Número de motor'),
    name: "engineNumber",
    containerStyle: {
      height: '50px'
    },
    maxLength: "22"
  }), jsx(GenericDropDown, {
    label: `${t('Estado del vehículo')}:`,
    name: "vehicleState",
    query: vehicleStatesQuery,
    containerStyle: {
      height: '50px'
    },
    maxLength: "15"
  }), jsx(InputField, {
    label: `${t('Muestra de vehículos nuevos')}:`,
    name: "sampling",
    containerStyle: {
      height: '50px'
    },
    maxLength: "20"
  }), jsx(CustomDatePicker, {
    title: "Fecha de aprobaci\xF3n de muestra",
    placeholder: t('aaaa-mm-dd'),
    format: "yyyy-MM-dd",
    containerStyle: {
      height: '50px',
      borderRadius: '6px',
      border: `1px solid #0D1F2D`,
      padding: '14px 16px'
    },
    name: "samplingDate",
    formik: formik,
    showClearDate: true
  }), jsx(InputField, {
    label: `${t('Factura comercial')}:`,
    name: "comercialInvoice",
    containerStyle: {
      height: '50px'
    },
    maxLength: "20"
  }), jsx(InputField, {
    label: `${t('Conocimiento del embarque')}:`,
    name: "billOfLanding",
    containerStyle: {
      height: '50px'
    },
    maxLength: "20"
  }), jsx(GenericDropDown, {
    label: `${t('Marca de vehículo')}:`,
    name: "make",
    query: () => useVehicleMakes({
      manipulation: makes => makes.map(item => ({
        id: item === null || item === void 0 ? void 0 : item.code,
        name: `${item === null || item === void 0 ? void 0 : item.code} - ${item === null || item === void 0 ? void 0 : item.name}`
      }))
    }),
    containerStyle: {
      height: '50px'
    },
    maxLength: "255"
  }), jsx(InputField, {
    label: `${t('Estilo')}:`,
    name: "style",
    containerStyle: {
      height: '50px'
    },
    maxLength: "95"
  }), jsx(InputField, {
    label: `${t('Modelo')}:`,
    name: "model",
    containerStyle: {
      height: '50px'
    },
    maxLength: "20"
  }), jsx(InputField, {
    label: `${t('Año modelo')}:`,
    name: "year",
    containerStyle: {
      height: '50px'
    },
    maxLength: "4",
    mask: {
      regex: '^[0-9]*'
    }
  }), jsx(InputField, {
    label: `${t('Año póliza')}:`,
    name: "yearAcceptance",
    containerStyle: {
      height: '50px'
    },
    maxLength: "4",
    mask: {
      regex: '^[0-9]*'
    }
  }), jsx(InputField, {
    label: `${t('Número póliza')}:`,
    name: "policyNumber",
    containerStyle: {
      height: '50px'
    },
    maxLength: "6",
    mask: {
      regex: '^[0-9]*'
    }
  }), jsx(CustomDatePicker, {
    title: "Fecha p\xF3liza",
    placeholder: t('aaaa-mm-dd'),
    format: "yyyy-MM-dd",
    containerStyle: {
      height: '50px',
      borderRadius: '6px',
      border: `1px solid #0D1F2D`,
      padding: '14px 16px'
    },
    name: "policyDate",
    formik: formik,
    showClearDate: true
  }), jsx(InputField, {
    label: `${t('Número línea')}:`,
    name: "lineNumber",
    containerStyle: {
      height: '50px'
    },
    maxLength: "3",
    mask: {
      regex: '^[0-9]*'
    }
  }), jsx(InputField, {
    label: `${t('Cif en póliza Col')}:`,
    name: "cifNumber",
    placeholderText: t('000000.00'),
    containerStyle: {
      height: '50px'
    },
    maxLength: "19",
    onBlur: () => {
      handleOnBlur('cifNumber', 19);
    },
    mask: {
      regex: '^[0-9.]*$'
    }
  }), jsx(InputField, {
    label: `${t('Clase tributaria')}:`,
    name: "taxClassNumber",
    containerStyle: {
      height: '50px'
    },
    maxLength: "20"
  }), jsx(GenericDropDown, {
    label: `${t('Cuenta con Exoneración')}:`,
    name: "hasExoneration",
    query: exonerationQuery,
    containerStyle: {
      height: '50px'
    }
  }), jsx(GenericDropDown, {
    label: `${t('Tipo de Exoneración')}:`,
    name: "exonerationType",
    query: exonerationTypeQuery,
    containerStyle: {
      height: '50px'
    },
    maxLength: "7"
  }), jsx(GenericDropDown, {
    label: `${t('Categoría')}:`,
    name: "category",
    query: () => useVehicleCategories({
      manipulation: category => category.map(item => ({
        id: item === null || item === void 0 ? void 0 : item.code,
        name: `${item === null || item === void 0 ? void 0 : item.code} - ${item === null || item === void 0 ? void 0 : item.name}`
      }))
    }),
    containerStyle: {
      height: '50px'
    },
    maxLength: "255"
  }), jsx(GenericDropDown, {
    label: `${t('Carrocería')}:`,
    name: "aircraftTypeCode",
    query: () => useVehicleFrames({
      manipulation: frame => frame.map(item => ({
        id: item === null || item === void 0 ? void 0 : item.code,
        name: `${item === null || item === void 0 ? void 0 : item.code} - ${item === null || item === void 0 ? void 0 : item.name}`
      }))
    }),
    containerStyle: {
      height: '50px'
    },
    maxLength: "255"
  }), jsx(GenericDropDown, {
    label: t('Color'),
    name: "colorCode",
    query: () => useColors({
      manipulation: color => color.map(item => ({
        id: item === null || item === void 0 ? void 0 : item.code,
        name: `${item === null || item === void 0 ? void 0 : item.code} - ${item === null || item === void 0 ? void 0 : item.name}`
      }))
    }),
    containerStyle: {
      height: '50px'
    },
    maxLength: "20"
  }), jsx(GenericDropDown, {
    label: `${t('Número de Puertas')}:`,
    name: "doors",
    query: doorsQuery,
    containerStyle: {
      height: '50px'
    },
    maxLength: "1"
  }), jsx(GenericDropDown, {
    label: `${t('Número de Pasajeros')}:`,
    name: "passengers",
    query: passengersQuery,
    containerStyle: {
      height: '50px'
    },
    maxLength: "3"
  }), jsx(GenericDropDown, {
    label: `${t('Tipo de Cabina')}:`,
    name: "cabinType",
    query: () => useCabinTypes({
      manipulation: cabin => cabin.map(item => ({
        id: item === null || item === void 0 ? void 0 : item.code,
        name: `${item === null || item === void 0 ? void 0 : item.code} - ${item === null || item === void 0 ? void 0 : item.name}`
      }))
    }),
    containerStyle: {
      height: '50px'
    },
    maxLength: "20"
  }), jsx(GenericDropDown, {
    label: `${t('Código de Techo')}:`,
    name: "roofCode",
    query: () => useRoofTypes({
      manipulation: roof => roof.map(item => ({
        id: item === null || item === void 0 ? void 0 : item.code,
        name: `${item === null || item === void 0 ? void 0 : item.code} - ${item === null || item === void 0 ? void 0 : item.name}`
      }))
    }),
    containerStyle: {
      height: '50px'
    },
    maxLength: "20"
  }), jsx(GenericDropDown, {
    label: `${t('Cantidad de Ejes')}:`,
    name: "wheelLines",
    query: wheelLinesQuery,
    containerStyle: {
      height: '50px'
    },
    maxLength: "1"
  }), jsx(InputField, {
    label: `${t('Lectura Odómetro')}:`,
    name: "odometerLecture",
    containerStyle: {
      height: '50px'
    },
    maxLength: "255"
  }), jsx(GenericDropDown, {
    label: `${t('Cuenta con volante a la Derecha')}:`,
    name: "steeringWheel",
    query: steeringWheelQuery,
    containerStyle: {
      height: '50px'
    }
  }), jsx(GenericDropDown, {
    label: `${t('Clasificación energética integral')}:`,
    name: "energyClassification",
    query: () => useFuels({
      manipulation: fuel => fuel.map(item => ({
        id: item === null || item === void 0 ? void 0 : item.code,
        name: `${item === null || item === void 0 ? void 0 : item.code} - ${item === null || item === void 0 ? void 0 : item.name}`
      }))
    }),
    containerStyle: {
      height: '50px'
    },
    maxLength: "20"
  }), jsx(GenericDropDown, {
    label: `${t('Tracción')}:`,
    name: "traction",
    query: () => useTractions({
      manipulation: traction => traction.map(item => ({
        id: item === null || item === void 0 ? void 0 : item.code,
        name: `${item === null || item === void 0 ? void 0 : item.code} - ${item === null || item === void 0 ? void 0 : item.name}`
      }))
    }),
    containerStyle: {
      height: '50px'
    },
    maxLength: "20"
  }), jsx(InputField, {
    label: `${t('Tipo de motor')}:`,
    name: "engineType",
    containerStyle: {
      height: '50px'
    },
    maxLength: "20"
  }), jsx(GenericDropDown, {
    label: `${t('Marca de motor')}:`,
    name: "engineBrand",
    query: () => useVehicleMakes({
      manipulation: makes => makes.map(item => ({
        id: item === null || item === void 0 ? void 0 : item.code,
        name: `${item === null || item === void 0 ? void 0 : item.code} - ${item === null || item === void 0 ? void 0 : item.name}`
      }))
    }),
    containerStyle: {
      height: '50px'
    },
    maxLength: "255"
  }), jsx(InputField, {
    label: `${t('Número de cilindros')}:`,
    name: "engineSize",
    containerStyle: {
      height: '50px'
    },
    maxLength: "4",
    mask: {
      regex: '^[0-9]*'
    }
  }), jsx(InputField, {
    label: `${t('Cilindrada (cc)')}:`,
    name: "cilinders",
    containerStyle: {
      height: '50px'
    },
    maxLength: "10",
    mask: {
      regex: '^[0-9]*'
    }
  }), jsx(InputField, {
    label: `${t('Potencia (KW)')}:`,
    name: "potencial",
    placeholderText: t('000000.00'),
    containerStyle: {
      height: '50px'
    },
    maxLength: "20",
    onBlur: () => {
      handleOnBlur('potencial', 20);
    },
    mask: {
      regex: '^[0-9.]*$'
    }
  }), jsx(InputField, {
    label: `${t('PMA (Peso Bruto) kg')}:`,
    name: "rawWeight",
    placeholderText: t('000000.00'),
    containerStyle: {
      height: '50px'
    },
    maxLength: "9",
    onBlur: () => {
      handleOnBlur('rawWeight', 9);
    },
    mask: {
      regex: '^[0-9.]*$'
    }
  }), jsx(InputField, {
    label: `${t('Peso Neto (Peso TARA) kg')}:`,
    name: "netWeight",
    placeholderText: t('000000.00'),
    containerStyle: {
      height: '50px'
    },
    maxLength: "9",
    onBlur: () => {
      handleOnBlur('netWeight', 9);
    },
    mask: {
      regex: '^[0-9.]*$'
    }
  }), jsx(InputField, {
    label: `${t('Longitud (mm)')}:`,
    name: "length",
    placeholderText: t('000000.00'),
    containerStyle: {
      height: '50px'
    },
    maxLength: "9",
    onBlur: () => {
      handleOnBlur('length', 9);
    },
    mask: {
      regex: '^[0-9.]*$'
    }
  }))), jsx("div", {
    className: "button-container",
    css: {
      justifyContent: 'flex-end!important',
      alignItems: 'center',
      paddingLeft: 80
    }
  }, jsx("div", {
    css: {
      display: 'flex'
    }
  }, jsx(Button, {
    size: "md-icon",
    outline: true,
    className: "button blue",
    style: {
      color: colors.cerulean,
      border: `2px solid ${colors.cerulean}`,
      width: 210,
      height: 48,
      marginRight: 10,
      marginTop: 25
    },
    onClick: () => {
      setIsVisible(false);

      if (isEdit) {
        onCloseEdit();
      }
    },
    disabled: isLoading
  }, t('Cancelar')), jsx(Button, {
    size: "md-icon",
    className: "button red",
    style: {
      background: colors.fireEngineRed,
      color: colors.white,
      width: 184,
      height: 48,
      marginTop: 25,
      marginRight: 20
    },
    onClick: () => {
      formik.handleSubmit();
    },
    disabled: isLoading
  }, t('Guardar'), isLoading ? jsx(SpinnerSmall, {
    style: {
      marginLeft: '10px'
    }
  }) : '')))));
}
export default function FormDataNew({
  isVisible,
  setIsVisible,
  procedureId,
  refetch,
  vehicleData,
  onCloseEdit,
  customsName,
  importerName
}) {
  var _vehicleData$potencia;

  const {
    t
  } = useTranslation();
  const initialValues = {
    vin: vehicleData ? vehicleData.vin : '',
    chasis: vehicleData ? vehicleData.chasis : '',
    engineNumber: vehicleData ? vehicleData.engineNumber : '',
    vehicleState: vehicleData ? vehicleData.vehicleState : '',
    sampling: vehicleData ? vehicleData.sampling : '',
    samplingDate: vehicleData ? vehicleData.samplingDate : '',
    comercialInvoice: vehicleData ? vehicleData.comercialInvoice : '',
    billOfLanding: vehicleData ? vehicleData.billOfLanding : '',
    make: vehicleData ? vehicleData.make : '',
    style: vehicleData ? vehicleData.style : '',
    model: vehicleData ? vehicleData.model : '',
    year: vehicleData ? vehicleData.year : '',
    yearAcceptance: vehicleData ? vehicleData.yearAcceptance : '',
    policyNumber: vehicleData ? vehicleData.policyNumber : '',
    policyDate: vehicleData ? vehicleData.policyDate : '',
    lineNumber: vehicleData ? vehicleData.lineNumber : '',
    cifNumber: vehicleData ? vehicleData.cifNumber : '',
    taxClassNumber: vehicleData ? vehicleData.taxClassNumber : '',
    hasExoneration: vehicleData ? vehicleData.hasExoneration : '',
    exonerationType: vehicleData ? vehicleData.exonerationType : '',
    category: vehicleData ? vehicleData.category : '',
    frame: vehicleData ? vehicleData.frame : '',
    colorCode: vehicleData ? vehicleData.colorCode : '',
    doors: vehicleData ? vehicleData.doors : '',
    passengers: vehicleData ? vehicleData.passengers : '',
    cabinType: vehicleData ? vehicleData.cabinType : '',
    roofCode: vehicleData ? vehicleData.roofCode : '',
    wheelLines: vehicleData ? vehicleData.wheelLines : '',
    odometerLecture: vehicleData ? vehicleData.odometerLecture : '',
    steeringWheel: vehicleData ? vehicleData.steeringWheel : '',
    energyClassification: vehicleData ? vehicleData.energyClassification : '',
    traction: vehicleData ? vehicleData.traction : '',
    engineType: vehicleData ? vehicleData.engineType : '',
    engineBrand: vehicleData ? vehicleData.engineBrand : '',
    engineSize: vehicleData ? vehicleData.engineSize : '',
    cilinders: vehicleData ? vehicleData.cilinders : '',
    potencial: vehicleData ? (_vehicleData$potencia = vehicleData.potencial) === null || _vehicleData$potencia === void 0 ? void 0 : _vehicleData$potencia.replace(',', '.') : '',
    rawWeight: vehicleData ? vehicleData.rawWeight : '',
    netWeight: vehicleData ? vehicleData.netWeight : '',
    length: vehicleData ? vehicleData.length : '',
    procedureId,
    identification: vehicleData ? vehicleData.identification : '',
    identificationType: vehicleData ? vehicleData.identificationType : '',
    isNew: true,
    condition: 1,
    customsName,
    importerName,
    aircraftTypeCode: vehicleData ? vehicleData.aircraftTypeCode : ''
  };
  let reset;
  const {
    addToast
  } = useToasts();
  const [mutateCreateVehicle, {
    isLoading: isLoadingCreate
  }] = useCreateVehicleForm({
    config: {
      onSuccess: () => {
        setIsVisible(false);

        if (reset) {
          reset();
        }

        refetch();
      },
      onError: error => {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true
        });
      }
    }
  });
  const [mutateEditVehicle, {
    isLoading: isLoadingEdit
  }] = useEditVehicle({
    config: {
      onSuccess: () => {
        setIsVisible(false);
        onCloseEdit();
        refetch();
      },
      onError: error => {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true
        });
      }
    }
  });
  return jsx(Formik, {
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema(t),
    onSubmit: (values, {
      resetForm
    }) => {
      const updatedValues = preprocessVehicleFormFields(values);

      if (vehicleData) {
        mutateEditVehicle({
          id: vehicleData === null || vehicleData === void 0 ? void 0 : vehicleData.id,
          ...updatedValues
        });
      } else {
        reset = resetForm;
        mutateCreateVehicle({
          body: updatedValues
        });
      }
    }
  }, jsx(FormModalNew, {
    isVisible: isVisible,
    setIsVisible: setIsVisible,
    isEdit: !!vehicleData,
    onCloseEdit: onCloseEdit,
    isLoading: isLoadingCreate || isLoadingEdit
  }));
}