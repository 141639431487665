import { css, keyframes } from '@emotion/core';
import { phytosanitaryWeb, migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from '../Colors';
import { mediaQuery } from '../../utils';
const themes = {
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.white,
    beta: phytosanitaryWeb.allports,
    gamma: phytosanitaryWeb.mantis,
    delta: phytosanitaryWeb.sherpaBlue,
    epsilon: phytosanitaryWeb.geyser
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.white,
    beta: migratoryWebCitizen.cerulean,
    gamma: migratoryWebCitizen.cerulean,
    delta: migratoryWebCitizen.white,
    epsilon: migratoryWebCitizen.geyser
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.white,
    beta: sanitarianRegistersWeb.cerulean,
    gamma: sanitarianRegistersWeb.cerulean,
    delta: sanitarianRegistersWeb.white,
    epsilon: sanitarianRegistersWeb.geyser
  },
  tdCarWeb: {
    alpha: tdCarWeb.white,
    beta: tdCarWeb.cerulean,
    gamma: tdCarWeb.cerulean,
    delta: tdCarWeb.white,
    epsilon: tdCarWeb.geyser
  }
};
const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;
export default function ({
  theme = 'migratoryWebCitizen'
}) {
  return css`
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 16px;
    grid-row-gap: 15px;
    ${mediaQuery[0]} {
      grid-template-columns: 100%;
    }

    .skeleton {
      display: inline-block;
      height: 20px;
      position: relative;
      overflow: hidden;
      background-color: #dddbdd;
      width: 60%;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 0.2) 20%,
          rgba(255, 255, 255, 0.5) 60%,
          rgba(255, 255, 255, 0)
        );
        animation: ${shimmer} 1s infinite;
        content: '';
      }
    }

    li {
      width: 100%;
      padding: 45px 20px;
      box-sizing: border-box;
      background-color: ${themes[theme].alpha};
      border-radius: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: ${themes[theme].beta};
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      letter-spacing: 0.01em;
      cursor: pointer;
      transition: 0.5s;
      ${mediaQuery[0]} {
        height: 80px;
        padding: 0 10px;
      }

      &:hover {
        background-color: ${themes[theme].gamma};
        color: ${themes[theme].delta};
      }
    }

    .customBoxLi {
      background-color: #ebf1f4;
      color: #a0b9c8;
      &:hover {
        background-color: ${themes[theme].gamma};
        color: ${themes[theme].delta};
      }
    }

    li p {
      font-size: 18px;
      margin: 0px;
    }

    li h1 {
      font-size: 18px;
      margin: 0px;
    }

    .customBoxLiTDCar {
      background-color: #f4fbfe;
      color: ${themes[theme].gamma};
      &:hover {
        background-color: ${themes[theme].gamma};
        color: ${themes[theme].delta};
      }
    }

    .disabled {
      color: ${themes[theme].epsilon};
      cursor: not-allowed;

      &:hover {
        background-color: ${themes[theme].alpha};
        color: #d4e4ed;
      }
    }
  `;
}