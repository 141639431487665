/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Rodal from 'rodal';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'emotion-theming';
import React, { useMemo } from 'react';
import { home, migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../../Colors';
import useMediaQuery from '../../../hooks/use-media-query';
import { SearchIcon } from '../../../icon';
import { Button, FormError, LegalInfo as LegalInfoComponent, DropDownCombo, SpinnerSmall, Pagination } from '../../../core';
import ScrollWrapper from '../../ScrollWrapper';
import { DivRow, DivColumn, InputText, FormLabel } from '../InputsUI';
import styles from './modalStyle';
import modalCustomStyle from './utils';
import { useCountries } from '../../../queries';

var EmptyImage = function EmptyImage(props) {
  return jsx("svg", props, jsx("path", {
    d: "M12.232 97.343s-27.055 30.523 5.55 63.823c32.606 33.301 86.024 60.356 148.459 27.75 62.434-32.606 74.229-100.59 52.033-129.73-22.195-29.138-69.373-15.955-83.94 4.162-14.567 20.117-43.012 25.667-68.68 18.733-25.667-6.934-45.793 2.083-53.421 15.262z",
    fill: "#D4E4ED"
  }), jsx("path", {
    d: "M66.386 86.578h-8.485s-6.366-1.062-9.549 7.428c-3.183 8.486-24.4 65.781-24.4 65.781s-6.365 11.668 5.303 12.731c11.67 1.062 113.523 12.731 113.523 12.731s21.748 4.776 21.748-12.2-14.852-81.694-14.852-81.694l-83.288-4.777z",
    fill: "#F4F4F4"
  }), jsx("path", {
    d: "M209.085 155.01s9.548 18.034-7.428 19.097c-16.977 1.062-111.514 5.145-111.514 5.145l.112-109.119 78.512-1.063s14.851-1.062 16.976 8.486c2.125 9.553 23.342 77.454 23.342 77.454z",
    fill: "#E2F3F7"
  }), jsx("path", {
    d: "M59.49 81.806l-13.794 67.901s-4.245 13.794 9.548 13.794h117.768s18.034 2.12 14.851-12.731c-3.183-14.852-10.611-73.204-10.611-73.204s-2.12-10.611-21.217-10.611c-19.096 0-85.935 2.12-85.935 2.12s-8.485 0-10.61 12.731z",
    fill: "#AACDE0"
  }), jsx("path", {
    d: "M133.756 95.069h-57.29v3.182h57.29V95.07zM133.756 87.64H95.563v3.183h38.193v-3.182zM136.407 116.286H71.689v3.182h64.718v-3.182zM136.412 108.858H93.265v3.182h43.147v-3.182zM138.532 140.69H66.386v3.183h72.146v-3.183zM138.532 133.262H90.437v3.183h48.095v-3.183z",
    fill: "#0F7CB5"
  }), jsx("g", {
    opacity: ".85"
  }, jsx("path", {
    opacity: ".85",
    d: "M161.935 109.296c18.026 0 32.639-14.613 32.639-32.639 0-18.026-14.613-32.639-32.639-32.639-18.026 0-32.639 14.613-32.639 32.64 0 18.025 14.613 32.638 32.639 32.638z",
    fill: "#EBF1F4"
  }), jsx("path", {
    opacity: ".85",
    d: "M161.935 114.068c-20.63 0-37.411-16.781-37.411-37.41 0-20.63 16.781-37.411 37.411-37.411 20.63 0 37.41 16.78 37.41 37.41 0 20.63-16.78 37.411-37.41 37.411zm0-65.273c-15.364 0-27.862 12.498-27.862 27.862 0 15.364 12.498 27.862 27.862 27.862 15.364 0 27.862-12.498 27.862-27.862 0-15.364-12.498-27.862-27.862-27.862z",
    fill: "#AACDE0"
  })), jsx("path", {
    d: "M224.467 116.425l-32.363-20.294-8.23 9.325 28.524 25.779c.005 0 9.329-7.13 12.069-14.81z",
    fill: "#0F7CB5"
  }), jsx("path", {
    d: "M119.962 45.202a3.713 3.713 0 0 1-1.57-.35l-15.914-7.428a3.712 3.712 0 1 1 3.141-6.729l15.914 7.428a3.712 3.712 0 0 1 1.794 4.935 3.707 3.707 0 0 1-3.365 2.144zM139.064 34.596a3.717 3.717 0 0 1-3.398-2.204l-8.485-19.097a3.715 3.715 0 0 1 6.789-3.015l8.486 19.097a3.712 3.712 0 0 1-1.887 4.902c-.49.21-1.002.317-1.505.317zM157.098 33.533a3.71 3.71 0 0 1-3.43-5.126l7.428-18.039a3.71 3.71 0 0 1 4.846-2.017 3.71 3.71 0 0 1 2.018 4.846l-7.428 18.039a3.719 3.719 0 0 1-3.434 2.297z",
    fill: "#CE1625"
  }), jsx("path", {
    d: "M157.662 113.811c-20.63 0-37.411-16.78-37.411-37.41 0-20.63 16.781-37.41 37.411-37.41 20.63 0 37.41 16.78 37.41 37.41 0 20.63-16.78 37.41-37.41 37.41zm0-65.272c-15.364 0-27.862 12.498-27.862 27.862 0 15.364 12.498 27.862 27.862 27.862 15.364 0 27.862-12.498 27.862-27.862 0-15.364-12.498-27.862-27.862-27.862z",
    fill: "#0F7CB5"
  }));
};

EmptyImage.defaultProps = {
  width: "233",
  height: "233",
  viewBox: "0 0 233 233",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
};
const themes = {
  home: {
    alpha: home.cerulean,
    beta: home.frenchPass,
    gamma: home.cerulean,
    delta: home.fireEngineRed,
    epsilon: home.white
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.cerulean,
    beta: migratoryWebCitizen.frenchPass,
    gamma: migratoryWebCitizen.cerulean,
    delta: migratoryWebCitizen.fireEngineRed,
    epsilon: migratoryWebCitizen.white
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.deepCerulean,
    beta: sanitarianRegistersWeb.frenchPass,
    gamma: sanitarianRegistersWeb.deepCerulean,
    delta: sanitarianRegistersWeb.fireEngineRed,
    epsilon: sanitarianRegistersWeb.white
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.mantis,
    beta: phytosanitaryWeb.frenchPass,
    gamma: phytosanitaryWeb.mantis,
    delta: phytosanitaryWeb.mantis,
    epsilon: phytosanitaryWeb.sherpaBlue
  },
  tdCarWeb: {
    alpha: tdCarWeb.cerulean,
    beta: tdCarWeb.frenchPass,
    gamma: tdCarWeb.cerulean,
    delta: tdCarWeb.fireEngineRed,
    epsilon: tdCarWeb.white
  }
};
const {
  LegalInfo,
  LegalInfoCard,
  LegalInfoTitle,
  LegalInfoSubTitle,
  LegalInfoRadio
} = LegalInfoComponent;
/**
 * This component represents a modal with generic data
 * @param {boolean} isVisible Determines if the modal is visible or not.
 * @param {function} setIsVisible Function triggered when close the modal to hide it.
 * @param {string} modalTitle Modal title.
 * @param {function} onClickUsed Function is triggered when the use button is pressed, and sends the information of the selected card.
 * @param {component} children components to render inside the modal.
 * @param {object} formik Formik.
 */

export function SearchGeneric({
  isVisible,
  setIsVisible,
  modalTitle,
  onClickUsed,
  isDisabledButton = true,
  formik,
  isFetching,
  isLoading,
  isFetchingByPagination,
  list,
  setData,
  doClearSelected,
  INITIAL_VALUES,
  handlerPagination,
  paginationRef,
  type = 'holder',
  inputsConfig = {}
}) {
  const {
    theme
  } = useTheme();
  const {
    isMobile
  } = useMediaQuery();
  const {
    t
  } = useTranslation();
  return useMemo(() => {
    var _list$paginate;

    return jsx(Rodal, {
      visible: isVisible,
      showCloseButton: false,
      width: 1112,
      height: 650,
      onClose: setIsVisible,
      customStyles: { ...modalCustomStyle({
          isMobile,
          width: 1112,
          height: 'auto',
          maxHeight: 650
        }),
        borderRadius: '20px',
        overflow: 'hidden'
      }
    }, jsx("div", {
      css: [styles, {
        padding: '35px 25px 0 25px'
      }]
    }, jsx(DivRow, {
      className: "modals-row",
      style: {
        marginBottom: 30
      }
    }, jsx(DivColumn, {
      className: "modals-column"
    }, jsx("h1", {
      className: "modals-title",
      style: {
        fontSize: 20,
        marginTop: 'unset',
        color: themes[theme].alpha
      }
    }, modalTitle))), jsx("form", {
      onSubmit: formik.handleSubmit,
      disabled: isFetching || isLoading
    }, type === 'company' && jsx(CompanyInputs, {
      formik: formik,
      inputsConfig: inputsConfig
    }, jsx(SearchButton, {
      isButtonDisabled: isFetching
    })), type === 'holder' && jsx(HolderInputs, {
      formik: formik,
      inputsConfig: inputsConfig
    }, jsx(SearchButton, {
      isButtonDisabled: isFetching
    })), type === 'record' && jsx(RecordsInputs, {
      formik: formik
    }, jsx(SearchButton, {
      isButtonDisabled: isFetching
    }))), jsx(ScrollWrapper, {
      containerStyle: {
        minHeight: 185
      }
    }, jsx("div", {
      style: {
        display: 'flex',
        flexDirection: 'column',
        opacity: isFetchingByPagination ? 0.5 : 1
      }
    }, jsx("div", {
      style: {
        flex: 1
      }
    }, jsx(Cards, {
      type: type,
      data: list === null || list === void 0 ? void 0 : list.data,
      onClickCard: setData,
      radioButtonName: `${type}Radios`,
      clearSelected: doClearSelected
    })), !isLoading && jsx("div", {
      style: {
        marginTop: 10
      }
    }, jsx(Pagination, {
      total: list === null || list === void 0 ? void 0 : (_list$paginate = list.paginate) === null || _list$paginate === void 0 ? void 0 : _list$paginate.count,
      limit: INITIAL_VALUES.limit,
      returnSelectedPage: handlerPagination,
      activeButtonColor: "#0F7CB5",
      textColorActive: "#FFF",
      position: "right",
      paginationRef: paginationRef
    })))), jsx("div", {
      css: css`
              position: relative;
              ::before {
                content: '';
                position: absolute;
                top: 0;
                right: -100px;
                left: -100px;
                border-top: 1px solid ${themes[theme].beta};
              }
            `
    }), jsx("div", {
      className: "button-container",
      style: {
        paddingTop: 15,
        paddingBottom: 3,
        position: 'relative'
      }
    }, jsx(Button, {
      size: "md-icon",
      outline: true,
      className: "button blue",
      style: {
        color: themes[theme].gamma,
        border: `2px solid ${themes[theme].gamma}`,
        width: 139
      },
      onClick: setIsVisible
    }, t('Salir')), jsx(Button, {
      size: "md-icon",
      className: "button red",
      style: {
        background: themes[theme].delta,
        color: themes[theme].epsilon,
        width: 139
      },
      disabled: isDisabledButton || isFetching,
      onClick: onClickUsed
    }, t('Utilizar'), isFetching && jsx(SpinnerSmall, {
      style: {
        marginLeft: 10,
        width: 19,
        height: 19
      }
    })))));
  }, [isVisible, modalTitle, isDisabledButton, isFetching, formik.values, isFetching, isLoading, isFetchingByPagination, list]);
}
export function GenericSearchModal({
  onClose,
  title,
  buttons,
  children
}) {
  const {
    theme
  } = useTheme();
  const {
    isMobile
  } = useMediaQuery();
  return jsx(Rodal, {
    visible: true,
    showCloseButton: false,
    width: 1112,
    height: 650,
    onClose: onClose,
    customStyles: { ...modalCustomStyle({
        isMobile,
        width: 1112,
        height: 'auto',
        maxHeight: 650
      }),
      borderRadius: '20px',
      overflow: 'hidden'
    }
  }, jsx("div", {
    css: [styles, {
      padding: '35px 25px 0 25px'
    }]
  }, jsx(DivRow, {
    className: "modals-row",
    style: {
      marginBottom: 30
    }
  }, jsx(DivColumn, {
    className: "modals-column"
  }, jsx("h1", {
    className: "modals-title",
    style: {
      fontSize: 20,
      marginTop: 'unset',
      color: themes[theme].alpha
    }
  }, title))), children, jsx("div", {
    css: css`
            position: relative;
            ::before {
              content: '';
              position: absolute;
              top: 0;
              right: -100px;
              left: -100px;
              border-top: 1px solid ${themes[theme].beta};
            }
          `
  }), jsx("div", {
    className: "button-container",
    style: {
      paddingTop: 15,
      paddingBottom: 3,
      position: 'relative'
    }
  }, buttons)));
}
export function SearchButton({
  isButtonDisabled = false
}) {
  const {
    theme
  } = useTheme();
  const {
    t
  } = useTranslation();
  return jsx(DivColumn, {
    containerStyle: {
      marginLeft: 16,
      justifyContent: 'flex-end'
    }
  }, jsx(Button, {
    size: "md-icon",
    className: "button red",
    style: {
      border: 'none',
      background: themes[theme].delta,
      color: themes[theme].epsilon,
      borderRadius: 8,
      height: 38
    },
    disabled: isButtonDisabled,
    type: "submit"
  }, t('Buscar'), isButtonDisabled ? jsx(SpinnerSmall, {
    style: {
      marginLeft: 10,
      width: 19,
      height: 19
    }
  }) : ''));
}

const HolderCards = ({
  data
}) => {
  var _data$identification, _data$Identification;

  return jsx(React.Fragment, null, jsx(LegalInfoTitle, null, data.name), jsx(LegalInfoSubTitle, null, (_data$identification = data === null || data === void 0 ? void 0 : data.identification) !== null && _data$identification !== void 0 ? _data$identification : data === null || data === void 0 ? void 0 : (_data$Identification = data.Identification) === null || _data$Identification === void 0 ? void 0 : _data$Identification.value));
};

const RecordCards = ({
  data
}) => {
  const {
    t
  } = useTranslation();
  return jsx(React.Fragment, null, jsx(LegalInfoTitle, null, data.name), jsx(LegalInfoSubTitle, null, t('Número de registro'), ": ", data.code));
};

const CompanyCards = ({
  data
}) => {
  var _data$Country, _data$IdentificationT;

  return jsx(React.Fragment, null, jsx(LegalInfoTitle, null, data.name), jsx(LegalInfoSubTitle, null, data === null || data === void 0 ? void 0 : (_data$Country = data.Country) === null || _data$Country === void 0 ? void 0 : _data$Country.name), jsx(LegalInfoSubTitle, null, data === null || data === void 0 ? void 0 : (_data$IdentificationT = data.IdentificationType) === null || _data$IdentificationT === void 0 ? void 0 : _data$IdentificationT.name, " ", data.legalId), jsx(LegalInfoSubTitle, null, data.address));
};

export function Cards({
  data,
  onClickCard,
  radioButtonName,
  type = 'holder',
  clearSelected
}) {
  const {
    t
  } = useTranslation();
  const {
    isMobile
  } = useMediaQuery();
  return useMemo(() => jsx(React.Fragment, null, (data === null || data === void 0 ? void 0 : data.length) > 0 ? jsx(LegalInfo, null, data === null || data === void 0 ? void 0 : data.map(tempData => {
    var _tempData$id, _ref;

    return jsx(LegalInfoCard, {
      key: `${(_tempData$id = tempData === null || tempData === void 0 ? void 0 : tempData.id) !== null && _tempData$id !== void 0 ? _tempData$id : tempData === null || tempData === void 0 ? void 0 : tempData.referenceId}${(_ref = `-${tempData === null || tempData === void 0 ? void 0 : tempData.type}`) !== null && _ref !== void 0 ? _ref : ''}`,
      style: {
        height: 103
      }
    }, jsx(LegalInfoRadio, {
      name: radioButtonName,
      onChange: () => onClickCard(tempData),
      value: tempData.id,
      checked: clearSelected === true ? false : null
    }), jsx("div", null, type === 'holder' && jsx(HolderCards, {
      data: tempData
    }), type === 'record' && jsx(RecordCards, {
      data: tempData
    }), type === 'company' && jsx(CompanyCards, {
      data: tempData
    }), type === 'manufacturer' && jsx(ManufacturerCards, {
      data: tempData
    })));
  })) : jsx(DivRow, {
    containerStyle: {
      minHeight: isMobile ? 300 : 390
    }
  }, jsx(DivColumn, {
    containerStyle: {
      justifyContent: 'center',
      alignItems: 'flex-end'
    }
  }, jsx(EmptyImage, {
    style: {
      marginRight: 54
    }
  })), jsx(DivColumn, {
    containerStyle: {
      justifyContent: 'center',
      flexBasis: '25%'
    }
  }, jsx("h1", {
    style: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 18,
      letterSpacing: '0.01em',
      color: '#0F7CB5',
      margin: '0 0 17px'
    }
  }, t('No hay resultados para mostrar')), jsx("p", {
    style: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      letterSpacing: '0.01em',
      color: '#2B2D42',
      margin: '0 0 17px',
      maxWidth: 619
    }
  }, t('No se han encontrado resultados con los criterios de búsqueda ingresados Por favor verifique la información e intente de nuevo'))))), [data, type, clearSelected]);
}
export function ManufacturerCards({
  data
}) {
  return jsx(React.Fragment, null, jsx(LegalInfoTitle, null, data.name), jsx(LegalInfoSubTitle, null, data.identification));
}
export function HolderInputs({
  formik,
  children,
  inputsConfig
}) {
  const {
    theme
  } = useTheme();
  const {
    t
  } = useTranslation();
  return useMemo(() => {
    var _inputsConfig$identif, _inputsConfig$name, _inputsConfig$name2, _inputsConfig$name3, _inputsConfig$name4;

    return jsx(DivRow, {
      className: "modals-row",
      style: {
        marginBottom: 28
      }
    }, jsx(DivColumn, {
      containerStyle: {
        marginRight: 9,
        flexBasis: '30%'
      }
    }, jsx(FormLabel, {
      containerStyle: {
        margin: 0
      }
    }, t('Número de identificación'), ":"), jsx(InputText, {
      autoComplete: "off",
      placeholder: t('Ingresar_placeholder'),
      name: "identification",
      onChange: formik.handleChange,
      value: formik.values.identification,
      error: formik.errors.identification,
      touched: formik.touched.identification,
      width: "100%",
      maxLength: 30,
      mask: (inputsConfig === null || inputsConfig === void 0 ? void 0 : (_inputsConfig$identif = inputsConfig.identification) === null || _inputsConfig$identif === void 0 ? void 0 : _inputsConfig$identif.mask) ? inputsConfig.identification.mask : {
        mask: '9-9999-9999'
      },
      style: {
        paddingLeft: 32,
        height: 38
      }
    }, jsx(SearchIcon, {
      css: {
        width: 18,
        height: 18,
        path: {
          fill: themes[theme].gamma
        },
        position: 'absolute',
        top: 10,
        left: 8
      }
    })), jsx(FormError, {
      msg: formik.errors.identification,
      touched: formik.touched.identification
    })), jsx(DivColumn, {
      containerStyle: {
        marginLeft: 9,
        flexBasis: '30%'
      }
    }, jsx(FormLabel, {
      containerStyle: {
        margin: 0
      }
    }, t('Nombre'), ":"), jsx(InputText, {
      autoComplete: "off",
      placeholder: t('Ingresar_placeholder'),
      name: "name",
      onChange: formik.handleChange,
      value: formik.values.name,
      error: formik.errors.name,
      touched: formik.touched.name,
      width: "100%",
      maxLength: (inputsConfig === null || inputsConfig === void 0 ? void 0 : (_inputsConfig$name = inputsConfig.name) === null || _inputsConfig$name === void 0 ? void 0 : _inputsConfig$name.maxLength) ? inputsConfig === null || inputsConfig === void 0 ? void 0 : (_inputsConfig$name2 = inputsConfig.name) === null || _inputsConfig$name2 === void 0 ? void 0 : _inputsConfig$name2.maxLength : 50,
      mask: (inputsConfig === null || inputsConfig === void 0 ? void 0 : (_inputsConfig$name3 = inputsConfig.name) === null || _inputsConfig$name3 === void 0 ? void 0 : _inputsConfig$name3.mask) ? inputsConfig === null || inputsConfig === void 0 ? void 0 : (_inputsConfig$name4 = inputsConfig.name) === null || _inputsConfig$name4 === void 0 ? void 0 : _inputsConfig$name4.mask : {
        regex: '^[A-Za-záéíóúüñÁÉÍÓÚÜÑ ]*$'
      },
      style: {
        paddingLeft: 32,
        height: 38
      }
    }, jsx(SearchIcon, {
      css: {
        width: 18,
        height: 18,
        path: {
          fill: themes[theme].gamma
        },
        position: 'absolute',
        top: 10,
        left: 8
      }
    })), jsx(FormError, {
      msg: formik.errors.name,
      touched: formik.touched.name
    })), children);
  }, [formik.values.identification, formik.values.name, children]);
}
export function RecordsInputs({
  formik,
  children
}) {
  const {
    theme
  } = useTheme();
  const {
    t
  } = useTranslation();
  return useMemo(() => jsx(DivRow, {
    className: "modals-row",
    style: {
      marginBottom: 28
    }
  }, jsx(DivColumn, {
    containerStyle: {
      marginRight: 9,
      flexBasis: '30%'
    }
  }, jsx(FormLabel, {
    containerStyle: {
      margin: 0
    }
  }, t('Nombre del registro'), ":"), jsx(InputText, {
    autoComplete: "off",
    placeholder: t('Ingresar_placeholder'),
    name: "productName",
    onChange: formik.handleChange,
    value: formik.values.productName,
    error: formik.errors.productName,
    touched: formik.touched.productName,
    width: "100%",
    maxLength: 150,
    mask: {
      regex: '^[a-zA-Z0-9áéíóúüñÁÉÍÓÚÜÑ -]*$'
    },
    style: {
      paddingLeft: 32,
      height: 38
    }
  }, jsx(SearchIcon, {
    css: {
      width: 18,
      height: 18,
      path: {
        fill: themes[theme].gamma
      },
      position: 'absolute',
      top: 10,
      left: 8
    }
  })), jsx(FormError, {
    msg: formik.errors.productName,
    touched: formik.touched.productName
  })), jsx(DivColumn, {
    containerStyle: {
      marginLeft: 9,
      flexBasis: '30%'
    }
  }, jsx(FormLabel, {
    containerStyle: {
      margin: 0
    }
  }, t('Número de registro sanitario'), ":"), jsx(InputText, {
    autoComplete: "off",
    placeholder: t('Ingresar_placeholder'),
    name: "registerNumber",
    onChange: formik.handleChange,
    value: formik.values.registerNumber,
    error: formik.errors.registerNumber,
    touched: formik.touched.registerNumber,
    width: "100%",
    maxLength: 30,
    mask: {
      regex: '^[a-zA-Z0-9áéíóúüñÁÉÍÓÚÜÑ -]*$'
    },
    style: {
      paddingLeft: 32,
      height: 38
    }
  }, jsx(SearchIcon, {
    css: {
      width: 18,
      height: 18,
      path: {
        fill: themes[theme].gamma
      },
      position: 'absolute',
      top: 10,
      left: 8
    }
  })), jsx(FormError, {
    msg: formik.errors.registerNumber,
    touched: formik.touched.registerNumber
  })), children), [formik.values.productName, formik.values.registerNumber, children]);
}
export function CompanyInputs({
  formik,
  children,
  inputsConfig
}) {
  const {
    theme
  } = useTheme();
  const {
    t
  } = useTranslation();
  const {
    data: countries = []
  } = useCountries();
  return useMemo(() => {
    var _inputsConfig$name5, _inputsConfig$name6, _formik$values;

    return jsx(DivRow, {
      className: "modals-row",
      style: {
        marginBottom: 28
      }
    }, jsx(DivColumn, {
      containerStyle: {
        marginRight: 9
      }
    }, jsx(FormLabel, {
      containerStyle: {
        margin: 0
      }
    }, t('Nombre de la empresa'), ":"), jsx(InputText, {
      autoComplete: "off",
      placeholder: t('Ingresar_placeholder'),
      name: "name",
      onChange: formik.handleChange,
      value: formik.values.name,
      error: formik.errors.name,
      touched: formik.touched.name,
      width: "100%",
      maxLength: 255,
      mask: (inputsConfig === null || inputsConfig === void 0 ? void 0 : (_inputsConfig$name5 = inputsConfig.name) === null || _inputsConfig$name5 === void 0 ? void 0 : _inputsConfig$name5.mask) ? inputsConfig === null || inputsConfig === void 0 ? void 0 : (_inputsConfig$name6 = inputsConfig.name) === null || _inputsConfig$name6 === void 0 ? void 0 : _inputsConfig$name6.mask : {
        regex: '^[a-zA-Z0-9áéíóúüñÁÉÍÓÚÜÑ -]*$'
      },
      style: {
        paddingLeft: 32,
        height: 38
      }
    }, jsx(SearchIcon, {
      css: {
        width: 18,
        height: 18,
        path: {
          fill: themes[theme].gamma
        },
        position: 'absolute',
        top: 10,
        left: 8
      }
    })), jsx(FormError, {
      msg: formik.errors.name,
      touched: formik.touched.name
    })), (!((_formik$values = formik.values) === null || _formik$values === void 0 ? void 0 : _formik$values.hideCountry) || formik.values.hideCountry !== 1) && jsx(DivColumn, {
      containerStyle: {
        marginLeft: 9
      }
    }, jsx(FormLabel, {
      containerStyle: {
        margin: 0
      }
    }, t('País'), ":"), jsx(DropDownCombo, {
      items: countries,
      placeholder: t('Ingresar_placeholder'),
      style: {
        margin: 0,
        width: '100%',
        height: 34
      },
      onChange: ({
        id
      }) => formik.setFieldValue('countryId', id || ''),
      value: formik.values.countryId,
      error: formik.errors.countryId,
      touched: formik.touched.countryId
    }), jsx(FormError, {
      msg: formik.errors.countryId,
      touched: formik.touched.countryId
    })), jsx(DivColumn, {
      containerStyle: {
        marginLeft: 9
      }
    }, jsx(FormLabel, {
      containerStyle: {
        margin: 0
      }
    }, t('Cédula jurídica'), ":"), jsx(InputText, {
      autoComplete: "off",
      placeholder: t('Ingresar_placeholder'),
      name: "legalId",
      onChange: formik.handleChange,
      value: formik.values.legalId,
      error: formik.errors.legalId,
      touched: formik.touched.legalId,
      width: "100%",
      mask: {
        mask: '9-999-999999'
      },
      maxLength: 30,
      style: {
        paddingLeft: 32,
        height: 38
      }
    }, jsx(SearchIcon, {
      css: {
        width: 18,
        height: 18,
        path: {
          fill: themes[theme].gamma
        },
        position: 'absolute',
        top: 10,
        left: 8
      }
    })), jsx(FormError, {
      msg: formik.errors.legalId,
      touched: formik.touched.legalId
    })), jsx(DivColumn, {
      containerStyle: {
        marginLeft: 9
      }
    }, jsx(FormLabel, {
      containerStyle: {
        margin: 0
      }
    }, t('Dirección'), ":"), jsx(InputText, {
      autoComplete: "off",
      placeholder: t('Ingresar_placeholder'),
      name: "address",
      onChange: formik.handleChange,
      value: formik.values.address,
      error: formik.errors.address,
      touched: formik.touched.address,
      width: "100%",
      mask: {
        regex: '^[a-zA-Z0-9áéíóúüñÁÉÍÓÚÜÑ .,;]*$'
      },
      maxLength: 255,
      style: {
        paddingLeft: 32,
        height: 38
      }
    }, jsx(SearchIcon, {
      css: {
        width: 18,
        height: 18,
        path: {
          fill: themes[theme].gamma
        },
        position: 'absolute',
        top: 10,
        left: 8
      }
    })), jsx(FormError, {
      msg: formik.errors.address,
      touched: formik.touched.address
    })), children);
  }, [formik.values.name, formik.values.countryId, formik.values.legalId, formik.values.address, countries, children]);
}