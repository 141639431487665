/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '17',
  height: '12',
  viewBox: '0 0 17 12',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M1.29922 0.8125C0.444868 0.8125 -0.0160417 1.81462 0.539964 2.46329L7.74074 10.8642C8.13984 11.3298 8.86016 11.3298 9.25926 10.8642L16.46 2.46329C17.016 1.81462 16.5551 0.8125 15.7008 0.8125H1.29922Z',
    fill: 'white',
  }),
  name: 'CaretDown',
})
