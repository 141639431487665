import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '30',
  height: '30',
  viewBox: '0 0 30 30',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M15 17.8125C16.2426 17.8125 17.25 16.8051 17.25 15.5625C17.25 14.3199 16.2426 13.3125 15 13.3125C13.7574 13.3125 12.75 14.3199 12.75 15.5625C12.75 16.8051 13.7574 17.8125 15 17.8125Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M21.1875 11.0625H19.1133C19.0078 11.0625 18.877 10.9943 18.7751 10.8867L17.8631 9.44742C17.8487 9.42458 17.8326 9.40284 17.815 9.38238C17.5 9.015 17.0742 8.8125 16.6172 8.8125H13.3828C12.9258 8.8125 12.5 9.015 12.185 9.38238C12.1674 9.40284 12.1513 9.42458 12.1369 9.44742L11.2249 10.8888C11.1469 10.9739 11.0372 11.0646 10.9219 11.0646V10.7834C10.9219 10.6342 10.8626 10.4911 10.7571 10.3856C10.6516 10.2801 10.5086 10.2209 10.3594 10.2209H9.51562C9.36644 10.2209 9.22337 10.2801 9.11788 10.3856C9.01239 10.4911 8.95312 10.6342 8.95312 10.7834V11.0646H8.8125C8.36509 11.0651 7.93614 11.243 7.61977 11.5594C7.3034 11.8757 7.12547 12.3047 7.125 12.7521V19.5C7.12547 19.9474 7.3034 20.3764 7.61977 20.6927C7.93614 21.0091 8.36509 21.187 8.8125 21.1875H21.1875C21.6349 21.187 22.0639 21.0091 22.3802 20.6927C22.6966 20.3764 22.8745 19.9474 22.875 19.5V12.75C22.8745 12.3026 22.6966 11.8736 22.3802 11.5573C22.0639 11.2409 21.6349 11.063 21.1875 11.0625ZM15 18.9375C14.3325 18.9375 13.68 18.7396 13.125 18.3687C12.5699 17.9979 12.1374 17.4708 11.8819 16.8541C11.6265 16.2374 11.5596 15.5588 11.6898 14.9041C11.8201 14.2494 12.1415 13.648 12.6135 13.176C13.0855 12.704 13.6869 12.3826 14.3416 12.2523C14.9963 12.1221 15.6749 12.189 16.2916 12.4444C16.9083 12.6999 17.4354 13.1324 17.8062 13.6875C18.1771 14.2425 18.375 14.895 18.375 15.5625C18.374 16.4573 18.0181 17.3151 17.3854 17.9479C16.7526 18.5806 15.8948 18.9365 15 18.9375Z',
      fill: 'white',
    })
  ),
  name: 'Camera',
})
