/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '21',
  height: '21',
  viewBox: '0 0 21 21',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M10.5002 0.083252C4.74704 0.083252 0.0834961 4.74679 0.0834961 10.4999C0.0834961 16.253 4.74704 20.9166 10.5002 20.9166C16.2533 20.9166 20.9168 16.253 20.9168 10.4999C20.9168 4.74679 16.2533 0.083252 10.5002 0.083252ZM10.5002 19.3541C9.33735 19.354 8.18594 19.1249 7.11166 18.6799C6.03739 18.2348 5.06129 17.5825 4.23911 16.7602C3.41692 15.938 2.76475 14.9618 2.31982 13.8875C1.87489 12.8131 1.64593 11.6617 1.646 10.4989C1.64606 9.33606 1.87517 8.18465 2.32022 7.11037C2.76527 6.0361 3.41756 5.06001 4.23984 4.23782C5.06212 3.41563 6.0383 2.76346 7.11262 2.31853C8.18695 1.87361 9.33839 1.64464 10.5012 1.64471C12.8496 1.64485 15.1018 2.57788 16.7623 4.23856C18.4227 5.89923 19.3555 8.15151 19.3554 10.4999C19.3552 12.8483 18.4222 15.1005 16.7615 16.761C15.1009 18.4215 12.8486 19.3542 10.5002 19.3541ZM10.5002 11.0208C7.33558 11.0208 4.771 12.8208 4.771 14.6666C4.771 16.5124 7.33558 18.3124 10.5002 18.3124C13.6647 18.3124 16.2293 16.5124 16.2293 14.6666C16.2293 12.8208 13.6647 11.0208 10.5002 11.0208ZM10.5002 10.4999C11.329 10.4999 12.1238 10.1707 12.7099 9.58463C13.2959 8.99858 13.6252 8.20372 13.6252 7.37492C13.6252 6.54612 13.2959 5.75126 12.7099 5.16521C12.1238 4.57916 11.329 4.24992 10.5002 4.24992C9.67136 4.24992 8.87651 4.57916 8.29045 5.16521C7.7044 5.75126 7.37516 6.54612 7.37516 7.37492C7.37516 8.20372 7.7044 8.99858 8.29045 9.58463C8.87651 10.1707 9.67136 10.4999 10.5002 10.4999Z',
    fill: 'white',
  }),
  name: 'UserCircle',
})
