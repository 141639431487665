import { init } from '@elastic/apm-rum'
const {
  REACT_APP_APM_SERVICENAME,
  REACT_APP_APM_ACTIVE,
  REACT_APP_APM_SERVER,
  REACT_APP_ENV,
} = process.env
export default function useApm({ user = null } = {}) {
  const apm = init({
    serviceName: REACT_APP_APM_SERVICENAME,
    serverUrl: REACT_APP_APM_SERVER,
    environment: REACT_APP_ENV || 'missing',
    ignoreTransactions: [/socket.io*/],
    active: parseInt(REACT_APP_APM_ACTIVE, 10) === 1,
  })

  if (user) {
    apm.setUserContext({
      id: user.id,
      username: user.fullName,
      email: user.email,
    })
  }

  return apm
}
