import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '30',
  height: '18',
  viewBox: '0 0 30 18',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M27.0417 0H2.29167C1.02659 0.00447591 0.00447591 1.0266 0 2.29167V15.3083C0.00465495 16.5732 1.02659 17.5953 2.29167 17.6H27.0417C28.3066 17.5955 29.3287 16.5734 29.3333 15.3083V2.29167C29.3289 1.02212 28.3067 0 27.0417 0ZM1.96636 1.96636C2.05337 1.87917 2.16348 1.83333 2.29185 1.83333H27.0418C27.1656 1.83333 27.2755 1.88364 27.3672 1.96636C27.4497 2.05355 27.5002 2.16348 27.5002 2.29167V3.37788H1.83351V2.29167C1.83351 2.1633 1.884 2.05337 1.96636 1.96636ZM27.3672 15.6336C27.28 15.7208 27.17 15.7667 27.0418 15.7667H2.29185C2.16813 15.7667 2.05802 15.7164 1.96636 15.6336C1.88382 15.5465 1.83351 15.4365 1.83351 15.3083V6.58621H27.5002V15.3083C27.5002 15.4367 27.4499 15.5466 27.3672 15.6336Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M19.3646 11.6875H23.8333V14.2083H19.3646V11.6875Z',
      fill: 'white',
    })
  ),
  name: 'CreditCard',
})
