import { css, keyframes } from '@emotion/core';
import { migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from '../Colors';
import { mediaQuery } from '../../utils';
const themes = {
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.brightGray,
    // #EBF1F4
    beta: migratoryWebCitizen.cerulean,
    // #0F7CB5
    gamma: migratoryWebCitizen.fireEngineRed,
    // #CE1625
    delta: migratoryWebCitizen.white,
    epsilon: migratoryWebCitizen.cerulean
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.brightGray,
    // #EBF1F4
    beta: sanitarianRegistersWeb.cerulean,
    // #0F7CB5
    gamma: sanitarianRegistersWeb.fireEngineRed,
    // #CE1625
    delta: sanitarianRegistersWeb.white,
    epsilon: sanitarianRegistersWeb.cerulean
  },
  tdCarWeb: {
    alpha: tdCarWeb.brightGray,
    // #EBF1F4
    beta: tdCarWeb.cerulean,
    // #0F7CB5
    gamma: tdCarWeb.fireEngineRed,
    // #CE1625
    delta: tdCarWeb.white,
    epsilon: tdCarWeb.cerulean
  }
};
const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;
export default function ({
  theme = 'migratoryWebCitizen'
}) {
  return css`
    flex: 1;

    .header-container {
      background-color: ${themes[theme].alpha};
      padding: 40px;
      ${mediaQuery[0]} {
        padding: 19px 16px;
      }
    }

    .header-data {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 0 65px;
      ${mediaQuery[0]} {
        padding: 0;
        position: relative;
        margin-top: 19px;
      }

      .button-help {
        ${mediaQuery[0]} {
          position: absolute;
          top: 16px;
          right: 0;
          width: 96px !important;
          height: 34px !important;
          margin-right: 0 !important;
          padding: 11px 9px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-size: 12px !important;
        }

        span {
          ${mediaQuery[0]} {
            margin-top: 0;
          }
        }

        .icon {
          width: 30px;
          height: 30px;
          ${mediaQuery[0]} {
            width: 18px;
            height: 18px;
            margin-right: 10px;
          }
        }
      }

      .button-create-procedure {
        ${mediaQuery[0]} {
          display: none;
        }
      }

      .procedure {
        display: flex;
        align-items: center;
        margin-top: 36px;
        ${mediaQuery[0]} {
          flex-wrap: wrap;
          margin-top: 0px;
          flex-direction: column;
          align-items: flex-start;
        }

        .logo {
          width: 100px;
          margin-right: 20px;
        }

        h1 {
          margin: 0;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          text-transform: uppercase;
          color: ${themes[theme].beta};
          ${mediaQuery[0]} {
            flex-grow: 1;
            margin-top: 26px;
          }
        }
      }

      button {
        background-color: ${themes[theme].gamma};
        color: ${themes[theme].delta};

        svg {
          width: 32px;
          height: 32px;
        }
      }
    }

    .procedure-data {
      padding: 22px 100px;
      ${mediaQuery[0]} {
        padding: 18px 16px;
      }
    }

    .details-list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0;
      margin: 0;
      list-style: none;
      ${mediaQuery[0]} {
        flex-direction: column;
      }

      .details-list-item {
        width: 32%;
        background-color: #f1f6f6;
        border-radius: 6px;
        padding: 22px 24px;
        box-sizing: border-box;
        ${mediaQuery[0]} {
          width: 100%;
          margin-bottom: 11px;
          padding: 26px;
        }

        p {
          ${mediaQuery[0]} {
            margin: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 15px;
            letter-spacing: 0.01em;
          }
        }
      }

      .details-title {
        color: ${themes[theme].epsilon};
        font-weight: bold;
        text-transform: uppercase;

        ${mediaQuery[0]} {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.05em;
          margin-bottom: 19px;
          display: block;
        }
      }
    }

    .requirements-container {
      background-color: #f1f6f6;
      border-radius: 6px;
      margin-top: 15px;
      padding: 22px 24px;
      min-height: 250px;
      ${mediaQuery[0]} {
        margin-top: 0px;
        padding: 26px;
      }

      .requirements-title {
        color: ${themes[theme].epsilon};
        font-weight: bold;
        text-transform: uppercase;
        ${mediaQuery[0]} {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.05em;
          margin-bottom: 19px;
          display: block;
        }
      }

      p {
        ${mediaQuery[0]} {
          margin: 0 !important;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: 0.01em;
        }
      }
      .requirements-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        color: #2b2d42;
        margin-top: 50px;
        list-style: none;
        ${mediaQuery[0]} {
          padding: 0;
          padding-left: 22px;
          margin-top: 20px;
        }

        .requirements-list-item {
          width: 40%;
          margin-bottom: 20px;
          margin-right: 20px;
          ${mediaQuery[0]} {
            width: 100%;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 24px;
            margin-bottom: 0px;
            letter-spacing: 0.01em;
          }

          &:before {
            content: '⦁';
            color: ${themes[theme].epsilon};
            margin-left: -1em;
            margin-right: 10px;
          }
        }

        .requirements-list-item:nth-of-type(odd) {
          margin-right: 20px;
        }
      }
    }

    .button-create-procedure-responsive {
      display: none;
      border: none;
      background: ${themes[theme].gamma};
      border-radius: 8px;
      color: ${themes[theme].alpha};
      width: 96%;
      margin: 0 auto;
      min-height: 55px;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.01em;
      margin-top: 14px;

      ${mediaQuery[0]} {
        display: flex;
      }

      svg {
        margin-right: 10px;
        width: 30px;
        height: 30px;
      }
      .icon {
        color: ${themes[theme].delta};
      }
    }

    .skeleton-p {
      display: block;
      height: 10px;
      margin-top: 1em;
      position: relative;
      overflow: hidden;
      background-color: #0d1f2d;
      width: 90%;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 0.2) 20%,
          rgba(255, 255, 255, 0.5) 60%,
          rgba(255, 255, 255, 0)
        );
        animation: ${shimmer} 1s infinite;
        content: '';
      }
    }

    .skeleton-title {
      display: block;
      height: 20px;
      position: relative;
      overflow: hidden;
      background-color: #0d1f2d;
      width: 400px;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 0.2) 20%,
          rgba(255, 255, 255, 0.5) 60%,
          rgba(255, 255, 255, 0)
        );
        animation: ${shimmer} 1s infinite;
        content: '';
      }
    }

    .clock-container {
      margin: 16px 0;
    }
  `;
}