function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon, FolderIcon } from '../../icon';
import { mediaQuery } from '../../utils';
import { phytosanitaryWeb, migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from '../Colors';
/**
 * This component represent a container in the folder.
 * @param {*} children What will be shown inside the container.
 */

export function Container({
  children
}) {
  return jsx("div", {
    css: style
  }, jsx("ul", {
    "data-testid": "folder-list",
    className: "folder-list"
  }, children));
}
/**
 * This component represent an item.
 * @param {string} name Folder name.
 * @param {*} props All other supported props.
 */

export function Item({
  name,
  type,
  date,
  ...props
}) {
  return jsx("li", _extends({
    className: "folder-item"
  }, props), jsx(FolderIcon, {
    className: "icon-folder"
  }), jsx("h4", {
    className: "name"
  }, name), type && jsx("h4", {
    className: "txtAttributes"
  }, "Tipo : ", type), date && jsx("h4", {
    className: "txtAttributes"
  }, "Fecha de registro : ", date));
}
/**
 * This component represent the folder header.
 * @param {string} title Folder header title.
 * @param {*} children What will be shown inside the folder header div.
 */

export function Header({
  title,
  children
}) {
  return jsx("div", {
    className: "header",
    css: headerStyle
  }, jsx("h1", {
    className: "title"
  }, title), jsx("div", {
    className: "container-folders"
  }, children));
}
/**
 * This component represent a link in the folder.
 * @param {string} path Path to navigate when click in the folder.
 * @param {string} description Link text in the folder.
 */

export function Navigation({
  path,
  description
}) {
  return jsx(Link, {
    to: path,
    className: "back",
    css: navigationStyle
  }, jsx(ArrowLeftIcon, {
    className: "icon-back"
  }), description);
}
const themes = {
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.white,
    beta: migratoryWebCitizen.prussianBlue,
    gamma: migratoryWebCitizen.blackPearl,
    delta: migratoryWebCitizen.valhalla,
    epsilon: migratoryWebCitizen.cerulean,
    dseta: migratoryWebCitizen.fireEngineRed
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.white,
    beta: sanitarianRegistersWeb.deepCerulean,
    gamma: sanitarianRegistersWeb.blackPearl,
    delta: sanitarianRegistersWeb.valhalla,
    epsilon: sanitarianRegistersWeb.cerulean,
    dseta: sanitarianRegistersWeb.fireEngineRed
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.white,
    beta: phytosanitaryWeb.sherpaBlue,
    gamma: phytosanitaryWeb.blackPearl,
    delta: phytosanitaryWeb.mantis,
    epsilon: phytosanitaryWeb.allports,
    dseta: phytosanitaryWeb.mantis
  },
  tdCarWeb: {
    alpha: tdCarWeb.white,
    beta: tdCarWeb.prussianBlue,
    gamma: tdCarWeb.blackPearl,
    delta: tdCarWeb.valhalla,
    epsilon: tdCarWeb.cerulean,
    dseta: tdCarWeb.fireEngineRed
  }
};

const navigationStyle = ({
  theme = 'migratoryWebCitizen'
}) => css`
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${themes[theme].epsilon} !important;
  margin-bottom: unset !important;
  ${mediaQuery[0]} {
    margin-top: -20px;
    margin-bottom: 15px !important;
    color: ${themes[theme].dseta} !important;
    font-size: 12px;
  }

  .icon-back {
    width: 20px;
    margin-right: 12px;
    color: currentColor;
    ${mediaQuery[0]} {
      width: 16px;
    }
  }
`;

const headerStyle = ({
  theme = 'migratoryWebCitizen'
}) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  ${mediaQuery[0]} {
    display: block;
    margin-bottom: 0px;
  }

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${themes[theme].beta};
  }
  .container-folders {
    display: flex;
  }

  .search {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 12px;
    ${mediaQuery[0]} {
      display: none;
    }

    input {
      border-radius: 4px;
      background-color: #fff;
      border: 1px solid #0f7cb5;
      box-sizing: border-box;
      height: 45px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #bdbdbd;
      min-width: 297px;
      padding-left: 34px;
    }

    .icon {
      position: absolute;
      left: 0;
      margin: 0;
      color: #0f7cb5;
      width: 18px;
      height: 18px;
      margin-left: 12px;
    }
  }
`;

const style = ({
  theme = 'migratoryWebCitizen'
}) => css`
  .folder-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 1fr 1fr;
    ${mediaQuery[0]} {
      grid-template-columns: 1fr 1fr;
      gap: 9px;
    }

    .folder-item.add {
      display: none;
      ${mediaQuery[0]} {
        background-color: #0f7cb5;
        color: #fff;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .folder-item {
      background-color: ${themes[theme].alpha};
      border-radius: 10px;
      padding: 27px;
      cursor: pointer;
      .icon-folder {
        width: 44px;
        height: 37.5px;
        color: ${themes[theme].beta};
      }

      .name {
        margin: 15px 0 3px 0;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: ${themes[theme].gamma};
      }

      .txtAttributes {
        margin: 0px 0 3px 0;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: ${themes[theme].gamma};
      }
    }
  }
`;