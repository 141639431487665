/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '111',
  height: '111',
  viewBox: '0 0 111 111',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M55.4999 0.0834961C24.9099 0.0834961 0.083252 24.9102 0.083252 55.5002C0.083252 86.0902 24.9099 110.917 55.4999 110.917C86.0899 110.917 110.917 86.0902 110.917 55.5002C110.917 24.9102 86.0899 0.0834961 55.4999 0.0834961ZM40.482 79.2739L20.5874 59.3793C20.0744 58.8663 19.6674 58.2572 19.3897 57.5868C19.1121 56.9165 18.9691 56.198 18.9691 55.4725C18.9691 54.7469 19.1121 54.0284 19.3897 53.3581C19.6674 52.6877 20.0744 52.0786 20.5874 51.5656C21.1005 51.0525 21.7096 50.6455 22.3799 50.3679C23.0503 50.0902 23.7687 49.9473 24.4943 49.9473C25.2199 49.9473 25.9383 50.0902 26.6087 50.3679C27.279 50.6455 27.8881 51.0525 28.4012 51.5656L44.4166 67.5256L82.5433 29.3989C83.5794 28.3627 84.9848 27.7806 86.4501 27.7806C87.9155 27.7806 89.3208 28.3627 90.357 29.3989C91.3932 30.4351 91.9753 31.8404 91.9753 33.3058C91.9753 34.7712 91.3932 36.1765 90.357 37.2127L48.2958 79.2739C47.7831 79.7877 47.1741 80.1952 46.5037 80.4733C45.8333 80.7514 45.1147 80.8945 44.3889 80.8945C43.6631 80.8945 42.9444 80.7514 42.274 80.4733C41.6037 80.1952 40.9947 79.7877 40.482 79.2739Z',
    fill: 'white',
  }),
  name: 'CheckCircle',
})
