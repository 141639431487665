import { css } from '@emotion/core';
import { mediaQuery, HEX2RGB } from '../../utils';
import { phytosanitaryWeb, migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from '../Colors';
const themes = {
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.antiFlashWhite,
    beta: phytosanitaryWeb.sherpaBlue,
    gamma: phytosanitaryWeb.sherpaBlue,
    delta: phytosanitaryWeb.lightSeaGreen,
    epsilon: phytosanitaryWeb.salem,
    dzeta: phytosanitaryWeb.morningGlory,
    eta: phytosanitaryWeb.valhalla,
    theta: phytosanitaryWeb.antiFlashWhite,
    kappa: phytosanitaryWeb.sherpaBlue
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.brightGray,
    beta: migratoryWebCitizen.cerulean,
    gamma: migratoryWebCitizen.valhalla,
    delta: migratoryWebCitizen.geyser,
    epsilon: migratoryWebCitizen.fireEngineRed,
    dzeta: migratoryWebCitizen.AzureishWhite,
    eta: migratoryWebCitizen.valhalla,
    theta: migratoryWebCitizen.pacificBlue,
    kappa: migratoryWebCitizen.prussianBlue
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.brightGray,
    beta: sanitarianRegistersWeb.cerulean,
    gamma: sanitarianRegistersWeb.valhalla,
    delta: sanitarianRegistersWeb.geyser,
    epsilon: sanitarianRegistersWeb.fireEngineRed,
    dzeta: sanitarianRegistersWeb.AzureishWhite,
    eta: sanitarianRegistersWeb.valhalla,
    theta: sanitarianRegistersWeb.pacificBlue,
    kappa: sanitarianRegistersWeb.prussianBlue
  },
  tdCarWeb: {
    alpha: tdCarWeb.brightGray,
    beta: tdCarWeb.cerulean,
    gamma: tdCarWeb.valhalla,
    delta: tdCarWeb.geyser,
    epsilon: tdCarWeb.fireEngineRed,
    dzeta: tdCarWeb.AzureishWhite,
    eta: tdCarWeb.valhalla,
    theta: tdCarWeb.pacificBlue,
    kappa: tdCarWeb.prussianBlue
  }
};
export default (({
  theme = 'migratoryWebCitizen'
}) => css`
  display: flex;
  box-sizing: border-box;
  ${mediaQuery[0]} {
    flex-direction: column;
  }

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: ${themes[theme].kappa};
    margin: 25px 0px;
    ${mediaQuery[0]} {
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.01em;
    }
  }

  .paragraph {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: ${themes[theme].eta};
    ${mediaQuery[0]} {
      margin: 0 0 10px 0;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .back {
    color: ${themes[theme].epsilon};
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 28px;
    ${mediaQuery[0]} {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 17px;
    }

    .back-icon {
      color: ${themes[theme].epsilon};
      width: 28px;
      height: 24px;
      margin-right: 8px;
      ${mediaQuery[0]} {
        width: 16px;
        height: 16px;
      }
    }
  }

  .container {
    background: ${themes[theme].alpha};
    border-radius: 6px;
    width: 20%;
    margin-right: 13px;
    min-height: 470px;
    ${mediaQuery[0]} {
      width: 100%;
      margin-right: 0px;
      min-height: auto;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .logo-container {
      padding: 23px 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      ${mediaQuery[0]} {
        display: none;
      }

      .logo {
        width: 162px;
        height: 80px;

        @media (max-width: 768px) {
          width: 100%;
          max-width: 162px;
          height: auto;
        }
      }

      @media (max-width: 768px) {
        padding: 5px 5px;
      }
    }

    .options {
      list-style: none;
      padding: 0;
      margin: 0;
      color: ${themes[theme].beta};
      margin-bottom: 17px;
      ${mediaQuery[0]} {
        display: flex;
        overflow-x: scroll;
        color: rgba(${HEX2RGB(themes[theme].theta).join(',')}, 0.4);
        margin-top: 17px;
      }
    }

    .option {
      text-transform: uppercase;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      border-bottom: 1px solid ${themes[theme].delta};
      text-align: center;
      padding: 25px 10px;
      cursor: pointer;
      ${mediaQuery[0]} {
        white-space: nowrap;
        text-transform: unset;
        line-height: 30px;
        letter-spacing: 0.03em;
        margin: 0 10px;
        border-bottom: unset;
        height: 30px;
        box-sizing: border-box;
        padding: 0;
      }

      &:first-of-type {
        border-top: 1px solid ${themes[theme].delta};
        ${mediaQuery[0]} {
          border-top: unset;
        }
      }

      &:hover {
        background-color: ${themes[theme].dzeta};
        ${mediaQuery[0]} {
          background-color: unset;
        }
      }
    }

    .active {
      background-color: ${themes[theme].dzeta};
      ${mediaQuery[0]} {
        color: ${themes[theme].theta};
        background-color: unset;
        border-bottom: 4px solid ${themes[theme].theta};
      }
    }
  }

  .display {
    background-color: ${themes[theme].alpha};
    border-radius: 6px;
    width: 80%;
    padding: 28px 52px;
    min-height: 470px;
    ${mediaQuery[0]} {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-top: -1px;
      width: 100%;
      padding: 17px;
      min-height: unset;
    }

    .hide {
      display: none;
    }
  }
`);