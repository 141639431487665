/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '15',
  height: '15',
  viewBox: '0 0 15 15',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M9.67459 6.96859L9.03709 7.62025C8.52709 8.13025 8.20834 8.56234 8.20834 9.62484H6.79167V9.27067C6.79167 8.48442 7.11042 7.77609 7.62042 7.26609L8.49876 6.37359C8.76084 6.11859 8.91667 5.76442 8.91667 5.37484C8.91667 4.58859 8.27917 3.95817 7.50001 3.95817C7.12428 3.95817 6.76395 4.10743 6.49827 4.3731C6.2326 4.63878 6.08334 4.99911 6.08334 5.37484H4.66667C4.66667 4.62339 4.96518 3.90272 5.49654 3.37137C6.02789 2.84001 6.74856 2.5415 7.50001 2.5415C8.25145 2.5415 8.97212 2.84001 9.50348 3.37137C10.0348 3.90272 10.3333 4.62339 10.3333 5.37484C10.3333 5.99817 10.0783 6.55775 9.67459 6.96859ZM8.20834 12.4582H6.79167V11.0415H8.20834V12.4582ZM7.50001 0.416504C6.56981 0.416504 5.64872 0.59972 4.78933 0.955691C3.92994 1.31166 3.14908 1.83342 2.49133 2.49116C1.16295 3.81955 0.416672 5.62122 0.416672 7.49984C0.416672 9.37845 1.16295 11.1801 2.49133 12.5085C3.14908 13.1663 3.92994 13.688 4.78933 14.044C5.64872 14.4 6.56981 14.5832 7.50001 14.5832C9.37862 14.5832 11.1803 13.8369 12.5087 12.5085C13.8371 11.1801 14.5833 9.37845 14.5833 7.49984C14.5833 3.58275 11.3958 0.416504 7.50001 0.416504Z',
    fill: 'white',
  }),
  name: 'QuestionCircle',
})
