import { css } from '@emotion/core'

export default css`
  position: relative;

  // .timePicker {
  //   width: 100%;
  // }
`

const input = styles =>
  css`
    input {
      cursor: pointer;
      ${styles}
    }
  `

export { input }
