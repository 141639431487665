import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
  css: css({}),
  paths: jsx(
    React.Fragment,
    null,
    jsx(
      'g',
      null,
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M11.9999 0.51297L10.9512 3.42367C11.9201 6.62317 11.9201 11.373 10.9512 14.5725L11.9999 17.4832C15.4958 16.2482 17.9999 12.9158 17.9999 8.99809C17.9961 5.08042 15.492 1.74793 11.9999 0.51297Z',
        fill: '#E61E25',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M12 3.42369V0.512983C11.0614 0.182394 10.0507 0 8.99811 0C10.3851 0 8.46233 4.70424 12 3.42369Z',
        fill: '#E4E4E4',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M8.99812 18C10.0507 18 11.0615 17.8176 12 17.487V14.5763C8.49654 14.2229 8.99812 18 8.99812 18Z',
        fill: '#E4E4E4',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M0 8.9981C0 12.931 2.52312 16.2711 6.0342 17.4946V0.501587C2.51932 1.72895 0 5.06903 0 8.9981Z',
        fill: '#3757A6',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M12 3.42368V14.5725C12.969 11.373 12.969 6.62317 12 3.42368Z',
        fill: '#EF4F4E',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M12.0001 14.5725V3.42369C11.3845 1.40215 10.3851 0 8.99817 0C7.95701 0 6.96144 0.178594 6.03427 0.501583V17.4946C6.96144 17.8176 7.96081 17.9962 8.99817 17.9962C10.3851 18 11.3845 16.594 12.0001 14.5725Z',
        fill: '#F3F4F5',
      })
    )
  ),
  name: 'FlagFr',
})
