import React from 'react';
import { useQuery, useQueryCache } from 'react-query';
import isEqual from 'react-fast-compare';
import qs from 'qs';
import useIsMounted from '../../../hooks/use-is-mounted';
import { client, coreClient } from '../../../utils/api-client';
const CONFIG = {
  refetchOnWindowFocus: false
}; // REACT_APP_APPLICATION_ID=3 Migracion

const {
  REACT_APP_APPLICATION_ID
} = process.env;
const isMigra = REACT_APP_APPLICATION_ID === 3;
/**
 * This function is used to fetch the procedure detail using react query.
 * @param {string} procedureId Procedure id.
 */

export function useProcedure(procedureId, params = null) {
  const query = qs.stringify(params, {
    addQueryPrefix: true
  });
  return useQuery({
    queryKey: ['procedure', {
      procedureId
    }],
    queryFn: () => client(`/procedures/${procedureId}/detail${query}`).then(response => response.data),
    config: CONFIG
  });
}
/**
 * This function is used to fetch the procedure.
 * @param {string} procedureId Procedure id.
 */

export function useProcedureData(procedureId) {
  const queryCache = useQueryCache();
  const [procedure, setProcedure] = React.useState(queryCache.getQueryData(['procedure', {
    procedureId
  }]));
  const isMounted = useIsMounted();

  function safeSetProcedure(...args) {
    return isMounted.current ? setProcedure(...args) : null;
  }

  const unsuscribe = queryCache.subscribe((cache, query) => {
    const [key, value] = query.queryKey;

    if (key === 'procedure' && (value === null || value === void 0 ? void 0 : value.procedureId) === procedureId) {
      if (!isEqual(query.state.data, procedure)) {
        safeSetProcedure(query.state.data);
      }
    }
  });
  React.useEffect(() => {
    return () => {
      unsuscribe();
    };
  }, [unsuscribe]);
  return procedure;
}
/**
 * This function is used to fetch the procedure relations using react query.
 * @param {string} procedureCode Procedure code.
 * @param {JSON} config Config on the mutation.
 * @param {string} type Relation type.
 */

export function useProcedureRelations({
  procedureCode,
  config = {},
  type = 'residencies'
}) {
  return useQuery({
    queryKey: ['procedureRelation', {
      procedureCode
    }],
    queryFn: () => client(`/procedures/relations?code=${procedureCode}&type=${type}`).then(response => response.data),
    config: { ...CONFIG,
      ...config
    }
  });
}
/**
 * This function is used to fetch the possible procedure relations.
 * @param {string} queryParam Param for the query.
 * @param {JSON} queryParamValue Value of the param.
 * @param {string} procedureDefinitionId Procedure definition id.
 * @param {string} type Relation type.
 */

export async function getPossibleRelations(queryParam, queryParamValue, procedureDefinitionId, type = 'residencies') {
  const response = await client(`/procedures/findByRelations?procedureDefinitionId=${procedureDefinitionId}&${queryParam}=${queryParamValue}&type=${type}`, {
    method: 'GET'
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to fetch organizations.
 * @param {string} procedureId Procedure id.
 */

export async function getOrganizations(procedureId) {
  const response = await client(`/procedures/${procedureId}/organization`, {
    method: 'GET'
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to create a procedure relation.
 * @param {JSON} body Body for the procedure relation.
 */

export async function createProcedureRelation(body) {
  const response = await client(`/procedures/relations`, {
    body,
    method: 'POST'
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to delete a procedure relation.
 * @param {string} code Procedure code.
 */

export async function deleteteProcedureRelation(code) {
  const response = await client(`/procedures/relations/${code}`, {
    method: 'DELETE'
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to fetch the procedure requirements using react query.
 * @param {string} procedureId Procedure id.
 * @param {JSON} config Config on the mutation.
 */

export function useRequirements({
  procedureId,
  config = {}
}) {
  return useQuery({
    queryKey: ['requirements', {
      procedureId
    }],
    queryFn: () => getProcedureRequirements({
      procedureId
    }),
    config: { ...CONFIG,
      ...config
    }
  });
}
/**
 * This function is used to fetch the procedure requirements.
 * @param {string} procedureId Procedure id.
 * @param {boolean} validate validate param.
 */

export async function getProcedureRequirements({
  procedureId,
  validate = false
}) {
  const response = await client(`/procedures/${procedureId}/requirements`, {
    params: {
      validate
    }
  }).catch(error => {
    return Promise.reject(error);
  });
  return response.data;
}
/**
 * This function is used to fetch the procedure costs breadown with react query.
 * @param {string} procedureId Procedure id.
 * @param {JSON} config Config on the mutation.
 */

export function useCostsBreakdown({
  procedureId,
  config = {}
}) {
  return useQuery({
    queryKey: ['costs', {
      procedureId
    }],
    queryFn: () => client(`/procedures/${procedureId}/paymentBreakdown`).then(response => response.data),
    config: { ...CONFIG,
      ...config
    }
  });
}
/**
 * This function is used to fetch the user payments with react query.
 * @param {string} procedureId Procedure id.
 */

export function useUserPayments(procedureId) {
  return useQuery({
    queryKey: ['userPaymentsByProcedure', {
      procedureId
    }],
    queryFn: () => client(`/procedures/${procedureId}/getPayments`).then(response => response.data),
    config: CONFIG
  });
}
/**
 * This function is used to fetch the possible civil statutes with react query.
 * @param {string} manipulation Type of manipulation.
 * @param {JSON} config Config on the mutation.
 */

export function useCiviltStatutes({
  manipulation,
  config = {}
} = {}) {
  return useQuery({
    queryKey: ['civilsstatuses'],
    queryFn: async () => {
      const response = isMigra ? await client('/civilstatuses').catch(() => ({
        data: []
      })) : await coreClient('/civilstatuses').catch(() => ({
        data: []
      }));

      if ((response === null || response === void 0 ? void 0 : response.statusCode) !== 200) {
        return {
          data: []
        };
      }

      if (manipulation && typeof manipulation === 'function') {
        return manipulation(response.data);
      }

      return response.data;
    },
    config: { ...CONFIG,
      ...config
    }
  });
}
/**
 * This function is used to fetch the consulates with react query.
 * @param {string} countryCode Country code todo use as a filter in the query.
 */

export function useConsulates(countryCode) {
  return useQuery({
    queryKey: ['consulates'],
    queryFn: async () => {
      const response = await client(`/locations?countryCode=${countryCode}`).catch(() => {
        return {
          data: []
        };
      });

      if (response && response.statusCode !== 200) {
        return {
          data: []
        };
      }

      return response.data;
    },
    config: CONFIG
  });
}
/**
 * This function is used to fetch the identification types with react query.
 * @param {string} manipulation Type of manipulation.
 * @param {string} scope Query param scope.
 * @param {JSON} config Config on the mutation.
 */

export function useIdentificationTypes({
  manipulation,
  scope = 'procedure',
  config = {}
} = {}) {
  return useQuery({
    queryKey: ['identificationTypes'],
    queryFn: async () => {
      const response = await coreClient(`/identificationtypes?scope=${scope}&applicationId=${REACT_APP_APPLICATION_ID}`).catch(error => Promise.reject(error));

      if (manipulation && typeof manipulation === 'function') {
        return manipulation(response.data);
      }

      return response.data;
    },
    config: { ...CONFIG,
      ...config
    }
  });
}
/**
 * This function is used to fetch the identification types.
 * @param {string} scope Query param scope.
 */

export async function getIdentificationTypes(scope = '') {
  const response = await coreClient(`/identificationtypes?scope=${scope}&applicationId=${REACT_APP_APPLICATION_ID}`, {}, 'core').catch(error => {
    return Promise.reject(error);
  });
  return response.data;
}
/**
 * This function is used to fetch the genders with react query.
 * @param {string} manipulation Type of manipulation.
 * @param {JSON} config Config on the mutation.
 */

export function useGenders({
  manipulation,
  config = {}
} = {}) {
  return useQuery({
    queryKey: ['genders'],
    queryFn: async () => {
      const response = isMigra ? await client('/genders').catch(() => ({
        data: []
      })) : await coreClient('/genders').catch(() => ({
        data: []
      }));

      if ((response === null || response === void 0 ? void 0 : response.statusCode) !== 200) {
        return {
          data: []
        };
      }

      if (manipulation && typeof manipulation === 'function') {
        return manipulation(response.data);
      }

      return response.data;
    },
    config: { ...CONFIG,
      ...config
    }
  });
}
/**
 * This function is used to fetch the request types with react query.
 * @param {string} procedureDefinitionId Procedure definition id.
 * @param {string} manipulation Type of manipulation.
 * @param {JSON} config Config on the mutation.
 */

export function useRequestTypes({
  procedureDefinitionId,
  manipulation,
  config = {}
}) {
  return useQuery({
    queryKey: ['requestTypes'],
    queryFn: async () => {
      const response = await client(`/procedurerequesttypes/findAllByDefinition?procedureDefinitionId=${procedureDefinitionId}`).catch(error => Promise.reject(error));

      if (manipulation && typeof manipulation === 'function') {
        return manipulation(response.data);
      }

      return response.data;
    },
    config: { ...CONFIG,
      ...config
    }
  });
}
/**
 * This function is used to fetch a person basic information from the TSE by id.
 * @param {string} id Person id.
 */

export async function getPersonFromCensus(id) {
  const response = await coreClient(`/tse/persons/${id}`).catch(error => {
    return Promise.reject(error);
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to fetch a person basic information from the TSE by dimex.
 * @param {string} id Person id.
 */

export async function getPersonFromDimex(id) {
  const response = await coreClient(`/dimex/${id}`).catch(error => {
    return Promise.reject(error);
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to fetch an entity name from the National Register by id.
 * @param {string} id entity id.
 */

export async function getEntityNameFromId(id) {
  const response = await coreClient(`/registronacional/${id}/name`).catch(error => {
    return Promise.reject(error);
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to fetch a bussiness basic information using the legal id.
 * @param {string} legalID legal id.
 */

export async function getBusinessDataFromId(legalID) {
  const response = await client(`/businesses/?legalID=${legalID}`).catch(error => {
    return Promise.reject(error);
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to fetch a person basic information using passportNumber and nationality id
 * @param {string} nationalityId nationality id.
 * @param {string} passportNumber passport number.
 */

export async function getCitizenInfo(passportNumber, nationalityId) {
  const response = await client(`/applicantdata?identification=${passportNumber}&nationalityId=${nationalityId}`).catch(error => {
    return Promise.reject(error);
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to fetch states information.
 * @param {string} name State name.
 */

export async function getState(name) {
  const response = isMigra ? await client(`/places/states?name=${name}`).catch(() => {
    return {
      data: []
    };
  }) : await coreClient(`/places/states?name=${name}`).catch(() => {
    return {
      data: []
    };
  });

  if (response && response.statusCode !== 200) {
    return {
      data: []
    };
  }

  return response.data;
}
/**
 * This function is used to fetch cities information.
 * @param {string} name City name.
 * @param {string} stateId State id.
 */

export async function getCity({
  name,
  stateId
}) {
  const response = isMigra ? await client(`/places/cities?name=${name}&stateId=${stateId}`).catch(() => ({
    data: []
  })) : await coreClient(`/places/cities?name=${name}&stateId=${stateId}`).catch(() => ({
    data: []
  }));

  if (response && response.statusCode !== 200) {
    return {
      data: []
    };
  }

  return response.data;
}
/**
 * This function is used to fetch district information.
 * @param {string} name District name.
 * @param {string} cityId City id.
 */

export async function getDistrict({
  name,
  cityId
}) {
  const response = isMigra ? await client(`/places/districts?name=${name}&cityId=${cityId}`).catch(() => ({
    data: []
  })) : await coreClient(`/places/districts?name=${name}&cityId=${cityId}`).catch(() => ({
    data: []
  }));

  if (response && response.statusCode !== 200) {
    return {
      data: []
    };
  }

  return response.data;
}
/**
 * This function is used to fetch the kinship of a person with other.
 * @param {string} procedureDefinitionId Procedure definition id.
 * @param {string} manipulation Type of manipulation.
 * @param {JSON} config Config on the mutation.
 */

export function useKinships({
  procedureDefinitionId,
  manipulation,
  config = {}
}) {
  return useQuery({
    queryKey: ['kinships'],
    queryFn: async () => {
      const response = await client(`/proceduresdefinitionpersonalrelation?procedureDefinitionId=${procedureDefinitionId}`).catch(error => Promise.reject(error));

      if (manipulation && typeof manipulation === 'function') {
        return manipulation(response.data);
      }

      return response.data;
    },
    config: { ...CONFIG,
      ...config
    }
  });
}
/**
 * This function is used to fetch states with react query.
 */

export function useStates() {
  return useQuery({
    queryKey: ['states'],
    queryFn: async () => {
      var _response$data$map, _response$data;

      const response = isMigra ? await client('/places/states').catch(() => ({
        data: []
      })) : await coreClient('/places/states').catch(() => ({
        data: []
      }));

      if ((response === null || response === void 0 ? void 0 : response.statusCode) !== 200) {
        return {
          data: []
        };
      }

      return (_response$data$map = response === null || response === void 0 ? void 0 : (_response$data = response.data) === null || _response$data === void 0 ? void 0 : _response$data.map(({
        id,
        name
      }) => ({
        id: id.toString(),
        name
      }))) !== null && _response$data$map !== void 0 ? _response$data$map : [];
    },
    config: CONFIG
  });
}
/**
 * This function is used to fetch cities with react query.
 * @param {string} state State name.
 * @param {JSON} config Config on the mutation.
 */

export function useCities({
  state,
  config
}) {
  return useQuery({
    queryKey: ['cities', {
      state
    }],
    queryFn: async () => {
      var _response$data$map2, _response$data2;

      if (!state) {
        return [];
      }

      const response = isMigra ? await client(`/places/cities?stateId=${state}`).catch(() => ({
        data: []
      })) : await coreClient(`/places/cities?stateId=${state}`).catch(() => ({
        data: []
      }));

      if ((response === null || response === void 0 ? void 0 : response.statusCode) !== 200) {
        return {
          data: []
        };
      }

      return (_response$data$map2 = response === null || response === void 0 ? void 0 : (_response$data2 = response.data) === null || _response$data2 === void 0 ? void 0 : _response$data2.map(({
        id,
        name
      }) => ({
        id: id.toString(),
        name
      }))) !== null && _response$data$map2 !== void 0 ? _response$data$map2 : [];
    },
    config: { ...CONFIG,
      ...config
    }
  });
}
/**
 * This function is used to fetch districts with react query.
 * @param {string} city City name.
 * @param {JSON} config Config on the mutation.
 */

export function useDistricts({
  city,
  config
}) {
  return useQuery({
    queryKey: ['districts', {
      city
    }],
    queryFn: async () => {
      var _response$data$map3, _response$data3;

      if (!city) {
        return [];
      }

      const response = isMigra ? await client(`/places/districts?cityId=${city}`).catch(() => ({
        data: []
      })) : await coreClient(`/places/districts?cityId=${city}`).catch(() => ({
        data: []
      }));

      if ((response === null || response === void 0 ? void 0 : response.statusCode) !== 200) {
        return {
          data: []
        };
      }

      return (_response$data$map3 = response === null || response === void 0 ? void 0 : (_response$data3 = response.data) === null || _response$data3 === void 0 ? void 0 : _response$data3.map(({
        id,
        name
      }) => ({
        id: id.toString(),
        name
      }))) !== null && _response$data$map3 !== void 0 ? _response$data$map3 : [];
    },
    config: { ...CONFIG,
      ...config
    }
  });
}
/**
 * This function is used to fetch countries with react query.
 */

export function useCountries() {
  return useQuery({
    queryKey: ['countries'],
    queryFn: async () => {
      const response = isMigra ? await client(`/countries?limit=300`).catch(() => []) : await coreClient(`/countries?limit=300`).catch(() => []);

      if ((response === null || response === void 0 ? void 0 : response.statusCode) !== 200) {
        return [];
      }

      return response.data;
    },
    config: CONFIG
  });
}
/**
 * This function is used to fetch Vehicles Types with react query.
 * @param {string} manipulation Type of manipulation.
 * @param {JSON} config Config on the mutation.
 */

export function useVehicleTypes({
  manipulation,
  config = {}
} = {}) {
  return useQuery({
    queryKey: ['vehicleTypes'],
    queryFn: async () => {
      const response = await client('/vehicleTypes').catch(() => ({
        data: []
      }));

      if ((response === null || response === void 0 ? void 0 : response.statusCode) !== 200) {
        return {
          data: []
        };
      }

      if (manipulation && typeof manipulation === 'function') {
        return manipulation(response.data);
      }

      return response.data;
    },
    config: { ...CONFIG,
      ...config
    }
  });
}
/**
 * This function is used to fetch Vehicles Identifications Types with react query.
 */

export function useVehicleIdentificationTypes() {
  return useQuery({
    queryKey: ['vehicleIdentificationTypes'],
    queryFn: async () => {
      const response = await client(`/vehicleIdentificationTypes`).catch(() => []);

      if ((response === null || response === void 0 ? void 0 : response.statusCode) !== 200) {
        return [];
      }

      return response.data;
    },
    config: CONFIG
  });
}
/**
 * This function is used to fetch professions with react query.
 */

export function useProfessions() {
  return useQuery({
    queryKey: ['professions'],
    queryFn: async () => {
      const response = await client('/professions').catch(() => ({
        data: []
      }));

      if ((response === null || response === void 0 ? void 0 : response.statusCode) !== 200) {
        return {
          data: []
        };
      }

      return response.data;
    },
    config: CONFIG
  });
}
/**
 * This function is used to fetch professions for visa procedures with react query.
 */

export function useProfessionsVisas() {
  return useQuery({
    queryKey: ['professionsVisas'],
    queryFn: async () => {
      const response = await client('/visaprofessions').catch(() => ({
        data: []
      }));

      if ((response === null || response === void 0 ? void 0 : response.statusCode) !== 200) {
        return {
          data: []
        };
      }

      return response.data;
    },
    config: CONFIG
  });
}
/**
 * This function is used to fetch academic levels with react query.
 * @param {string} manipulation Type of manipulation.
 * @param {JSON} config Config on the mutation.
 */

export function useAcademicLevels({
  manipulation,
  config = {}
} = {}) {
  return useQuery({
    queryKey: ['academiclevels'],
    queryFn: async () => {
      const response = isMigra ? await client('/academiclevels').catch(() => ({
        data: []
      })) : await coreClient('/academiclevels').catch(() => ({
        data: []
      }));

      if ((response === null || response === void 0 ? void 0 : response.statusCode) !== 200) {
        return {
          data: []
        };
      }

      if (manipulation && typeof manipulation === 'function') {
        return manipulation(response.data);
      }

      return response.data;
    },
    config: { ...CONFIG,
      ...config
    }
  });
}
/**
 * This function is used to search an user record.
 * @param {*} params Params for the query.
 */

export async function searchUserRecord(params) {
  let query = '';

  if (params) {
    Object.keys(params).forEach((filter, index) => {
      var _query$filter, _params$filter;

      if (index === 0) {
        query += '?';
      } else if (index >= 1 && query[filter] !== null || ((_query$filter = query[filter]) === null || _query$filter === void 0 ? void 0 : _query$filter.length)) {
        query += '&';
      }

      if (params[filter] !== null || ((_params$filter = params[filter]) === null || _params$filter === void 0 ? void 0 : _params$filter.length)) {
        query += `${filter}=${params[filter]}`;
      }
    });
  }

  const response = await client(`/filiations${query}`).catch(error => {
    return Promise.reject(error);
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to check if the user has an active procedure on going.
 * @param {string} procedureId Procedure Id.
 * @param {string} requesterDigitalRecordId Requester digital record id.
 */

export async function checkIfHasProcedureActive({
  procedureId,
  requesterDigitalRecordId
}) {
  const response = await client(`/validations/pendingProcedures/${procedureId}?requesterDigitalRecordId=${requesterDigitalRecordId}`, {
    method: 'GET'
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to check if the user has an active filiation procedure on going.
 * @param {string} procedureId Procedure Id.
 */

export async function checkIfHasFiliationActive(procedureId) {
  const response = await client(`/validations/filiationPendingProcedures/${procedureId}`, {
    method: 'GET'
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to check if the vehicle is already on parent record.
 * @param {string} procedureId Procedure Id.
 */

export async function verifyVehicleExistence(vehicleId) {
  const response = await client(`/vehicleData/verifyVehicleExistence/${vehicleId}`);

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to update a procedure requirement.
 * @param {string} procedureId Procedure Id.
 * @param {string} requirementId Requirement Id.
 * @param {JSON} body Requirement new body.
 */

export async function updateProcedureRequirement({
  procedureId,
  requirementId,
  body
}) {
  const response = await client(`/procedures/${procedureId}/requirements/${requirementId}`, {
    method: 'PUT',
    body
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to update a procedure info.
 * @param {string} procedureId Procedure Id.
 * @param {JSON} body procedure body to be updated.
 */

export async function updateProcedure({
  procedureId,
  body
}) {
  const response = await client(`/procedures/${procedureId}`, {
    body,
    method: 'PUT'
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to valitade the requirements deposit status.
 * @param {string} number deposit number.
 * @param {string} date deposit date.
 */

export async function validateRequirementDepositStatus({
  number,
  date
}) {
  const response = await client(`/wsgetdeposit/depositStatus?depositNumber=${number}&depositDate=${date}`).catch(error => {
    return Promise.reject(error);
  });

  if (response.statusCode !== 200) {
    return {
      isValid: false,
      message: response.message
    };
  }

  return {
    isValid: true,
    message: null,
    data: response.data
  };
}
/**
 * This function is used to send the procedure to revision.
 * @param {string} procedureId Procedure Id.
 */

export async function sendProcedure(procedureId) {
  const response = await client(`/procedures/${procedureId}/send`, {
    method: 'POST'
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to send Procedure to Ministry Finance
 * @param {string} procedureId Procedure Id.
 */

export async function sendProcedureMinistryFinance(procedureId) {
  const response = await client(`/hacienda/${procedureId}/send`, {
    method: 'POST'
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
export function useVehicleAccesories() {
  return useQuery({
    queryKey: ['vehicleAccesorieses'],
    queryFn: async () => {
      const response = await client(`/vehicleaccessories`, {}, 'tdcar').catch(() => []);

      if ((response === null || response === void 0 ? void 0 : response.statusCode) !== 200) {
        return [];
      }

      return response.data;
    },
    config: CONFIG
  });
}
/**
 * This function is used to send the procedure to revision.
 * @param {string} procedureId Procedure Id.
 */

export async function createImportRecord(procedureId) {
  const response = await client(`/procedures/${procedureId}/createGroupRecord`, {
    method: 'POST'
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to search if the company has debt with hacienda.
 * @param {string} id entity id.
 */

export async function getHasDebtEntityFromId(id) {
  const response = await client(`/hacienda/${id}/hasDebt`).catch(error => {
    return Promise.reject(error);
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to Clone Parent Requirements
 * @param {string} procedureId Procedure Id.
 */

export async function cloneParentRequirements(procedureId, body) {
  const response = await client(`/procedures/${procedureId}/cloneParentRequirements`, {
    method: 'POST',
    body
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to Create Vehicle Record.
 * @param {string} procedureId Procedure Id.
 */

export async function createVehicleRecord(procedureId, body) {
  const response = await client(`/procedures/${procedureId}/createVehicleRecord`, {
    method: 'POST',
    body
  });

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}
/**
 * This function is used to Clone Parent Requirements
 * @param {string} procedureId Procedure Id.
 */

export async function useValidateTaxClassData(taxNumber, year) {
  const response = await client(`/hacienda/class?number=${taxNumber}&year=${year}`);

  if (response.statusCode >= 400) {
    throw response;
  }

  return response.data;
}