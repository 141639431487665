import { css } from '@emotion/core';
export default css`
  display: flex;
  box-sizing: border-box;

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 20;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #2b2d42;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 10px;
  }

  .paragraph {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #2b2d42;
  }

  .container {
    background: #f1f6f6;
    border-radius: 6px;
    width: 20%;
    margin-right: 13px;

    .logo-container {
      padding: 23px 55px;
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        width: 162px;
        height: 80px;
      }
    }

    .options {
      list-style: none;
      padding: 0;
      margin: 0;
      color: #0883a0;
    }

    .option {
      text-transform: uppercase;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      border-bottom: 1px solid #afccd1;
      text-align: center;
      padding: 30px 10px;
      cursor: pointer;

      &:first-child {
        border-top: 1px solid #afccd1;
      }

      &:hover {
        background-color: #cde1e4;
      }
    }

    .active {
      background-color: #cde1e4;
    }
  }

  .display {
    background-color: #f1f6f6;
    border-radius: 6px;
    width: 80%;
    padding: 28px 52px;

    .hide {
      display: none;
    }
  }
`;