/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '21',
  height: '21',
  viewBox: '0 0 21 21',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M11.6744 4.33424C11.2144 3.8743 11.2078 3.13528 11.6665 2.67649C12.1221 2.22088 12.8551 2.21515 13.3213 2.68132L20.6533 10.0134C21.1115 10.4716 21.1194 11.2068 20.6533 11.673L13.3213 19.005C12.863 19.4633 12.1253 19.4686 11.6665 19.0098C11.2109 18.5542 11.2094 17.8171 11.6744 17.3521L17.0166 12.0098L1.16957 12.0098C0.523601 12.0098 -5.72759e-05 11.492 -5.71625e-05 10.8432C-5.70498e-05 10.1988 0.527878 9.67649 1.16957 9.67649L17.0166 9.67649L11.6744 4.33424Z',
    fill: 'white',
  }),
  name: 'ArrowRight',
})
