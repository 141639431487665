/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '34',
  height: '34',
  viewBox: '0 0 34 34',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34ZM10.3103 18.5442L14.1254 22.6644C14.2237 22.7708 14.3405 22.8552 14.4691 22.9128C14.5976 22.9704 14.7354 23 14.8746 23C15.0138 23 15.1516 22.9704 15.2802 22.9128C15.4087 22.8552 15.5255 22.7708 15.6238 22.6644L23.6897 13.9534C23.8884 13.7388 24 13.4478 24 13.1443C24 12.8408 23.8884 12.5497 23.6897 12.3351C23.491 12.1206 23.2215 12 22.9405 12C22.6595 12 22.39 12.1206 22.1913 12.3351L14.8799 20.2313L11.8087 16.9259C11.7103 16.8197 11.5935 16.7354 11.465 16.6779C11.3364 16.6204 11.1987 16.5908 11.0595 16.5908C10.9204 16.5908 10.7826 16.6204 10.6541 16.6779C10.5255 16.7354 10.4087 16.8197 10.3103 16.9259C10.2119 17.0322 10.1339 17.1583 10.0807 17.2971C10.0274 17.436 10 17.5848 10 17.735C10 17.8853 10.0274 18.0341 10.0807 18.1729C10.1339 18.3118 10.2119 18.4379 10.3103 18.5442Z',
    fill: '#0672AB',
  }),
  name: 'CircleCheckFill',
})
