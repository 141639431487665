import { css } from '@emotion/core'

import { mediaQuery } from '../utils'

export default css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${mediaQuery[0]} {
    display: none;
  }

  .divider {
    background-color: #bbd3df;
    height: 1.5px;
    width: 100%;
    margin: 0 auto;
  }

  .column-left,
  .column-right,
  .column {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
  }

  .column-left {
    align-items: start;
    padding-left: 20px;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      text-align: right;
      letter-spacing: 0.01em;
      color: #bbd3df;
      margin: 0;
    }
  }

  .column-right {
    align-items: flex-end;
    padding-right: 20px;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      text-align: right;
      letter-spacing: 0.01em;
      color: #bbd3df;
      margin: 0;
    }
  }
`
