import { css, keyframes } from '@emotion/core'

const bounce = keyframes`
  from {
    transform: translate3d(-50%, -50%, 0) scale(0) perspective(1000px);
  }
  to {
    transform: translate3d(-50%, -50%, 0) scale(1) perspective(1000px);
  }
  0% {
    transform: translate3d(-50%, -50%, 0) scale(0) perspective(1000px);
  }
`

export const check = css`
  transform: translate3d(-50%, -50%, 0) scale(1) perspective(1000px);
  animation: ${bounce} 0.3s ease;
  margin-top: 15px;
  margin-left: 15px;
  width: 16px;
  height: 12px;
  fill: {
    path: #f93545;
  }
`

export const check2 = css`
  transform: translate3d(-50%, -50%, 0) scale(1) perspective(1000px);
  animation: ${bounce} 0.3s ease;
  margin-top: 15px;
  margin-left: 15px;
  color: #f2f2f2;
`

export function list(percentage) {
  return css`
    list-style: none;
    padding: 0;
    position: relative;
    ::before {
      content: '';
      position: absolute;
      left: 16px;
      top: 0;
      height: 100%;
      width: 2px;
      background-color: #0883a0;
      transition: 0.6s ease;
      z-index: 0;
    }
    ::after {
      content: '';
      position: absolute;
      left: 16px;
      height: ${percentage}%;
      width: 2px;
      background-color: #d33e43;
      transition: 0.6s ease;
      top: 0;
      z-index: -1;
    }
  `
}

export const printStyle = css`
  @media print {
    .ScrollbarsCustom {
      width: 100% !important;
      height: 100% !important;
      position: absolute !important;
      top: 0px !important;
      bottom: 0px !important;
      margin: auto !important;
      margin-top: 0px !important;
    }

    .ScrollbarsCustom-Wrapper {
      overflow: visible !important;
    }

    .ScrollbarsCustom-Scroller {
      overflow: visible !important;
    }
  }
`
