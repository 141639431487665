/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '74',
  height: '66',
  viewBox: '0 0 74 66',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M5.55461 66H68.4454C69.4204 66 70.3783 65.7417 71.2227 65.2511C72.0671 64.7604 72.7683 64.0546 73.2559 63.2048C73.7434 62.3549 74 61.3908 74 60.4094C74 59.4281 73.7433 58.464 73.2557 57.6141L41.8108 2.79583C41.3233 1.94581 40.6221 1.23993 39.7776 0.749151C38.9331 0.258375 37.9751 0 37 0C36.0249 0 35.0669 0.258375 34.2224 0.749151C33.3779 1.23993 32.6767 1.94581 32.1892 2.79583L0.744262 57.6141C0.256708 58.464 2.06501e-05 59.4281 1.2459e-09 60.4094C-2.06477e-05 61.3908 0.256627 62.3549 0.744144 63.2048C1.23166 64.0546 1.93287 64.7604 2.77729 65.2511C3.6217 65.7417 4.57957 66 5.55461 66ZM37 58.4699C36.4188 58.4699 35.8508 58.2965 35.3675 57.9715C34.8843 57.6465 34.5077 57.1847 34.2853 56.6443C34.0629 56.1039 34.0047 55.5092 34.1181 54.9356C34.2315 54.3619 34.5113 53.8349 34.9223 53.4213C35.3332 53.0077 35.8568 52.7261 36.4268 52.6119C36.9967 52.4978 37.5875 52.5564 38.1244 52.7802C38.6614 53.0041 39.1203 53.3831 39.4431 53.8695C39.766 54.3558 39.9383 54.9276 39.9383 55.5125C39.9383 56.2969 39.6288 57.0491 39.0777 57.6037C38.5267 58.1583 37.7793 58.4699 37 58.4699ZM36.9999 18.0426C37.5192 18.0426 38.0331 18.1488 38.5103 18.3547C38.9876 18.5607 39.4184 18.8621 39.7764 19.2407C40.1345 19.6192 40.4124 20.0671 40.5932 20.5571C40.7741 21.047 40.8542 21.5688 40.8286 22.0909L39.6887 45.3552C39.6547 46.0499 39.3565 46.7048 38.856 47.1844C38.3555 47.6639 37.6909 47.9314 36.9998 47.9314C36.3088 47.9314 35.6442 47.6639 35.1436 47.1844C34.6431 46.7048 34.3449 46.0499 34.3109 45.3552L33.171 22.0909C33.1454 21.5688 33.2255 21.047 33.4064 20.557C33.5873 20.0671 33.8652 19.6192 34.2232 19.2406C34.5813 18.8621 35.012 18.5606 35.4893 18.3547C35.9666 18.1487 36.4805 18.0426 36.9999 18.0426Z',
    fill: 'white',
  }),
  name: 'ExclamationTriangle',
})
