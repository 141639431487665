/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '21',
  height: '22',
  viewBox: '0 0 21 22',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M18.5736 0.166748H7.76085C6.55726 0.166748 5.58335 1.14067 5.58335 2.34425V8.451L0.489512 13.4798C0.336887 13.6308 0.232547 13.8237 0.189734 14.034C0.146921 14.2444 0.167565 14.4627 0.249047 14.6613C0.330529 14.8599 0.469174 15.0298 0.647388 15.1495C0.825602 15.2691 1.03535 15.3332 1.25001 15.3334V20.7501C1.25001 21.0374 1.36415 21.313 1.56731 21.5161C1.77048 21.7193 2.04603 21.8334 2.33335 21.8334H19.6667C19.954 21.8334 20.2295 21.7193 20.4327 21.5161C20.6359 21.313 20.75 21.0374 20.75 20.7501V2.34316C20.75 1.14066 19.7772 0.166748 18.5736 0.166748ZM9.83218 14.3703V19.6668H3.41668V13.6348L6.6526 10.44L9.83218 13.6932V14.3703ZM12.0833 7.75008H9.91668V5.58342H12.0833V7.75008ZM16.4167 16.4167H14.25V14.2501H16.4167V16.4167ZM16.4167 12.0834H14.25V9.91675H16.4167V12.0834ZM16.4167 7.75008H14.25V5.58342H16.4167V7.75008Z',
    fill: 'white',
  }),
  name: 'Building',
})
