/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '34',
  height: '34',
  viewBox: '0 0 34 34',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M17 0.375C7.81914 0.375 0.375 7.81914 0.375 17C0.375 26.1809 7.81914 33.625 17 33.625C26.1809 33.625 33.625 26.1809 33.625 17C33.625 7.81914 26.1809 0.375 17 0.375ZM18.1875 25.0156C18.1875 25.1789 18.0539 25.3125 17.8906 25.3125H16.1094C15.9461 25.3125 15.8125 25.1789 15.8125 25.0156V14.9219C15.8125 14.7586 15.9461 14.625 16.1094 14.625H17.8906C18.0539 14.625 18.1875 14.7586 18.1875 14.9219V25.0156ZM17 12.25C16.5339 12.2405 16.0901 12.0486 15.7638 11.7156C15.4375 11.3826 15.2547 10.935 15.2547 10.4688C15.2547 10.0025 15.4375 9.55489 15.7638 9.22188C16.0901 8.88886 16.5339 8.69701 17 8.6875C17.4661 8.69701 17.9099 8.88886 18.2362 9.22188C18.5625 9.55489 18.7453 10.0025 18.7453 10.4688C18.7453 10.935 18.5625 11.3826 18.2362 11.7156C17.9099 12.0486 17.4661 12.2405 17 12.25Z',
    fill: 'white',
  }),
  name: 'InfoCircle',
})
