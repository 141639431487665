import { useState, useImperativeHandle } from 'react';
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { FileAddIcon, FileRemoveIcon } from '../icon';
import style from '../styles/inputFile';
import { migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from './Colors';
const themes = {
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.fireEngineRed,
    beta: migratoryWebCitizen.botticelli
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.fireEngineRed,
    beta: sanitarianRegistersWeb.botticelli
  },
  tdCarWeb: {
    alpha: tdCarWeb.fireEngineRed,
    beta: tdCarWeb.botticelli
  }
};
/**
 * This component represents an Input file.
 * @param {Array} accept Array with all the accepted extensions.
 * @param {string} text Input file description text.
 * @param {function} onChange Function triggered when the file change.
 * @param {object} inputRef Input ref.
 * @param {JSON} containerStyle Custom style for the input file container.
 */

const InputFile = ({
  accept,
  text,
  onChange,
  inputRef,
  containerStyle: css = {}
}) => {
  const [icon, setIcon] = useState('file-add');
  const [fileValue, setFileValue] = useState('');
  const [currentText, setCurrentText] = useState(text);
  const {
    theme
  } = useTheme();

  const doChange = e => {
    const {
      files
    } = e.target;
    const file = files[0];
    let fileName = file.name;

    if (fileName.length > 20) {
      fileName = fileName.split('.').slice(0, -1).join('.');
      fileName = `${fileName.substring(0, 20)}...`;
    }

    setIcon('file-remove');
    setCurrentText(fileName);
    onChange(file);
  };

  useImperativeHandle(inputRef, () => ({
    clear() {
      setIcon('file-add');
      setCurrentText(text);
      setFileValue('');
      onChange('');
    }

  }));

  const clear = () => {
    setIcon('file-add');
    setCurrentText(text);
    setFileValue('');
    onChange('');
  };

  return jsx("div", {
    css: style(css)
  }, jsx("div", {
    className: "column"
  }, icon === 'file-add' ? jsx(FileAddIcon, {
    css: {
      width: 40,
      height: 40,
      color: themes[theme].beta
    }
  }) : jsx(FileRemoveIcon, {
    css: {
      width: 40,
      height: 40,
      color: themes[theme].alpha
    }
  })), jsx("div", {
    className: "column"
  }, jsx("p", {
    style: {
      color: icon === 'file-remove' ? themes[theme].alpha : themes[theme].beta
    }
  }, currentText)), jsx("input", {
    type: "file",
    accept: accept,
    onChange: doChange,
    value: fileValue,
    style: {
      width: icon === 'file-add' ? '100%' : 0,
      height: icon === 'file-add' ? '100%' : 0
    }
  }), jsx("button", {
    type: "button",
    onClick: clear,
    style: {
      width: icon === 'file-remove' ? '100%' : 0,
      height: icon === 'file-remove' ? '100%' : 0
    }
  }, "''"));
};

export default InputFile;