/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '19',
  height: '19',
  viewBox: '0 0 19 19',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M0 0H15.8333L19 3.16667V19H0V0ZM4.52381 1.80952H1.80952V17.1905H17.1905V3.9162L15.0838 1.80952H14.4762V6.33333H4.52381V1.80952ZM9.95238 1.80952V4.97619H12.2143V1.80952H9.95238ZM9.5 8.59524C11.2489 8.59524 12.6667 10.013 12.6667 11.7619C12.6667 13.5108 11.2489 14.9286 9.5 14.9286C7.7511 14.9286 6.33333 13.5108 6.33333 11.7619C6.33333 10.013 7.7511 8.59524 9.5 8.59524Z',
    fill: 'white',
  }),
  name: 'Save',
})
