function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import ScrollBar from 'react-scrollbars-custom';
import { useTheme } from 'emotion-theming';
import { migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from './Colors';
const themes = {
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.fireEngineRed,
    beta: migratoryWebCitizen.fairPink
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.fireEngineRed,
    beta: sanitarianRegistersWeb.fairPink
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.sherpaBlue,
    beta: phytosanitaryWeb.riceFlower
  },
  tdCarWeb: {
    alpha: tdCarWeb.fireEngineRed,
    beta: tdCarWeb.fairPink
  }
};
/**
 * This component represents a scroll wrapper.
 * @param {*} children What will be shonw inside the wrapper.
 * @param {JSON} containerStyle Custom styles for the scroll bar.
 * @param {number} scrollBarWidth Scroll bar width.
 */

export default function ScrollWrapper({
  children,
  containerStyle,
  scrollBarWidth = 5,
  scrollBarStyle,
  isTDCar,
  noScrollX = true,
  noScrollY = false,
  wrapperStyle,
  scrollerStyle
}) {
  const {
    theme
  } = useTheme();
  return jsx(ScrollBar, {
    style: {
      minHeight: 275,
      ...containerStyle
    },
    noScrollX: noScrollX,
    noScrollY: noScrollY,
    wrapperProps: {
      renderer: props => {
        const {
          elementRef,
          style,
          ...restProps
        } = props;
        return jsx("div", _extends({}, restProps, {
          ref: elementRef,
          style: { ...style,
            ...(!noScrollY && {
              marginRight: isTDCar ? 0 : 30
            }),
            ...wrapperStyle
          }
        }));
      }
    },
    trackYProps: {
      renderer: props => {
        const {
          elementRef,
          style,
          ...restProps
        } = props;
        return jsx("span", _extends({}, restProps, {
          style: { ...style,
            backgroundColor: themes[theme].beta,
            width: scrollBarWidth,
            ...scrollBarStyle
          },
          ref: elementRef
        }));
      }
    },
    thumbYProps: {
      renderer: props => {
        const {
          elementRef,
          ...restProps
        } = props;
        return jsx("div", _extends({}, restProps, {
          style: {
            backgroundColor: themes[theme].alpha
          },
          ref: elementRef
        }));
      }
    },
    trackXProps: {
      renderer: props => {
        const {
          elementRef,
          style,
          ...restProps
        } = props;
        return jsx("span", _extends({}, restProps, {
          style: { ...style,
            backgroundColor: themes[theme].beta,
            height: scrollBarWidth,
            left: 0,
            width: '100%',
            ...scrollBarStyle
          },
          ref: elementRef
        }));
      }
    },
    thumbXProps: {
      renderer: props => {
        const {
          elementRef,
          style,
          ...restProps
        } = props;
        return jsx("div", _extends({}, restProps, {
          style: { ...style,
            backgroundColor: themes[theme].alpha
          },
          ref: elementRef
        }));
      }
    },
    scrollerProps: {
      renderer: props => {
        const {
          elementRef,
          style,
          ...restProps
        } = props;
        return jsx("div", _extends({}, restProps, {
          ref: elementRef,
          style: { ...style,
            ...scrollerStyle
          }
        }));
      }
    }
  }, children);
}