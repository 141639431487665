import { css } from '@emotion/core';
import { HEX2RGB } from '../../../utils';
import { phytosanitaryWeb, migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from '../../Colors';
const themes = {
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.antiFlashWhite,
    beta: phytosanitaryWeb.allports
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.fireEngineRed,
    beta: migratoryWebCitizen.fireEngineRed
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.fireEngineRed,
    beta: sanitarianRegistersWeb.fireEngineRed
  },
  tdCarWeb: {
    alpha: tdCarWeb.fireEngineRed,
    beta: tdCarWeb.fireEngineRed
  }
}; // TODO agregar los colores para usar temas

export default (({
  theme = 'migratoryWebCitizen'
}) => {
  return css`
    background-color: rgba(${HEX2RGB(themes[theme].alpha).join(',')}, 0.05);
    border-radius: 6px;
    margin-bottom: 16px;
    padding: 23px 17px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .alert-container {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .alert-title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      letter-spacing: 0.05em;
      color: ${themes[theme].beta};
      margin: 0;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    .alert-item {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      letter-spacing: 0.01em;
      color: #4f4f4f;
    }

    @media screen and (min-width: 768px) {
      padding: 34px 49px;
      flex-direction: row;
    }
  `;
});