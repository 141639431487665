import { css } from '@emotion/core';
import { home, migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../../Colors'; // temas usados para componente button selector en todas las aplicaciones

const themes = {
  home: {
    alpha: home.blackPearl,
    beta: home.raven,
    gamma: home.cerulean,
    delta: home.white,
    epsilon: home.AzureishWhite,
    dseta: home.frenchPass,
    eta: home.fireEngineRed,
    theta: home.cello,
    iota: home.cadetBlue,
    kappa: home.cerulean,
    lambda: home.white
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.darkGray,
    beta: migratoryWebCitizen.raven,
    gamma: migratoryWebCitizen.cerulean,
    delta: migratoryWebCitizen.white,
    epsilon: migratoryWebCitizen.darkElectricBlue,
    dseta: migratoryWebCitizen.cello,
    eta: migratoryWebCitizen.fireEngineRed,
    theta: migratoryWebCitizen.cello,
    iota: migratoryWebCitizen.cadetBlue,
    kappa: migratoryWebCitizen.cadetBlue,
    lambda: migratoryWebCitizen.cello
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.darkGray,
    beta: sanitarianRegistersWeb.raven,
    gamma: sanitarianRegistersWeb.cerulean,
    delta: sanitarianRegistersWeb.white,
    epsilon: sanitarianRegistersWeb.darkElectricBlue,
    dseta: sanitarianRegistersWeb.cello,
    eta: sanitarianRegistersWeb.fireEngineRed,
    theta: sanitarianRegistersWeb.cello,
    iota: sanitarianRegistersWeb.cadetBlue,
    kappa: sanitarianRegistersWeb.cadetBlue,
    lambda: sanitarianRegistersWeb.cello
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.allports,
    beta: phytosanitaryWeb.sherpaBlue,
    gamma: phytosanitaryWeb.mantis,
    delta: phytosanitaryWeb.sherpaBlue,
    epsilon: phytosanitaryWeb.morningGlory,
    dseta: phytosanitaryWeb.mabel,
    eta: phytosanitaryWeb.fireEngineRed,
    theta: phytosanitaryWeb.smaltBlue,
    iota: phytosanitaryWeb.mabel,
    kappa: phytosanitaryWeb.mantis,
    lambda: phytosanitaryWeb.sherpaBlue
  },
  tdCarWeb: {
    alpha: tdCarWeb.darkGray,
    beta: tdCarWeb.raven,
    gamma: tdCarWeb.cerulean,
    delta: tdCarWeb.white,
    epsilon: tdCarWeb.darkElectricBlue,
    dseta: tdCarWeb.cello,
    eta: tdCarWeb.fireEngineRed,
    theta: tdCarWeb.cello,
    iota: tdCarWeb.cadetBlue,
    kappa: tdCarWeb.cadetBlue,
    lambda: tdCarWeb.cello
  }
};
export default (({
  theme = 'migratoryWebCitizen'
}) => css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  button:disabled,
  button[disabled] {
    cursor: not-allowed;
  }
  button {
    border: 1px solid ${themes[theme].alpha};
    background: #fff;
    border-radius: 6px;
    cursor: pointer;
    width: 165px;
    height: 48px;
    color: ${themes[theme].beta};
    font-size: 15px;
    font-weight: bold;
    margin: 0 8px 8px 0;
    letter-spacing: 0.01em;
  }
  button:last-of-type {
    margin-right: 0;
  }
  .selected {
    border: none;
    color: ${themes[theme].delta};
    background: ${themes[theme].gamma};
  }
  button:disabled.selected {
    color: ${themes[theme].lambda};
    background: ${themes[theme].kappa};
  }

  button:disabled:not(.selected),
  button[disabled]:not(.selected) {
    ${theme !== 'phytosanitaryWeb' ? `border: 1px solid ${themes[theme].epsilon};
    background: none;
    color: ${themes[theme].dseta};
    font-weight: normal;
    ` : `
    border: 1px solid ${themes[theme].theta};
    background: ${themes[theme].iota};
    color: ${themes[theme].theta};
      `}
  }

  .error {
    border: 1px solid ${themes[theme].eta};
  }
`);