/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import { useTheme } from 'emotion-theming';
import { ChevronRightIcon } from '../icon';
import { migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from './Colors';
const themes = {
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.allports
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.cerulean
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.cerulean
  },
  tdCarWeb: {
    alpha: tdCarWeb.cerulean
  }
};
/**
 * This component represents is the accordion alternative styles.
 * @param {string} tittle Accordion title, default: 'Titulo del Acordion'.
 * @param {*} children Accordion content.
 */

export default function Accordion({
  tittle = 'Titulo del Acordion',
  children
}) {
  const {
    theme
  } = useTheme();
  const [isContentVisible, setIsContentVisible] = React.useState(false);

  const onClickCollapsible = () => {
    setIsContentVisible(!isContentVisible);
  };

  return jsx("div", {
    css: style(isContentVisible, theme)
  }, jsx("button", {
    className: "collapsible",
    type: "button",
    onClick: onClickCollapsible
  }, tittle), jsx(ChevronRightIcon, {
    onClick: onClickCollapsible,
    css: {
      width: 20,
      height: 15,
      transform: `rotate(${isContentVisible ? '0deg' : '-90deg'})`,
      cursor: 'pointer',
      color: themes[theme].alpha
    }
  }), jsx("div", {
    className: "accordion-content"
  }, children));
}

const style = (isContentVisible, theme = 'migratoryWebCitizen') => css`
  margin-top: 20px;

  .collapsible {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.05em;
    margin-bottom: 36px;
    color: ${themes[theme].alpha};
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    padding-left: 0;
  }

  .accordion-content {
    display: ${isContentVisible ? 'block' : 'none'};
    padding-left: 10px;
  }
`;