/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '43',
  height: '54',
  viewBox: '0 0 43 54',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M26.5909 0L42.5455 15.9055V47.7164C42.5455 49.1225 41.9851 50.471 40.9878 51.4653C39.9904 52.4596 38.6377 53.0182 37.2273 53.0182H5.31818C3.90771 53.0182 2.55501 52.4596 1.55766 51.4653C0.560307 50.471 0 49.1225 0 47.7164V5.30182C0 3.89569 0.560307 2.54715 1.55766 1.55287C2.55501 0.558583 3.90771 0 5.31818 0H26.5909ZM37.2273 47.7164V18.5564H23.9318V5.30182H5.31818V47.7164H37.2273ZM21.2727 26.5091L31.9091 37.1127H25.2614V45.0655H17.2841V37.1127H10.6364L21.2727 26.5091Z',
    fill: 'white',
  }),
  name: 'FileAdd',
})
