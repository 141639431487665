import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
  css: css({}),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M3.86835 16.3892H14.1318V16.3854H3.86835V16.3892Z',
      fill: '#C42126',
    }),
    jsx('path', {
      d: 'M8.99826 0C8.81967 0 8.64487 0.00759815 8.47388 0.0151963H9.52644C9.35165 0.00759815 9.17686 0 8.99826 0Z',
      fill: '#C42126',
    }),
    jsx('path', {
      d: 'M11.6581 3.28999H15.9558C15.4466 2.66694 14.8538 2.11987 14.1964 1.65259H10.9703C11.2363 2.12367 11.4643 2.67834 11.6581 3.28999Z',
      fill: '#E7E7E7',
    }),
    jsx('path', {
      d: 'M10.9704 1.6526H14.1965C12.8627 0.706629 11.2592 0.113974 9.52641 0.0151978H9.24902C10.066 0.117773 10.4422 0.721825 10.9704 1.6526Z',
      fill: '#DC3027',
    }),
    jsx('path', {
      d: 'M17.6656 6.56477C17.506 5.99491 17.2894 5.44784 17.0273 4.92737H12.0836C12.1824 5.45164 12.2622 6.00251 12.323 6.56477H17.6656Z',
      fill: '#E7E7E7',
    }),
    jsx('path', {
      d: 'M11.8023 4.92738H17.0234C16.727 4.34232 16.3698 3.79146 15.9556 3.28998H11.3958C11.5553 3.79905 11.6921 4.34992 11.8023 4.92738Z',
      fill: '#DC3027',
    }),
    jsx('path', {
      d: 'M12.5092 9.83959H17.9582C17.9848 9.56226 18 9.28113 18 9C18 8.73027 17.9848 8.46813 17.962 8.20599H12.5092C12.5282 8.74546 12.5282 9.29633 12.5092 9.83959Z',
      fill: '#E7E7E7',
    }),
    jsx('path', {
      d: 'M17.962 8.20222C17.9126 7.63996 17.81 7.09289 17.6618 6.56482H12.0874C12.1444 7.10049 12.1786 7.64755 12.1976 8.20222H17.962Z',
      fill: '#DC3027',
    }),
    jsx('path', {
      d: 'M11.9886 13.1144H17.0045C17.2705 12.5977 17.4909 12.0469 17.6543 11.477H12.2318C12.171 12.0393 12.0874 12.5863 11.9886 13.1144Z',
      fill: '#E7E7E7',
    }),
    jsx('path', {
      d: 'M17.6505 11.477C17.8025 10.9489 17.9051 10.4019 17.9583 9.8396H12.3382C12.3192 10.3943 12.2812 10.9413 12.2242 11.477H17.6505Z',
      fill: '#DC3027',
    }),
    jsx('path', {
      d: 'M14.1317 16.3854C14.7967 15.9219 15.4009 15.371 15.9177 14.748H11.696C11.4984 15.3634 11.2628 15.9181 10.993 16.3854H14.1317Z',
      fill: '#E7E7E7',
    }),
    jsx('path', {
      d: 'M11.5364 14.7518H15.9177C16.3357 14.2503 16.7004 13.6994 17.0006 13.1144H11.9506C11.8404 13.6918 11.6998 14.2427 11.5364 14.7518Z',
      fill: '#DC3027',
    }),
    jsx('path', {
      d: 'M8.67503 17.9886C8.65603 17.9886 8.63703 17.9886 8.62183 17.9848C8.63703 17.9886 8.65603 17.9886 8.67503 17.9886Z',
      fill: '#DC3027',
    }),
    jsx('path', {
      d: 'M8.99805 17.9962C10.9056 17.9962 12.6725 17.3997 14.1317 16.3892H10.8752C10.2976 17.3845 9.91382 17.9962 8.99805 17.9962Z',
      fill: '#DC3027',
    }),
    jsx('path', {
      d: 'M8.99818 17.9962C8.88798 17.9962 8.78158 17.9924 8.67139 17.9886C8.78158 17.9924 8.89178 17.9962 8.99818 17.9962Z',
      fill: '#C42126',
    }),
    jsx('path', {
      d: 'M11.2666 1.65259H3.80365C3.14627 2.11987 2.55349 2.66694 2.04431 3.28999H11.9582C11.7644 2.67834 11.5326 2.12367 11.2666 1.65259Z',
      fill: '#F3F4F5',
    }),
    jsx('path', {
      d: 'M3.80371 1.6526H11.2667C10.7385 0.721825 10.0659 0.117773 9.24893 0.0151978H8.47376C6.73722 0.117773 5.13747 0.706629 3.80371 1.6526Z',
      fill: '#E73625',
    }),
    jsx('path', {
      d: 'M0.334351 6.56477H12.6003C12.5433 6.00251 12.4636 5.45164 12.361 4.92737H0.968928C0.710536 5.44784 0.497745 5.99491 0.334351 6.56477Z',
      fill: '#F3F4F5',
    }),
    jsx('path', {
      d: 'M2.04433 3.28998C1.63014 3.79146 1.27295 4.34232 0.976562 4.92738H12.3686C12.2584 4.34992 12.1216 3.79905 11.962 3.28998C11.9582 3.28998 2.04433 3.28998 2.04433 3.28998Z',
      fill: '#E73625',
    }),
    jsx('path', {
      d: 'M0 8.99617C0 9.2811 0.0151986 9.56223 0.0417977 9.83576H12.7144C12.7334 9.2925 12.7334 8.74163 12.7144 8.19836H0.0379988C0.0151995 8.4643 0 8.73023 0 8.99617Z',
      fill: '#F3F4F5',
    }),
    jsx('path', {
      d: 'M0.0379639 8.20222H12.7143C12.6953 7.65135 12.6573 7.10049 12.6041 6.56482H0.338155C0.18616 7.09289 0.0873622 7.63996 0.0379639 8.20222Z',
      fill: '#E73625',
    }),
    jsx('path', {
      d: 'M12.6004 11.477H0.349609C0.513004 12.0469 0.729601 12.5939 0.999392 13.1144H12.3572C12.4598 12.5863 12.5396 12.0393 12.6004 11.477Z',
      fill: '#F3F4F5',
    }),
    jsx('path', {
      d: 'M0.349659 11.477H12.6005C12.6575 10.9413 12.6955 10.3943 12.7145 9.8396H0.0418701C0.0912685 10.4019 0.197664 10.9489 0.349659 11.477Z',
      fill: '#E73625',
    }),
    jsx('path', {
      d: 'M3.86834 16.3854H11.2401C11.5137 15.9181 11.7455 15.3634 11.9431 14.748H2.0824C2.59918 15.371 3.19956 15.9219 3.86834 16.3854Z',
      fill: '#F3F4F5',
    }),
    jsx('path', {
      d: 'M0.99939 13.1144C1.29958 13.6994 1.66437 14.2503 2.08235 14.7518H11.943C12.1064 14.2427 12.2432 13.6956 12.3572 13.1144H0.99939Z',
      fill: '#E73625',
    }),
    jsx('path', {
      d: 'M8.61815 17.9886C8.63715 17.9886 8.65614 17.9886 8.67134 17.9924C8.77774 17.9962 8.88794 18 8.99814 18C9.91391 18 10.6587 17.3883 11.2363 16.393H3.8645C5.22486 17.3313 6.85881 17.9126 8.61815 17.9886Z',
      fill: '#E73625',
    }),
    jsx('path', {
      d: 'M8.81188 0.00384521C4.19883 0.098822 0.440751 3.65855 0.0379639 8.18705H8.81188V0.00384521Z',
      fill: '#283991',
    }),
    jsx('path', {
      d: 'M1.80111 5.09833L1.9379 5.52002H2.38249L2.0215 5.78216L2.1583 6.20386L1.80111 5.94172L1.44012 6.20386L1.58071 5.78216L1.21973 5.52002H1.66431L1.80111 5.09833Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M1.80111 6.58752L1.9379 7.01302H2.38249L2.0215 7.27136L2.1583 7.69305L1.80111 7.43472L1.44012 7.69305L1.58071 7.27136L1.21973 7.01302H1.66431L1.80111 6.58752Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M3.29451 2.86066L3.43131 3.28235H3.8721L3.51491 3.54449L3.6517 3.96619L3.29451 3.70405L2.93352 3.96619L3.07032 3.54449L2.71313 3.28235H3.15392L3.29451 2.86066Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M3.29451 4.3537L3.43131 4.7754H3.8721L3.51491 5.03373L3.6517 5.45923L3.29451 5.19709L2.93352 5.45923L3.07032 5.03373L2.71313 4.7754H3.15392L3.29451 4.3537Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M3.29451 5.84296L3.43131 6.26465H3.8721L3.51491 6.52679L3.6517 6.94849L3.29451 6.68635L2.93352 6.94849L3.07032 6.52679L2.71313 6.26465H3.15392L3.29451 5.84296Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M0.668742 6.94848L0.531948 6.52678L0.892936 6.26465H0.448352L0.436951 6.23425C0.387552 6.38622 0.345754 6.53818 0.303955 6.69014L0.311553 6.68634L0.668742 6.94848Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M1.44007 4.71082L1.79726 4.44868L2.15825 4.71082L2.02145 4.28912L2.38243 4.02698H1.93785L1.80106 3.60529L1.66426 4.02698H1.50087C1.47047 4.07257 1.44007 4.11816 1.40967 4.16755L1.57686 4.28912L1.44007 4.71082Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M3.29446 2.21486L3.65165 2.477L3.51485 2.0515L3.87584 1.78937H3.61745C3.40086 1.95273 3.18806 2.12369 2.98667 2.30604L2.93347 2.4732L3.29446 2.21486Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M4.42688 1.72855L4.78787 1.46641L5.14886 1.72855L5.01206 1.30685L5.36925 1.04472H4.92086L4.90186 0.987732C4.74987 1.06371 4.60168 1.14349 4.45728 1.23087L4.55988 1.30685L4.42688 1.72855Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M4.78414 2.11609L4.92094 2.53779H5.36552L5.00834 2.79992L5.14513 3.22162L4.78414 2.95948L4.42695 3.22162L4.56375 2.79992L4.20276 2.53779H4.64735L4.78414 2.11609Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M4.78414 3.60529L4.92094 4.02698H5.36552L5.00834 4.28912L5.14513 4.71082L4.78414 4.45248L4.42695 4.71082L4.56375 4.28912L4.20276 4.02698H4.64735L4.78414 3.60529Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M4.78414 5.09833L4.92094 5.52002H5.36552L5.00834 5.78216L5.14513 6.20386L4.78414 5.94172L4.42695 6.20386L4.56375 5.78216L4.20276 5.52002H4.64735L4.78414 5.09833Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M4.78414 6.58752L4.92094 7.01302H5.36552L5.00834 7.27136L5.14513 7.69305L4.78414 7.43472L4.42695 7.69305L4.56375 7.27136L4.20276 7.01302H4.64735L4.78414 6.58752Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M6.2773 1.36768L6.4141 1.79317H6.85868L6.4977 2.05151L6.63449 2.47321L6.2773 2.21487L5.91631 2.47321L6.05311 2.05151L5.69592 1.79317H6.14051L6.2773 1.36768Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M6.2773 2.86066L6.4141 3.28235H6.85868L6.4977 3.54449L6.63449 3.96619L6.2773 3.70405L5.91631 3.96619L6.05311 3.54449L5.69592 3.28235H6.14051L6.2773 2.86066Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M6.2773 4.3537L6.4141 4.7754H6.85868L6.4977 5.03373L6.63449 5.45923L6.2773 5.19709L5.91631 5.45923L6.05311 5.03373L5.69592 4.7754H6.14051L6.2773 4.3537Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M6.2773 5.84296L6.4141 6.26465H6.85868L6.4977 6.52679L6.63449 6.94849L6.2773 6.68635L5.91631 6.94849L6.05311 6.52679L5.69592 6.26465H6.14051L6.2773 5.84296Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M7.76693 0.623047L7.90372 1.04474H8.34831L7.99112 1.30688L8.12792 1.72858L7.76693 1.46644L7.40974 1.72858L7.54653 1.30688L7.18555 1.04474H7.63013L7.76693 0.623047Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M7.76693 2.11609L7.90372 2.53779H8.34831L7.99112 2.79992L8.12792 3.22162L7.76693 2.95948L7.40974 3.22162L7.54653 2.79992L7.18555 2.53779H7.63013L7.76693 2.11609Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M7.76693 3.60529L7.90372 4.02698H8.34831L7.99112 4.28912L8.12792 4.71082L7.76693 4.45248L7.40974 4.71082L7.54653 4.28912L7.18555 4.02698H7.63013L7.76693 3.60529Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M7.76693 5.09833L7.90372 5.52002H8.34831L7.99112 5.78216L8.12792 6.20386L7.76693 5.94172L7.40974 6.20386L7.54653 5.78216L7.18555 5.52002H7.63013L7.76693 5.09833Z',
      fill: '#EFEFEF',
    }),
    jsx('path', {
      d: 'M7.76693 6.58752L7.90372 7.01302H8.34831L7.99112 7.27136L8.12792 7.69305L7.76693 7.43472L7.40974 7.69305L7.54653 7.27136L7.18555 7.01302H7.63013L7.76693 6.58752Z',
      fill: '#EFEFEF',
    })
  ),
  name: 'FlagEn',
})
