import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '204',
  height: '201',
  viewBox: '0 0 204 201',
  css: css({}),
  paths: jsx(
    React.Fragment,
    null,
    jsx(
      'g',
      null,
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M132.746 162.661L170.965 98.4031L170.079 97.8722L131.944 161.985C130.745 161.112 129.273 160.587 127.676 160.587H123.803L144.86 96.1977L143.877 95.8742L122.713 160.59H115.593V93.939H114.559V160.59H105.757L87.3301 94.85L86.3344 95.1296L104.683 160.59H99.3384C98.1767 160.59 97.0839 160.87 96.107 161.357L61.6571 95.2741L60.7428 95.7548L95.2177 161.878C93.3139 163.195 92.0614 165.397 92.0614 167.891V181.626C92.0614 182.006 92.099 182.38 92.1554 182.745C92.1021 183.228 92.0614 183.712 92.0614 184.205C92.0614 193.479 101.662 201 113.507 201C125.353 201 134.953 193.482 134.953 184.205C134.953 183.712 134.912 183.228 134.859 182.745C134.916 182.38 134.953 182.006 134.953 181.626V167.891C134.953 165.837 134.108 163.986 132.746 162.661Z',
        fill: '#7DBC44',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M113.341 186.062C112.443 186.062 111.7 185.352 111.66 184.45L111.055 171.57C111.055 171.567 111.055 171.564 111.055 171.564C111.055 171.535 111.052 171.51 111.052 171.482C111.04 170.219 112.057 169.179 113.319 169.167C113.363 169.167 113.404 169.167 113.448 169.17C114.058 169.198 114.619 169.462 115.032 169.914C115.445 170.367 115.655 170.954 115.627 171.567L115.023 184.45C114.982 185.352 114.243 186.062 113.341 186.062Z',
        fill: '#156472',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M113.341 192.813C112.18 192.813 111.237 191.867 111.237 190.702C111.237 189.536 112.18 188.591 113.341 188.591C114.503 188.591 115.445 189.536 115.445 190.702C115.445 191.867 114.506 192.813 113.341 192.813Z',
        fill: '#156472',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M95.1989 32.0124C94.0435 32.0124 93.1072 31.0731 93.1072 29.9139V26.766C93.1072 25.6068 94.0435 24.6675 95.1989 24.6675C96.3543 24.6675 97.2906 25.6068 97.2906 26.766V29.9139C97.2937 31.0731 96.3543 32.0124 95.1989 32.0124Z',
        fill: '#156472',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M95.4619 22.5658H94.939C93.9276 22.5658 93.1072 21.7427 93.1072 20.728C93.1072 19.7132 93.9276 18.8901 94.939 18.8901H95.4619C96.4733 18.8901 97.2937 19.7132 97.2937 20.728C97.2937 21.7427 96.4733 22.5658 95.4619 22.5658Z',
        fill: '#38B4A6',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M138.701 37.262H138.532C137.712 37.262 137.048 36.596 137.048 35.7729V20.9071C137.048 20.084 137.712 19.418 138.532 19.418H138.701C139.522 19.418 140.185 20.084 140.185 20.9071V35.7729C140.185 36.5929 139.522 37.262 138.701 37.262Z',
        fill: '#156472',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M138.617 17.3192C137.749 17.3192 137.048 16.6155 137.048 15.7453C137.048 14.8751 137.749 14.1714 138.617 14.1714C139.484 14.1714 140.185 14.8751 140.185 15.7453C140.185 16.6124 139.481 17.3192 138.617 17.3192Z',
        fill: '#38B4A6',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M144.369 50.9059C142.787 49.3194 141.353 48.3329 140.185 47.7172V39.8853C140.185 39.0151 139.484 38.3113 138.617 38.3113C137.749 38.3113 137.048 39.0151 137.048 39.8853V46.7087C136.002 51.9583 129.724 50.9059 127.632 47.758V37.2621C127.632 34.9436 125.76 33.0649 123.449 33.0649C121.138 33.0649 119.266 34.9436 119.266 37.2621V48.8073C119.266 49.5676 119.482 50.2681 119.832 50.8839C118.708 52.907 115.909 52.574 115.079 50.909V40.413C115.079 38.0946 113.207 36.2159 110.896 36.2159C108.585 36.2159 106.712 38.0946 106.712 40.413V48.8104C105.667 51.9583 104.621 53.0076 100.434 53.0076C99.2946 53.0076 98.2394 52.5332 97.2969 51.8546V37.2652C97.2969 36.106 96.3606 35.1666 95.2052 35.1666C94.0498 35.1666 93.1135 36.106 93.1135 37.2652V46.7119C56.4937 65.5989 59.6312 96.0375 59.6312 96.0375C73.2302 86.5908 76.3708 89.7387 79.5083 93.9389C82.6458 98.1392 89.9698 96.0375 94.1531 92.8896C98.3364 89.7418 103.569 89.7418 107.752 92.8896C111.935 96.0375 114.03 97.0868 123.446 92.8896C132.861 88.6925 135.999 94.9882 142.277 97.0868C148.555 99.1853 149.601 93.9389 154.83 90.7879C160.062 87.6401 166.337 94.9851 168.429 98.136C170.521 101.284 171.567 100.235 171.567 100.235C171.57 70.8485 148.555 55.103 144.369 50.9059Z',
        fill: '#156472',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M105.661 24.1399C104.793 24.1399 104.092 23.4362 104.092 22.566V13.1193C104.092 12.2491 104.793 11.5454 105.661 11.5454C106.528 11.5454 107.229 12.2491 107.229 13.1193V22.566C107.232 23.4362 106.528 24.1399 105.661 24.1399Z',
        fill: '38B4A6',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M117.168 33.5865C116.3 33.5865 115.599 32.8828 115.599 32.0126V11.0207C115.599 10.1505 116.3 9.44678 117.168 9.44678C118.035 9.44678 118.736 10.1505 118.736 11.0207V32.0126C118.74 32.8828 118.035 33.5865 117.168 33.5865Z',
        fill: '#156472',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M117.168 7.3481C116.3 7.3481 115.599 6.64439 115.599 5.77418V1.57392C115.599 0.703709 116.3 0 117.168 0C118.035 0 118.736 0.703709 118.736 1.57392V5.77104C118.74 6.64125 118.035 7.3481 117.168 7.3481Z',
        fill: '#38B4A6',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M129.724 31.4877C128.857 31.4877 128.155 30.784 128.155 29.9138V24.6643C128.155 23.794 128.857 23.0903 129.724 23.0903C130.591 23.0903 131.293 23.794 131.293 24.6643V29.9138C131.293 30.784 130.588 31.4877 129.724 31.4877Z',
        fill: '#156472',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M74.0693 89.9241C74.0693 89.9241 69.0468 69.4255 97.2937 49.6714V51.9553C97.2937 51.9553 98.2362 52.9229 99.8581 52.9637C99.8581 52.9637 96.5265 67.5468 99.0033 90.7127C99.0033 90.7127 97.6663 90.8509 94.8638 92.3934C92.0614 93.9359 89.3247 96.5214 84.418 96.2795C84.418 96.2795 80.8359 96.867 78.6033 92.7798C78.6002 92.7798 76.997 90.1064 74.0693 89.9241Z',
        fill: '#38B4A6',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M119.263 46.071C119.263 46.071 127.889 61.0688 127.238 91.6739C127.238 91.6739 130.961 90.7565 134.527 92.8708C138.094 94.9882 142.994 98.4125 146.821 97.225C146.821 97.225 149.022 96.5841 152.974 92.1137C152.974 92.1137 155.015 90.3105 157.539 90.0246C157.539 90.0246 156.872 64.5371 132.837 50.375C132.837 50.375 129.724 50.9059 127.632 47.7549V46.2815C127.632 46.2815 123.449 41.2833 119.263 39.7974V46.071Z',
        fill: '#38B4A6',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M196.676 150.619C196.676 147.141 193.864 144.32 190.398 144.32C189.449 144.32 188.557 144.537 187.749 144.914C186.496 141.505 183.243 139.07 179.413 139.07C175.747 139.07 172.6 141.298 171.238 144.48C170.662 144.383 170.076 144.32 169.475 144.32C163.698 144.32 159.013 149.02 159.013 154.816C159.013 160.612 163.698 165.312 169.475 165.312C169.941 165.312 170.399 165.271 170.849 165.211L196.676 165.312C200.721 165.312 204 162.023 204 157.964C204 153.908 200.721 150.619 196.676 150.619Z',
        fill: '#A0C8C8',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M26.9913 78.2124C26.0268 73.4153 21.8028 69.7993 16.7365 69.7993C10.9593 69.7993 6.275 74.4991 6.275 80.2953C6.275 80.6942 6.30005 81.0838 6.34388 81.4702C5.98379 81.3917 5.61118 81.3445 5.22916 81.3445C2.34216 81.3445 0 83.6944 0 86.5909C0 89.4874 2.34216 91.8405 5.2323 91.8405H26.1552L26.1677 91.8153C26.3368 91.8279 26.5059 91.8405 26.6781 91.8405C30.4325 91.8405 33.4792 88.7869 33.4792 85.017C33.476 81.3571 30.6016 78.3789 26.9913 78.2124Z',
        fill: '#A0C8C8',
      })
    )
  ),
  name: 'Ballon',
})
