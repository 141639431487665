import React from 'react';
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx } from '@emotion/core';
import Rodal from 'rodal';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'emotion-theming';
import { home, migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../../Colors';
import useMediaQuery from '../../../hooks/use-media-query';
import { Button, TextArea } from '../../../core';
import { reducer } from '../../../utils';
import { DivRow, DivColumn, FormLabel } from '../InputsUI';
import SpinnerSmall from '../../SpinnerSmall';
import styles from './modalStyle';
import modalCustomStyle from './utils';
const themes = {
  home: {
    alpha: home.cerulean,
    beta: home.white,
    gamma: home.fireEngineRed,
    delta: home.cerulean,
    epsilon: home.fireEngineRed,
    dseta: home.white
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.cerulean,
    beta: migratoryWebCitizen.white,
    gamma: migratoryWebCitizen.fireEngineRed,
    delta: migratoryWebCitizen.cerulean,
    epsilon: migratoryWebCitizen.fireEngineRed,
    dseta: migratoryWebCitizen.white
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.deepCerulean,
    beta: sanitarianRegistersWeb.blackPearl,
    gamma: sanitarianRegistersWeb.fireEngineRed,
    delta: sanitarianRegistersWeb.deepCerulean,
    epsilon: sanitarianRegistersWeb.fireEngineRed,
    dseta: sanitarianRegistersWeb.white
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.mantis,
    beta: phytosanitaryWeb.white,
    gamma: phytosanitaryWeb.mantis,
    delta: phytosanitaryWeb.mantis,
    epsilon: phytosanitaryWeb.mantis,
    dseta: phytosanitaryWeb.sherpaBlue
  },
  tdCarWeb: {
    alpha: tdCarWeb.cerulean,
    beta: tdCarWeb.white,
    gamma: tdCarWeb.fireEngineRed,
    delta: tdCarWeb.cerulean,
    epsilon: tdCarWeb.fireEngineRed,
    dseta: tdCarWeb.white
  }
};
/**
 * This component represents a modal for cancel a procedure.
 * @param {boolean} isVisible Determines if the modal is visible or not.
 * @param {function} setIsVisible Function triggered when close the modal to hide it.
 * @param {function} signToCancel Sign to cancel the procedure.
 * @param {string} isCanceling Determine if it is in the process of cancellation.
 */

export default function SignCancelProcedure({
  isVisible,
  setIsVisible,
  signToCancel,
  isCanceling
}) {
  const {
    theme
  } = useTheme();
  const {
    t
  } = useTranslation();
  const {
    isMobile
  } = useMediaQuery();
  const [{
    comment,
    isButtonDisabled
  }, setMetaData] = React.useReducer(reducer, {
    comment: '',
    isButtonDisabled: true
  });

  const handleCloseModal = () => {
    setMetaData({
      comment: ''
    });
    setIsVisible();
  };

  const onChangeTextArea = tempComment => {
    setMetaData({
      comment: tempComment,
      isButtonDisabled: !tempComment.length >= 1
    });
  };

  return jsx(Rodal, {
    visible: isVisible,
    showCloseButton: false,
    width: 882,
    height: 563,
    closeOnEsc: true,
    onClose: () => {},
    customStyles: modalCustomStyle({
      isMobile,
      width: 882,
      height: 563
    })
  }, jsx("div", {
    css: [styles, {
      padding: '33px 26px 30px 22px'
    }]
  }, jsx(DivRow, {
    className: "modals-row",
    containerStyle: {
      marginBottom: 0
    }
  }, jsx(DivColumn, {
    className: "modals-column"
  }, jsx("h1", {
    className: "modals-title",
    style: {
      fontSize: 20,
      marginTop: 'unset',
      color: themes[theme].alpha
    }
  }, t('Cancelar este trámite')), jsx("p", {
    className: "modals-description",
    style: {
      margin: '0 0 25px 0'
    }
  }, t('Si está seguro que desea cancelarlo, por favor ingrese un motivo y proceda a firmarlo digitalmente')))), jsx(DivRow, {
    className: "modals-row"
  }, jsx(DivColumn, {
    className: "modals-column"
  }, jsx(FormLabel, {
    text: t('Ingrese el motivo por el cual desea cancelar este trámite'),
    styles: {
      marginBottom: '0px'
    },
    containerStyle: {
      display: 'flex',
      color: '#0D1F2D',
      fontSize: 14
    }
  }), jsx("div", {
    style: {
      textAlign: 'left'
    }
  }, jsx(TextArea, {
    autoComplete: "off",
    placeholder: "Ingresar",
    width: "100%",
    maxLength: "2800",
    style: {
      height: '170px',
      fontSize: '14px !important'
    },
    value: comment,
    showCount: true,
    showCountStyle: {
      color: `${themes[theme].beta} !important`,
      bottom: '11px !important',
      right: '19px !important',
      fontSize: '15px !important'
    },
    onChange: onChangeTextArea
  }), jsx("div", {
    style: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '15px 0 39px'
    }
  }, jsx("span", {
    style: {
      color: themes[theme].gamma,
      fontStyle: 'italic',
      fontWeight: 500,
      fontSize: 14
    }
  }, t('*Al cancelarlo perderá el dinero del costo del trámite ya que este no es reembolsable')))))), jsx("div", {
    className: "button-container"
  }, jsx(Button, {
    size: "md-icon",
    outline: true,
    className: "button blue",
    style: {
      color: themes[theme].delta,
      border: `2px solid ${themes[theme].delta}`,
      width: 150
    },
    disabled: isCanceling,
    onClick: handleCloseModal
  }, t('Salir')), jsx(Button, {
    size: "md-icon",
    className: "button red",
    style: {
      background: themes[theme].epsilon,
      color: themes[theme].dseta
    },
    disabled: isCanceling || isButtonDisabled,
    onClick: () => signToCancel(comment)
  }, t('Cancelar trámite'), isCanceling ? jsx(SpinnerSmall, {
    className: "spinner"
  }) : ''))));
}