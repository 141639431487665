import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { web } from '../../locales'

const detection = {
  lookupLocalStorage: 'accept-language',
  caches: ['localStorage'],
}
const { REACT_APP_LOCALES_RESOURCES, NODE_ENV } = process.env
const lngTemp = localStorage.getItem('accept-language') || 'es-CR'

i18n.use(initReactI18next).init({
  ns: ['translations-web'],
  defaultNS: 'translations-web',
  lng: REACT_APP_LOCALES_RESOURCES === 'operator' ? 'es-CR' : lngTemp,
  detection,
  fallbackLng: 'es-CR',
  resources: web[REACT_APP_LOCALES_RESOURCES],
  saveMissing: NODE_ENV === 'development',
  missingKeyHandler(lng, ns, key) {
    const hideErrors = localStorage.getItem('hideErrors')
    if (process.env.NODE_ENV === 'development' && !hideErrors) {
      console.log(
        `%c 🚨 Hacen falta esta key en el archivo ${ns} 🚨 `,
        'color: red'
      )
      console.log(`%c Key: ${key}`, 'color: yellow')
    }
  },
  interpolation: {
    escapeValue: false,
    prefix: '{{',
    suffix: '}}',
  },
})

export default i18n
