/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '30',
  height: '30',
  viewBox: '0 0 30 30',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M15 0C12.0333 0 9.13319 0.879734 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.0064992 12.0006 -0.290551 15.0166 0.288227 17.9264C0.867006 20.8361 2.29562 23.5088 4.3934 25.6066C6.49119 27.7044 9.16393 29.133 12.0736 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4811 27.7229 25.8238 25.8003 27.472 23.3335C29.1203 20.8668 30 17.9667 30 15C29.9949 11.0233 28.4129 7.21096 25.601 4.39902C22.789 1.58707 18.9767 0.00508853 15 0ZM20.8385 16L12.1769 21C12.0015 21.1013 11.8025 21.1546 11.6 21.1546C11.3975 21.1546 11.1985 21.1013 11.0231 21C10.8476 20.8987 10.7018 20.7529 10.6006 20.5773C10.4993 20.4018 10.446 20.2027 10.4462 20V10C10.446 9.79739 10.4992 9.59832 10.6005 9.42282C10.7017 9.24731 10.8473 9.10154 11.0228 9.00018C11.1982 8.89882 11.3972 8.84544 11.5998 8.84541C11.8024 8.84538 12.0015 8.8987 12.1769 9L20.8385 14C21.0142 14.1012 21.1602 14.2469 21.2617 14.4224C21.3632 14.598 21.4167 14.7972 21.4167 15C21.4167 15.2028 21.3632 15.402 21.2617 15.5776C21.1602 15.7531 21.0142 15.8988 20.8385 16Z',
    fill: 'white',
  }),
  name: 'PlayCircle',
})
