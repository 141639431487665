/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '19',
  height: '16',
  viewBox: '0 0 19 16',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M10.1143 3.77623V0.429252C10.1143 0.191931 10.3067 0 10.5436 0C10.6681 0 10.7798 0.052708 10.8584 0.136862L18.1849 7.01885C18.3571 7.18102 18.3656 7.45201 18.2034 7.62435L18.1849 7.64288L10.837 14.5446C10.6648 14.7067 10.3937 14.6983 10.2315 14.526C10.1535 14.4436 10.1154 14.338 10.1154 14.2326H10.1142V10.7357C3.2606 10.5579 0.82243 15.7426 0.815417 15.7576C0.712739 15.9708 0.456237 16.0601 0.243582 15.9574C0.0904145 15.8839 0.00169524 15.7306 0.0011742 15.5713H0C0 11.2047 1.94095 8.3844 4.22672 6.57715C6.31627 4.92517 8.69047 4.1315 10.1143 3.77623Z',
    fill: '#0672AB',
  }),
  name: 'Resend',
})
