import { css } from '@emotion/core';
import { home, migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../../Colors';
import { mediaQuery } from '../../../utils';
const themes = {
  home: {
    alpha: home.blackPearl,
    beta: home.raven,
    gamma: home.cerulean,
    delta: home.white,
    epsilon: home.AzureishWhite,
    dseta: home.frenchPass,
    eta: home.fireEngineRed,
    theta: home.cello,
    iota: home.cadetBlue
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.blackPearl,
    beta: migratoryWebCitizen.raven,
    gamma: migratoryWebCitizen.cerulean,
    delta: migratoryWebCitizen.white,
    epsilon: migratoryWebCitizen.AzureishWhite,
    dseta: migratoryWebCitizen.frenchPass,
    eta: migratoryWebCitizen.fireEngineRed,
    theta: migratoryWebCitizen.cello,
    iota: migratoryWebCitizen.cadetBlue,
    kappa: migratoryWebCitizen.brightGray,
    lambda: migratoryWebCitizen.prussianBlue
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.blackPearl,
    beta: sanitarianRegistersWeb.raven,
    gamma: sanitarianRegistersWeb.cerulean,
    delta: sanitarianRegistersWeb.white,
    epsilon: sanitarianRegistersWeb.AzureishWhite,
    dseta: sanitarianRegistersWeb.frenchPass,
    eta: sanitarianRegistersWeb.fireEngineRed,
    theta: sanitarianRegistersWeb.cello,
    iota: sanitarianRegistersWeb.cadetBlue,
    kappa: sanitarianRegistersWeb.brightGray,
    lambda: sanitarianRegistersWeb.prussianBlue
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.allports,
    beta: phytosanitaryWeb.sherpaBlue,
    gamma: phytosanitaryWeb.mantis,
    delta: phytosanitaryWeb.sherpaBlue,
    epsilon: phytosanitaryWeb.morningGlory,
    dseta: phytosanitaryWeb.mabel,
    eta: phytosanitaryWeb.fireEngineRed,
    theta: phytosanitaryWeb.smaltBlue,
    iota: phytosanitaryWeb.mabel
  },
  tdCarWeb: {
    alpha: tdCarWeb.blackPearl,
    beta: tdCarWeb.raven,
    gamma: tdCarWeb.cerulean,
    delta: tdCarWeb.white,
    epsilon: tdCarWeb.AzureishWhite,
    dseta: tdCarWeb.frenchPass,
    eta: tdCarWeb.fireEngineRed,
    theta: tdCarWeb.cello,
    iota: tdCarWeb.cadetBlue,
    kappa: tdCarWeb.brightGray,
    lambda: tdCarWeb.prussianBlue
  }
};
export default (({
  theme = 'default'
}) => css`
  .card {
    background: ${themes[theme].kappa};
    display: flex;
    width: 70%;
    border-radius: 10px;
    margin-bottom: 8px;
    padding: 20px 22px 17px;

    ${mediaQuery[0]} {
      width: 100%;
      padding: 10px 0;
    }
  }

  .checkbox-container,
  card-text {
    display: flex;
    flex: 1;
    flex-basis: 0%;
    flex-direction: column;
  }

  .checkbox-container {
    justify-content: center;
    align-items: center;

    div {
      position: relative;
    }

    input[type='radio'] {
      display: none;
    }

    label:before {
      content: ' ';
      display: inline-block;
      position: relative;
      top: 5px;
      margin: 0 5px 0 0;
      width: 18px;
      height: 18px;
      border-radius: 11px;
      border: 2px solid red;
      background-color: transparent;
      cursor: pointer;
    }

    input[type='radio']:checked + label:after {
      border-radius: 11px;
      width: 12px;
      height: 12px;
      position: absolute;
      top: 10px;
      left: 5px;
      content: ' ';
      display: block;
      background: red;
    }
  }

  .card-text {
    flex-basis: 90%;

    ${mediaQuery[0]} {
      flex-basis: 80%;
    }

    h4 {
      margin: 0;
      font-weight: 600;
    }

    p {
      margin: 0;
      font-weight: 400;
    }

    h4,
    p {
      font-size: 14px;
      margin-bottom: 4px;
      color: ${themes[theme].lambda};
    }
  }

  .selected {
    border: none;
    color: ${themes[theme].delta};
    background: ${themes[theme].gamma};
  }

  .error {
    border: 1px solid ${themes[theme].eta};
  }
`);