import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M8 0.000244141C3.5888 0.000244141 0 3.58904 0 8.00024C0 12.4106 3.5888 16.0002 8 16.0002C12.4112 16.0002 16 12.4106 16 8.00024C16 3.58904 12.4112 0.000244141 8 0.000244141ZM12.4824 12.5602C11.3592 11.873 9.8056 11.2002 8 11.2002C6.1952 11.2002 4.6408 11.8738 3.5168 12.5602C2.3352 11.3986 1.6 9.78421 1.6 8.0002C1.6 4.4714 4.4712 1.6002 8 1.6002C11.5288 1.6002 14.4 4.4714 14.4 8.0002C14.4 9.78421 13.6648 11.3986 12.4824 12.5602ZM4.9192 13.6074C5.7744 13.1658 6.8296 12.8002 8 12.8002C9.1712 12.8002 10.2256 13.1658 11.0808 13.6066C10.1656 14.1106 9.116 14.4002 8 14.4002C6.8832 14.4002 5.8336 14.1106 4.9192 13.6074ZM8 8.00004C7.1176 8.00004 6.4 7.28244 6.4 6.40004C6.4 5.51764 7.1176 4.80004 8 4.80004C8.8824 4.80004 9.6 5.51764 9.6 6.40004C9.6 7.28244 8.8824 8.00004 8 8.00004ZM8 3.20004C6.2352 3.20004 4.8 4.63524 4.8 6.40004C4.8 8.16484 6.2352 9.60004 8 9.60004C9.7648 9.60004 11.2 8.16484 11.2 6.40004C11.2 4.63524 9.7648 3.20004 8 3.20004Z',
      fill: '#08415C',
    })
  ),
  name: 'UserCircleAlt',
})
