/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '44',
  height: '38',
  viewBox: '0 0 44 38',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M40.8571 6.5H22V3.375C22 1.3125 21.0257 0.25 18.8571 0.25H3.14286C1.41429 0.25 0 1.65625 0 3.375V34.625C0 36.3438 1.41429 37.75 3.14286 37.75H40.8571C42.5857 37.75 44 36.3438 44 34.625V9.625C44 7.90625 42.5857 6.5 40.8571 6.5ZM18.8571 6.5H3.14286V3.375H18.8571V6.5Z',
    fill: 'white',
  }),
  name: 'Folder',
})
