import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '18',
  height: '12',
  viewBox: '0 0 18 12',
  css: css({
    path: {
      stroke: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M6.10816 8.37928C8.14566 8.37928 9.79737 6.72757 9.79737 4.69007C9.79737 2.65257 8.14566 1.00085 6.10816 1.00085C4.07066 1.00085 2.41895 2.65257 2.41895 4.69007C2.41895 6.72757 4.07066 8.37928 6.10816 8.37928Z',
      stroke: '#08415C',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    }),
    jsx('path', {
      d: 'M10.8909 1.13827C11.3983 0.995301 11.9305 0.962733 12.4515 1.04276C12.9726 1.12278 13.4705 1.31353 13.9116 1.60217C14.3528 1.89081 14.7269 2.27064 15.0089 2.71606C15.2909 3.16148 15.4742 3.66216 15.5463 4.18437C15.6185 4.70658 15.578 5.2382 15.4274 5.74342C15.2769 6.24864 15.0198 6.71573 14.6735 7.11323C14.3272 7.51073 13.8998 7.8294 13.4199 8.04779C12.9401 8.26618 12.4191 8.37921 11.8919 8.37927',
      stroke: '#08415C',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    }),
    jsx('path', {
      d: 'M1 11.0324C1.57615 10.2129 2.34103 9.54395 3.23008 9.08217C4.11912 8.6204 5.10622 8.37931 6.10804 8.37927C7.10986 8.37923 8.09698 8.62024 8.98606 9.08195C9.87514 9.54366 10.6401 10.2125 11.2163 11.032',
      stroke: '#08415C',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    }),
    jsx('path', {
      d: 'M11.8918 8.37927C12.8938 8.37855 13.8811 8.61926 14.7703 9.08103C15.6595 9.5428 16.4243 10.212 17 11.032',
      stroke: '#08415C',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    })
  ),
  name: 'UserGroup',
})
