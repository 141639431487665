import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: 'null',
  height: 'null',
  viewBox: '0 0 40 40',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx(
      'g',
      null,
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M28.6667 30.8333V28.6667H22.1667V22.1667H28.6667V26.5H33V30.8333H30.8333V35.1667H26.5V39.5H22.1667V33H26.5V30.8333H28.6667ZM39.5 39.5H30.8333V35.1667H35.1667V30.8333H39.5V39.5ZM0.5 0.5H17.8333V17.8333H0.5V0.5ZM4.83333 4.83333V13.5H13.5V4.83333H4.83333ZM22.1667 0.5H39.5V17.8333H22.1667V0.5ZM26.5 4.83333V13.5H35.1667V4.83333H26.5ZM0.5 22.1667H17.8333V39.5H0.5V22.1667ZM4.83333 26.5V35.1667H13.5V26.5H4.83333ZM33 22.1667H39.5V26.5H33V22.1667ZM7 7H11.3333V11.3333H7V7ZM7 28.6667H11.3333V33H7V28.6667ZM28.6667 7H33V11.3333H28.6667V7Z',
        fill: 'white',
      })
    )
  ),
  name: 'Qr',
})
