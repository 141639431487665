import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M8 14.8C11.7555 14.8 14.8 11.7555 14.8 8C14.8 4.24446 11.7555 1.2 8 1.2C4.24446 1.2 1.2 4.24446 1.2 8C1.2 11.7555 4.24446 14.8 8 14.8ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z',
      fill: '#0672AB',
    }),
    jsx('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M8.60039 4.79922C8.60039 4.46785 8.33176 4.19922 8.00039 4.19922C7.66902 4.19922 7.40039 4.46785 7.40039 4.79922V7.39936H4.80117C4.4698 7.39936 4.20117 7.66799 4.20117 7.99936C4.20117 8.33073 4.4698 8.59936 4.80117 8.59936H7.40039V11.1992C7.40039 11.5306 7.66902 11.7992 8.00039 11.7992C8.33176 11.7992 8.60039 11.5306 8.60039 11.1992V8.59936H11.2012C11.5325 8.59936 11.8012 8.33073 11.8012 7.99936C11.8012 7.66799 11.5325 7.39936 11.2012 7.39936H8.60039V4.79922Z',
      fill: '#0672AB',
    })
  ),
  name: 'CirclePlusAlt',
})
