import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: 'null',
  height: 'null',
  viewBox: '0 0 26 26',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx(
      'g',
      null,
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M20.5736 2.1665H9.76085C8.55726 2.1665 7.58335 3.14042 7.58335 4.344V10.4508L2.48951 15.4796C2.33689 15.6305 2.23255 15.8234 2.18973 16.0338C2.14692 16.2441 2.16757 16.4625 2.24905 16.6611C2.33053 16.8596 2.46917 17.0296 2.64739 17.1492C2.8256 17.2689 3.03535 17.3329 3.25001 17.3332V22.7498C3.25001 23.0372 3.36415 23.3127 3.56731 23.5159C3.77048 23.719 4.04603 23.8332 4.33335 23.8332H21.6667C21.954 23.8332 22.2295 23.719 22.4327 23.5159C22.6359 23.3127 22.75 23.0372 22.75 22.7498V4.34292C22.75 3.14042 21.7772 2.1665 20.5736 2.1665ZM11.8322 16.3701V21.6665H5.41668V15.6345L8.6526 12.4398L11.8322 15.693V16.3701ZM14.0833 9.74984H11.9167V7.58317H14.0833V9.74984ZM18.4167 18.4165H16.25V16.2498H18.4167V18.4165ZM18.4167 14.0832H16.25V11.9165H18.4167V14.0832ZM18.4167 9.74984H16.25V7.58317H18.4167V9.74984Z',
        fill: 'white',
      }),
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M7.58398 16.25H9.75065V18.4167H7.58398V16.25Z',
        fill: 'white',
      })
    )
  ),
  name: 'BuildingAlt',
})
