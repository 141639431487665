/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M0 8H8V0H0V8ZM0 18H8V10H0V18ZM10 18H18V10H10V18ZM10 0V8H18V0',
    fill: 'white',
  }),
  name: 'Grid',
})
