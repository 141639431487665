import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '25',
  height: '26',
  viewBox: '0 0 25 26',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M2.36635 16.7579L0.452148 23.9275L7.58688 21.9785L2.36635 16.7579Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M15.7804 3.32904L2.9834 16.1261L8.22524 21.3679L21.0222 8.57089L15.7804 3.32904Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M23.5621 6.00362C24.6062 4.95951 24.6062 3.32374 23.5621 2.27963L22.0656 0.783081C21.0215 -0.261027 19.3857 -0.261027 18.3416 0.783081L16.3926 2.73208L21.6479 7.98742L23.5621 6.00362Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M24.1885 24.3451H0.452446C0.208821 24.3451 0 24.5539 0 24.7976C0 25.0412 0.208821 25.25 0.452446 25.25H24.2233C24.4669 25.25 24.6757 25.0412 24.6757 24.7976C24.6409 24.5539 24.4321 24.3451 24.1885 24.3451Z',
      fill: 'white',
    })
  ),
  name: 'Edit',
})
