/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '63',
  height: '63',
  viewBox: '0 0 63 63',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M31.5 63C48.897 63 63 48.897 63 31.5C63 14.103 48.897 0 31.5 0C14.103 0 0 14.103 0 31.5C0 48.897 14.103 63 31.5 63ZM23 41V37.25L34.06 26.19L37.81 29.94L26.75 41H23ZM40.9264 26.7166C40.8763 26.8376 40.8027 26.9475 40.71 27.04L38.88 28.87L35.13 25.12L36.96 23.29C37.0525 23.1973 37.1624 23.1238 37.2834 23.0736C37.4043 23.0234 37.534 22.9976 37.665 22.9976C37.796 22.9976 37.9257 23.0234 38.0466 23.0736C38.1676 23.1238 38.2775 23.1973 38.37 23.29L40.71 25.63C40.8027 25.7225 40.8763 25.8324 40.9264 25.9534C40.9766 26.0744 41.0024 26.204 41.0024 26.335C41.0024 26.466 40.9766 26.5957 40.9264 26.7166Z',
    fill: 'white',
  }),
  name: 'EditCircle',
})
