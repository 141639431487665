function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useEffect, useReducer, useImperativeHandle } from 'react';
import style from '../styles/pagination';
import { ChevronRightIcon } from '../icon';
import { reducer } from '../utils';

const ScrollToTop = () => {
  // window.scrollTo({ top: 0, behavior: 'smooth' })
  return null;
};
/**
 * This component represents a Pagination.
 * @param {number} total Total of pages.
 * @param {number} limit Total of display results per page.
 * @param {function} returnSelectedPage Returns the selected page.
 * @param {string} activeButtonColor Active button color.
 * @param {string} textColorActive Text active color.
 * @param {string} position Pagination position.
 */


const Pagination = ({
  total,
  limit,
  returnSelectedPage,
  activeButtonColor,
  textColorActive,
  position,
  paginationRef,
  arrowsColor,
  arrowsColorDisabled,
  currentPage,
  customStyle,
  containerStyle = {}
}) => {
  const [state, setState] = useReducer(reducer, {
    loading: true,
    show: false,
    totalPages: [],
    current: 0,
    next: 0,
    pagination: {
      first: [],
      second: [],
      third: []
    },
    total: -1,
    limit: limit || 20,
    pageCount: 0,
    activeButtonColor: activeButtonColor || '#DEDEDE',
    textColor: '#07263b',
    textColorActive: textColorActive || '#07263b',
    buttonColor: '#fff',
    position: position || 'left'
  });

  function generatePagination() {
    const {
      pageCount,
      current,
      totalPages
    } = state;

    if (current !== 0 && current < pageCount + 1) {
      if (pageCount === 1) {
        setState({
          pagination: {
            first: [],
            second: [],
            third: []
          }
        });
      }

      if (pageCount < 6 && pageCount > 1) {
        setState({
          pagination: {
            first: totalPages,
            second: [],
            third: []
          }
        });
      }

      if (pageCount === 6) {
        if (pageCount - current < 2) {
          setState({
            pagination: {
              first: totalPages.slice(0, 1),
              // first,
              second: totalPages.slice(totalPages.length - 3, totalPages.length),
              // last4,
              third: []
            }
          });
        } else {
          if (current < 3) {
            setState({
              pagination: {
                first: totalPages.slice(0, 3),
                // first3,
                second: totalPages.slice(totalPages.length - 1, totalPages.length),
                // last,
                third: []
              }
            });
          }

          if (current === 3) {
            setState({
              pagination: {
                first: totalPages.slice(0, 4),
                // first4,
                second: totalPages.slice(totalPages.length - 1, totalPages.length),
                // last,
                third: []
              }
            });
          }

          if (current > 3) {
            setState({
              pagination: {
                first: totalPages.slice(0, 1),
                // first,
                second: totalPages.slice(totalPages.length - 4, totalPages.length),
                // last4,
                third: []
              }
            });
          }
        }
      }

      if (pageCount > 6) {
        if (pageCount - current < 2) {
          setState({
            pagination: {
              first: totalPages.slice(0, 1),
              // first,
              second: totalPages.slice(totalPages.length - 3, totalPages.length),
              // last4,
              third: []
            }
          });
        } else if (pageCount - current === 2) {
          setState({
            pagination: {
              first: totalPages.slice(0, 1),
              // first
              second: totalPages.slice(totalPages.length - 4, totalPages.length),
              // last4
              third: []
            }
          });
        } else {
          if (current < 3) {
            setState({
              pagination: {
                first: totalPages.slice(0, 3),
                // first3
                second: totalPages.slice(totalPages.length - 1, totalPages.length),
                // last
                third: []
              }
            });
          }

          if (current === 3) {
            setState({
              pagination: {
                first: totalPages.slice(0, 4),
                // first4
                second: totalPages.slice(totalPages.length - 1, totalPages.length),
                // last
                third: []
              }
            });
          }

          if (current > 3) {
            setState({
              pagination: {
                first: totalPages.slice(0, 1),
                // first
                second: totalPages.slice(current - 2, current + 1),
                // i-1, i, i+1
                third: totalPages.slice(totalPages.length - 1, totalPages.length) // last,

              }
            });
          }
        }
      }
    }
  }

  function doRender(currentRender, totalRender) {
    const totalPagesRender = [];
    let pageCountRender = 0;
    let lastRender = 0;
    let nextRender = 0;

    if (totalRender === 0) {
      setState({
        show: false
      });
    }

    if (totalRender && totalRender > 0) {
      pageCountRender = Math.ceil(totalRender / state.limit);

      if (state.limit > totalRender) {
        setState({
          pageCount: 1
        });
      }

      for (let i = 1; i < pageCountRender + 1; i++) {
        totalPagesRender.push(i);
      }

      lastRender = totalPagesRender[totalPagesRender.length - 1];
      nextRender = currentRender === lastRender ? 0 : currentRender + 1;
      setState({
        totalPages: totalPagesRender,
        pageCount: pageCountRender,
        last: lastRender,
        current: currentRender,
        next: nextRender,
        show: true,
        loading: false
      });
    }
  }

  useEffect(() => {
    if (total !== state.total) {
      setState({
        total
      });
      doRender(currentPage, total);
    }

    generatePagination();
  }, [state.total, state.totalPages, state.current, total]);

  function selectPage(page) {
    const pageC = page === 0 ? currentPage : page;
    returnSelectedPage(pageC);
    setState({
      current: pageC
    });

    if (pageC === state.last) {
      setState({
        next: 0
      });
    } else {
      setState({
        next: state.current + 1
      });
    }

    generatePagination();
    ScrollToTop();
  }

  useImperativeHandle(paginationRef, () => ({
    reset() {
      selectPage(0);
    }

  }));

  function goPrev() {
    if (state.current > 0) {
      selectPage(state.current - 1);
    }
  }

  function goNext() {
    if (state.pageCount > state.current) {
      selectPage(state.current + 1);
    }
  }

  function mapButtons(section) {
    return section.map(buttonObject => {
      const btnStyle = {
        backgroundColor: state.buttonColor,
        color: state.textColor
      };

      if (state.current === buttonObject) {
        btnStyle.backgroundColor = state.activeButtonColor;
        btnStyle.color = state.textColorActive;
      }

      return jsx("li", {
        key: buttonObject,
        onClick: () => selectPage(buttonObject),
        style: btnStyle
      }, jsx("span", null, buttonObject));
    });
  }

  function renderButtons(section) {
    const {
      first,
      second,
      third
    } = state.pagination;

    switch (section) {
      case 'first':
        return mapButtons(first);

      case 'second':
        return mapButtons(second);

      default:
        return mapButtons(third);
    }
  }

  function renderDots(section) {
    const {
      second,
      third
    } = state.pagination;
    const canSee = section === 'first' ? second.length : third.length;
    return jsx("li", {
      style: {
        display: canSee ? 'block' : 'none'
      },
      className: "empty"
    }, jsx("span", null, "..."));
  }

  function renderArrows(className) {
    const {
      current,
      next
    } = state;
    const canSee = current > 1 || next !== 0;
    const isDisabled = className === 'prev' ? current === 1 : next === 0;
    return jsx("li", _extends({
      style: {
        display: canSee ? 'block' : 'none',
        cursor: isDisabled ? 'not-allowed' : 'pointer'
      }
    }, !isDisabled ? {
      onClick: () => className === 'prev' ? goPrev() : goNext()
    } : {}, {
      className: className
    }), jsx("span", null, jsx(ChevronRightIcon, {
      css: {
        width: 20,
        color: isDisabled ? arrowsColorDisabled : arrowsColor,
        transform: className === 'prev' ? 'rotate(180deg)' : ''
      }
    })));
  }

  return jsx("div", {
    css: [style, css`
          height: 40px;
        `, customStyle],
    style: {
      height: 40,
      ...containerStyle
    }
  }, jsx("div", {
    style: {
      display: state.show ? 'block' : 'none',
      float: position
    }
  }, jsx("div", null, jsx("div", {
    style: {
      display: state.loading ? 'block' : 'none'
    }
  }, "Buscando..."), jsx("div", {
    style: {
      display: !state.loading ? 'block' : 'none'
    }
  }, jsx("nav", null, jsx("ul", {
    className: "pagination"
  }, renderArrows('prev', 'arrow-back'), renderButtons('first'), renderDots('first'), renderButtons('second'), renderDots('second'), renderButtons('third'), renderArrows('next', 'arrow-next')))))));
};

Pagination.defaultProps = {
  limit: 20,
  total: 0,
  pageCount: 0,
  activeButtonColor: '#DEDEDE',
  textColor: '#07263b',
  textColorActive: '#07263b',
  buttonColor: '#fff',
  position: 'left',
  arrowsColor: '#07263b',
  arrowsColorDisabled: '#A0B9C8',
  currentPage: 1,
  customStyle: css``
};
export default Pagination;