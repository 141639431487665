import { useQuery, useQueryCache } from 'react-query'
import qs from 'qs'
import { client, coreClient } from '../utils/api-client'
import {
  PROCEDURE_EXTENSIONS_REQUEST,
  REQUEST_EXTENSIONS_DEFINITIONS,
} from '../utils/queryKeyFactory'
const CONFIG = {
  refetchOnWindowsFocus: false,
} // const { REACT_APP_APPLICATION_ID } = process.env

export async function getProcedureDetail({ procedureId, params = null }) {
  const query = qs.stringify(params, {
    addQueryPrefix: true,
  })
  const response = await client(`/procedures/${procedureId}/detail${query}`)

  if (
    (response === null || response === void 0 ? void 0 : response.statusCode) >=
    400
  ) {
    throw response
  }

  return response.data
}
export function useProcedure({ procedureId, config, params = null }) {
  return useQuery({
    queryKey: [
      'procedure',
      {
        procedureId,
      },
    ],
    queryFn: async () =>
      getProcedureDetail({
        procedureId,
        params,
      }),
    config: { ...CONFIG, ...config },
  })
}
export function useProcedureCache(procedureId) {
  const queryCache = useQueryCache()
  return queryCache.getQueryData([
    'procedure',
    {
      procedureId,
    },
  ])
}
export function useProcedureDefinition({
  procedureDefinitionId,
  config,
  query = {},
} = {}) {
  return useQuery({
    queryKey: [
      'procedure-definition',
      {
        procedureDefinitionId,
      },
    ],
    queryFn: async () => {
      const queryParams = qs.stringify(query, {
        addQueryPrefix: true,
      })
      const response = await client(
        `/proceduredefinitions/${procedureDefinitionId}${queryParams}`
      )

      if (
        (response === null || response === void 0
          ? void 0
          : response.statusCode) >= 400
      ) {
        throw response
      }

      return response.data
    },
    config: { ...CONFIG, ...config },
  })
}
export function useProcedureDefinitions({ config, query = {} } = {}) {
  return useQuery({
    queryKey: ['procedure-definitions'],
    queryFn: async () => {
      const queryParams = qs.stringify(query, {
        addQueryPrefix: true,
      })
      const response = await client(`/proceduredefinitions/${queryParams}`)

      if (
        (response === null || response === void 0
          ? void 0
          : response.statusCode) >= 400
      ) {
        throw response
      }

      return response.data
    },
    config: { ...CONFIG, ...config },
  })
}
export async function getProcedureDefinitionByCode(procedureCode) {
  const response = await client(
    `/proceduredefinitions/findByCode/${procedureCode}`
  )

  if (
    (response === null || response === void 0 ? void 0 : response.statusCode) >=
    400
  ) {
    throw response
  }

  return response.data
}
export function useProcedureDefinitionRequirements(procedureDefinitionId) {
  return useQuery({
    queryKey: [
      'procedure-definition-requirements',
      {
        procedureDefinitionId,
      },
    ],
    queryFn: async () => {
      const response = await client(
        `/proceduredefinitions/${procedureDefinitionId}/requirements`
      )

      if (
        (response === null || response === void 0
          ? void 0
          : response.statusCode) >= 400
      ) {
        throw response
      }

      return response.data
    },
    config: CONFIG,
  })
}
export function useProcedureStatuses({ params, config = {} } = {}) {
  const query = qs.stringify(params, {
    addQueryPrefix: true,
  })
  return useQuery({
    queryKey: ['procedure-statuses'],
    queryFn: async () => {
      const response = await coreClient(
        `/procedurestatuses${query}`,
        {},
        'core'
      )

      if (response.statusCode >= 400) {
        throw response
      }

      return response.data
    },
    config: { ...CONFIG, ...config },
  })
}
export function useProcedureTypes({ params, config = {} } = {}) {
  const query = qs.stringify(params, {
    addQueryPrefix: true,
  })
  return useQuery({
    queryKey: ['procedure-types'],
    queryFn: async () => {
      const response = await coreClient(`/proceduretypes${query}`, {}, 'core')

      if (
        (response === null || response === void 0
          ? void 0
          : response.statusCode) >= 400
      ) {
        throw response
      }

      return response.data
    },
    config: { ...CONFIG, ...config },
  })
}

async function getProcedureCategories({ categoryId, params = '' }) {
  let path = '/procedurecategories'

  if (categoryId) {
    path += `/${categoryId}`
  }

  const response = await client(path + params)

  if (
    (response === null || response === void 0 ? void 0 : response.statusCode) >=
    400
  ) {
    throw response
  }

  return response.data
}

export function useProcedureCategories({
  parentId = null,
  digitalRecordId = null,
  q = null,
  config = {},
}) {
  let query = `?parentId=${parentId}&digitalRecordId=${digitalRecordId}`

  if (q) {
    query += `&word=${q}`
  }

  return useQuery({
    queryKey: [
      'procedure-categories',
      {
        parentId,
        q,
        digitalRecordId,
      },
    ],
    queryFn: async () => {
      const response = await getProcedureCategories({
        params: query,
      })
      return response
    },
    config: { ...CONFIG, ...config },
  })
}
export function useProcedureCategory({ categoryId, config = {} }) {
  return useQuery({
    queryKey: [
      'procedure-category',
      {
        categoryId,
      },
    ],
    queryFn: async () => {
      const response = await getProcedureCategories({
        categoryId,
      })
      return response
    },
    config: { ...CONFIG, ...config },
  })
}

async function getFiliationsData(params) {
  const response = await client(`/filiations${params}`).catch(error =>
    Promise.reject(error)
  )

  if (
    (response === null || response === void 0 ? void 0 : response.statusCode) >=
    400
  ) {
    throw response
  }

  return response.data
}

export function useUserFiliation({
  params,
  config = {},
  type = 'residencies',
}) {
  const query = qs.stringify(params, {
    addQueryPrefix: true,
  })
  return useQuery({
    queryKey: ['user-filiation', params],
    queryFn: async () => {
      const [response] = await getFiliationsData(query)

      if (!response) {
        return {}
      }

      const { id, code, ExternalDigitalRecords, FiliationForm } = response
      const residencyExternalDigitalRecord = ExternalDigitalRecords.find(
        ({ ExternalDigitalRecordType }) =>
          ExternalDigitalRecordType.code === type
      )
      const { ExternalDigitalRecordType } = residencyExternalDigitalRecord
      return {
        ...FiliationForm,
        recordNumber: code,
        recordType: ExternalDigitalRecordType.code,
        recordId: id,
      }
    },
    config: { ...CONFIG, ...config },
  })
}
export function useSearchFiliations({
  params,
  config = {},
  type = 'residencies',
}) {
  const query = qs.stringify(params, {
    addQueryPrefix: true,
  })
  return useQuery({
    queryKey: ['filiations', params],
    queryFn: async () => {
      var _response$filter$map, _response$filter

      const response = await getFiliationsData(query)
      return (_response$filter$map =
        response === null || response === void 0
          ? void 0
          : (_response$filter = response.filter(
              ({ FiliationForm, ExternalDigitalRecords }) => {
                var _ExternalDigitalRecor

                // se filtran por los resultados que tengan
                // - los datos de filiación
                // - external digital records
                // - y que el external digital record sea del tipo que se está pidiendo
                const recordType =
                  (_ExternalDigitalRecor =
                    ExternalDigitalRecords === null ||
                    ExternalDigitalRecords === void 0
                      ? void 0
                      : ExternalDigitalRecords.findIndex(
                          ({ ExternalDigitalRecordType }) =>
                            ExternalDigitalRecordType.code === type
                        )) !== null && _ExternalDigitalRecor !== void 0
                    ? _ExternalDigitalRecor
                    : -1

                if (!FiliationForm || recordType === -1) {
                  return false
                }

                return true
              }
            )) === null || _response$filter === void 0
          ? void 0
          : _response$filter.map(
              ({ id, code, FiliationForm, ExternalDigitalRecords }) => {
                var _FiliationForm$second, _FiliationForm$Nation

                const externalDigitalRecordType = ExternalDigitalRecords.find(
                  ({ ExternalDigitalRecordType }) =>
                    ExternalDigitalRecordType.code === type
                )
                return {
                  id,
                  text: `${
                    FiliationForm === null || FiliationForm === void 0
                      ? void 0
                      : FiliationForm.name
                  } ${
                    FiliationForm === null || FiliationForm === void 0
                      ? void 0
                      : FiliationForm.lastName
                  } ${
                    (_FiliationForm$second =
                      FiliationForm === null || FiliationForm === void 0
                        ? void 0
                        : FiliationForm.secondLastName) !== null &&
                    _FiliationForm$second !== void 0
                      ? _FiliationForm$second
                      : ''
                  } - ${
                    FiliationForm === null || FiliationForm === void 0
                      ? void 0
                      : (_FiliationForm$Nation = FiliationForm.Nationality) ===
                          null || _FiliationForm$Nation === void 0
                      ? void 0
                      : _FiliationForm$Nation.name
                  } - ${
                    externalDigitalRecordType.ExternalDigitalRecordType
                      .description
                  } Nº ${externalDigitalRecordType.code}`,
                  value: id,
                  record: {
                    recordId: id,
                    recordNumber: code,
                    recordType:
                      externalDigitalRecordType.ExternalDigitalRecordType.code,
                    externalDigitalRecordCode: externalDigitalRecordType.code,
                    ...FiliationForm,
                  },
                }
              }
            )) !== null && _response$filter$map !== void 0
        ? _response$filter$map
        : []
    },
    config: { ...CONFIG, ...config },
  })
}
export async function fetchUserPendingProcedures({
  procedureId,
  requesterDigitalRecordId,
}) {
  const response = await client(
    `/validations/pendingProcedures/${procedureId}?requesterDigitalRecordId=${requesterDigitalRecordId}`,
    {
      method: 'GET',
    }
  )

  if (response.statusCode >= 400) {
    throw response
  }

  return response.data
}
export function useRequestExtensionsDefinitions({ config = {} } = {}) {
  return useQuery({
    queryKey: REQUEST_EXTENSIONS_DEFINITIONS.list(),

    async queryFn() {
      try {
        const response = await coreClient(`/requests/extensions/definitions`)

        if (
          (response === null || response === void 0
            ? void 0
            : response.statusCode) >= 400
        ) {
          throw response.message
        }

        return response.data.map(definition => {
          var _definition$Extension

          return {
            text:
              (_definition$Extension = definition.ExtensionDefinitionType) ===
                null || _definition$Extension === void 0
                ? void 0
                : _definition$Extension.name,
            value: definition.id,
            editableDays: definition.editableDays,
            defaultExtensionDays: definition.defaultExtensionDays,
            autoapprove: definition.autoapprove,
          }
        })
      } catch (error) {
        return Promise.reject(error)
      }
    },

    config: { ...CONFIG, ...config },
  })
}
export function useProcedureRequestExtensions({
  procedureId,
  config = {},
} = {}) {
  return useQuery({
    queryKey: PROCEDURE_EXTENSIONS_REQUEST.list(procedureId),

    async queryFn() {
      try {
        const response = await coreClient(
          `/procedures/${procedureId}/requests/extension/all`
        )

        if (
          (response === null || response === void 0
            ? void 0
            : response.statusCode) >= 400
        ) {
          throw response.message
        }

        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },

    config: { ...CONFIG, ...config },
  })
}
