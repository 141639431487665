/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '21',
  height: '23',
  viewBox: '0 0 21 23',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M17.25 9.125L15.6637 7.5275L8.25 14.9412L5.33625 12.0387L3.75 13.625L8.25 18.125L17.25 9.125ZM18.375 2.375H13.6725C13.2 1.07 11.9625 0.125 10.5 0.125C9.0375 0.125 7.8 1.07 7.3275 2.375H2.625C2.4675 2.375 2.32125 2.38625 2.175 2.42C1.8167 2.49518 1.48205 2.65631 1.19984 2.88952C0.917634 3.12273 0.696336 3.42103 0.555 3.75875C0.4425 4.0175 0.375 4.31 0.375 4.625V20.375C0.375 20.6787 0.4425 20.9825 0.555 21.2525C0.6675 21.5225 0.83625 21.7588 1.03875 21.9725C1.3425 22.2763 1.73625 22.5012 2.175 22.5912C2.32125 22.6137 2.4675 22.625 2.625 22.625H18.375C19.6125 22.625 20.625 21.6125 20.625 20.375V4.625C20.625 3.3875 19.6125 2.375 18.375 2.375ZM10.5 2.09375C10.9613 2.09375 11.3438 2.47625 11.3438 2.9375C11.3438 3.39875 10.9613 3.78125 10.5 3.78125C10.0387 3.78125 9.65625 3.39875 9.65625 2.9375C9.65625 2.47625 10.0387 2.09375 10.5 2.09375ZM18.375 20.375H2.625V4.625H18.375V20.375Z',
    fill: 'white',
  }),
  name: 'ClipboardCheck',
})
