import { lazy } from 'react'
import { format, intervalToDuration } from 'date-fns'
import { enUS, es } from 'date-fns/locale'
import { jsx } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { getToken, getCSRFToken } from './api-client'

const { REACT_APP_CDN_URL } = process.env

/**
 * Esta función recibe un file y lo convierte a un buffer
 * @param {*} file
 * @return una promesa que resuelve el Buffer
 */
function getBufferFromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = e => {
      resolve(toBuffer(e.target.result))
    }
    reader.onerror = e => {
      reject(e.target.error)
    }
    reader.readAsArrayBuffer(file)
  })
}

/**
 * Esta función recibe un array de bytes o un array de buffers
 * y devuelve un buffer unificado
 * @param {*} array of bytes
 * @return Buffer
 */
function toBuffer(array) {
  const buf = Buffer.alloc(array.byteLength)
  const view = new Uint8Array(array)
  for (let i = 0; i < buf.length; ++i) {
    buf[i] = view[i]
  }
  return buf
}

/**
 * Esta función puede ser utilizado como reducer de los hooks
 * useReducer genéricos
 * @param {*} currentState
 * @param {*} newState
 * @return Object reduced
 */
function reducer(currentState, newState) {
  return { ...currentState, ...newState }
}

/**
 * Esta función recibe un parametro y pregunta si el valor que
 * recibe es primitivo (string, boolean, numbers) o no
 * @param {*} val
 * @return Boolean
 */
function isPrimitive(val) {
  return val === null || /^[sbn]/.test(typeof val)
}

/**
 * Esta función recibe dos parametros y obtiene la posición
 * correcta del cursor dentro de un input al editar
 * @param {*} text
 * @param {*} cursor
 * @return Integer
 */
function getCursorPosition(text, cursor) {
  return text.slice(0, cursor).length
}

/**
 * Esta función recibe un hexadecimal y lo pasa a RGB,
 * para ser más facilmente utilizado en CSS.
 *
 * @param {*} hexadecimal
 */
function HEX2RGB(hexadecimal) {
  let hex = hexadecimal
  if (hex.charAt(0) === '#') {
    hex = hex.substr(1)
  }
  if (hex.length < 2 || hex.length > 6) {
    return false
  }
  const values = hex.split('')
  let r
  let g
  let b

  if (hex.length === 2) {
    r = parseInt(values[0].toString() + values[1].toString(), 16)
    g = r
    b = r
  } else if (hex.length === 3) {
    r = parseInt(values[0].toString() + values[0].toString(), 16)
    g = parseInt(values[1].toString() + values[1].toString(), 16)
    b = parseInt(values[2].toString() + values[2].toString(), 16)
  } else if (hex.length === 6) {
    r = parseInt(values[0].toString() + values[1].toString(), 16)
    g = parseInt(values[2].toString() + values[3].toString(), 16)
    b = parseInt(values[4].toString() + values[5].toString(), 16)
  } else {
    return false
  }

  return [r, g, b]
}

async function handleDownloadSingleFile(uri) {
  try {
    const headers = {}
    const token = getToken()
    if (token && uri.includes(REACT_APP_CDN_URL)) {
      headers.Authorization = `Bearer ${token}`
    }

    let response = await window.fetch(uri, {
      method: 'GET',
      headers,
      credentials: 'include',
    })
    if (!response.ok) {
      throw new Error('Error')
    }

    response = await response.blob()

    const url = URL.createObjectURL(response)

    window.open(url, '_blank')
  } catch (error) {
    throw new Error(error)
  }
}

async function handleDownloadMultipleFile(uris) {
  try {
    const headers = {}
    const token = getToken()
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    headers['X-CSRF-Token'] = await getCSRFToken()
    headers['Content-Type'] = 'application/x-www-form-urlencoded'
    let response = await window.fetch(`${REACT_APP_CDN_URL}/downloadAll`, {
      method: 'POST',
      headers,
      credentials: 'include',
      body: `files=${JSON.stringify({
        paths: uris.map(uri => uri.replace(`${REACT_APP_CDN_URL}/`, '')),
      })}`,
    })
    if (!response.ok) {
      throw new Error('Error')
    }

    response = await response.blob()

    const url = URL.createObjectURL(response)

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'file.zip')
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  } catch (error) {
    throw new Error(error)
  }
}

/**
 * Esta función permite invocar componentes de React de la parte
 * de secciones conforme se vayan necesitando sin la necesidad de
 * cargar inicialmente todos los componentes y todo lo que eso implica
 * en el primer render y la carga inicial
 *
 * @param {*} factory el factory del componente que queremos importar
 * Ex: () => import('pathDelComponente')
 */
function importSectionComponentsOnDemand(factory) {
  const Component = lazy(factory)
  Component.preload = factory

  return Component
}

function convertEmptyStringValuesToNull(data) {
  const objectWithEmptyStrings = data
  if (typeof objectWithEmptyStrings === 'object') {
    Object.keys(objectWithEmptyStrings).forEach(keys => {
      if (typeof objectWithEmptyStrings[keys] === 'object') {
        convertEmptyStringValuesToNull(objectWithEmptyStrings[keys])
      } else if (objectWithEmptyStrings[keys].toString().trim().length === 0) {
        objectWithEmptyStrings[keys] = null
      }
    })
  }

  return objectWithEmptyStrings
}

// esta funcion remueve todos los acentos de las palabras y los cambia por su
// letra equivalente, solo acepta letras y la ñ
function normalizeTextSpecial(value, accepted = /[^a-zA-Z[\] ]/g) {
  return value
    .replace(/ñ/g, '[ene]')
    .replace(/Ñ/g, '[ENE]')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(accepted, '')
    .replace(/\[ENE\]/g, 'Ñ')
    .replace(/\[ene\]/g, 'ñ')
    .replace(/[[\]]/g, '')
    .trimStart()
}

const breakpoints = [576, 768, 992, 1200]
const mediaQuery = breakpoints.map(bp => `@media (max-width: ${bp}px)`)
const isUIWebView = /((iPhone|iPod|iPad).*AppleWebKit(?!.*Version)|; wv)/i.test(
  navigator.userAgent
)

const generateRangeArray = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => ({
    id: `${(start + i * step).toString().length === 1 ? '0' : ''}${
      start + i * step
    }`,
    name: `${(start + i * step).toString().length === 1 ? '0' : ''}${
      start + i * step
    }`,
  }))

function MonthsOfTheYearList() {
  const locales = {
    es,
    'es-CR': es,
    en: enUS,
    'en-US': enUS,
  }
  const currentDate = new Date()
  const monthList = []
  for (let i = 0; i <= 11; i++) {
    currentDate.setMonth(i)
    const month = format(currentDate, 'MMMM', {
      locale: locales[localStorage.getItem('accept-language')],
    })
    monthList.push({
      id: i + 1,
      name: month[0].toUpperCase() + month.slice(1),
    })
  }
  return monthList
}

const currencyFormat = (value, currencyStyle, currency) => {
  if (!Number.isNaN(Number(value))) {
    const formatter = new Intl.NumberFormat(currencyStyle || 'en-US', {
      style: 'currency',
      currency: currency || 'USD',
    })
    return formatter.format(value).slice(1)
  }

  if (!value) {
    return null
  }

  throw new Error('La función currencyFormat solo funciona con números')
}

/**
 * Esta función genera un array nuevo cambiando el key viejo por uno nuevo a conveniencia
 *
 * @param {Array} data es un array con los datos que se quieren cambiar los keys o agregar idioma
 * @param {Array} keys es un arreglo de objectos, [{ id: 'code }], se utiliza para crear el nuevo arreglo,
 * el key (id) es para crear el key en el nuevo arreglo y el valor (code) es para obtener el valor del arreglo viejo
 * se puede mandar mas key y values de ser necesario
 * @returns Array
 */
const dataToArray = (data = [], keys = []) => {
  if (keys.length === 0) {
    throw new Error('El arreglo de keys para dataToArray no puede estar vacio')
  }

  return data.map(item => {
    const tempItem = {}
    keys.forEach(key => {
      const newKey = Object.keys(key)[0]
      const oldKey = key[newKey]
      tempItem[newKey] = item[oldKey] || ''
    })

    return tempItem
  })
}

function cloneElement(element, props) {
  return jsx(element.type, {
    key: element.key,
    ref: element.ref,
    ...element.props,
    ...props,
  })
}

function formatDate(date) {
  if (date) {
    try {
      if (date.length === 10) {
        return date.replace(/-/g, '/')
      }

      return format(new Date(date), 'dd/MM/yyyy')
    } catch (error) {
      return ''
    }
  }
  return ''
}

function formatDateWithTime(date) {
  if (date) {
    try {
      return format(new Date(date), 'dd/MM/yyyy h:mm aaaa')
    } catch (error) {
      return ''
    }
  }
  return ''
}

function CustomError(msg, props) {
  let tempProps = props
  let tempMsg = msg
  if (tempMsg && typeof tempMsg === 'object') {
    tempProps = tempMsg
    tempMsg = undefined
  } else {
    this.message = tempMsg
  }
  if (tempProps) {
    Object.keys(tempProps).forEach(key => {
      this[key] = tempProps[key]
    })
  }
}

function parseMinutesToText(minutes) {
  const { t } = useTranslation()

  const duration = intervalToDuration({ start: 0, end: minutes * 60 * 1000 })
  const addAnd = hasTime => `${hasTime ? ` ${t('y')} ` : ''}`
  let result = `${
    duration.years
      ? `${duration.years} ${duration.years === 1 ? t('año') : t('años')}`
      : ''
  }${
    duration.months
      ? `${addAnd(duration.years)}${duration.months} ${
          duration.months === 1 ? t('mes') : t('meses')
        }`
      : ''
  }${
    duration.days
      ? `${addAnd(duration.years || duration.months)}${duration.days} ${
          duration.days === 1 ? t('día') : t('días')
        }`
      : ''
  }${
    duration.hours
      ? `${addAnd(duration.years || duration.months || duration.days)}${
          duration.hours
        } ${duration.hours === 1 ? t('hora') : t('horas')}`
      : ''
  }${
    duration.minutes
      ? `${addAnd(
          duration.years || duration.months || duration.days || duration.hours
        )}${duration.minutes} ${
          duration.minutes === 1 ? t('minuto') : t('minutos')
        }`
      : ''
  }`

  if (
    duration.years === 0 &&
    duration.months === 0 &&
    duration.days === 0 &&
    duration.hours === 0 &&
    duration.minutes < 1
  ) {
    result = `1 ${t('minuto')}`
  }

  return result
}

function preprocessVehicleFormFields(object) {
  // Define numeric fields
  const nullableFields = [
    'cifNumber',
    'length',
    'wheelLines',
    'lineNumber',
    'policyNumber',
    'yearAcceptance',
    'netWeight',
    'policyDate',
    'steeringWheel',
    'hasExoneration',
  ]

  let processedObject = { ...object }

  if (object.vin) {
    processedObject = {
      ...object,
      identification: object.vin.replace(/[\s\t]+/g, '').trim(),
      vin: object.vin.replace(/[\s\t]+/g, '').trim(),
      chasis: object.chasis.replace(/[\s\t]+/g, '').trim(),
      identificationType: 1,
    }
  } else {
    processedObject = {
      ...object,
      identification: object.chasis.replace(/[\s\t]+/g, '').trim(),
      chasis: object.chasis.replace(/[\s\t]+/g, '').trim(),
      vin: object.vin.replace(/[\s\t]+/g, '').trim(),
      identificationType: 3,
    }
  }

  // Iterate over numeric fields and update empty strings to null
  nullableFields.forEach(field => {
    if (object[field] === '') {
      processedObject[field] = null // Set empty strings to null
    }
  })

  return processedObject
}

export {
  getBufferFromFile,
  toBuffer,
  reducer,
  isPrimitive,
  getCursorPosition,
  HEX2RGB,
  handleDownloadSingleFile,
  handleDownloadMultipleFile,
  mediaQuery,
  isUIWebView,
  importSectionComponentsOnDemand,
  convertEmptyStringValuesToNull,
  normalizeTextSpecial,
  generateRangeArray,
  MonthsOfTheYearList,
  dataToArray,
  currencyFormat,
  cloneElement,
  formatDate,
  formatDateWithTime,
  CustomError,
  parseMinutesToText,
  preprocessVehicleFormFields,
}
