const application = require('./application.json')
const authorization = require('./authorization.json')
const calendar = require('./calendar.json')
const card = require('./card.json')
const catalogs = require('./catalogs.json')
const dependentprocedureform = require('./dependentprocedureform.json')
const devices = require('./devices.json')
const documentgenerator = require('./documentgenerator.json')
const documents = require('./documents.json')
const fito = require('./fito.json')
const idType = require('./idType.json')
const information = require('./information.json')
const location = require('./location.json')
const meeting = require('./meeting.json')
const meetings = require('./meetings.json')
const observation = require('./observation.json')
const payment = require('./payment.json')
const permission = require('./permission.json')
const polices = require('./polices.json')
const procedure = require('./procedure.json')
const procedurerequesttypes = require('./procedurerequesttypes.json')
const record = require('./record.json')
const requirements = require('./requirements.json')
const resolution = require('./resolution.json')
const requesttypes = require('./requesttypes.json')
const roles = require('./roles.json')
const sections = require('./sections.json')
const signature = require('./signature.json')
const sso = require('./sso.json')
const user = require('./user.json')
const webservice = require('./webservice.json')
const allowedchange = require('./allowedchange.json')
const allowedchangedefinition = require('./allowedchangedefinition.json')
const changesectiondefinition = require('./changesectiondefinition.json')
const changesectionform = require('./changesectionform.json')
const productform = require('./productform.json')
const foodform = require('./foodform.json')
const modulecomponentdefinition = require('./modulecomponentdefinition.json')
const sectionviewconfiguration = require('./sectionviewconfiguration.json')
const applicationmodule = require('./applicationmodule.json')
const applicationmodulecomponent = require('./applicationmodulecomponent.json')
const formresults = require('./formresults.json')
const evaluationguidedefinitions = require('./evaluationguidedefinitions.json')
const procedureevaluationguides = require('./procedureevaluationguides.json')
const manufacturerform = require('./manufacturerform.json')
const headlineform = require('./headlineform.json')
const importerform = require('./importerform.json')
const productcountries = require('./productcountries.json')
const recognitionforms = require('./recognitionforms.json')
const renovationforms = require('./renovationforms.json')
const transferforms = require('./transferforms.json')
const transferbyproducts = require('./transferbyproducts.json')
const useofregistrations = require('./useofregistrations.json')
const product = require('./product.json')
const productpresentation = require('./productpresentation.json')
const food = require('./food.json')
const manufacturer = require('./manufacturer.json')
const headline = require('./headline.json')
const importer = require('./importer.json')
const productcountry = require('./productcountry.json')
const qrcode = require('./qrcode.json')
const vehicletype = require('./vehicletype.json')
const vehicleidentificationtype = require('./vehicleidentificationtype.json')
const vehicledata = require('./vehicledata.json')
const productpresentationform = require('./productpresentationform.json')
const producttype = require('./producttype.json')
const presentationtype = require('./presentationtype.json')
const presentationclassification = require('./presentationclassification.json')
const measurementunit = require('./measurementunit.json')
const packagingtype = require('./packagingtype.json')
const material = require('./material.json')
const foodtype = require('./foodtype.json')
const businesstype = require('./businesstype.json')
const manufacturertype = require('./manufacturertype.json')
const person = require('./person.json')
const business = require('./business.json')
const extensiondefinitiontype = require('./extensiondefinitiontype.json')
const requestextensiondefinition = require('./requestextensiondefinition.json')
const procedureextensiondefinition = require('./procedureextensiondefinition.json')
const procedurerequestextension = require('./procedurerequestextension.json')
const pendingcertificatestransfer = require('./pendingcertificatestransfer.json')
const procedurecategorydigitalrecordtype = require('./procedurecategorydigitalrecordtype.json')
const entitysourcemapping = require('./entitysourcemapping.json')
const procedureprofilemapping = require('./procedureprofilemapping.json')
const newownerform = require('./newownerform.json')
const transferedrecordform = require('./transferedrecordform.json')
const productcountryform = require('./productcountryform.json')
const reporttype = require('./reporttype.json')
const reportdefinitiontype = require('./reportdefinitiontype.json')
const reportdefinition = require('./reportdefinition.json')
const integration = require('./integration.json')
const viewowner = require('./viewowner.json')
const certificateinformation = require('./certificateinformation.json')
const digitalrecordauthorizationform = require('./digitalrecordauthorizationform.json')
const digitalrecordauthorization = require('./digitalrecordauthorization.json')
const proceduredefinitionincome = require('./proceduredefinitionincome.json')
const procedureopenedbyoperator = require('./procedureopenedbyoperator.json')
const allowedchangedefinitioncatalog = require('./allowedchangedefinitioncatalog.json')
const digitalrecordfolderdefinition = require('./digitalrecordfolderdefinition.json')
const profession = require('./profession.json')
const professional = require('./professional.json')
const cosmeticshape = require('./cosmeticshape.json')
const cosmeticuse = require('./cosmeticuse.json')
const cosmetic = require('./cosmetic.json')
const cosmeticform = require('./cosmeticform.json')
const vehicleform = require('./vehicleform.json')
const importforms = require('./importforms.json')
const dua = require('./dua.json')
const duavehicle = require('./duavehicle.json')
const customs = require('./customs.json')
const vinvalidations = require('./vinvalidations.json')
const preprocessvehicle = require('./preprocessvehicle.json')
const vehiclecharges = require('./vehiclecharges.json')

const reportlogs = require('./reportlogs.json')
const businessdiscount = require('./businessdiscount.json')
const serviceslogs = require('./serviceslogs.json')
const webServiceExecutionReport = require('./webServiceExecutionReport.json')
const cabinTypes = require('./cabintypes.json')
const colors = require('./colors.json')
const fuels = require('./fuels.json')
const roofTypes = require('./rooftypes.json')
const tractions = require('./tractions.json')
const vehicleDeposit = require('./vehicledeposit.json')
const vehicleFrame = require('./vehicleframes.json')
const vehicleMake = require('./vehiclemakes.json')
// # NEW IMPORT (DON'T DELETE THIS) # //

module.exports = {
  application,
  authorization,
  calendar,
  card,
  catalogs,
  dependentprocedureform,
  devices,
  documentgenerator,
  documents,
  fito,
  idType,
  information,
  location,
  meeting,
  meetings,
  observation,
  payment,
  permission,
  polices,
  procedure,
  procedurerequesttypes,
  record,
  requirements,
  resolution,
  requesttypes,
  roles,
  sections,
  signature,
  sso,
  user,
  webservice,
  allowedchange,
  allowedchangedefinition,
  changesectiondefinition,
  changesectionform,
  productform,
  foodform,
  modulecomponentdefinition,
  sectionviewconfiguration,
  applicationmodule,
  applicationmodulecomponent,
  formresults,
  evaluationguidedefinitions,
  procedureevaluationguides,
  manufacturerform,
  headlineform,
  importerform,
  productcountries,
  recognitionforms,
  renovationforms,
  transferforms,
  transferbyproducts,
  useofregistrations,
  product,
  productpresentation,
  food,
  manufacturer,
  headline,
  importer,
  productcountry,
  qrcode,
  vehicletype,
  vehicleidentificationtype,
  vehicledata,
  productpresentationform,
  producttype,
  presentationtype,
  presentationclassification,
  measurementunit,
  packagingtype,
  material,
  foodtype,
  businesstype,
  manufacturertype,
  person,
  business,
  extensiondefinitiontype,
  requestextensiondefinition,
  procedureextensiondefinition,
  procedurerequestextension,
  pendingcertificatestransfer,
  procedurecategorydigitalrecordtype,
  entitysourcemapping,
  procedureprofilemapping,
  newownerform,
  TransferedRecordForm: transferedrecordform,
  productcountryform,
  reporttype,
  reportdefinitiontype,
  reportdefinition,
  integration,
  viewowner,
  certificateinformation,
  digitalrecordauthorizationform,
  digitalrecordauthorization,
  proceduredefinitionincome,
  procedureopenedbyoperator,
  allowedchangedefinitioncatalog,
  digitalrecordfolderdefinition,
  profession,
  professional,
  cosmeticshape,
  cosmeticuse,
  cosmetic,
  cosmeticform,
  vehicleform,
  importforms,
  dua,
  duavehicle,
  customs,
  vinvalidations,
  preprocessvehicle,
  vehiclecharges,
  reportlogs,
  businessdiscount,
  serviceslogs,
  webServiceExecutionReport,
  cabinTypes,
  colors,
  fuels,
  roofTypes,
  tractions,
  vehicleDeposit,
  vehicleFrame,
  vehicleMake,
  // # NEW EXPORT (DON'T DELETE THIS) # //
}
