import { css } from '@emotion/core';
import { migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../Colors';
import { mediaQuery } from '../../utils';
const themes = {
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.white,
    beta: phytosanitaryWeb.sherpaBlue,
    gamma: 'transparent',
    delta: phytosanitaryWeb.lightSeaGreen
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.white,
    beta: migratoryWebCitizen.prussianBlue,
    gamma: migratoryWebCitizen.fireEngineRed,
    delta: migratoryWebCitizen.blackPearl,
    epsilon: migratoryWebCitizen.cerulean
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.white,
    beta: sanitarianRegistersWeb.prussianBlue,
    gamma: sanitarianRegistersWeb.fireEngineRed,
    delta: sanitarianRegistersWeb.blackPearl,
    epsilon: sanitarianRegistersWeb.cerulean
  },
  tdCarWeb: {
    alpha: tdCarWeb.white,
    beta: tdCarWeb.prussianBlue,
    gamma: tdCarWeb.fireEngineRed,
    delta: tdCarWeb.blackPearl,
    epsilon: tdCarWeb.cerulean
  }
};
export default (({
  theme = 'migratoryWebCitizen'
}) => css`
  ul {
    list-style: none;
    display: grid;
    margin: 0;
    padding: 0;
    grid-gap: 14px;
  }

  .col-2 {
    grid-template-columns: repeat(1, 1fr);
  }

  .col-3 {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px 22px;
  }

  li {
    background-color: ${themes[theme].alpha};
    border-radius: 10px;
    padding: 18px 16px 14px 20px;
    position: relative;
  }

  .title-container {
    display: flex;
    align-items: center;
    padding-right: 54px;
    ${mediaQuery[0]} {
      flex-basis: unset !important;
      width: 100%;
    }

    .icon {
      color: ${themes[theme].beta};
      width: 36px;
      height: 43px;
    }

    .document-title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: ${themes[theme].delta};
      margin-left: 16px;
    }
  }

  .document-button {
    text-align: center;
    border-radius: 100px;
    position: absolute;
    right: 16px;
    top: 19px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding: 3px 14px;
    text-transform: uppercase;
    background-color: ${themes[theme].gamma};
    color: ${themes[theme].alpha};
    cursor: pointer;
  }

  .document-button-download {
    text-align: center;
    border-radius: 100px;
    position: absolute;
    right: 16px;
    top: 19px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding: 3px 14px;
    text-transform: uppercase;
    background-color: ${themes[theme].epsilon};
    color: ${themes[theme].alpha};
    cursor: pointer;
  }

  .document-head {
    display: flex;
  }

  .document-info {
    font-style: normal;
    font-size: 14px;
    padding-left: 55px;

    span {
      font-weight: 500;
    }
  }

  @media screen and (min-width: 1024px) {
    .col-2 {
      grid-template-columns: repeat(2, 1fr);
    }

    .col-3 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px 22px;
    }
  }

  @media screen and (min-width: 1400px) {
    .col-3 {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 15px 22px;
    }
  }
`);