import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '30',
  height: '30',
  viewBox: '0 0 30 30',
  css: css({
    path: {
      stroke: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M20.3918 10.0781L25.3124 15L20.3918 19.9219',
      stroke: '#F93545',
      strokeWidth: '3',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    }),
    jsx('path', {
      d: 'M12.1875 15H25.3091',
      stroke: '#F93545',
      strokeWidth: '3',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    }),
    jsx('path', {
      d: 'M12.1875 25.3125H5.625C5.37636 25.3125 5.1379 25.2137 4.96209 25.0379C4.78627 24.8621 4.6875 24.6236 4.6875 24.375V5.625C4.6875 5.37636 4.78627 5.1379 4.96209 4.96209C5.1379 4.78627 5.37636 4.6875 5.625 4.6875H12.1875',
      stroke: '#F93545',
      strokeWidth: '3',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    })
  ),
  name: 'ArrowRightBrackets',
})
