const common = {
  aliceBlue: '#F4FBFE',
  aliceBlueAlt: '#EDF6FA',
  allports: '#156472',
  aquaHaze: '#F1F6F6',
  AzureishWhite: '#D4E4ED',
  black: '#000000',
  blackPearl: '#0D1F2D',
  botticelli: '#93B7BE',
  brightGray: '#EBF1F4',
  cadetBlue: '#A0B9C8',
  cello: '#214A61',
  cerulean: '#0F7CB5',
  chineseWhite: '#E0E0E0',
  cloudBurst: '#373A51',
  columbiaBlue: '#C7E2F0',
  darkGray: '#333333',
  deepCerulean: '#0672AB',
  eastern: '#0883A0',
  fairPink: '#FFE8EB',
  fireEngineRed: '#CE1625',
  frenchPass: '#AACDE0',
  geyser: '#afccd1',
  gray: '#313131',
  grey: '#828282',
  mabel: '#C3E0E5',
  mantis: '#7DBC44',
  morningGlory: '#A3C6CD',
  pacificBlue: '#1896D8',
  prussianBlue: '#08415C',
  raven: '#616C7C',
  sherpaBlue: '#044550',
  silver: '#BDBDBD',
  valhalla: '#2B2D42',
  white: '#FFFFFF',
  whiteSmoke: '#F2F2F2',
  ziggurat: '#CDE1E4'
};
const home = { ...common
};
const userWeb = { ...common,
  bismark: '#547080',
  black: '#000000',
  lightSilver: '#D8D8D8',
  pacific: '#0A9CBE',
  snow: '#f9f9f9'
};
const migratoryWebCitizen = { ...common,
  amaranth: '#F93545',
  blueZodiac: '#424459',
  darkElectricBlue: '#5B7889',
  limerick: '#85BB23'
};
const sanitarianRegistersWeb = { ...common,
  amaranth: '#F93545',
  blueZodiac: '#424459',
  darkElectricBlue: '#5B7889',
  limerick: '#85BB23'
};
const tdCarWeb = { ...common,
  amaranth: '#F93545',
  blueZodiac: '#424459',
  darkElectricBlue: '#5B7889',
  limerick: '#85BB23'
};
const phytosanitaryWeb = { ...common,
  aliceBlueAlt: '#EAF8F8',
  antiFlashWhite: '#EEF4F4',
  burntSienna: '#EB5757',
  darkGreen: '#548289',
  flamingo: '#F44545',
  lightSeaGreen: '#38B4A6',
  mabel: '#C7E0E0',
  morningGlory: '#A0C8C8',
  riceFlower: '#EEFFE8',
  salem: '#0B8535',
  smaltBlue: '#548289'
};
export { home, userWeb, migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb, phytosanitaryWeb };