import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '34',
  height: '34',
  viewBox: '0 0 34 34',
  css: css({
    path: {
      stroke: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('circle', {
      cx: '17',
      cy: '17',
      r: '16',
      stroke: '#0672AB',
      strokeWidth: '2',
    }),
    jsx('path', {
      d: 'M14.1254 22.6644L10.3103 18.5442C10.2119 18.4379 10.1339 18.3118 10.0807 18.1729C10.0274 18.0341 10 17.8853 10 17.735C10 17.5848 10.0274 17.436 10.0807 17.2971C10.1339 17.1583 10.2119 17.0322 10.3103 16.9259C10.4087 16.8197 10.5255 16.7354 10.6541 16.6779C10.7826 16.6204 10.9204 16.5908 11.0595 16.5908C11.1987 16.5908 11.3364 16.6204 11.465 16.6779C11.5935 16.7354 11.7103 16.8197 11.8087 16.9259L14.8799 20.2313L22.1913 12.3351C22.39 12.1206 22.6595 12 22.9405 12C23.2215 12 23.491 12.1206 23.6897 12.3351C23.8884 12.5497 24 12.8408 24 13.1443C24 13.4478 23.8884 13.7388 23.6897 13.9534L15.6238 22.6644C15.5255 22.7708 15.4087 22.8552 15.2802 22.9128C15.1516 22.9704 15.0138 23 14.8746 23C14.7354 23 14.5976 22.9704 14.4691 22.9128C14.3405 22.8552 14.2237 22.7708 14.1254 22.6644Z',
      fill: '#0672AB',
    })
  ),
  name: 'CircleCheck',
})
