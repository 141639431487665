/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '10',
  height: '7',
  viewBox: '0 0 10 7',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M0.220797 5.91743C0.253912 5.99737 0.309988 6.0657 0.381935 6.11377C0.453882 6.16184 0.538469 6.1875 0.624998 6.1875L9.375 6.1875C9.46153 6.1875 9.54612 6.16184 9.61806 6.11377C9.69001 6.06569 9.74609 5.99736 9.7792 5.91742C9.81231 5.83748 9.82097 5.74951 9.80409 5.66464C9.78721 5.57977 9.74554 5.50182 9.68435 5.44063L5.30935 1.06565C5.26873 1.02502 5.2205 0.992791 5.16742 0.970803C5.11434 0.948815 5.05745 0.937499 5 0.937499C4.94255 0.937499 4.88566 0.948815 4.83258 0.970803C4.7795 0.992791 4.73127 1.02502 4.69065 1.06565L0.315645 5.44063C0.254457 5.50182 0.212786 5.57977 0.195903 5.66464C0.17902 5.74951 0.187683 5.83748 0.220797 5.91743Z',
    fill: '#0672AB',
  }),
  name: 'BlueArrowUp',
})
