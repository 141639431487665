import { css } from '@emotion/core';
import { home, migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../../Colors';
import { mediaQuery } from '../../../utils';
const themes = {
  home: {
    alpha: home.cerulean,
    beta: home.cerulean,
    gamma: home.white,
    delta: home.cerulean,
    epsilon: home.pacificBlue,
    dseta: home.cello,
    eta: home.cadetBlue,
    zeta: home.cerulean
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.cerulean,
    beta: migratoryWebCitizen.cerulean,
    gamma: migratoryWebCitizen.white,
    delta: migratoryWebCitizen.cerulean,
    epsilon: migratoryWebCitizen.pacificBlue,
    dseta: migratoryWebCitizen.cello,
    eta: migratoryWebCitizen.cadetBlue,
    zeta: migratoryWebCitizen.cerulean
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.cerulean,
    beta: sanitarianRegistersWeb.cerulean,
    gamma: sanitarianRegistersWeb.white,
    delta: sanitarianRegistersWeb.cerulean,
    epsilon: sanitarianRegistersWeb.pacificBlue,
    dseta: sanitarianRegistersWeb.cello,
    eta: sanitarianRegistersWeb.cadetBlue,
    zeta: sanitarianRegistersWeb.cerulean
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.lightSeaGreen,
    beta: phytosanitaryWeb.mantis,
    gamma: phytosanitaryWeb.white,
    delta: phytosanitaryWeb.mantis,
    epsilon: phytosanitaryWeb.salem,
    dseta: phytosanitaryWeb.sherpaBlue,
    eta: phytosanitaryWeb.mabel,
    zeta: phytosanitaryWeb.salem
  },
  tdCarWeb: {
    alpha: tdCarWeb.cerulean,
    beta: tdCarWeb.cerulean,
    gamma: tdCarWeb.white,
    delta: tdCarWeb.cerulean,
    epsilon: tdCarWeb.pacificBlue,
    dseta: tdCarWeb.cello,
    eta: tdCarWeb.cadetBlue,
    zeta: tdCarWeb.cerulean
  }
};
export default (({
  theme = 'migratoryWebCitizen'
}) => css`
  .calendar-icon,
  .times-icon {
    position: absolute;
    cursor: pointer;
    right: 8px;
    color: ${themes[theme].alpha};
  }

  .calendar-icon {
    height: 30px;
    width: 30px;
  }

  .times-icon {
    height: 15px;
    width: 15px;
  }

  input {
    color: #2b2d42;
    box-sizing: border-box;
    cursor: pointer;
    background: transparent;
  }

  input::placeholder {
    color: #2b2d42;
    font-size: 15px;
    opacity: 0.7;
  }

  input:focus,
  input:hover {
    border: 1px solid ${themes[theme].zeta} !important;
  }

  input:focus,
  input:hover,
  input:hover::placeholder,
  input:focus::placeholder {
    color: ${themes[theme].epsilon} !important;
  }

  input:disabled {
    cursor: not-allowed !important;
    border: none !important;
    color: ${themes[theme].dseta} !important;
    background: ${themes[theme].eta} !important;
    -webkit-text-fill-color: ${themes[theme].dseta};
  }

  .datePicker-disable + svg {
    path {
      fill: ${themes[theme].dseta} !important;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .custom-calendar {
    background-color: #fefefe;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
    border-radius: 6px;

    .react-datepicker__triangle {
      left: 25px !important;
      border-bottom-color: #fefefe;
    }
  }

  .custom-calendar .react-datepicker__header {
    background-color: unset;
    border-bottom: unset;
  }

  .custom-calendar .react-datepicker__current-month {
    color: #1a051d;
    text-transform: capitalize;
    margin-top: 5px;
  }

  .date-picker-div {
    position: absolute;
    right: 0;
    top: -34px;
  }

  .date-picker-div span {
    font-size: 13px;
    margin-right: 4px;
  }

  .date-picker-div > div:first-of-type {
    margin-right: 21px;
  }

  .custom-calendar .react-datepicker__day-names {
    border-bottom: 1px dashed ${themes[theme].beta};
  }

  .custom-calendar .react-datepicker__day-name {
    color: ${themes[theme].beta};
  }

  .custom-calendar .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    border-radius: 1rem;
    background-color: ${themes[theme].delta};
  }

  .react-datepicker__header__dropdown--select {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 0;

    select {
      text-transform: capitalize;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      color: #1a051d;
      border: 1px solid #afccd1;
      appearance: none;
      box-shadow: none;
      border-radius: 3px;
      background: #fff;
      background-image: none;
    }
  }

  .react-datepicker__month-dropdown-container--select,
  .react-datepicker__year-dropdown-container--select {
    position: relative;
    display: flex;
    width: 100px;
    height: 30px;
    line-height: 3;
    overflow: hidden;
    border-radius: 0.25em;
  }

  .react-datepicker__month-dropdown-container--select select,
  .react-datepicker__year-dropdown-container--select select {
    flex: 1;
    padding: 0 0.5em;
    cursor: pointer;
    color: #000;
    font-size: 12px;
  }

  .react-datepicker__month-dropdown-container--select:after,
  .react-datepicker__year-dropdown-container--select:after {
    content: '\\25BC';
    position: absolute;
    top: -7px;
    right: 0;
    padding: 0 5px;
    border-left: 1px solid #afccd1;
    cursor: pointer;
    pointer-events: none;
    transition: 0.25s all ease;
    color: ${themes[theme].beta};
    font-size: 15px;
  }

  .react-datepicker__month-dropdown-container--select:hover:after,
  .react-datepicker__year-dropdown-container--select:hover:after {
    color: #afccd1;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    border: 0;
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
  }

  .react-datepicker__navigation--previous:after,
  .react-datepicker__navigation--next:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 1px;
    top: 8px;
    border: solid ${themes[theme].beta};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
  }

  .react-datepicker__navigation--previous:after {
    left: 10px;
    transform: rotate(135deg);
  }

  .react-datepicker__navigation--next:after {
    transform: rotate(-45deg);
    right: 10px;
  }

  .react-datepicker__navigation--previous:hover:after,
  .react-datepicker__navigation--next:hover:after {
    border: solid #afccd1;
    border-width: 0 2px 2px 0;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected {
    background-color: ${themes[theme].delta};
    color: ${themes[theme].gamma};
  }

  .react-datepicker__current-month {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
`);
export const parentDiv = width => css`
  .react-datepicker__input-container {
    width: ${width};
    ${mediaQuery[0]} {
      width: 100%;
    }
  }
`;