import qs from 'qs'
import i18n from 'i18next'
import useApm from '../hooks/use-apm'

const isUIWebView = /((iPhone|iPod|iPad).*AppleWebKit(?!.*Version)|; wv)/i.test(
  navigator.userAgent
)
const {
  REACT_APP_LOCAL_STORAGE_KEY,
  REACT_APP_API_TOKEN,
  REACT_APP_API_CORE_TOKEN,
  REACT_APP_GATEWAY_TOKEN,
  REACT_APP_API_VERSION,
  REACT_APP_API_CORE_VERSION,
  REACT_APP_API_URL,
  REACT_APP_CDN_URL,
  REACT_APP_SSO_API_TOKEN,
  REACT_APP_DGME_GATEWAY_URL,
  REACT_APP_SSO_API_URL,
  REACT_APP_SSO_API_VERSION,
  REACT_APP_DGME_CORE_VERSION,
  REACT_APP_DGME_CORE_TOKEN,
  REACT_APP_DGME_GATEWAY_TOKEN,
  REACT_APP_PUBLIC_URL,
  REACT_APP_APM_SERVICENAME,
  REACT_APP_MAIN_API_HOST,
  REACT_APP_MAIN_API_TOKEN,
  REACT_APP_GATEWAY_URL,
  REACT_APP_REGISTRELO_GATEWAY_URL,
  REACT_APP_REGISTRELO_CORE_VERSION,
  REACT_APP_REGISTRELO_CORE_TOKEN,
  REACT_APP_REGISTRELO_GATEWAY_TOKEN,
  REACT_APP_TDCAR_GATEWAY_URL,
  REACT_APP_TDCAR_CORE_VERSION,
  REACT_APP_TDCAR_CORE_TOKEN,
  REACT_APP_TDCAR_GATEWAY_TOKEN,
} = process.env

function getToken() {
  return isUIWebView
    ? window.localStorage.getItem(REACT_APP_LOCAL_STORAGE_KEY)
    : ''
}
function apmError(apm, config, response, err) {
  apm.setCustomContext({
    fetchConfig: JSON.stringify(config),
    response: JSON.stringify(response),
  })
  apm.captureError(new Error(err))
}
async function getCSRFToken(app = '') {
  const token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')
  if (!token) {
    let urlPath = `${
      REACT_APP_API_URL || REACT_APP_GATEWAY_URL
    }${REACT_APP_API_CORE_VERSION}`
    const fetchConfig = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'api-token': REACT_APP_API_CORE_TOKEN,
        'gateway-token': REACT_APP_GATEWAY_TOKEN,
      },
      credentials: 'include',
    }

    if (app === 'sso') {
      urlPath = `${REACT_APP_SSO_API_URL}${REACT_APP_SSO_API_VERSION}`
      fetchConfig.headers['api-token'] = REACT_APP_SSO_API_TOKEN
      delete fetchConfig.headers['gateway-token']
    } else if (app === 'dgme') {
      urlPath = `${REACT_APP_DGME_GATEWAY_URL}${REACT_APP_DGME_CORE_VERSION}`
      fetchConfig.headers['api-token'] = REACT_APP_DGME_CORE_TOKEN
      fetchConfig.headers['gateway-token'] = REACT_APP_DGME_GATEWAY_TOKEN
    } else if (app === 'main') {
      urlPath = REACT_APP_MAIN_API_HOST
      fetchConfig.headers['api-token'] = REACT_APP_MAIN_API_TOKEN
      delete fetchConfig.headers['gateway-token']
    } else if (app === 'registrelo') {
      urlPath = `${REACT_APP_REGISTRELO_GATEWAY_URL}${REACT_APP_REGISTRELO_CORE_VERSION}`
      fetchConfig.headers['api-token'] = REACT_APP_REGISTRELO_CORE_TOKEN
      fetchConfig.headers['gateway-token'] = REACT_APP_REGISTRELO_GATEWAY_TOKEN
    } else if (app === 'tdcar') {
      urlPath = `${REACT_APP_TDCAR_GATEWAY_URL}${REACT_APP_TDCAR_CORE_VERSION}`
      fetchConfig.headers['api-token'] = REACT_APP_TDCAR_CORE_TOKEN
      fetchConfig.headers['gateway-token'] = REACT_APP_TDCAR_GATEWAY_TOKEN
    }

    const response = await window
      .fetch(`${urlPath}/auth/csrf-token`, fetchConfig)
      .catch(error => {
        let tempMessage = error
        if (error && error.message) {
          tempMessage = error.message
        }
        if (typeof tempMessage === 'string') {
          tempMessage = tempMessage.toLowerCase()
        }
        if (
          [
            'networkerror when attempting to fetch resource.', // firefox
            'failed to fetch', // chrome
          ].includes(tempMessage)
        ) {
          throw Error(i18n.t('Servicio temporalmente no disponible'))
        }
        throw Error(error)
      })
    const { csrfToken } = await response.json()
    setCSRFToken(csrfToken)
    return csrfToken
  }

  return token
}

function setCSRFToken(token) {
  document
    .querySelector('meta[name="csrf-token"]')
    .setAttribute('content', token)
}

async function coreClient(
  endpoint,
  {
    body,
    params = null,
    method = 'GET',
    transformResponse = null,
    responseType = 'json',
    ...rest
  } = {},
  api = ''
) {
  const token = getToken()
  const headers = {
    'content-type': 'application/json',
    'api-token': REACT_APP_API_CORE_TOKEN || REACT_APP_DGME_CORE_TOKEN,
    'gateway-token': REACT_APP_GATEWAY_TOKEN || REACT_APP_DGME_GATEWAY_TOKEN,
  }
  const { headers: customHeaders, ...customConfig } = rest
  let url =
    (REACT_APP_DGME_GATEWAY_URL || REACT_APP_API_URL) +
    (REACT_APP_API_CORE_VERSION || REACT_APP_DGME_CORE_VERSION) +
    endpoint

  if (
    REACT_APP_SSO_API_TOKEN &&
    REACT_APP_SSO_API_URL &&
    REACT_APP_SSO_API_VERSION &&
    api === 'sso'
  ) {
    headers['api-token'] = REACT_APP_SSO_API_TOKEN
    delete headers['gateway-token']
    url = REACT_APP_SSO_API_URL + REACT_APP_SSO_API_VERSION + endpoint
  } else if (
    REACT_APP_MAIN_API_HOST &&
    REACT_APP_MAIN_API_TOKEN &&
    api === 'main'
  ) {
    headers['api-token'] = REACT_APP_MAIN_API_TOKEN
    delete headers['gateway-token']
    url = `${REACT_APP_MAIN_API_HOST}${endpoint}`
  } else if (
    REACT_APP_API_CORE_VERSION &&
    api === 'core' &&
    (REACT_APP_GATEWAY_URL || REACT_APP_API_URL)
  ) {
    url = `${
      REACT_APP_GATEWAY_URL || REACT_APP_API_URL
    }${REACT_APP_API_CORE_VERSION}${endpoint}`
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const config = {
    method,
    ...customConfig,
    headers: {
      ...headers,
      ...customHeaders,
    },
    credentials: 'include',
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  if (params) {
    const paramsStrings = qs.stringify(params)
    url += `?${paramsStrings}`
  }

  if (!['GET', 'HEAD', 'OPTIONS'].includes(method.toUpperCase())) {
    config.headers['X-CSRF-Token'] = await getCSRFToken(api)
  }

  return window
    .fetch(url, config)
    .then(async r => {
      // Venció el tiempo del token, por lo tanto
      // se quita el token y se hace redirect
      if (
        r.status === 401 &&
        !endpoint.includes('login') &&
        !endpoint.includes('me') && // este no deberia estar aqui, pero sino se pone se vuelve loco login, no encontre como mejorarlo
        !isUIWebView // movil no deberia ir al home
      ) {
        config.headers['X-CSRF-Token'] = await getCSRFToken()
        let apiVersion = REACT_APP_API_VERSION
        if (REACT_APP_APM_SERVICENAME === 'operator-ui') {
          apiVersion = REACT_APP_API_CORE_VERSION
        }
        await window.fetch(`${REACT_APP_API_URL}${apiVersion}/auth/logout`, {
          ...config,
          method: 'POST',
        })
        setCSRFToken('')
        window.location.href = `${REACT_APP_PUBLIC_URL}?error=session_expired`
        throw new Error('Token Expired')
      }
      if (r.status !== 200) {
        const response = await r.clone().json()
        const apm = useApm()
        const { message } = response
        apmError(apm, config, response, message || 'Error')
      }
      return r
    })
    .then(response => {
      if (responseType === 'raw') {
        return response
      }
      if (responseType === 'blob') {
        return response.blob()
      }
      if (responseType === 'text') {
        return response.text()
      }
      if (responseType === 'formData') {
        return response.formData()
      }
      if (responseType === 'arrayBuffer') {
        return response.arrayBuffer()
      }
      return response.json()
    })
    .then(response => {
      if (transformResponse) {
        return transformResponse(response)
      }
      return response
    })
}

async function client(
  endpoint,
  {
    body,
    params = null,
    method = 'GET',
    responseType = 'json',
    transformResponse = null,
    ...rest
  } = {}
) {
  const token = getToken()
  const headers = {
    'content-type': 'application/json',
    'api-token': REACT_APP_API_TOKEN,
    'gateway-token': REACT_APP_GATEWAY_TOKEN,
    'accept-language': localStorage['accept-language'] || 'es-CR',
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const { headers: customHeaders, ...customConfig } = rest

  const config = {
    method,
    ...customConfig,
    headers: {
      ...headers,
      ...customHeaders,
    },
    credentials: 'include',
  }
  let url =
    (REACT_APP_API_URL || REACT_APP_GATEWAY_URL) +
    REACT_APP_API_VERSION +
    endpoint

  if (body) {
    config.body = JSON.stringify(body)
  }

  if (!['GET', 'HEAD', 'OPTIONS'].includes(method.toUpperCase())) {
    config.headers['X-CSRF-Token'] = await getCSRFToken()
  }

  if (params) {
    const paramsString = qs.stringify(params)
    url += `?${paramsString}`
  }

  return window
    .fetch(url, config)
    .then(async r => {
      // Venció el tiempo del token, por lo tanto
      // se quita el token y se hace redirect
      if (
        r.status === 401 &&
        !endpoint.includes('login') &&
        !endpoint.includes('me') && // este no deberia estar aqui, pero sino se pone se vuelve loco login, no encontre como mejorarlo
        !isUIWebView // movil no deberia ir al home
      ) {
        config.headers['X-CSRF-Token'] = await getCSRFToken()
        let apiVersion = REACT_APP_API_VERSION
        if (REACT_APP_APM_SERVICENAME === 'operator-ui') {
          apiVersion = REACT_APP_API_CORE_VERSION
        }
        await window.fetch(`${REACT_APP_API_URL}${apiVersion}/auth/logout`, {
          ...config,
          method: 'POST',
        })
        setCSRFToken('')
        window.location.href = `${REACT_APP_PUBLIC_URL}?error=session_expired`
        throw new Error('Token Expired')
      }
      if (r.status !== 200) {
        const response = await r.clone().json()
        const apm = useApm()
        const { message } = response
        apmError(apm, config, response, message || 'Error')
      }
      return r
    })
    .then(response => {
      if (responseType === 'raw') {
        return response
      }

      if (responseType === 'blob') {
        return response.blob()
      }

      if (responseType === 'text') {
        return response.text()
      }

      if (responseType === 'formData') {
        return response.formData()
      }

      if (responseType === 'arrayBuffer') {
        return response.arrayBuffer()
      }

      return response.json()
    })
    .then(response => {
      if (transformResponse) {
        return transformResponse(response)
      }

      return response
    })
}

async function clientWithoutProcess(
  endpoint,
  { body, method = 'GET', ...customConfig } = {}
) {
  const token = getToken()
  const headers = { 'content-type': 'multipart/form-data' }
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const config = {
    method,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
    credentials: 'include',
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  if (!['GET', 'HEAD', 'OPTIONS'].includes(method.toUpperCase())) {
    config.headers['X-CSRF-Token'] = await getCSRFToken()
  }

  return window
    .fetch(`${REACT_APP_CDN_URL}${endpoint}`, config)
    .then(async r => {
      // Venció el tiempo del token, por lo tanto
      // se quita el token y se hace redirect
      if (
        r.status === 401 &&
        !endpoint.includes('login') &&
        !endpoint.includes('me') && // este no deberia estar aqui, pero sino se pone se vuelve loco login, no encontre como mejorarlo
        !isUIWebView // movil no deberia ir al home
      ) {
        config.headers['X-CSRF-Token'] = await getCSRFToken()
        let apiVersion = REACT_APP_API_VERSION
        if (REACT_APP_APM_SERVICENAME === 'operator-ui') {
          apiVersion = REACT_APP_API_CORE_VERSION
        }
        await window.fetch(`${REACT_APP_API_URL}${apiVersion}/auth/logout`, {
          ...config,
          method: 'POST',
        })
        setCSRFToken('')
        window.location.href = `${REACT_APP_PUBLIC_URL}?error=session_expired`
        throw new Error('Token Expired')
      }
      if (r.status !== 200) {
        const response = await r.clone().json()
        const apm = useApm()
        const { message } = response
        apmError(apm, config, response, message || 'Error')
      }
      return r
    })
    .then(r => r.json())
}

export {
  client,
  coreClient,
  clientWithoutProcess,
  getToken,
  getCSRFToken,
  setCSRFToken,
}
