/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '27',
  height: '27',
  viewBox: '0 0 27 27',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M13.5 0.90625C6.55588 0.90625 0.90625 6.55588 0.90625 13.5C0.90625 20.4441 6.55588 26.0938 13.5 26.0938C20.4441 26.0938 26.0938 20.4441 26.0938 13.5C26.0938 6.55588 20.4441 0.90625 13.5 0.90625ZM13.5 20.2758C13.2605 20.2758 13.0264 20.2048 12.8272 20.0717C12.6281 19.9387 12.4729 19.7495 12.3812 19.5283C12.2896 19.307 12.2656 19.0635 12.3123 18.8286C12.3591 18.5937 12.4744 18.378 12.6437 18.2086C12.8131 18.0392 13.0289 17.9239 13.2638 17.8772C13.4987 17.8305 13.7421 17.8545 13.9634 17.9461C14.1847 18.0378 14.3738 18.193 14.5069 18.3921C14.6399 18.5912 14.7109 18.8254 14.7109 19.0649C14.7109 19.386 14.5834 19.694 14.3563 19.9211C14.1292 20.1482 13.8212 20.2758 13.5 20.2758ZM14.8151 8.0968L14.4675 15.4835C14.4675 15.7404 14.3655 15.9868 14.1838 16.1685C14.0021 16.3502 13.7557 16.4523 13.4988 16.4523C13.2419 16.4523 12.9955 16.3502 12.8138 16.1685C12.6321 15.9868 12.53 15.7404 12.53 15.4835L12.1825 8.10043V8.0974C12.1749 7.91996 12.2033 7.7428 12.2659 7.57661C12.3286 7.41042 12.4242 7.25863 12.5471 7.13037C12.6699 7.00212 12.8175 6.90006 12.9808 6.83033C13.1442 6.76061 13.32 6.72466 13.4976 6.72466C13.6752 6.72466 13.851 6.76061 14.0143 6.83033C14.1777 6.90006 14.3252 7.00212 14.4481 7.13037C14.5709 7.25863 14.6666 7.41042 14.7292 7.57661C14.7919 7.7428 14.8203 7.91996 14.8127 8.0974L14.8151 8.0968Z',
    fill: 'white',
  }),
  name: 'ExclamationCircle',
})
