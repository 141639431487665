/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import Rodal from 'rodal';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import useMediaQuery from '../../../hooks/use-media-query';
import { Button, Colors, DropDownCombo, SpinnerSmall } from '../../../core';
import styles from './modalStyle';
import modalCustomStyle from './utils';
import { DivRow, DivColumn } from '../InputsUI';
import { CloseIcon, ExclamationTriangleIcon } from '../../../icon';
const {
  tdCarWeb: colors
} = Colors;

function rolesLegalRepresentatives(rolesList) {
  const filterCode = rolesList === null || rolesList === void 0 ? void 0 : rolesList.filter(x => (x === null || x === void 0 ? void 0 : x.code) === 'legalRepresentative' || (x === null || x === void 0 ? void 0 : x.code) === 'authorizedLegal');
  const legalRepresentatives = filterCode === null || filterCode === void 0 ? void 0 : filterCode.map(x => {
    var _x$DigitalRecord, _x$DigitalRecord$name, _x$name;

    return {
      name: `${x === null || x === void 0 ? void 0 : (_x$DigitalRecord = x.DigitalRecord) === null || _x$DigitalRecord === void 0 ? void 0 : (_x$DigitalRecord$name = _x$DigitalRecord.name) === null || _x$DigitalRecord$name === void 0 ? void 0 : _x$DigitalRecord$name.toUpperCase()} - ${x === null || x === void 0 ? void 0 : (_x$name = x.name) === null || _x$name === void 0 ? void 0 : _x$name.toUpperCase()}`,
      id: x === null || x === void 0 ? void 0 : x.id
    };
  });
  return legalRepresentatives;
}
/**
 * This component represents a modal for verify user before creating a procedure.
 */


export default function ChangeProcedureRoles({
  isVisible,
  setIsVisible,
  changeRole,
  user,
  roles,
  selectedRole,
  setSelectedRole,
  isLoading,
  changeToModal
}) {
  const {
    t
  } = useTranslation();
  const {
    isMobile
  } = useMediaQuery();
  const legalRepresentativeRoles = rolesLegalRepresentatives(roles);
  const formik = useFormik({
    initialValues: {
      role: selectedRole || ''
    },
    onSubmit: values => {
      try {
        const {
          role
        } = values;
        setSelectedRole({
          role
        });
      } catch (error) {
        console.error(error);
      }
    }
  });

  const onChangeRoleSelected = role => {
    setSelectedRole(role === null || role === void 0 ? void 0 : role.id);
    formik.setFieldValue('role', role === null || role === void 0 ? void 0 : role.id);
  };

  return jsx(Rodal, {
    visible: isVisible,
    showCloseButton: false,
    width: 830,
    height: 526,
    closeOnEsc: true,
    onClose: () => {},
    customStyles: modalCustomStyle({
      isMobile,
      width: 781,
      height: 375,
      borderRadius: 20
    })
  }, jsx("div", {
    style: {
      textAlign: 'right',
      margin: '16px 16px 0 0'
    }
  }, jsx(CloseIcon, {
    css: {
      width: 20,
      height: 20,
      cursor: 'pointer',
      color: colors.fireEngineRed
    },
    onClick: () => setIsVisible(false)
  })), jsx("div", {
    css: [styles, {
      padding: '21px 16px 16px 16px',
      height: '256px'
    }]
  }, jsx(DivRow, {
    style: {
      marginBottom: '0px!important',
      display: 'flex'
    }
  }, jsx(DivColumn, null, jsx(ExclamationTriangleIcon, {
    style: {
      width: 74,
      height: 66,
      color: colors.cerulean,
      marginRight: 24
    }
  })), jsx(DivColumn, {
    containerStyle: {
      flexBasis: '80%'
    }
  }, jsx("h1", {
    className: "modals-title",
    style: {
      fontSize: 24,
      marginTop: 0,
      marginBottom: 10,
      color: colors.deepCerulean
    }
  }, t('Verificar Rol')), jsx("p", null, t('Está a punto de iniciar el trámite con el rol de'), ' ', jsx("span", {
    css: {
      fontWeight: 'bold'
    }
  }, t(user === null || user === void 0 ? void 0 : user.fullName)), ' ', t('. Si desea crear el trámite con este rol haga click en "CONTINUAR". De lo contrario, seleccione el rol de'), ' ', jsx("span", {
    css: {
      fontWeight: 'bold'
    }
  }, t('EMPRESA')), ' ', t('al que desea cambiarse y haga click en "CAMBIAR ROL".')), jsx("div", null, jsx("label", {
    className: "label"
  }, t('Rol'), "*"), jsx(DropDownCombo, {
    items: legalRepresentativeRoles,
    style: {
      width: '100%'
    },
    placeholder: t('Seleccionar'),
    value: formik.values.role,
    onChange: onChangeRoleSelected
  })))), jsx("div", {
    className: "button-container",
    css: {
      justifyContent: 'flex-end!important',
      alignItems: 'center',
      paddingLeft: 80
    }
  }, jsx("div", {
    css: {
      display: 'flex',
      marginTop: '40px'
    }
  }, jsx(Button, {
    size: "md-icon",
    outline: true,
    className: "button blue",
    style: {
      color: colors.cerulean,
      border: `2px solid ${colors.cerulean}`,
      width: 235,
      height: 48,
      marginRight: 10
    },
    onClick: () => {
      setIsVisible(false);
      changeToModal(true);
    }
  }, t(`Continuar con ${user === null || user === void 0 ? void 0 : user.fullName}`)), jsx(Button, {
    size: "md-icon",
    className: "button red",
    style: {
      background: colors.fireEngineRed,
      color: colors.white,
      width: 235,
      height: 48
    },
    disabled: !selectedRole || isLoading,
    onClick: async () => {
      await changeRole();
    }
  }, t('Cambiar Rol'), isLoading && jsx(SpinnerSmall, null))))));
}