import React from 'react'
import ReactDOM from 'react-dom'
import { ToastProvider } from 'react-toast-notifications'
import { ThemeProvider } from 'emotion-theming'
import { I18nextProvider } from 'react-i18next'
import 'bootstrap/dist/css/bootstrap.min.css'

import './index.css'
import 'rodal/lib/rodal.css'
import { CustomToast } from '@migratory/ui/core'
import i18n from './utils/i18n'
import App from './App'

import * as serviceWorker from './serviceWorker'

import 'typeface-rubik'
import { PublicContextProvider } from './context/public-context'

ReactDOM.render(
  <ThemeProvider theme={{ theme: 'home' }}>
    <I18nextProvider i18n={i18n}>
      <ToastProvider
        autoDismissTimeout={10000}
        components={{ Toast: CustomToast }}
      >
        <PublicContextProvider>
          <App />
        </PublicContextProvider>
      </ToastProvider>
    </I18nextProvider>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
