/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '10',
  height: '7',
  viewBox: '0 0 10 7',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M5.30937 5.93435L9.68437 1.55935C9.74555 1.49816 9.78722 1.42021 9.8041 1.33534C9.82097 1.25048 9.81231 1.16251 9.77919 1.08257C9.74608 1.00263 9.69 0.934301 9.61805 0.88623C9.54611 0.838158 9.46152 0.8125 9.37499 0.8125L0.624993 0.8125C0.538465 0.812501 0.453881 0.83816 0.381936 0.886233C0.309991 0.934305 0.253916 1.00263 0.220801 1.08257C0.187686 1.16251 0.179019 1.25048 0.195895 1.33534C0.212772 1.42021 0.254434 1.49816 0.315614 1.55935L4.69061 5.93435C4.73124 5.97498 4.77947 6.00721 4.83256 6.0292C4.88564 6.05119 4.94254 6.06251 4.99999 6.06251C5.05745 6.06251 5.11435 6.05119 5.16743 6.0292C5.22051 6.00721 5.26875 5.97498 5.30937 5.93435Z',
    fill: '#0672AB',
  }),
  name: 'BlueArrowDown',
})
