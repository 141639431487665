/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M8.09368 11.7042H9.90632V13.5211H8.09368V11.7042ZM8.09368 4.47887H9.90632V9.88732H8.09368V4.47887ZM9 0C11.4871 0 13.6089 0.880282 15.3653 2.64085C17.1218 4.40141 18 6.52113 18 9C18 11.4789 17.1218 13.5986 15.3653 15.3592C13.6089 17.1197 11.4871 18 9 18C6.51288 18 4.3911 17.1197 2.63466 15.3592C0.87822 13.5986 0 11.4789 0 9C0 6.52113 0.87822 4.40141 2.63466 2.64085C4.3911 0.880282 6.51288 0 9 0ZM9 16.2254C10.9813 16.2254 12.6745 15.5211 14.0796 14.1127C15.4848 12.7042 16.1874 11 16.1874 9C16.1874 7 15.4848 5.29577 14.0796 3.88732C12.6745 2.47887 10.9813 1.77465 9 1.77465C7.01873 1.77465 5.32553 2.47887 3.92037 3.88732C2.51522 5.29577 1.81265 7 1.81265 9C1.81265 11 2.51522 12.7042 3.92037 14.1127C5.32553 15.5211 7.01873 16.2254 9 16.2254Z',
    fill: 'white',
  }),
  name: 'ExclamationCircleAlt',
})
