import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '20',
  height: '20',
  viewBox: '0 0 20 20',
  css: css({
    path: {
      stroke: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z',
      fill: 'white',
      stroke: '#CE1625',
      strokeWidth: '2',
    }),
    jsx('path', {
      d: 'M10.0003 15.8333C13.222 15.8333 15.8337 13.2217 15.8337 9.99999C15.8337 6.77833 13.222 4.16666 10.0003 4.16666C6.77866 4.16666 4.16699 6.77833 4.16699 9.99999C4.16699 13.2217 6.77866 15.8333 10.0003 15.8333Z',
      fill: '#CE1625',
    })
  ),
  name: 'CircleDot',
})
