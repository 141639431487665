import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '23',
  height: '23',
  viewBox: '0 0 23 23',
  css: css({}),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M11.5 22.9999C17.8513 22.9999 23 17.8512 23 11.5C23 5.14871 17.8513 0 11.5 0C5.14873 0 0 5.14871 0 11.5C0 17.8512 5.14873 22.9999 11.5 22.9999Z',
      fill: '#F0F0F0',
    }),
    jsx('path', {
      d: 'M22.2847 7.50008H0.715291C0.253045 8.7459 0 10.0934 0 11.5001C0 12.9067 0.253045 14.2542 0.715291 15.5H22.2848C22.747 14.2542 23 12.9067 23 11.5001C23 10.0934 22.747 8.7459 22.2847 7.50008Z',
      fill: '#D80027',
    }),
    jsx('path', {
      d: 'M11.5 0C8.01446 0 4.89136 1.55088 2.78241 3.99997H20.2175C18.1086 1.55088 14.9855 0 11.5 0Z',
      fill: '#0052B4',
    }),
    jsx('path', {
      d: 'M20.2176 19.0001H2.78241C4.89136 21.4492 8.01446 23.0001 11.5 23.0001C14.9855 23.0001 18.1086 21.4492 20.2176 19.0001Z',
      fill: '#0052B4',
    })
  ),
  name: 'FlagEs',
})
