/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '10',
  height: '7',
  viewBox: '0 0 10 7',
  css: css({
    path: {
      stroke: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M1 1L5 5L9 1',
    stroke: '#0672AB',
    strokeWidth: '1.5',
    strokeLinecap: 'round',
  }),
  name: 'Chevron',
})
