/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import Rodal from 'rodal';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '../../../hooks/use-media-query';
import { Button, Colors } from '../../../core';
import styles from './modalStyle';
import modalCustomStyle from './utils';
import { DivRow, DivColumn } from '../InputsUI';
const {
  tdCarWeb: colors
} = Colors;

const VehicleItem = ({
  title,
  text
}) => {
  const {
    t
  } = useTranslation();
  return jsx(DivRow, {
    className: "modals-row",
    containerStyle: {
      marginBottom: 20,
      borderBottom: `1px solid ${colors.deepCerulean}`,
      paddingBottom: 10
    }
  }, jsx(DivColumn, null, jsx("span", {
    css: {
      fontSize: 16
    }
  }, t(title), ":")), jsx(DivColumn, null, jsx("span", {
    css: {
      fontSize: 16,
      fontWeight: 500
    }
  }, text)));
};
/**
 * This component represents a modal for cancel a procedure.
 * @param {boolean} isVisible Determines if the modal is visible or not.
 * @param {function} setIsVisible Function triggered when close the modal to hide it.
 * @param {function} proceedToCancel Cancel the procedure.
 * @param {string} isRejecting Determine if it is in the process of cancellation.
 */


export default function VehicleInfo({
  isVisible,
  setIsVisible,
  carInfo
}) {
  var _carInfo$Identificati, _carInfo$Identificati2, _carInfo$identificati, _carInfo$year, _carInfo$Category$nam, _carInfo$Category, _carInfo$model, _carInfo$chasis, _carInfo$rawWeight;

  const {
    t
  } = useTranslation();
  const {
    isMobile
  } = useMediaQuery();
  return jsx(Rodal, {
    visible: isVisible,
    showCloseButton: false,
    width: 739,
    height: 500,
    closeOnEsc: true,
    onClose: () => {},
    customStyles: modalCustomStyle({
      isMobile,
      width: 739,
      height: 500,
      borderRadius: 20
    })
  }, jsx("div", {
    css: [styles, {
      padding: '55px 35px'
    }]
  }, jsx("h1", {
    className: "modals-title",
    style: {
      fontSize: 16,
      marginTop: 'unset',
      marginBottom: 20,
      color: colors.deepCerulean
    }
  }, t('INFORMACIÓN DEL VEHÍCULO')), jsx("div", {
    css: {
      marginBottom: 40
    }
  }, jsx(VehicleItem, {
    title: (_carInfo$Identificati = carInfo === null || carInfo === void 0 ? void 0 : (_carInfo$Identificati2 = carInfo.IdentificationType) === null || _carInfo$Identificati2 === void 0 ? void 0 : _carInfo$Identificati2.name) !== null && _carInfo$Identificati !== void 0 ? _carInfo$Identificati : '---',
    text: (_carInfo$identificati = carInfo === null || carInfo === void 0 ? void 0 : carInfo.identification) !== null && _carInfo$identificati !== void 0 ? _carInfo$identificati : '---'
  }), jsx(VehicleItem, {
    title: "A\xF1o",
    text: (_carInfo$year = carInfo === null || carInfo === void 0 ? void 0 : carInfo.year) !== null && _carInfo$year !== void 0 ? _carInfo$year : '---'
  }), jsx(VehicleItem, {
    title: "Categor\xEDa",
    text: (_carInfo$Category$nam = carInfo === null || carInfo === void 0 ? void 0 : (_carInfo$Category = carInfo.Category) === null || _carInfo$Category === void 0 ? void 0 : _carInfo$Category.name) !== null && _carInfo$Category$nam !== void 0 ? _carInfo$Category$nam : '---'
  }), jsx(VehicleItem, {
    title: "Modelo",
    text: (_carInfo$model = carInfo === null || carInfo === void 0 ? void 0 : carInfo.model) !== null && _carInfo$model !== void 0 ? _carInfo$model : '---'
  }), jsx(VehicleItem, {
    title: "Chasis",
    text: (_carInfo$chasis = carInfo === null || carInfo === void 0 ? void 0 : carInfo.chasis) !== null && _carInfo$chasis !== void 0 ? _carInfo$chasis : '---'
  }), jsx(VehicleItem, {
    title: "Peso",
    text: (_carInfo$rawWeight = carInfo === null || carInfo === void 0 ? void 0 : carInfo.rawWeight) !== null && _carInfo$rawWeight !== void 0 ? _carInfo$rawWeight : '---'
  })), jsx("div", {
    className: "button-container"
  }, jsx(Button, {
    size: "md-icon",
    className: "button red",
    style: {
      background: colors.fireEngineRed,
      color: colors.white,
      width: 117,
      height: 48
    },
    onClick: setIsVisible
  }, t('Cerrar')))));
}