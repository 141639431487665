/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core'; // eslint-disable-next-line no-unused-vars

import React from 'react';
import Rodal from 'rodal';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import useMediaQuery from '../../../hooks/use-media-query';
import { ExclamationTriangleIcon } from '../../../icon';
import { Button, Colors } from '../../../core';
import { DivRow, DivColumn } from '../InputsUI';
import { InputField, GenericDropDown } from '../../advanced/procedure/sections/shared/components';
import ScrollWraper from '../../ScrollWrapper';
import { useColors } from '../../../queries';
import styles from './modalStyle';
import modalCustomStyle from './utils';
const {
  tdCarWeb: colors
} = Colors;
/**
 * this component represents modal when a vehicle is going to be deleted
 * @param {boolean} isVisible Determines if the modal is visible or not.
 * @param {function} setIsVisible Function triggered when close the modal to hide it.
 * @param {function} onClickDeleteVehicle remove a vehicle from the list
 */

export function DeleteVehicle({
  isVisible,
  setIsVisible,
  onClickDeleteVehicle,
  vehicleData
}) {
  const {
    t
  } = useTranslation();
  const {
    isMobile
  } = useMediaQuery();
  return jsx(Rodal, {
    visible: isVisible,
    showCloseButton: false,
    width: 781,
    height: 317,
    maxHeight: 374,
    closeOnEsc: true,
    onClose: () => {},
    customStyles: (modalCustomStyle({
      isMobile,
      width: 781,
      height: 255
    }), {
      borderRadius: 10
    })
  }, jsx("div", {
    css: [styles, {
      paddingTop: 48
    }]
  }, jsx(DivRow, {
    className: "modals-row"
  }, jsx(DivColumn, {
    className: "modals-column"
  }, jsx(ExclamationTriangleIcon, {
    className: "icon",
    css: {
      color: colors.cerulean
    }
  })), jsx(DivColumn, {
    className: "modals-column"
  }, jsx("h1", {
    className: "modals-title",
    style: {
      color: colors.prussianBlue
    }
  }, t('Eliminar vehículo')), jsx("p", {
    className: "modals-description"
  }, vehicleData && t('Está a punto de eliminar el vehículo', {
    brand: vehicleData.makeName,
    model: vehicleData.model,
    year: vehicleData.year
  }), jsx("div", {
    style: {
      whiteSpace: 'pre-line'
    }
  }, vehicleData && vehicleData.vin && jsx(React.Fragment, null, jsx("br", null), t('Número de VIN', {
    vin: vehicleData.vin
  })), vehicleData && vehicleData.chasis && jsx(React.Fragment, null, jsx("br", null), t('Número de chasis', {
    chassis: vehicleData.chasis
  })), vehicleData && vehicleData.engineNumber && jsx(React.Fragment, null, jsx("br", null), t('Número de motor', {
    engine: vehicleData.engineNumber
  })), vehicleData && vehicleData.lineNumber && jsx(React.Fragment, null, jsx("br", null), t('Número línea de la póliza', {
    line: vehicleData.lineNumber
  })), vehicleData && vehicleData.colorCode && jsx(React.Fragment, null, jsx("br", null), t('Color', {
    color: vehicleData.colorCode
  })))))), jsx("div", {
    className: "button-container"
  }, jsx(Button, {
    size: "md-icon",
    outline: true,
    className: "button blue",
    style: {
      color: colors.cerulean,
      border: `2px solid ${colors.cerulean}`,
      width: 150
    },
    onClick: setIsVisible
  }, t('Cancelar')), jsx(Button, {
    size: "md-icon",
    className: "button red",
    style: {
      background: colors.fireEngineRed,
      color: colors.white
    },
    onClick: onClickDeleteVehicle
  }, t('Eliminar')))));
}
/**
 * this component represents modal when presentation with different unit of measure is added
 * @param {boolean} isVisible Determines if the modal is visible or not.
 * @param {function} setIsVisible Function triggered when close the modal to hide it.
 */

export function AddDuplicateVehicle({
  isVisible,
  setNotVisible,
  setPrevious,
  vehicleData,
  counter,
  length
}) {
  const {
    t
  } = useTranslation();
  const formik = useFormikContext();
  const {
    isMobile
  } = useMediaQuery();
  return jsx(Rodal, {
    visible: isVisible,
    showCloseButton: false,
    width: 1112,
    height: 650,
    closeOnEsc: true,
    onClose: () => {},
    customStyles: modalCustomStyle({
      isMobile,
      width: 1112,
      height: 650,
      borderRadius: 20
    })
  }, jsx("div", {
    css: [styles, {
      padding: '21px 16px 16px 16px',
      height: '650px'
    }]
  }, jsx(DivRow, {
    style: {
      marginBottom: '0px!important',
      display: 'flex'
    }
  }, jsx(DivColumn, {
    containerStyle: {
      flexBasis: '80%'
    }
  }, jsx("h1", {
    className: "modals-title",
    style: {
      fontSize: 24,
      marginTop: 10,
      marginBottom: 0,
      color: colors.deepCerulean
    }
  }, t('Duplicar vehículo')), jsx("p", {
    className: "modals-description",
    style: {
      fontWeight: 'bold'
    }
  }, vehicleData && t('Duplicando', {
    brand: vehicleData.makeName,
    model: vehicleData.model,
    year: vehicleData.year
  })))), jsx(ScrollWraper, {
    containerStyle: {
      maxHeight: '100%'
    }
  }, jsx("div", {
    style: {
      padding: '0px 1px'
    }
  }, jsx(InputField, {
    label: t('Número de VIN'),
    name: "vin",
    containerStyle: {
      marginBottom: '5px'
    },
    maxLength: "25"
  }), jsx(InputField, {
    label: t('Número de chasis'),
    name: "chasis",
    containerStyle: {
      marginBottom: '5px'
    },
    maxLength: "20"
  }), jsx(InputField, {
    label: t('Número de motor'),
    name: "engineNumber",
    containerStyle: {
      marginBottom: '5px'
    },
    maxLength: "22"
  }), jsx(InputField, {
    label: `${t('Número línea')}:`,
    name: "lineNumber",
    containerStyle: {
      marginBottom: '5px'
    },
    maxLength: "3",
    mask: {
      regex: '^[0-9]*'
    }
  }), jsx(GenericDropDown, {
    label: t('Color'),
    name: "colorCode",
    query: () => useColors({
      manipulation: color => color.map(item => ({
        id: item === null || item === void 0 ? void 0 : item.code,
        name: item === null || item === void 0 ? void 0 : item.name
      }))
    }),
    containerStyle: {
      marginBottom: '5px'
    },
    maxLength: "20"
  }))), jsx("hr", {
    style: {
      height: 0.5,
      border: 'none',
      width: 'auto',
      backgroundColor: colors.cerulean,
      opacity: 0.5,
      marginLeft: -31,
      marginRight: -31
    }
  }), jsx("div", {
    style: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 20
    }
  }, jsx("div", {
    className: "button-container",
    css: {
      justifyContent: 'flex-start!important',
      alignItems: 'center',
      marginLeft: 20
    }
  }, jsx(Button, {
    size: "md-icon",
    outline: true,
    className: "button blue",
    style: {
      color: colors.cerulean,
      border: `2px solid ${colors.cerulean}`,
      width: 210,
      height: 48,
      marginRight: 10
    },
    onClick: () => {
      setNotVisible();
    }
  }, t('Cancelar'))), jsx("div", {
    className: "button-container",
    css: {
      justifyContent: 'flex-end!important',
      alignItems: 'center'
    }
  }, jsx("span", {
    style: {
      color: colors.frenchPass,
      fontWeight: 'bold',
      fontSize: 16,
      marginRight: 15
    }
  }, `${counter + 1}/${length}`), jsx("div", {
    css: {
      display: 'flex'
    }
  }, jsx(Button, {
    size: "md-icon",
    outline: true,
    className: "button blue",
    style: {
      color: colors.cerulean,
      border: `2px solid ${colors.cerulean}`,
      width: 210,
      height: 48,
      marginRight: 10
    },
    onClick: () => {
      setPrevious();
    }
  }, t('Anterior')), jsx(Button, {
    size: "md-icon",
    className: "button red",
    style: {
      background: colors.fireEngineRed,
      color: colors.white,
      width: 184,
      height: 48,
      marginRight: 20
    },
    onClick: () => {
      formik.handleSubmit();
    }
  }, t(counter === length - 1 ? 'Guardar' : 'Siguiente')))))));
}
export function DuplicateAmountVehicle({
  isVisible,
  setNotVisible,
  vehicleData
}) {
  const {
    t
  } = useTranslation();
  const formik = useFormikContext();
  const {
    isMobile
  } = useMediaQuery();
  return jsx(Rodal, {
    visible: isVisible,
    showCloseButton: false,
    width: 781,
    height: 334,
    closeOnEsc: true,
    onClose: () => {},
    customStyles: (modalCustomStyle({
      isMobile,
      width: 781,
      height: 334
    }), {
      borderRadius: 10,
      padding: '15px 0px'
    })
  }, jsx("div", {
    css: [styles, {
      padding: '21px 0px 16px 0px'
    }]
  }, jsx(DivRow, {
    className: "modals-row",
    style: {
      marginBottom: 0,
      padding: 15
    }
  }, jsx(DivColumn, {
    className: "modals-column"
  }, jsx("h1", {
    className: "modals-title",
    style: {
      fontSize: 24,
      marginTop: 10,
      marginBottom: 0,
      color: colors.deepCerulean,
      marginLeft: 30
    }
  }, t('Duplicar vehículo')), jsx("p", {
    className: "modals-description",
    style: {
      fontWeight: 'bold',
      marginLeft: 30
    }
  }, vehicleData && t('Duplicando', {
    brand: vehicleData.makeName,
    model: vehicleData.model,
    year: vehicleData.year
  })))), jsx(DivRow, {
    className: "modals-row",
    style: {
      padding: '0px 45px',
      marginBottom: 0
    }
  }, jsx(InputField, {
    label: t('Cantidad de veces a duplicar'),
    name: "duplicateAmount",
    maxLength: "3",
    containerStyle: {
      flex: '1'
    }
  })), jsx("hr", {
    style: {
      height: 0.5,
      border: 'none',
      width: '100%',
      backgroundColor: colors.cerulean,
      opacity: 0.5,
      margin: 0
    }
  }), jsx("div", {
    className: "button-container",
    css: {
      justifyContent: 'flex-end!important',
      alignItems: 'center',
      paddingLeft: 80,
      marginTop: 5
    }
  }, jsx("div", {
    css: {
      display: 'flex'
    }
  }, jsx(Button, {
    size: "md-icon",
    outline: true,
    className: "button blue",
    style: {
      color: colors.cerulean,
      border: `2px solid ${colors.cerulean}`,
      width: 150,
      height: 48,
      marginRight: 10
    },
    onClick: () => {
      setNotVisible();
      formik.resetForm();
    }
  }, t('Cancelar')), jsx(Button, {
    size: "md-icon",
    className: "button red",
    style: {
      background: colors.fireEngineRed,
      color: colors.white,
      width: 150,
      height: 48,
      marginRight: 20
    },
    onClick: async () => {
      formik.handleSubmit();
    }
  }, t('Duplicar'))))));
}