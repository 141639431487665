import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '70',
  height: '70',
  viewBox: '0 0 70 70',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx(
      'g',
      null,
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70ZM35.7538 14.5251C31.4115 14.5251 27.8915 18.0143 27.8915 22.3184V28.352H43.6161V22.3184C43.6161 18.0143 40.096 14.5251 35.7538 14.5251ZM23.3262 22.3184V28.352H22C19.7909 28.352 18 30.1429 18 32.352V51.0001C18 53.2092 19.7909 55.0001 22 55.0001H49C51.2091 55.0001 53 53.2092 53 51.0001V32.352C53 30.1429 51.2091 28.352 49 28.352H48.1813V22.3184C48.1813 15.5152 42.6173 10 35.7538 10C28.8902 10 23.3262 15.5152 23.3262 22.3184Z',
        fill: '#0F7CB5',
      })
    )
  ),
  name: 'CircleLock',
})
