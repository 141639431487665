/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import Rodal from 'rodal';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '../../../hooks/use-media-query';
import { Button, Colors } from '../../../core';
import styles from './modalStyle';
import modalCustomStyle from './utils';
import { DivRow, DivColumn } from '../InputsUI';
import { CloseIcon, ExclamationTriangleIcon } from '../../../icon';
const {
  tdCarWeb: colors
} = Colors;
/**
 * This component represents a modal for cancel a procedure.
 */

export default function HelpCreateProcedure({
  isVisible,
  setIsVisible,
  createProcedure,
  viewProcedures
}) {
  const {
    t
  } = useTranslation();
  const {
    isMobile
  } = useMediaQuery();
  return jsx(Rodal, {
    visible: isVisible,
    showCloseButton: false,
    width: 830,
    height: 526,
    closeOnEsc: true,
    onClose: () => {},
    customStyles: modalCustomStyle({
      isMobile,
      width: 781,
      height: 295,
      borderRadius: 20
    })
  }, jsx("div", {
    style: {
      textAlign: 'right',
      margin: '16px 16px 0 0'
    }
  }, jsx(CloseIcon, {
    css: {
      width: 20,
      height: 20,
      cursor: 'pointer',
      color: colors.fireEngineRed
    },
    onClick: () => setIsVisible(false)
  })), jsx("div", {
    css: [styles, {
      padding: '21px 16px 16px 16px',
      height: '256px'
    }]
  }, jsx(DivRow, {
    style: {
      marginBottom: '0px!important',
      display: 'flex'
    }
  }, jsx(DivColumn, null, jsx(ExclamationTriangleIcon, {
    style: {
      width: 74,
      height: 66,
      color: colors.cerulean,
      marginRight: 24
    }
  })), jsx(DivColumn, {
    containerStyle: {
      flexBasis: '80%'
    }
  }, jsx("h1", {
    className: "modals-title",
    style: {
      fontSize: 24,
      marginTop: 0,
      marginBottom: 10,
      color: colors.deepCerulean
    }
  }, t('Iniciar nuevo trámite')), jsx("p", null, t('Está a punto de iniciar un nuevo trámite En caso de haber empezado y guardado el trámite anteriormente búsquelo en la sección de'), ' ', jsx("span", {
    css: {
      fontWeight: 'bold'
    }
  }, t('Mis trámites')), ' ', t('para continuarlo')))), jsx("div", {
    className: "button-container",
    css: {
      justifyContent: 'flex-end!important',
      alignItems: 'center',
      paddingLeft: 80
    }
  }, jsx("div", {
    css: {
      display: 'flex'
    }
  }, jsx(Button, {
    size: "md-icon",
    outline: true,
    className: "button blue",
    style: {
      color: colors.cerulean,
      border: `2px solid ${colors.cerulean}`,
      width: 210,
      height: 48,
      marginRight: 10
    },
    onClick: () => {
      setIsVisible(true);
      viewProcedures();
    }
  }, t('Ver mi lista de trámites')), jsx(Button, {
    size: "md-icon",
    className: "button red",
    style: {
      background: colors.fireEngineRed,
      color: colors.white,
      width: 184,
      height: 48
    },
    onClick: () => {
      setIsVisible(false);
      createProcedure();
    }
  }, t('Crear nuevo trámite'))))));
}