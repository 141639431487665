/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '33',
  height: '41',
  viewBox: '0 0 33 41',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M4.25008 0.083334C3.16711 0.083334 2.1285 0.513541 1.36273 1.27931C0.596955 2.04509 0.166748 3.0837 0.166748 4.16667V36.8333C0.166748 37.9163 0.596955 38.9549 1.36273 39.7207C2.1285 40.4865 3.16711 40.9167 4.25008 40.9167H28.7501C29.833 40.9167 30.8717 40.4865 31.6374 39.7207C32.4032 38.9549 32.8334 37.9163 32.8334 36.8333V12.3333L20.5834 0.083334H4.25008ZM20.5834 14.375H18.5417V4.16667L28.7501 14.375H20.5834Z',
    fill: 'white',
  }),
  name: 'File',
})
