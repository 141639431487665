/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '17',
  height: '19',
  viewBox: '0 0 17 19',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M2.58301 18.9987C2.0969 18.9987 1.6837 18.8216 1.34342 18.4675C1.00315 18.1133 0.833008 17.707 0.833008 17.2487V4.6237H2.58301V17.2487H12.4997V18.9987H2.58301ZM5.64551 15.9154C5.1594 15.9154 4.74968 15.7418 4.41634 15.3945C4.08301 15.0473 3.91634 14.6445 3.91634 14.1862V2.70703C3.91634 2.2487 4.08301 1.84245 4.41634 1.48828C4.74968 1.13411 5.1594 0.957031 5.64551 0.957031H14.6038C15.0761 0.957031 15.4858 1.13411 15.833 1.48828C16.1802 1.84245 16.3538 2.2487 16.3538 2.70703V14.1862C16.3538 14.6445 16.1802 15.0473 15.833 15.3945C15.4858 15.7418 15.0761 15.9154 14.6038 15.9154H5.64551Z',
    fill: 'white',
  }),
  name: 'Copy',
})
