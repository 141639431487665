/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '12',
  height: '17',
  viewBox: '0 0 12 17',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M0.8125 15.7008C0.8125 16.5551 1.81462 17.016 2.46329 16.46L10.8642 9.25926C11.3298 8.86016 11.3298 8.13984 10.8642 7.74074L2.46329 0.539962C1.81462 -0.0160433 0.812499 0.444867 0.812499 1.29922L0.8125 15.7008Z',
    fill: 'white',
  }),
  name: 'CaretRight',
})
