/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import Rodal from 'rodal';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '../../../hooks/use-media-query';
import { Button, TextArea, Colors, FormError } from '../../../core';
import { DivRow, DivColumn, FormLabel } from '../InputsUI';
import SpinnerSmall from '../../SpinnerSmall';
import styles from './modalStyle';
import modalCustomStyle from './utils';
const {
  tdCarWeb: colors
} = Colors;

const VehicleItem = ({
  title,
  text
}) => {
  const {
    t
  } = useTranslation();
  return jsx("span", {
    style: {
      display: 'block',
      fontSize: 18,
      color: colors.darkGray
    }
  }, jsx("span", {
    style: {
      fontWeight: 600
    }
  }, t(title)), ": ", jsx("span", null, text));
};
/**
 * This component represents a modal for cancel a procedure.
 * @param {boolean} isVisible Determines if the modal is visible or not.
 * @param {function} setIsVisible Function triggered when close the modal to hide it.
 * @param {function} proceedToCancel Cancel the procedure.
 * @param {string} isRejecting Determine if it is in the process of cancellation.
 */


export default function RefuseEntry({
  isVisible,
  setIsVisible,
  carInfo,
  proceedToReject,
  isRejecting
}) {
  var _carInfo$identificati, _carInfo$model, _carInfo$year;

  const {
    t
  } = useTranslation();
  const {
    isMobile
  } = useMediaQuery();
  const formik = useFormik({
    initialValues: {
      observation: ''
    },
    validationSchema: yup.object().shape({
      observation: yup.string().required(t('Debe indicar el motivo de rechazo'))
    }),
    onSubmit: values => {
      proceedToReject({ ...values,
        id: carInfo.id
      });
      formik.resetForm();
    }
  });
  return jsx(Rodal, {
    visible: isVisible,
    showCloseButton: false,
    width: 882,
    height: 563,
    closeOnEsc: true,
    onClose: () => {},
    customStyles: modalCustomStyle({
      isMobile,
      width: 800,
      height: 559
    })
  }, jsx("form", {
    css: [styles, {
      padding: '55px 35px'
    }],
    onSubmit: formik.handleSubmit
  }, jsx(DivRow, {
    className: "modals-row",
    containerStyle: {
      marginBottom: 27
    }
  }, jsx(DivColumn, {
    className: "modals-column"
  }, jsx("h1", {
    className: "modals-title",
    style: {
      fontSize: 24,
      marginTop: 'unset',
      marginBottom: 30,
      color: colors.deepCerulean
    }
  }, t('Rechazar ingreso')), jsx(VehicleItem, {
    title: "Identificaci\xF3n",
    text: (_carInfo$identificati = carInfo === null || carInfo === void 0 ? void 0 : carInfo.identification) !== null && _carInfo$identificati !== void 0 ? _carInfo$identificati : ''
  }), jsx(VehicleItem, {
    title: "Modelo",
    text: (_carInfo$model = carInfo === null || carInfo === void 0 ? void 0 : carInfo.model) !== null && _carInfo$model !== void 0 ? _carInfo$model : ''
  }), jsx(VehicleItem, {
    title: "A\xF1o",
    text: (_carInfo$year = carInfo === null || carInfo === void 0 ? void 0 : carInfo.year) !== null && _carInfo$year !== void 0 ? _carInfo$year : ''
  }))), jsx(DivRow, {
    className: "modals-row",
    containerStyle: {
      marginBottom: 41
    }
  }, jsx(DivColumn, {
    className: "modals-column"
  }, jsx(FormLabel, {
    text: t('Motivo de rechazo'),
    styles: {
      marginBottom: '0px'
    },
    containerStyle: {
      display: 'flex',
      color: '#0D1F2D',
      fontSize: 14
    }
  }), jsx(TextArea, {
    name: "observation",
    autoComplete: "off",
    placeholder: "Ingresar",
    width: "100%",
    maxLength: "512",
    style: {
      height: '170px',
      fontSize: '14px !important'
    },
    value: formik.values.observation,
    showCount: true,
    showCountStyle: {
      color: `${colors.blackPearl} !important`,
      bottom: '11px !important',
      right: '19px !important',
      fontSize: '15px !important'
    },
    error: formik.errors.observation,
    touched: formik.touched.observation,
    onChange: formik.handleChange
  }), jsx(FormError, {
    msg: formik.errors.observation,
    touched: formik.touched.observation
  }))), jsx("div", {
    className: "button-container"
  }, jsx(Button, {
    size: "md-icon",
    outline: true,
    className: "button blue",
    style: {
      color: colors.deepCerulean,
      border: `2px solid ${colors.deepCerulean}`,
      width: 150,
      height: 48
    },
    disabled: isRejecting,
    onClick: setIsVisible
  }, t('Cancelar')), jsx(Button, {
    type: "submit",
    size: "md-icon",
    className: "button red",
    style: {
      background: colors.fireEngineRed,
      color: colors.white,
      width: 150,
      height: 48
    },
    disabled: isRejecting
  }, t('Rechazar'), isRejecting ? jsx(SpinnerSmall, {
    className: "spinner"
  }) : ''))));
}