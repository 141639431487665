import { css } from '@emotion/core';
import { migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../../Colors'; // temas usados para componente button selector en todas las aplicaciones

const themes = {
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.blackPearl,
    beta: migratoryWebCitizen.raven,
    gamma: migratoryWebCitizen.cerulean,
    delta: migratoryWebCitizen.white,
    epsilon: migratoryWebCitizen.AzureishWhite,
    dseta: migratoryWebCitizen.frenchPass,
    eta: migratoryWebCitizen.fireEngineRed,
    theta: migratoryWebCitizen.cello,
    iota: migratoryWebCitizen.cadetBlue
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.blackPearl,
    beta: sanitarianRegistersWeb.raven,
    gamma: sanitarianRegistersWeb.cerulean,
    delta: sanitarianRegistersWeb.white,
    epsilon: sanitarianRegistersWeb.AzureishWhite,
    dseta: sanitarianRegistersWeb.frenchPass,
    eta: sanitarianRegistersWeb.fireEngineRed,
    theta: sanitarianRegistersWeb.cello,
    iota: sanitarianRegistersWeb.cadetBlue
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.allports,
    beta: phytosanitaryWeb.sherpaBlue,
    gamma: phytosanitaryWeb.mantis,
    delta: phytosanitaryWeb.sherpaBlue,
    epsilon: phytosanitaryWeb.morningGlory,
    dseta: phytosanitaryWeb.mabel,
    eta: phytosanitaryWeb.fireEngineRed,
    theta: phytosanitaryWeb.sherpaBlue,
    iota: phytosanitaryWeb.mantis
  },
  tdCarWeb: {
    alpha: tdCarWeb.blackPearl,
    beta: tdCarWeb.raven,
    gamma: tdCarWeb.cerulean,
    delta: tdCarWeb.white,
    epsilon: tdCarWeb.AzureishWhite,
    dseta: tdCarWeb.frenchPass,
    eta: tdCarWeb.fireEngineRed,
    theta: tdCarWeb.cello,
    iota: tdCarWeb.cadetBlue
  }
};
export default (({
  theme = 'migratoryWebCitizen'
}) => css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  button:disabled,
  button[disabled] {
    cursor: not-allowed;
  }
  button {
    border: 1px solid ${themes[theme].alpha};
    background: #fff;
    border-radius: 6px;
    cursor: pointer;
    width: 101px;
    height: 48px;
    color: ${themes[theme].beta};
    font-size: 15px;
    margin: 0 8px 8px 0;
    letter-spacing: 0.01em;
  }
  button:last-of-type {
    margin-right: 0;
  }
  .selected {
    border: none;
    color: ${themes[theme].delta};
    background: ${themes[theme].gamma};
  }
  button:disabled.selected {
    color: ${themes[theme].theta};
    background: ${themes[theme].iota};
  }
  button:disabled:not(.selected),
  button[disabled]:not(.selected) {
    border: 1px solid ${themes[theme].epsilon};
    background: none;
    color: ${themes[theme].dseta};
  }
  .error {
    border: 1px solid ${themes[theme].eta};
  }
`);