/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from '../../Colors';
import { DivRow, DivColumn } from '../../components/InputsUI';
import { TrashIcon } from '../../../icon';
import { SpinnerSmall } from '../../../core';
const themes = {
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.white,
    beta: migratoryWebCitizen.deepCerulean,
    gamma: migratoryWebCitizen.gray,
    delta: migratoryWebCitizen.deepCerulean
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.white,
    beta: sanitarianRegistersWeb.deepCerulean,
    gamma: sanitarianRegistersWeb.gray,
    delta: sanitarianRegistersWeb.deepCerulean
  },
  tdCarWeb: {
    alpha: tdCarWeb.white,
    beta: tdCarWeb.deepCerulean,
    gamma: tdCarWeb.gray,
    delta: tdCarWeb.deepCerulean
  }
};
/**
 * This component represent a record information card container.
 * @param {*} children What can be display on the card.
 * @param {JSON} style Custom style for the card div.
 */

export function RecordInfo({
  children,
  style
}) {
  const {
    theme = 'migratoryWebCitizen'
  } = useTheme();
  return jsx("div", {
    css: styles(theme),
    style: { ...style
    }
  }, children);
}
/**
 * This component represent a record information card container.
 * @param {*} children What can be display on the card.
 * @param {JSON} style Custom style for the card div.
 */

export function RecordInfoCard({
  children,
  onClick,
  style,
  isLoading = false
}) {
  const {
    theme = 'migratoryWebCitizen'
  } = useTheme();
  return jsx("div", {
    className: "record-info-card",
    style: { ...style
    }
  }, typeof onClick === 'function' ? jsx(DivRow, null, jsx(DivColumn, {
    containerStyle: {
      flexBasis: '90%'
    }
  }, children), jsx(DivColumn, {
    containerStyle: {
      alignItems: 'flex-end'
    }
  }, isLoading ? jsx(SpinnerSmall, {
    style: {
      width: 21,
      height: 21,
      marginLeft: 5,
      color: themes[theme].delta
    }
  }) : jsx(TrashIcon, {
    onClick: onClick,
    css: {
      width: 21,
      height: 21,
      cursor: 'pointer',
      color: themes[theme].delta
    }
  }))) : children);
}
/**
 * This component represent a generic information card title.
 * @param {*} children What can be display on the card title.
 * @param {JSON} style Custom style for the card title.
 */

export function RecordInfoTitle({
  children,
  style
}) {
  return jsx("span", {
    className: "record-info-card-title",
    style: { ...style
    }
  }, children);
}
/**
 * This component represent a generic information card subtitle.
 * @param {*} children What can be display on the card title.
 * @param {JSON} style Custom style for the card title.
 */

export function RecordInfoSubTitle({
  children,
  style
}) {
  return jsx("span", {
    className: "record-info-card-subtitle",
    style: { ...style
    }
  }, children);
}
/**
 * This component represent a generic information card subtitle.
 * @param {*} children What can be display on the card title.
 * @param {JSON} style Custom style for the card title.
 */

export function RecordInfoSpan({
  children,
  style
}) {
  return jsx("span", {
    className: "record-info-card-span",
    style: { ...style
    }
  }, children);
}

const styles = theme => css`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  list-style-type: none;

  .record-info-card {
    height: 157px;
    padding: 30px 27px;
    background: ${themes[theme].alpha};
    border-radius: 10px;
  }

  .record-info-card-title,
  .record-info-card-subtitle,
  .record-info-card-span {
    margin: 0;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .record-info-card-title {
    color: ${themes[theme].beta};
  }

  .record-info-card-title,
  .record-info-card-subtitle {
    display: block;
  }

  .record-info-card-subtitle,
  .record-info-card-span {
    color: ${themes[theme].gamma};
  }

  .record-info-card-subtitle:last-of-type {
    margin-bottom: 0;
  }

  .record-info-card-span {
    font-weight: normal;
  }
`;