import { useQuery } from 'react-query'
import { getToken } from '../utils/api-client'
const { REACT_APP_CDN_URL } = process.env
export function useDownLoadFile({ fileUri = '', options = {} } = {}) {
  return useQuery({
    queryKey: ['downloadFile', fileUri],
    queryFn: async () => {
      try {
        const headers = {}
        const token = getToken()

        if (token && fileUri.includes(REACT_APP_CDN_URL)) {
          headers.Authorization = `Bearer ${token}`
        }

        let response = await window.fetch(fileUri, {
          method: 'GET',
          headers,
          credentials: 'include',
        })

        if (!response.ok) {
          throw new Error('Error')
        }

        response = await response.blob()
        const url = URL.createObjectURL(response)
        window.open(url, '_blank')
        return
      } catch (error) {
        throw new Error(error)
      }
    },
    config: { ...options },
  })
}
export function useDownLoadFiles({ fileUris = '', options = {} } = {}) {
  return useQuery({
    queryKey: ['downloadFiles', fileUris],
    queryFn: async () => {
      try {
        const headers = {}
        const token = getToken()

        if (token) {
          headers.Authorizatio = `Bearer ${token}`
        }

        let response = await window.fetch(
          `${REACT_APP_CDN_URL}/downloadAll?files=${JSON.stringify({
            paths: fileUris.map(uri =>
              uri.replace(`${REACT_APP_CDN_URL}/`, '')
            ),
          })}`,
          {
            method: 'GET',
            headers,
            credentials: 'include',
          }
        )

        if (!response.ok) {
          throw new Error('Error')
        }

        response = await response.blob()
        const url = URL.createObjectURL(response)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file.zip')
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
        return
      } catch (error) {
        throw new Error(error)
      }
    },
    config: { ...options },
  })
}
