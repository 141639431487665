import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '30',
  height: '30',
  viewBox: '0 0 30 30',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx(
      'g',
      null,
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M29.3353 27.7399C29.7759 28.1805 29.7759 28.8947 29.3353 29.3353C28.8947 29.7759 28.1805 29.7759 27.7399 29.3353L22.3249 23.9203C21.8843 23.4797 21.8843 22.7655 22.3249 22.3249C22.7655 21.8843 23.4797 21.8843 23.9203 22.3249L29.3353 27.7399ZM12.2926 24.2507C5.68831 24.2507 0.334473 18.8969 0.334473 12.2926C0.334473 5.68831 5.68831 0.334473 12.2926 0.334473C18.8969 0.334473 24.2507 5.68831 24.2507 12.2926C24.2507 18.8969 18.8969 24.2507 12.2926 24.2507ZM12.2926 21.9945C17.6508 21.9945 21.9945 17.6508 21.9945 12.2926C21.9945 6.9344 17.6508 2.59072 12.2926 2.59072C6.9344 2.59072 2.59072 6.9344 2.59072 12.2926C2.59072 17.6508 6.9344 21.9945 12.2926 21.9945ZM6.72718 11.3901C6.72718 11.8055 6.39046 12.1422 5.9751 12.1422C5.55973 12.1422 5.22301 11.8055 5.22301 11.3901C5.22301 7.98411 7.98411 5.22301 11.3901 5.22301C11.8055 5.22301 12.1422 5.55973 12.1422 5.9751C12.1422 6.39046 11.8055 6.72718 11.3901 6.72718C8.81484 6.72718 6.72718 8.81484 6.72718 11.3901Z',
        fill: 'white',
      })
    )
  ),
  name: 'SearchAlternative',
})
