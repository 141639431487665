import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '34',
  height: '34',
  viewBox: '0 0 34 34',
  css: css({
    path: {
      stroke: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('circle', {
      cx: '17',
      cy: '17',
      r: '16',
      stroke: '#CE1625',
      strokeWidth: '2',
    }),
    jsx('path', {
      d: 'M21.6816 21.6816C21.5809 21.7825 21.4612 21.8626 21.3295 21.9172C21.1978 21.9719 21.0566 22 20.914 22C20.7714 22 20.6302 21.9719 20.4985 21.9172C20.3668 21.8626 20.2471 21.7825 20.1464 21.6816L16.9998 18.535L13.8532 21.6816C13.6496 21.8852 13.3735 21.9995 13.0856 21.9995C12.7976 21.9995 12.5215 21.8852 12.318 21.6816C12.1144 21.478 12 21.2019 12 20.914C12 20.7714 12.0281 20.6303 12.0826 20.4986C12.1372 20.3669 12.2171 20.2472 12.318 20.1464L15.4646 16.9998L12.318 13.8532C12.1144 13.6496 12 13.3735 12 13.0856C12 12.7976 12.1144 12.5215 12.318 12.318C12.5215 12.1144 12.7976 12 13.0856 12C13.3735 12 13.6496 12.1144 13.8532 12.318L16.9998 15.4646L20.1464 12.318C20.2472 12.2171 20.3669 12.1372 20.4986 12.0826C20.6303 12.0281 20.7714 12 20.914 12C21.0565 12 21.1977 12.0281 21.3294 12.0826C21.4611 12.1372 21.5808 12.2171 21.6816 12.318C21.7824 12.4188 21.8624 12.5384 21.9169 12.6701C21.9715 12.8018 21.9995 12.943 21.9995 13.0856C21.9995 13.2281 21.9715 13.3693 21.9169 13.501C21.8624 13.6327 21.7824 13.7523 21.6816 13.8532L18.535 16.9998L21.6816 20.1464C22.0953 20.5601 22.0953 21.257 21.6816 21.6816Z',
      fill: '#CE1625',
    })
  ),
  name: 'CircleXAlt',
})
