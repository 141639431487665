/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '15',
  height: '20',
  viewBox: '0 0 15 20',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M9.38117 17.3499V19.0062C9.38117 19.5652 8.87662 20 8.28034 20H5.45945C4.84023 20 4.35862 19.5445 4.35862 19.0062V17.3499C4.35862 16.7909 4.86317 16.3561 5.45945 16.3561H8.28034C8.89955 16.3354 9.38117 16.7909 9.38117 17.3499ZM12.9589 1.61491C11.5828 0.538302 9.72518 0 7.34004 0C5.11544 0 3.32659 0.47619 1.97348 1.40787C0.780913 2.23602 0.138761 3.41615 0.0011571 4.96894C-0.0217769 5.32091 0.299299 5.63147 0.689177 5.65217L3.89994 5.71428C4.42742 5.71428 4.90904 5.42443 5.04664 4.98965C5.16131 4.61698 5.36772 4.32712 5.64292 4.07867C6.1016 3.706 6.65202 3.51967 7.31711 3.51967C8.14273 3.51967 8.78488 3.74741 9.24356 4.2029C9.70224 4.65838 9.90865 5.2588 9.90865 6.02484C9.90865 6.74948 9.72518 7.41201 9.3353 7.97101C8.94542 8.53002 8.41794 9.00621 7.75285 9.37888C6.44561 10.207 5.55119 10.9317 5.06957 11.5528C4.6797 12.0497 4.45036 12.7329 4.35862 13.6232C4.31275 14.0373 4.6797 14.3892 5.13838 14.3892H8.64728C9.03716 14.3892 9.38117 14.1201 9.42704 13.7681C9.4729 13.3126 9.58757 12.9193 9.72518 12.6087C9.95452 12.1739 10.3903 11.7598 11.0324 11.3872C12.1791 10.8489 13.1194 10.1035 13.8762 9.15114C14.6101 8.19876 15 7.16356 15 6.04555C15 4.16149 14.312 2.71222 12.9589 1.61491Z',
    fill: 'white',
  }),
  name: 'Question',
})
