import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '17',
  height: '16',
  viewBox: '0 0 17 16',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M8.00134 0C6.05844 0 4.22978 0.685725 2.79476 1.93019V0H1.5249V4.06356H5.58846V2.79369H3.73446C4.92813 1.81589 6.41385 1.26986 8.00119 1.26986C11.7092 1.26986 14.7316 4.29199 14.7316 8.00027H16.0015C16.0015 3.59393 12.4078 0.000290261 8.00148 0.000290261L8.00134 0Z',
      fill: '#0672AB',
    }),
    jsx('path', {
      d: 'M10.413 13.2066H12.267C11.0733 14.1844 9.58761 14.7304 8.00027 14.7304C4.29228 14.7304 1.26986 11.7083 1.26986 8H0C0 12.4063 3.59363 16 7.99998 16C9.94288 16 11.7715 15.3143 13.2066 14.0698V16H14.4764V11.9364H10.4129L10.413 13.2066Z',
      fill: '#0672AB',
    })
  ),
  name: 'Regenerate',
})
