/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import styles from './NotesInfo.style';
/**
 * This component represent a note information card.
 * @param {*} children What can be display on the card.
 * @param {JSON} style Custom style for the card div.
 */

function NotesInfo({
  children,
  style
}) {
  return jsx("div", {
    css: styles,
    style: { ...style
    }
  }, children);
}
/**
 * This component represent a note information card title.
 * @param {*} children What can be display on the card title.
 */


function NotesInfoTitle({
  children
}) {
  return jsx("h1", {
    className: "notes-title"
  }, children);
}
/**
 * This component represent a note information card container.
 * @param {*} children What will be shown inside the card container.
 * @param {JSON} style Custom style for the card container.
 */


function NotesInfoContainer({
  children,
  style
}) {
  return jsx("div", {
    className: "notes-container",
    style: style
  }, children);
}
/**
 * This component represent a note information card inside row.
 * @param {*} children What will be shown inside the card inside row.
 * @param {JSON} style Custom style for the card inside row.
 */


function NotesInfoRow({
  children,
  style
}) {
  return jsx("div", {
    className: "notes-row",
    style: style
  }, children);
}
/**
 * This component represent a note information card inside column.
 * @param {*} children What will be shown inside the card inside column.
 * @param {JSON} style Custom style for the card inside column.
 */


function NotesInfoColumn({
  children,
  style
}) {
  return jsx("div", {
    className: "notes-column",
    style: style
  }, children);
}
/**
 * This component represent a note information card item title.
 * @param {*} children What will be shown inside the card item title.
 */


function NotesInfoItemTitle({
  children
}) {
  return jsx("h1", {
    className: "notes-item-title"
  }, children);
}
/**
 * This component represent a note information card item sub title.
 * @param {*} children What will be shown inside the card item sub title.
 */


function NotesInfoItemSubTitle({
  children
}) {
  return jsx("h2", {
    className: "notes-item-subtitle"
  }, children);
}
/**
 * This component represent a note information card item text.
 * @param {*} children What will be shown inside the card item text.
 */


function NotesInfoItemText({
  children
}) {
  return jsx("span", {
    className: "notes-item-text"
  }, children);
}
/**
 * This component represent a note information document card container.
 * @param {*} children What will be shown inside the note information document card container.
 * @param {JSON} style Custom style for the note information document card container.
 */


function NotesInfoDocumentsContainer({
  children,
  style
}) {
  return jsx("div", {
    className: "notes-doc-container",
    style: style
  }, children);
}
/**
 * This component represent a note information document card.
 * @param {*} children What will be shown inside the note information document card.
 * @param {JSON} style Custom style for the note information document card.
 */


function NotesInfoDocument({
  children,
  style
}) {
  return jsx("div", {
    className: "notes-document",
    style: style
  }, children);
}

export { NotesInfo, NotesInfoTitle, NotesInfoContainer, NotesInfoRow, NotesInfoColumn, NotesInfoItemTitle, NotesInfoItemSubTitle, NotesInfoItemText, NotesInfoDocumentsContainer, NotesInfoDocument };