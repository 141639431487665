function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './RadioButton.style';

const RadioButton = ({
  label = '',
  value,
  onChange,
  checked,
  labelStyle,
  spanStyle,
  ...rest
}) => {
  return jsx("div", {
    css: style
  }, jsx("label", {
    className: "radio font-label",
    style: { ...labelStyle
    }
  }, jsx("input", _extends({
    type: "radio",
    value: value,
    onChange: onChange,
    checked: checked
  }, rest)), jsx("span", {
    className: "check",
    style: { ...spanStyle
    }
  }), label));
};

export default RadioButton;