/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '29',
  height: '29',
  viewBox: '0 0 29 29',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M25.375 7.77393V29H3.625V0H17.6011L25.375 7.77393ZM18.125 7.25H22.2739L18.125 3.10107V7.25ZM23.5625 27.1875V9.0625H16.3125V1.8125H5.4375V27.1875H23.5625ZM12.6875 19.9375H16.3125V25.375H12.6875V19.9375ZM7.25 16.3125H10.875V25.375H7.25V16.3125ZM18.125 12.6875H21.75V25.375H18.125V12.6875Z',
    fill: 'white',
  }),
  name: 'FileChartLine',
})
