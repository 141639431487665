/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { userWeb, sanitarianRegistersWeb, tdCarWeb } from '../Colors';
import { QuestionIcon } from '../../icon';
/**
 * This component represent help badge.
 * @param {*} children What can be display on the help.
 */

export default function Help({
  children
}) {
  return jsx("div", {
    css: style
  }, jsx(QuestionIcon, {
    css: {
      color: '#ffffff'
    }
  }), jsx("span", null, children));
}
const colors = {
  'phytosanitary-web': {
    gray: '#828282',
    white: '#ffffff'
  }
};
const themes = {
  userWeb: {
    alpha: userWeb.eastern,
    beta: userWeb.white
  },
  'phytosanitary-web': {
    alpha: colors['phytosanitary-web'].darkCharcoal,
    beta: colors['phytosanitary-web'].white
  },
  default: {
    alpha: userWeb.eastern,
    beta: userWeb.white
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.cerulean,
    beta: sanitarianRegistersWeb.white
  },
  tdCarWeb: {
    alpha: tdCarWeb.cerulean,
    beta: tdCarWeb.white
  }
};

function style({
  theme = 'migratoryWebCitizen'
}) {
  return css`
    background-color: ${themes[theme].alpha};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 72px;
    width: 72px;
    .icon {
      width: 15px;
      height: 20px;
    }
    span {
      color: ${themes[theme].beta};
      text-transform: uppercase;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      letter-spacing: 0.01em;
      margin-top: 5px;
    }
  `;
}