export const MEASUREMENTUNITS_KEYS = {
  all: ['measurementUnits'],
  list: params => [...MEASUREMENTUNITS_KEYS.all, 'list', params],
}

export const PRESENTATION_TYPES_KEYS = {
  all: ['presentationtypes'],
  list: params => [...PRESENTATION_TYPES_KEYS.all, 'list', params],
}

export const COSMETICSHAPES_KEYS = {
  all: ['cosmeticshapes'],
  list: params => [...COSMETICSHAPES_KEYS.all, 'list', params],
}

export const COSMETICUSES_KEYS = {
  all: ['cosmeticuses'],
  list: params => [...COSMETICUSES_KEYS.all, 'list', params],
}

export const BUSINESSES_KEYS = {
  all: ['businesses'],
  list: params => [...BUSINESSES_KEYS.all, 'list', params],
}

export const PERSONS_KEYS = {
  all: ['persons'],
  list: params => [...PERSONS_KEYS.all, 'list', params],
}

export const PROFESSIONS_KEYS = {
  all: ['professions'],
  list: params => [...PROFESSIONS_KEYS.all, 'list', params],
}

export const PROFESSIONALS_KEYS = {
  all: ['profesionals'],
  list: params => [...PROFESSIONALS_KEYS.all, 'list', params],
}

export const MANUFACTURERS_KEYS = {
  all: ['manufacturers'],
  list: params => [...MANUFACTURERS_KEYS.all, 'list', params],
}

export const MANUFACTURERTYPES_KEYS = {
  all: ['manufacturerTypes'],
  list: params => [...MANUFACTURERTYPES_KEYS.all, 'list', params],
}

export const RECORDS_KEYS = {
  all: ['records'],
  list: params => [...RECORDS_KEYS.all, 'list', params],
}

export const FOODS_KEYS = {
  all: ['foods'],
  list: params => [...FOODS_KEYS.all, 'list', params],
}

export const PERMISSIONS_KEYS = {
  all: ['permissions'],
  list: params => [...PERMISSIONS_KEYS.all, 'list', params],
}

export const COUNTRIES_KEYS = {
  all: ['countries'],
  list: params => [...COUNTRIES_KEYS.all, 'list', params],
}

export const DIGITAL_RECORDS_EVENTS_KEYS = {
  all: ['digitalrecordevents'],
  list: id => [...DIGITAL_RECORDS_EVENTS_KEYS.all, id],
}

export const OWNERS_KEYS = {
  all: ['owners'],
  list: params => [...OWNERS_KEYS.all, 'list', params],
}

export const REQUEST_EXTENSIONS_DEFINITIONS = {
  all: ['request-extensions-definitions'],
  list: () => [...REQUEST_EXTENSIONS_DEFINITIONS.all, 'list'],
}

export const PROCEDURE_EXTENSIONS_REQUEST = {
  all: ['procedure-extensions-request'],
  list: procedureId => [
    ...PROCEDURE_EXTENSIONS_REQUEST.all,
    'list',
    procedureId,
  ],
}

export const LEGALREPRESENTATIVES_KEYS = {
  all: ['owners'],
  list: params => [...LEGALREPRESENTATIVES_KEYS.all, 'list', params],
}

export const DIGITAL_RECORDS_TYPES_KEYS = {
  all: ['digitalRecordsTypes'],
  list: params => [...DIGITAL_RECORDS_TYPES_KEYS.all, params],
}

export const VIEW_OWNERS = {
  all: ['viewOwners'],
  list: params => [...VIEW_OWNERS.all, params],
}

export const DIGITAL_RECORDS_KEYS = {
  all: ['digitalRecords'],
  list: params => [...DIGITAL_RECORDS_KEYS.all, params],
  detail: id => [...DIGITAL_RECORDS_KEYS.all, id, 'detail'],
}

export const CATALOGS_KEYS = {
  all: ['catalogs'],
  list: params => [...CATALOGS_KEYS.all, 'list', params],
  detail: id => [...CATALOGS_KEYS.all, id, 'detail'],
  searchPerson: id => [...CATALOGS_KEYS.all, id, 'searchPerson'],
  searchCompany: id => [...CATALOGS_KEYS.all, id, 'searchCompany'],
  search: params => [...CATALOGS_KEYS.all, 'search', params],
}

export const ALLOWEDCHANGEDEFINITION_KEYS = {
  all: ['allowedchangedefinition'],
  list: params => [...ALLOWEDCHANGEDEFINITION_KEYS.all, 'list', params],
}

export const CUSTOMS_KEYS = {
  all: ['customs'],
  list: params => [...CUSTOMS_KEYS.all, 'list', params],
}
