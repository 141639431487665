import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '63',
  height: '63',
  viewBox: '0 0 63 63',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx(
      'g',
      null,
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M31.5 63C48.897 63 63 48.897 63 31.5C63 14.103 48.897 0 31.5 0C14.103 0 0 14.103 0 31.5C0 48.897 14.103 63 31.5 63ZM39.1667 20H20V43H43V23.8333L39.1667 20ZM22.1905 22.1905H25.4762V27.6667H37.5238V22.1905H38.2593L40.8095 24.7407V40.8095H22.1905V22.1905ZM32.0476 26.0238V22.1905H34.7857V26.0238H32.0476ZM35.3333 34.2381C35.3333 32.121 33.6171 30.4048 31.5 30.4048C29.3829 30.4048 27.6667 32.121 27.6667 34.2381C27.6667 36.3552 29.3829 38.0714 31.5 38.0714C33.6171 38.0714 35.3333 36.3552 35.3333 34.2381Z',
        fill: '#fff',
      })
    )
  ),
  name: 'SaveCircle',
})
