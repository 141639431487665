/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import Rodal from 'rodal';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '../../../hooks/use-media-query';
import { Button, Colors } from '../../../core';
import styles from './modalStyle';
import modalCustomStyle from './utils';
import { CustomDropdown, CustomInput } from '../filters/utils';
import { DivRow, DivColumn } from '../InputsUI';
import { EditAltIcon } from '../../../icon';
import { useVehicleIdentificationTypes } from '../../../queries';
import SpinnerSmall from '../../SpinnerSmall';
const {
  tdCarWeb: colors
} = Colors;
/**
 * This component represents a modal for cancel a procedure.
 */

export default function UpdateIdentification({
  isVisible,
  setIsVisible,
  updateProcedure,
  data,
  isLoading
}) {
  const {
    t
  } = useTranslation();
  const {
    isMobile
  } = useMediaQuery();
  const {
    data: vehicleIdentificationTypes = []
  } = useVehicleIdentificationTypes();
  const {
    identificationType,
    identification,
    id
  } = data;
  const formik = useFormik({
    initialValues: {
      identificationType,
      identification,
      id
    },
    enableReinitialize: true,
    validationSchema: () => yup.object().shape({})
  });
  return jsx(Rodal, {
    visible: isVisible,
    showCloseButton: false,
    width: 830,
    height: 526,
    duration: 0,
    closeOnEsc: true,
    enterAnimation: () => {},
    onClose: () => {},
    customStyles: modalCustomStyle({
      isMobile,
      width: 781,
      height: 315,
      borderRadius: 20
    })
  }, jsx("div", {
    css: [styles, {
      padding: '21px 16px 16px 16px',
      height: '256px'
    }]
  }, jsx(DivRow, {
    style: {
      marginBottom: '0px!important',
      display: 'flex'
    }
  }, jsx(DivColumn, null, jsx(EditAltIcon, {
    style: {
      width: 54,
      height: 46,
      color: colors.cerulean,
      marginRight: 24
    }
  })), jsx(DivColumn, {
    containerStyle: {
      flexBasis: '80%'
    }
  }, jsx("h1", {
    className: "modals-title",
    style: {
      fontSize: 24,
      marginTop: 0,
      marginBottom: 10,
      color: colors.deepCerulean
    }
  }, t('Actualizar vehículo')), jsx("p", null, t('Está a punto de actualizar el tipo de identificación y la identificación del vehículo.')))), jsx(DivRow, null, jsx(DivColumn, null, jsx(CustomDropdown, {
    title: "Tipo",
    name: "identificationType",
    items: vehicleIdentificationTypes,
    formik: formik
  }), jsx(CustomInput, {
    title: "Identificaci\xF3n",
    name: "identification",
    formik: formik
  }))), jsx("div", {
    className: "button-container",
    css: {
      justifyContent: 'flex-end!important',
      alignItems: 'center',
      paddingLeft: 80
    }
  }, jsx("div", {
    css: {
      display: 'flex'
    }
  }, jsx(Button, {
    size: "md-icon",
    outline: true,
    className: "button blue",
    style: {
      color: colors.cerulean,
      border: `2px solid ${colors.cerulean}`,
      width: 210,
      height: 48,
      marginRight: 10
    },
    onClick: () => {
      setIsVisible(false);
      formik.resetForm();
    }
  }, t('Cancelar')), jsx(Button, {
    size: "md-icon",
    className: "button red",
    style: {
      background: colors.fireEngineRed,
      color: colors.white,
      width: 210,
      height: 48
    },
    onClick: () => {
      updateProcedure(formik.values);
    }
  }, t('Actualizar vehículo'), isLoading ? jsx(SpinnerSmall, {
    style: {
      marginLeft: '10px'
    }
  }) : false)))));
}