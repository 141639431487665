import { css } from '@emotion/core';
import { phytosanitaryWeb, migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from '../../Colors';
const themes = {
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.antiFlashWhite,
    beta: phytosanitaryWeb.allports,
    gama: phytosanitaryWeb.sherpaBlue
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.brightGray,
    beta: migratoryWebCitizen.cerulean,
    gama: migratoryWebCitizen.cerulean
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.brightGray,
    beta: sanitarianRegistersWeb.cerulean,
    gama: sanitarianRegistersWeb.cerulean
  },
  tdCarWeb: {
    alpha: tdCarWeb.brightGray,
    beta: tdCarWeb.cerulean,
    gama: tdCarWeb.cerulean
  }
}; // TODO agregar los colores para usar temas

export default (({
  theme = 'migratoryWebCitizen'
}) => css`
  background-color: ${themes[theme].alpha};
  border-radius: 6px;
  margin-bottom: 16px;
  padding: 23px 17px;

  .notes-title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.05em;
    margin-bottom: 36px;
    color: ${themes[theme].beta};
    text-transform: uppercase;
  }

  .notes-container {
    display: flex;
    flex-direction: column;
  }

  .notes-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-bottom: 11px;
    margin-bottom: 14px;
    border-bottom: 0.3px solid ${themes[theme].gama};
  }

  .notes-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 19px;

    :last-of-type {
      margin-bottom: 0;
    }
  }

  .notes-container div:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .notes-item-title {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: ${themes[theme].gama};
    margin: 0 0 10px;
  }

  .notes-item-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    margin: 0;
    color: #2b2d42;
    text-transform: uppercase;
  }

  .notes-item-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    margin: 0 0 10px;
    color: #333333;
  }

  .notes-doc-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.01em;
    color: #4f4f4f;
  }

  .notes-document {
    margin-bottom: 10px;
    width: 100%;
    margin-right: 0;
  }

  @media screen and (min-width: 768px) {
    padding: 34px 49px;

    .notes-row {
      flex-direction: row;
    }

    .notes-column {
      margin-bottom: 0;
    }

    .notes-document {
      flex: 0 calc(50% - 10px);
      margin-right: 10px;
      width: unset;
    }
  }
`);