import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '184',
  height: '184',
  viewBox: '0 0 184 184',
  css: css({}),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M91.3229 157.975C139.93 157.975 179.334 150.452 179.334 141.172C179.334 131.892 139.93 124.369 91.3229 124.369C42.7158 124.369 3.31198 131.892 3.31198 141.172C3.31198 150.452 42.7158 157.975 91.3229 157.975Z',
      fill: '#AACDE0',
    }),
    jsx('path', {
      d: 'M36.1155 61.9639L21.7157 137.974C21.7157 137.974 21.7157 145.975 27.3167 143.575C32.9176 141.176 62.5195 119.571 62.5195 119.571L101.723 51.5642L107.324 24.3617C107.324 24.3617 108.924 19.5593 106.525 17.9621C104.126 16.3613 100.924 17.9621 98.5247 19.5629C96.1216 21.1601 36.1155 61.9639 36.1155 61.9639Z',
      fill: '#D4E4ED',
    }),
    jsx('path', {
      d: 'M92.1215 37.1609C92.1215 37.1609 36.1156 62.7626 39.3135 121.172L148.926 144.374C148.926 144.374 158.527 146.773 160.926 141.172C163.326 135.571 156.124 127.571 152.926 122.769C149.728 117.97 92.1215 37.1609 92.1215 37.1609Z',
      fill: '#D4E4ED',
    }),
    jsx('path', {
      d: 'M39.3172 121.172C39.3172 121.172 36.9509 92.7583 52.9368 71.3591C68.9228 49.9599 82.524 41.161 92.1252 37.1609L106.481 57.3089C106.481 57.3089 66.9282 74.1228 62.3245 125.65L39.3172 121.172Z',
      fill: '#CE1625',
    }),
    jsx('path', {
      d: 'M87.8306 131.442C87.8306 131.442 90.1158 101.645 121.922 79.0061L137.01 100.229C137.01 100.229 118.102 117.55 113.914 136.966L87.8306 131.442Z',
      fill: '#CE1625',
    })
  ),
  name: 'TrafficCone',
})
