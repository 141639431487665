import { css } from '@emotion/core'

export default css`
  padding: 3%;
  scrollbar-color: rgba(0, 0, 0, 0) transparent;

  .cardInfo {
    background: #ebf1f4;
    border-radius: 12px;
    padding: 15px;
  }

  .cardInfo2 {
    background: #ffffff;
    border-radius: 2px;
    padding: 3%;
  }

  .cardInfoEnc {
    background: transparent;
    border-radius: 2px;
    padding: 3%;
    padding-bottom: 0;
  }

  .header-left-side {
    padding-left: 31px;
  }

  .header-btn-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .header-title,
  .lblTittle {
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 41px;
    color: #08415c;
    margin: 0;
  }

  .header-title {
    margin-bottom: 12px;
  }

  .lblTittle2 {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #0f7cb5;
    margin: 0;
  }

  .lblTittle3 {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #4f4f4f;
    margin: 0;
  }

  .lblTittle4 {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #0f7cb5;
  }

  .lblTittle5 {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #d33e43;
    margin: 0;
  }

  .lblTittle6 {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #585858;
    margin: 0;
  }

  .lblTittle7 {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #0f7cb5;
    margin: 0;
  }

  .header-version,
  .lblTittle8 {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: #0f7cb5;
    text-transform: uppercase;
    margin: 0;
  }

  .header-version {
    margin-bottom: 8px;
  }

  .general-data-title,
  .lblTittle9 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: #0f7cb5;
    text-transform: uppercase;
    margin: 0;
  }

  .general-data-btn-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
  }

  .lblTittle10 {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #0f7cb5;
    letter-spacing: 0.05em;
    margin: 0;
  }

  .procedure-status {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #0f7cb5;
    margin: 0;

    span {
      font-size: 26px;
    }
  }

  .procedure-status.error {
    color: #ce1625;
  }

  .lblTittle11 {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0f7cb5;
    margin: 0;
    letter-spacing: 0.05em;
  }

  .lblTittle12 {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    color: #0f7cb5;
    margin: 0;
  }

  .lblTittle13 {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.05em;
    margin: 0;
  }

  .lblTittle14 {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #0f7cb5;
    margin: 0;
  }

  .lblTxt {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #313131;
    margin: 0;
  }

  .lblTxt2 {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #313131;
    margin: 0;
  }

  .lblTxt3 {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #313131;
  }

  .lblTxt4 {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #93b7be;
    margin: 0;
  }

  .header-create-by,
  .header-authorized-by,
  .lblTxt5 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #0d1f2d;
    margin: 0;
  }

  .header-create-by {
    margin-bottom: 7px;
  }

  .card-div,
  .box-card {
    background: #ebf1f4;
    border-radius: 12px;
  }

  .card-div {
    padding: 28px 46px 33px;
  }

  .box-card {
    padding: 49px 26px 24px 34px;
  }

  .boxes-subTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #333333;
  }

  .boxes-text {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #4f4f4f;
  }

  .lblTxt6 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #4f4f4f;
    letter-spacing: 0.01em;
    margin: 0;
  }

  .lblTxt7 {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #2b2d42;
    letter-spacing: 0.01em;
    margin: 0;
  }

  .lblTxt8 {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #4f4f4f;
    margin: 0;
  }

  .lblTxt9 {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #333333;
    margin: 0;
  }

  .lblTxt10 {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #333333;
    margin: 0;
  }

  .lblTxt11 {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #0f7cb5;
    margin: 0;
  }

  .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #4f4f4f;
  }

  .lblLink {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #333333;
    text-decoration: underline;
    margin: 0;
    cursor: pointer;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    flex: 1;
  }
  .row-no-scroll {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    flex: 1;
  }
  .column {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .button {
    width: 165px;
    height: 67px;
    background: #0f7cb5;
    border-radius: 8px;
    text-align: center;
    padding-top: 10px;
  }
  .buttonDetail {
    width: 55px;
    height: 22px;
    background: #d33e43;
    border-radius: 100px;
    text-align: center;
    cursor: pointer;
  }
  .buttonAdd {
    width: 170px;
    height: 35px;
    background: #d63e43;
    border-radius: 6px;
    text-align: center;
    padding-top: 4px;
    cursor: pointer;
  }
  .spanButton {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #f7f8fa;
  }
  .spanButtonIcon {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #f7f8fa;
    float: left;
    margin-left: 20%;
  }
  .divPhoto {
    height: 131px;
    width: 131px;
    background: #2b2d42;
    margin-right: 20px;
  }
  .search {
    background: #ffffff;
    border: 1px solid #93b7be;
    box-sizing: border-box;
    border-radius: 4px;
    height: 49px;
    padding-top: 13px;
    padding-left: 15px;
  }

  .infoRequirement {
    background: #f9f9f9;
    border-radius: 10px;
    width: 46%;
    margin-top: 20px;
    padding-bottom: 15px;
    padding-left: 12px;
    padding-top: 15px;
    padding-right: 2px;
    max-height: 160px;
  }

  .bodyInfoRequirement {
    margin-left: 18px;
    margin-right: 10px;
    margin-top: 5px;
  }

  @media only screen and (max-width: 900px) {
    .bodyInfoRequirement {
      margin-top: 20px !important;
    }
  }

  @media only screen and (max-width: 1250px) {
    .infoRequirement {
      width: 44% !important;
    }
  }

  .infoRequirementFooter {
    background: #0f7cb5;
    margin-top: 25px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 35px;
  }

  /* Style the tab */
  .tab {
    overflow: hidden;
    border: 1px solid #0f7cb5;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 2px;
    display: flex;
    justify-content: space-between;
  }

  /* Style the buttons inside the tab */
  .tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;

    font-family: -apple-system, BlinkMacSystemFont, 'Ubuntu';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    height: 100%;
    width: 25%;
    border-radius: 5px;
  }

  /* Change background color of buttons on hover */
  .tab button:hover {
    background-color: transparent;
  }

  /* Create an active/current tablink class */
  .tab button.active {
    background-color: #0f7cb5;
    color: #fff;
    width: 25%;
    border-radius: 5px;
  }

  /* Style the tab content */
  .tdTable {
    padding: 20px 20px 15px 15px;
    vertical-align: top;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: #0f7cb5;
  }

  .tdTableData {
    padding: 20px 20px 15px 15px;
    vertical-align: top;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: pointer;
  }

  .tdTableLeftData {
    padding: 20px 20px 15px 15px;
    vertical-align: top;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: pointer;
  }

  .tdTableInnerData {
    padding: 20px 20px 15px 15px;
    vertical-align: top;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    cursor: pointer;
  }

  .tdTableRightData {
    padding: 20px 20px 15px 15px;
    vertical-align: top;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    text-transform: capitalize;
  }

  .tdTableData2 {
    padding: 20px 20px 15px 15px;
    vertical-align: top;
    border-bottom: 0.3px solid #afccd1;
  }

  .homeActive {
    background: #cde1e4;
  }

  input.css-bek6gg {
    border: 1px solid #0f7cb5;
    box-shadow: none;
  }

  div.react-datepicker__input-container input {
    border: 1px solid #0f7cb5 !important;
    box-shadow: none !important;
  }

  .formLabel {
    margin-bottom: 0px !important;
  }

  label.label {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #282828;
    margin: 0;
  }

  div.formLabel label {
    font-family: -apple-system, BlinkMacSystemFont, 'Rubik' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #282828 !important;
    margin: 0 !important;
  }

  div.buttonSelector button {
    width: 32%;
    border: 1px solid #afccd1;
  }

  div.buttonSelector div {
    justify-content: end;
  }

  div.datePicker input,
  div.datePicker div.react-datepicker__input-container {
    width: 100% !important;
  }

  div.search input {
    width: 85%;
  }

  .tbody .trow:last-of-type {
    border-bottom: 0 !important;
  }

  .seeProcedureButton {
    width: 67px;
    height: 22px;
    border-radius: 100px;
    margin-right: 10px;
    background: #ce1625;
    text-align: center;
    cursor: pointer;
  }

  @media only screen and (max-width: 1100px) {
    .lblMenu {
      width: min-content;
    }
  }

  @media only screen and (max-width: 850px) {
    .cardProcedureDefinition {
      width: 89% !important;
      min-height: 80px !important;
    }
  }
`
