import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '53',
  height: '52',
  viewBox: '0 0 53 52',
  css: css({}),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M30.3 23.7998C27.6 23.7998 25.5 25.9998 25.5 28.5998C25.5 31.1998 27.7 33.3998 30.3 33.3998C32.9 33.3998 35.1 31.1998 35.1 28.5998C35.1 25.9998 32.9 23.7998 30.3 23.7998Z',
      fill: '#08415C',
    }),
    jsx('path', {
      d: 'M26.7002 0C12.3002 0 0.700195 11.6 0.700195 26C0.700195 40.4 12.3002 52 26.7002 52C41.1002 52 52.7002 40.4 52.7002 26C52.7002 11.6 41.0002 0 26.7002 0ZM17.5002 14.2H30.3002C31.2002 14.2 31.9002 14.9 31.9002 15.8C31.9002 16.7 31.2002 17.4 30.3002 17.4H17.5002C16.6002 17.4 15.9002 16.7 15.9002 15.8C15.9002 15 16.6002 14.2 17.5002 14.2ZM41.0002 39.3C40.7002 39.6 40.3002 39.8 39.9002 39.8C39.5002 39.8 39.1002 39.6 38.8002 39.3L34.8002 35.3C33.5002 36.1 32.0002 36.6 30.4002 36.6C26.5002 36.6 23.3002 33.8 22.6002 30.2H17.6002C16.7002 30.2 16.0002 29.5 16.0002 28.6C16.0002 27.7 16.7002 27 17.6002 27H22.6002C22.8002 25.8 23.3002 24.8 24.0002 23.9L17.7002 24C16.8002 24 16.1002 23.3 16.1002 22.4C16.1002 21.5 16.8002 20.8 17.7002 20.8L30.5002 20.7C34.9002 20.7 38.5002 24.3 38.5002 28.7C38.5002 30.3 38.0002 31.9 37.2002 33.1L41.2002 37.1C41.6002 37.7 41.6002 38.7 41.0002 39.3Z',
      fill: '#08415C',
    }),
    jsx('path', {
      d: 'M17.5004 17.4002H30.3004C31.2004 17.4002 31.9004 16.7002 31.9004 15.8002C31.9004 14.9002 31.2004 14.2002 30.3004 14.2002H17.5004C16.6004 14.2002 15.9004 14.9002 15.9004 15.8002C15.9004 16.7002 16.6004 17.4002 17.5004 17.4002Z',
      fill: '#fff',
    }),
    jsx('path', {
      d: 'M36.9998 33.0001C37.7998 31.7001 38.2998 30.2001 38.2998 28.6001C38.2998 24.2001 34.6998 20.6001 30.2998 20.6001L17.4998 20.7001C16.5998 20.7001 15.8998 21.4001 15.8998 22.3001C15.8998 23.2001 16.5998 23.9001 17.4998 23.9001L23.7998 23.8001C23.0998 24.7001 22.5998 25.8001 22.3998 26.9001H17.3998C16.4998 26.9001 15.7998 27.6001 15.7998 28.5001C15.7998 29.4001 16.4998 30.1001 17.3998 30.1001H22.3998C23.0998 33.7001 26.3998 36.5001 30.1998 36.5001C31.7998 36.5001 33.2998 36.0001 34.5998 35.2001L38.5998 39.2001C38.8998 39.5001 39.2998 39.7001 39.6998 39.7001C40.0998 39.7001 40.4998 39.5001 40.7998 39.2001C41.3998 38.6001 41.3998 37.6001 40.7998 36.9001L36.9998 33.0001ZM30.2998 33.4001C27.6998 33.4001 25.4998 31.2001 25.4998 28.6001C25.4998 26.0001 27.6998 23.8001 30.2998 23.8001C32.8998 23.8001 35.0998 26.0001 35.0998 28.6001C35.0998 31.2001 32.8998 33.4001 30.2998 33.4001Z',
      fill: '#fff',
    })
  ),
  name: 'SearchInFile',
})
