import { useQuery } from 'react-query'
import qs from 'qs'
import { client } from '../utils/api-client'
import { CUSTOMS_KEYS } from '../utils/queryKeyFactory'
export function useCustoms({ manipulation }) {
  return useQuery({
    queryKey: CUSTOMS_KEYS.list(),
    queryFn: async () => {
      const response = await client('/customs').catch(error =>
        Promise.reject(error)
      )

      if (manipulation && typeof manipulation === 'function') {
        return manipulation(response.data)
      }

      return response.data
    },
  })
}
export function useVehicleIdentificationTypes({ options = {} } = {}) {
  return useQuery({
    queryKey: ['vehicleIdentificationTypes'],
    queryFn: async () => {
      const response = await client('/vehicle/identification/type').catch(
        error => Promise.reject(error)
      )
      return response.data
    },
    config: { ...options },
  })
}
export function useVehicleCategories({ manipulation }) {
  return useQuery({
    queryKey: ['vehicleCategories'],
    queryFn: async () => {
      const response = await client('/vehicle/categories').catch(error =>
        Promise.reject(error)
      )

      if (manipulation && typeof manipulation === 'function') {
        return manipulation(response.data)
      }

      return response.data
    },
  })
}
export function useVehicleQueuePosition({ formId, options = {} } = {}) {
  return useQuery({
    queryKey: [
      'vehicles-queue',
      {
        formId,
      },
    ],
    queryFn: async () => {
      const response = await client(
        `/import/forms/${formId}/queueNumber`
      ).catch(error => Promise.reject(error))
      return response.data
    },
    config: { ...options },
  })
}
export function useVehicleRTVTypes({ options = {} } = {}) {
  return useQuery({
    queryKey: ['vehicleRTVTypes'],
    queryFn: async () => {
      const response = await client('/vehicle/rtv/type').catch(error =>
        Promise.reject(error)
      )
      return response.data
    },
    config: { ...options },
  })
}
export function useVehicleRN({ options = {} } = {}) {
  return useQuery({
    queryKey: [],
    queryFn: async () => {
      const response = await client('/vehicle/rn/statuses').catch(error =>
        Promise.reject(error)
      )
      return response.data
    },
    config: { ...options },
  })
}
export function useVehicleFrames({ manipulation }) {
  return useQuery({
    queryKey: ['vehicleFrames'],
    queryFn: async () => {
      const response = await client('/vehicle/frames').catch(error =>
        Promise.reject(error)
      )

      if (manipulation && typeof manipulation === 'function') {
        return manipulation(response.data)
      }

      return response.data
    },
  })
}
export function useColors({ manipulation }) {
  return useQuery({
    queryKey: ['vehicleColors'],
    queryFn: async () => {
      const response = await client('/vehicle/colors').catch(error =>
        Promise.reject(error)
      )

      if (manipulation && typeof manipulation === 'function') {
        return manipulation(response.data)
      }

      return response.data
    },
  })
}
export function useTractions({ manipulation }) {
  return useQuery({
    queryKey: ['vehicleTractions'],
    queryFn: async () => {
      const response = await client('/vehicle/tractions').catch(error =>
        Promise.reject(error)
      )

      if (manipulation && typeof manipulation === 'function') {
        return manipulation(response.data)
      }

      return response.data
    },
  })
}
export function useVehicleMakes({ manipulation }) {
  return useQuery({
    queryKey: ['vehicleMakes'],
    queryFn: async () => {
      const response = await client('/vehicle/makes').catch(error =>
        Promise.reject(error)
      )

      if (manipulation && typeof manipulation === 'function') {
        return manipulation(response.data)
      }

      return response.data
    },
  })
}
export function useFuels({ manipulation }) {
  return useQuery({
    queryKey: ['vehicleFuels'],
    queryFn: async () => {
      const response = await client('/vehicle/fuels').catch(error =>
        Promise.reject(error)
      )

      if (manipulation && typeof manipulation === 'function') {
        return manipulation(response.data)
      }

      return response.data
    },
  })
}
export function useCabinTypes({ manipulation }) {
  return useQuery({
    queryKey: ['vehicleCabinTypes'],
    queryFn: async () => {
      const response = await client('/vehicle/cabinTypes').catch(error =>
        Promise.reject(error)
      )

      if (manipulation && typeof manipulation === 'function') {
        return manipulation(response.data)
      }

      return response.data
    },
  })
}
export function useRoofTypes({ manipulation }) {
  return useQuery({
    queryKey: ['vehicleRoofTypes'],
    queryFn: async () => {
      const response = await client('/vehicle/roofTypes').catch(error =>
        Promise.reject(error)
      )

      if (manipulation && typeof manipulation === 'function') {
        return manipulation(response.data)
      }

      return response.data
    },
  })
}
export function useVehicleDeposit({ manipulation }) {
  return useQuery({
    queryKey: ['vehicleDeposit'],
    queryFn: async () => {
      const response = await client('/vehicle/deposit').catch(error =>
        Promise.reject(error)
      )

      if (manipulation && typeof manipulation === 'function') {
        return manipulation(response.data)
      }

      return response.data
    },
  })
}

export function useVehicleFormData({ query, config = {} }) {
  return useQuery({
    queryKey: [
      'vehicleData',
      {
        query,
      },
    ],
    queryFn: async () => {
      const queryParams = qs.stringify(query, {
        addQueryPrefix: true,
      })
      const response = await client(`/vehicle/forms${queryParams}`).catch(
        error => Promise.reject(error)
      )
      return response
    },
    config,
  })
}
