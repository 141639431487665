/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '25',
  height: '25',
  viewBox: '0 0 25 25',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M24.6085 0.15346C24.9154 0.376674 25.041 0.674293 24.9852 1.04632L21.4137 22.4749C21.3672 22.7446 21.2184 22.9539 20.9673 23.1027C20.8371 23.1771 20.6929 23.2143 20.5348 23.2143C20.4325 23.2143 20.3209 23.191 20.2 23.1445L13.8802 20.5636L10.5041 24.6791C10.3367 24.893 10.1089 25 9.82054 25C9.69963 25 9.59732 24.9814 9.51362 24.9442C9.33691 24.8791 9.19507 24.7698 9.08811 24.6164C8.98116 24.4629 8.92768 24.2932 8.92768 24.1071V19.2383L20.9813 4.46429L6.06775 17.3689L0.557144 15.1088C0.213022 14.9786 0.0270105 14.7228 -0.000891307 14.3415C-0.0194925 13.9695 0.129317 13.6951 0.445537 13.5184L23.6598 0.125558C23.7993 0.0418527 23.9481 0 24.1063 0C24.2923 0 24.4597 0.0511533 24.6085 0.15346Z',
    fill: 'white',
  }),
  name: 'PaperPlane',
})
