/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from './Colors';
import { ErrorCircleIcon } from '../icon';
import { mediaQuery } from '../utils';
const themes = {
  home: {
    alpha: migratoryWebCitizen.cerulean,
    beta: migratoryWebCitizen.white,
    gama: migratoryWebCitizen.fireEngineRed
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.cerulean,
    beta: migratoryWebCitizen.white,
    gama: migratoryWebCitizen.fireEngineRed
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.cerulean,
    beta: sanitarianRegistersWeb.white,
    gama: sanitarianRegistersWeb.fireEngineRed
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.mantis,
    beta: phytosanitaryWeb.sherpaBlue,
    gama: phytosanitaryWeb.flamingo
  },
  tdCarWeb: {
    alpha: tdCarWeb.cerulean,
    beta: tdCarWeb.white,
    gama: tdCarWeb.fireEngineRed
  }
};
/**
 * This component is the custom Toast.
 * @param {*} children Custom styles for the button when disabled.
 * @param {string} appearance Appearance of the toast (success, error, warning).
 * @param {function} onDismiss Function that is triggered when click the toast Icon.
 */

export default function ({
  children,
  appearance,
  onDismiss
}) {
  const {
    theme
  } = useTheme();
  let background = '';

  switch (appearance) {
    case 'success':
      background = '#48c892'; // se debe cambiar el color si se usa, ver con diseño

      break;

    case 'error':
      background = themes[theme].gama;
      break;

    case 'warning':
      background = '#ffa30e'; // se debe cambiar el color si se usa, ver con diseño

      break;

    default:
      // info
      background = themes[theme].alpha;
      break;
  }

  return jsx("div", {
    css: {
      background,
      padding: '12px 19px 9px  25px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginBottom: 23,
      borderRadius: 6,
      width: 376,
      [mediaQuery[0]]: {
        width: 'unset',
        marginLeft: 16,
        '@supports (margin-top: constant(safe-area-inset-top))': {
          '--safe-area-inset-top': 'constant(safe-area-inset-top)',
          marginTop: 'var(--safe-area-inset-top)'
        },
        '@supports (margin-top: env(safe-area-inset-top))': {
          '--safe-area-inset-top': 'env(safe-area-inset-top)',
          marginTop: 'var(--safe-area-inset-top)'
        }
      }
    }
  }, jsx("div", {
    style: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'center',
      flexBasis: '70%',
      color: '#FFFFFF',
      fontSize: 14
    }
  }, children), jsx("div", {
    style: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'flex-end'
    }
  }, jsx(ErrorCircleIcon, {
    css: {
      width: 30.88,
      height: 30.88,
      cursor: 'pointer',
      color: '#FFFFFF'
    },
    onClick: onDismiss
  })));
}