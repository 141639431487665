import { css } from '@emotion/core';
import { migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../../Colors';
import { mediaQuery } from '../../../utils';
const themes = {
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.frenchPass,
    beta: migratoryWebCitizen.brightGray,
    gamma: migratoryWebCitizen.brightGray,
    delta: migratoryWebCitizen.frenchPass,
    epsilon: migratoryWebCitizen.prussianBlue,
    dseta: migratoryWebCitizen.prussianBlue,
    eta: migratoryWebCitizen.prussianBlue,
    theta: migratoryWebCitizen.cerulean,
    iota: migratoryWebCitizen.columbiaBlue,
    kapppa: migratoryWebCitizen.fireEngineRed,
    lambda: migratoryWebCitizen.frenchPass,
    my: migratoryWebCitizen.prussianBlue,
    ny: migratoryWebCitizen.deepCerulean,
    xi: migratoryWebCitizen.frenchPass
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.frenchPass,
    beta: sanitarianRegistersWeb.brightGray,
    gamma: sanitarianRegistersWeb.brightGray,
    delta: sanitarianRegistersWeb.frenchPass,
    epsilon: sanitarianRegistersWeb.prussianBlue,
    dseta: sanitarianRegistersWeb.prussianBlue,
    eta: sanitarianRegistersWeb.prussianBlue,
    theta: sanitarianRegistersWeb.cerulean,
    iota: sanitarianRegistersWeb.columbiaBlue,
    kapppa: sanitarianRegistersWeb.fireEngineRed,
    lambda: sanitarianRegistersWeb.frenchPass,
    my: sanitarianRegistersWeb.prussianBlue,
    ny: sanitarianRegistersWeb.prussianBlue,
    xi: sanitarianRegistersWeb.frenchPass
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.mabel,
    beta: phytosanitaryWeb.mabel,
    gamma: phytosanitaryWeb.mabel,
    delta: phytosanitaryWeb.mabel,
    epsilon: phytosanitaryWeb.sherpaBlue,
    dseta: phytosanitaryWeb.sherpaBlue,
    eta: phytosanitaryWeb.sherpaBlue,
    theta: phytosanitaryWeb.sherpaBlue,
    iota: phytosanitaryWeb.aliceBlueAlt,
    kapppa: phytosanitaryWeb.lightSeaGreen,
    lambda: phytosanitaryWeb.mabel,
    my: phytosanitaryWeb.allports,
    ny: phytosanitaryWeb.allports,
    xi: phytosanitaryWeb.aliceBlueAlt
  },
  tdCarWeb: {
    alpha: tdCarWeb.frenchPass,
    beta: tdCarWeb.brightGray,
    gamma: tdCarWeb.brightGray,
    delta: tdCarWeb.frenchPass,
    epsilon: tdCarWeb.prussianBlue,
    dseta: tdCarWeb.prussianBlue,
    eta: tdCarWeb.prussianBlue,
    theta: tdCarWeb.cerulean,
    iota: tdCarWeb.columbiaBlue,
    kapppa: tdCarWeb.fireEngineRed,
    lambda: tdCarWeb.frenchPass,
    my: tdCarWeb.prussianBlue,
    ny: tdCarWeb.deepCerulean,
    xi: tdCarWeb.frenchPass
  }
};
export default (({
  theme = 'migratoryWebCitizen'
}) => css`
  height: 100%;

  .row-file-uploader {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .column-file-uploader {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }

  // pending styles
  .unselected-file {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.0241176em;
    color: ${themes[theme].delta};
  }

  .file-add-icon {
    width: 42.55px;
    height: 53.02px;
    margin-right: 14.45px;
    color: ${themes[theme].lambda};
    ${mediaQuery[0]} {
      width: 22px;
      height: 27px;
    }
  }
  .file-add-icon-modal {
    width: 42.55px;
    height: 53.02px;
    margin: 10px;
    color: ${themes[theme].lambda};
    ${mediaQuery[0]} {
      width: 22px;
      height: 27px;
    }
  }

  .file-add-icon-grid-view {
    width: 22px;
    height: 27px;
  }

  .pending.list {
    justify-content: flex-start;
    align-items: center;
    padding-left: 32px;
  }

  .pending {
    position: relative;
    border: 2px dashed ${themes[theme].alpha};
    border-radius: 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    ${mediaQuery[0]} {
      border: 3px dashed ${themes[theme].alpha};
      justify-content: flex-start;
    }

    input {
      cursor: pointer;
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  // uploading style
  .uploading {
    background: ${themes[theme].beta};
    border-radius: 10px;
  }

  .uploading-small {
    background: ${themes[theme].beta};
    border-radius: 10px;
    height: 50px;
  }

  .percentage-number,
  .percentage-text {
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.0241176em;
  }

  .percentage-text-small {
    font-style: normal;
    font-weight: 500;
    display: flex;
    font-size: 12px;
    color: ${themes[theme].dseta};
    letter-spacing: -0.0241176em;
    padding-left: 40px;
  }

  .percentage-number {
    font-size: 18px;
    margin-bottom: 14px;
    color: ${themes[theme].epsilon};
  }

  .percentage-number-small {
    font-size: 14px;
    width: 40px;
    margin-right: 10px;
    color: ${themes[theme].epsilon};
  }

  .percentage-text {
    font-size: 14px;
    color: ${themes[theme].dseta};
  }

  .progress-bar-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 18px;
    align-items: center;
  }

  .progress-bar-div-small {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px 0px 10px;
  }

  .progress-bar {
    width: 100%;
    border-radius: 5px;
    margin-right: 10px;
    background-color: ${themes[theme].iota};

    div {
      height: 7px;
      border-radius: 5px;
      background-color: ${themes[theme].kapppa};
    }
  }

  .progress-bar-small {
    width: 85%;
    border-radius: 5px;
    margin: 0px 10px;
    background-color: ${themes[theme].iota};

    div {
      height: 7px;
      border-radius: 5px;
      background-color: ${themes[theme].kapppa};
    }
  }

  .abort-icon {
    width: 18px;
    height: 20px;
    cursor: pointer;
    color: #0f7cb5;
  }

  // file seleted styles
  .file-selected {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: ${themes[theme].eta};
  }

  .file-icon {
    width: 24px;
    height: 30px;
    margin-right: 17px;
    color: ${themes[theme].my};
  }

  .delete-icon {
    width: 22px;
    height: 26px;
    margin-right: 10px;
    color: ${themes[theme].ny};
  }

  .done {
    background: ${themes[theme].gamma};
    border-radius: 10px;

    button {
      width: 100%;
      height: 100%;
      cursor: pointer;
      margin: 0;
      padding: 0;
      border: none;
      background: transparent;
      opacity: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      span {
        font-size: 14px;
        font-weight: bold;
        color: ${themes[theme].theta};
      }
    }

    button:disabled {
      cursor: not-allowed;

      span {
        color: #214a61;
      }

      .delete-icon {
        color: #214a61;
      }
    }
  }

  .remove-file-div {
    border-top: 1px solid ${themes[theme].xi};
    position: relative;
    height: 38px;
  }

  .error {
    color: #ce1625;
    font-size: 12px;
  }

  .file-error-icon {
    width: 31px;
    height: 27px;
    color: #ce1625;
  }

  .pendingCustom {
    border-radius: 10px;
    position: absolute;
    width: 96%;
    height: 100%;

    .row-file-uploader {
      padding: 9% 6% 0% 6%;
      transform: translateY(64%);
    }
  }

  .unselected-file-custom {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: left;
    color: #a0b9c8;
  }

  .input-custom {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  // catalogForm
  .catalog-form-pending {
    position: relative;
    border: 2px dashed ${themes[theme].alpha};
    border-radius: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    ${mediaQuery[0]} {
      border: 3px dashed ${themes[theme].alpha};
      justify-content: flex-start;
    }

    input {
      cursor: pointer;
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .catalog-file-add-icon {
    width: 20px;
    margin: 0px 25px;
    color: ${themes[theme].lambda};
    ${mediaQuery[0]} {
      width: 22px;
      height: 27px;
    }
  }

  .catalog-file-icon {
    width: 20px;
    margin: 0px 20px;
    color: ${themes[theme].my};
  }

  .catalog-delete-file {
    display: flex;
    height: 50px;
    margin: 0px 10px;
  }

  .catalog-delete-file-icon {
    width: 18px;
    height: 20px;
    color: ${themes[theme].ny};
  }
`);
export const parentDiv = ({
  containerStyle
}) => {
  return css('', containerStyle);
};