/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '15',
  height: '15',
  viewBox: '0 0 15 15',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M8.5 1.125C8.5 0.572715 8.05228 0.125 7.5 0.125C6.94772 0.125 6.5 0.572715 6.5 1.125V7.21103L4.66511 5.37615C4.27459 4.98562 3.64143 4.98562 3.2509 5.37614C2.86038 5.76667 2.86038 6.39983 3.2509 6.79036L6.79257 10.332C6.84178 10.3812 6.8956 10.4248 6.953 10.4623C7.11019 10.5652 7.29811 10.625 7.5 10.625C7.78481 10.625 8.0418 10.5059 8.22394 10.3149L11.7484 6.79036C12.139 6.39983 12.139 5.76667 11.7484 5.37614C11.3579 4.98562 10.7248 4.98562 10.3342 5.37615L8.5 7.21038V1.125ZM1.125 8.625C1.67728 8.625 2.125 9.07271 2.125 9.625V12.4583C2.125 12.5688 2.1689 12.6748 2.24704 12.753C2.32518 12.8311 2.43116 12.875 2.54167 12.875H12.4583C12.5688 12.875 12.6748 12.8311 12.753 12.753C12.8311 12.6748 12.875 12.5688 12.875 12.4583V9.625C12.875 9.07271 13.3227 8.625 13.875 8.625C14.4273 8.625 14.875 9.07271 14.875 9.625V12.4583C14.875 13.0993 14.6204 13.714 14.1672 14.1672C13.714 14.6204 13.0993 14.875 12.4583 14.875H2.54167C1.90073 14.875 1.28604 14.6204 0.832825 14.1672C0.379612 13.714 0.125 13.0993 0.125 12.4583V9.625C0.125 9.07271 0.572715 8.625 1.125 8.625Z',
    fill: 'white',
  }),
  name: 'DownloadAlt',
})
