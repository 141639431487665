function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import classnames from 'classnames';
import { useTheme } from 'emotion-theming';
import styles from './Table.style';
/**
 * This component represents a Table.
 * @param {*} children Table content.
 * @param {JSON} style Custom style for the table container.
 */

function Table({
  children,
  style
}) {
  const {
    theme
  } = useTheme();
  return jsx("div", {
    css: styles(theme),
    style: style
  }, children);
}
/**
 * This component represents a Table head.
 * @param {*} children Head content.
 * @param {JSON} style Custom style for the table head.
 * @param {string} className Used to apply a predetermine style to the head div.
 * @param {*} props All other supported props.
 */


function TableHead({
  children,
  style,
  className,
  ...props
}) {
  return jsx("div", _extends({
    className: classnames('thead', className),
    style: style
  }, props), children);
}
/**
 * This component represents a Table row.
 * @param {*} children Table row content.
 * @param {function} onClick Function triggered when click the table header.
 * @param {JSON} style Custom style for the table row.
 * @param {string} className Used to apply a predetermine style to the row div.
 * @param {JSON} props All other supported props.
 */


function TableRow({
  children,
  onClick,
  style,
  className,
  ...props
}) {
  let pointer = false;
  let properties = {};

  if (typeof onClick !== 'undefined') {
    pointer = true;
    properties = {
      onClick
    };
  }

  return jsx("div", _extends({
    className: classnames('trow', {
      pointer
    }, className)
  }, properties, {
    style: style
  }, props), children);
}
/**
 * This component represents a Table body.
 * @param {*} children Table body content.
 * @param {*} props All other supported props.
 */


function TableBody({
  children,
  ...props
}) {
  return jsx("div", _extends({
    className: "tbody"
  }, props), children);
}
/**
 * This component represents a Table cell.
 * @param {*} children Table cell content.
 * @param {string} className Used to apply a predetermine style to the table cell.
 * @param {JSON} style Custom style for the table cell.
 * @param {*} rest All other supported props.
 */


function TableCell({
  children,
  className,
  style,
  ...rest
}) {
  return jsx("div", _extends({
    className: classnames('tcell', className),
    style: style
  }, rest), children);
}
/**
 * This component represents a Table cell link.
 * @param {*} children Table cell link content .
 * @param {string} className Used to apply a predetermine style to the table cell link .
 * @param {JSON} style Custom style for the table cell link.
 * @param {function} onClick Function triggered when click the link.
 * @param {*} rest All other supported props.
 */


function TableCellLink({
  children,
  className,
  style,
  onClick = () => {},
  ...rest
}) {
  return jsx("div", _extends({
    className: classnames('tcell-link', className),
    style: style
  }, rest), jsx("span", {
    tabIndex: 0,
    role: "link",
    onClick: onClick,
    onKeyDown: e => {
      if (e.key === 'Enter') {
        onClick();
      }
    }
  }, children, jsx("div", {
    className: "underline",
    style: style && style.color ? {
      borderColor: style.color
    } : null
  })));
}
/**
 * This component represents a Table cell icon.
 * @param {*} children Table cell icon content .
 * @param {string} className Used to apply a predetermine style to the table cell icon .
 * @param {JSON} style Custom style for the table cell icon.
 * @param {function} onClick Function triggered when press key "Enter".
 * @param {*} rest All other supported props.
 */


function TableCellIcon({
  children,
  className,
  style,
  onClick,
  ...rest
}) {
  let pointer = false;
  let properties = {};

  if (typeof onClick !== 'undefined') {
    pointer = true;
    properties = {
      onClick,
      onKeyDown: e => {
        if (e.key === 'Enter') {
          onClick();
        }
      }
    };
  }

  return jsx("div", _extends({
    className: classnames('tcell-icon', className),
    style: style
  }, rest), jsx("span", _extends({
    className: classnames('tcell-icon-inner', {
      pointer
    }),
    tabIndex: 0,
    role: "link"
  }, properties), children));
}

function TableCellSkeleton({
  className
}) {
  return jsx("div", {
    className: classnames(className)
  }, jsx("div", {
    className: "tcell-skeleton"
  }));
}

function TableEmptyBody({
  children
}) {
  return jsx("div", {
    className: "tbody-empty"
  }, children);
}

export { Table, TableHead, TableRow, TableBody, TableCell, TableCellLink, TableCellIcon, TableCellSkeleton, TableEmptyBody };