/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '8',
  height: '14',
  viewBox: '0 0 8 14',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M1.7978e-06 11.5002L5 7.00019L2.28645e-06 2.50019L1 0.700195L8 7.00019L1 13.3002L1.7978e-06 11.5002Z',
    fill: 'white',
  }),
  name: 'ChevronRight',
})
