/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '17',
  height: '14',
  viewBox: '0 0 17 14',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M14.7274 0H2.27263C1.02 0 0 1.02 0 2.27263V10.8263C0 12.0789 1.02 13.0989 2.27263 13.0989H14.7274C15.98 13.0989 17 12.0789 17 10.8263V2.27263C17 1.02 15.98 0 14.7274 0ZM11.1305 8.33895C11.3632 8.57158 11.3632 8.92947 11.1305 9.1621C11.0232 9.26947 10.8621 9.34105 10.7189 9.34105C10.5758 9.34105 10.4147 9.28737 10.3074 9.1621L8.51789 7.37263L6.72842 9.1621C6.62105 9.26947 6.46 9.34105 6.31684 9.34105C6.17368 9.34105 6.01263 9.28737 5.90526 9.1621C5.67263 8.92947 5.67263 8.57158 5.90526 8.33895L7.69474 6.54947L5.90526 4.76C5.67263 4.52737 5.67263 4.16947 5.90526 3.93684C6.1379 3.70421 6.49579 3.70421 6.72842 3.93684L8.51789 5.72632L10.3074 3.93684C10.54 3.70421 10.8979 3.70421 11.1305 3.93684C11.3632 4.16947 11.3632 4.52737 11.1305 4.76L9.34105 6.54947L11.1305 8.33895Z',
    fill: 'white',
  }),
  name: 'ErrorRectangle',
})
