/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '14',
  height: '12',
  viewBox: '0 0 14 12',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M13 6.72728H8V10.8182C8 11.2682 7.55 11.6364 7 11.6364C6.45 11.6364 6 11.2682 6 10.8182V6.72728H1C0.45 6.72728 0 6.3591 0 5.9091C0 5.4591 0.45 5.09091 1 5.09091H6V1.00001C6 0.550006 6.45 0.181824 7 0.181824C7.55 0.181824 8 0.550006 8 1.00001V5.09091H13C13.55 5.09091 14 5.4591 14 5.9091C14 6.3591 13.55 6.72728 13 6.72728Z',
    fill: 'white',
  }),
  name: 'Plus',
})
