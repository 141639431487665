import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M15.2 0H0.8C0.3584 0 0 0.3584 0 0.8V15.2C0 15.6416 0.3584 16 0.8 16H15.2C15.6416 16 16 15.6416 16 15.2V0.8C16 0.3584 15.6416 0 15.2 0ZM1.6 14.4H14.4V1.6H1.6V14.4ZM4 8.8H12C12.4416 8.8 12.8 8.4416 12.8 8C12.8 7.5584 12.4416 7.2 12 7.2H4C3.5584 7.2 3.2 7.5584 3.2 8C3.2 8.4416 3.5584 8.8 4 8.8ZM4 5.6H12C12.4416 5.6 12.8 5.2416 12.8 4.8C12.8 4.3584 12.4416 4 12 4H4C3.5584 4 3.2 4.3584 3.2 4.8C3.2 5.2416 3.5584 5.6 4 5.6ZM8 10.4H4C3.5584 10.4 3.2 10.7584 3.2 11.2C3.2 11.6416 3.5584 12 4 12H7.2C7.6416 12 8 11.6416 8 11.2C8 10.7584 7.6416 10.4 7.2 10.4',
      fill: '#08415C',
    }),
    jsx(
      'mask',
      {
        id: 'mask0_1499_12439',
        maskUnits: 'userSpaceOnUse',
        x: '0',
        y: '0',
        width: '16',
        height: '16',
      },
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M15.2 0H0.8C0.3584 0 0 0.3584 0 0.8V15.2C0 15.6416 0.3584 16 0.8 16H15.2C15.6416 16 16 15.6416 16 15.2V0.8C16 0.3584 15.6416 0 15.2 0ZM1.6 14.4H14.4V1.6H1.6V14.4ZM4 8.8H12C12.4416 8.8 12.8 8.4416 12.8 8C12.8 7.5584 12.4416 7.2 12 7.2H4C3.5584 7.2 3.2 7.5584 3.2 8C3.2 8.4416 3.5584 8.8 4 8.8ZM4 5.6H12C12.4416 5.6 12.8 5.2416 12.8 4.8C12.8 4.3584 12.4416 4 12 4H4C3.5584 4 3.2 4.3584 3.2 4.8C3.2 5.2416 3.5584 5.6 4 5.6ZM8 10.4H4C3.5584 10.4 3.2 10.7584 3.2 11.2C3.2 11.6416 3.5584 12 4 12H7.2C7.6416 12 8 11.6416 8 11.2C8 10.7584 7.6416 10.4 7.2 10.4',
        fill: 'white',
      })
    ),
    jsx('g', {
      mask: 'url(#mask0_1499_12439)',
    })
  ),
  name: 'FileLines',
})
