import { css } from '@emotion/core';
import { phytosanitaryWeb, migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from '../../Colors';
const themes = {
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.antiFlashWhite,
    beta: phytosanitaryWeb.allports,
    gama: phytosanitaryWeb.sherpaBlue,
    delta: phytosanitaryWeb.flamingo
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.brightGray,
    beta: migratoryWebCitizen.cerulean,
    gama: migratoryWebCitizen.cerulean,
    delta: migratoryWebCitizen.fireEngineRed
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.brightGray,
    beta: sanitarianRegistersWeb.cerulean,
    gama: sanitarianRegistersWeb.cerulean,
    delta: sanitarianRegistersWeb.fireEngineRed
  },
  tdCarWeb: {
    alpha: tdCarWeb.brightGray,
    beta: tdCarWeb.cerulean,
    gama: tdCarWeb.cerulean,
    delta: tdCarWeb.fireEngineRed
  }
}; // TODO agregar los colores para usar temas

export default (({
  theme = 'migratoryWebCitizen'
}) => css`
  background-color: ${themes[theme].alpha};
  border-radius: 6px;
  margin-bottom: 16px;
  padding: 23px 17px;

  .requirements-title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.05em;
    margin-bottom: 36px;
    color: ${themes[theme].beta};
    text-transform: uppercase;
  }

  .requirements-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .requirements-item {
    display: flex;
    flex-direction: row;
    flex: 0 calc(50% - 15px);
    border-bottom: 0.3px solid ${themes[theme].gama};
    padding-bottom: 16px;
    margin-bottom: 19px;

    :last-of-type {
      border-bottom: 0;
    }
  }

  .requirements-container div:nth-of-type(odd) {
    margin-right: 0;
  }

  .requirements-container div:nth-of-type(even) {
    margin-left: 0;
  }

  .requirements-sub-item {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.01em;
    color: #4f4f4f;
    flex-basis: auto;
  }

  .item-not-valid {
    color: ${themes[theme].delta};
    font-weight: bold;
  }

  .requirements-sub-item-skeleton {
    display: inline-block;
    height: 20px;
    position: relative;
    background-color: #d4e4ed;
    overflow: hidden;
    width: 70%;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%
      );
      animation: shimmer 2s infinite;
      content: '';
    }
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  @media screen and (min-width: 768px) {
    padding: 34px 49px;

    .requirements-title {
      font-size: 20px;
    }

    .requirements-container {
      flex-direction: row;
    }

    .requirements-item {
      flex-direction: row;
    }

    .requirements-container div:nth-of-type(odd) {
      margin-right: 15px;
    }

    .requirements-container div:nth-of-type(even) {
      margin-left: 15px;
    }

    .requirements-sub-item {
      flex-basis: 0;
    }
  }
`);