/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../Colors';
import { mediaQuery } from '../../utils';
const themes = {
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.darkGray
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.darkGray
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.emperor
  },
  tdCarWeb: {
    alpha: tdCarWeb.darkGray
  }
};
/**
 * This component represent a grid container.
 * @param {*} children What will be shown inside the container.
 */

export const GridContainer = ({
  children,
  containerStyle = {}
}) => jsx("div", {
  css: [style(), containerStyle]
}, children);
/**
 * This component represent a grid container item.
 * @param {*} children What will be shown inside the div item.
 * @param {JSON} containerStyle Custom container styles.
 */

export const GridContainerItem = ({
  children,
  containerStyle
}) => jsx("div", {
  className: "grid-container-item",
  style: { ...containerStyle
  }
}, children);
/**
 * This component represent a grid item.
 * @param {*} children What will be shown inside the div.
 * @param {JSON} containerStyle Custom div item styles.
 */

export const GridItem = ({
  children,
  containerStyle
}) => jsx("div", {
  className: "grid-item",
  style: { ...containerStyle
  }
}, children);
/**
 * This component represent a grid title.
 * @param {*} children What will be shown inside the h4 tag.
 * @param {JSON} styles Custom h4 styles.
 */

export const GridTitle = ({
  children,
  style: styles = {}
}) => jsx("h4", {
  className: "grid-title",
  style: styles
}, children);
/**
 * This component represent a grid sub title.
 * @param {*} children What will be shown inside the span tag.
 * @param {JSON} containerStyle Custom span styles.
 */

export const GridSubTitle = ({
  children,
  containerStyle
}) => jsx("span", {
  className: "grid-subtitle",
  style: { ...containerStyle
  }
}, children);

const style = () => {
  const {
    theme = 'migratoryWebCitizen'
  } = useTheme();
  return css`
    .grid-container-item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 30px;
      ${mediaQuery[0]} {
        margin-bottom: 0;
      }
    }

    .grid-item {
      display: flex;
      flex-direction: column;
      flex: 1;
      ${mediaQuery[0]} {
        flex: unset;
        flex-grow: 1;
        width: 100%;
        margin-bottom: 14px;

        :empty {
          margin-bottom: 0px;
        }
      }
    }

    .grid-title {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: ${migratoryWebCitizen.deepCerulean};
      margin: 0 0 5px;
    }

    .grid-subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      color: ${themes[theme].alpha};
      word-break: break-word;
    }
  `;
};