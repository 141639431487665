/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import Rodal from 'rodal';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'emotion-theming';
import { home, migratoryWebCitizen, phytosanitaryWeb, sanitarianRegistersWeb, tdCarWeb } from '../../Colors';
import useMediaQuery from '../../../hooks/use-media-query';
import { Button, ButtonSelector, SpinnerSmall } from '../../../core';
import { DivRow, DivColumn, FormLabel } from '../InputsUI';
import styles from './modalStyle';
import modalCustomStyle from './utils';
const themes = {
  home: {
    alpha: home.cerulean,
    beta: home.darkGray,
    gamma: home.cerulean,
    delta: home.fireEngineRed,
    epsilon: home.white
  },
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.cerulean,
    beta: migratoryWebCitizen.darkGray,
    gamma: migratoryWebCitizen.cerulean,
    delta: migratoryWebCitizen.fireEngineRed,
    epsilon: migratoryWebCitizen.white
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.cerulean,
    beta: sanitarianRegistersWeb.darkGray,
    gamma: sanitarianRegistersWeb.deepCerulean,
    delta: sanitarianRegistersWeb.fireEngineRed,
    epsilon: sanitarianRegistersWeb.white
  },
  phytosanitaryWeb: {
    alpha: phytosanitaryWeb.lightSeaGreen,
    beta: phytosanitaryWeb.darkGray,
    gamma: phytosanitaryWeb.mantis,
    delta: phytosanitaryWeb.mantis,
    epsilon: phytosanitaryWeb.sherpaBlue
  },
  tdCarWeb: {
    alpha: tdCarWeb.cerulean,
    beta: tdCarWeb.darkGray,
    gamma: tdCarWeb.cerulean,
    delta: tdCarWeb.fireEngineRed,
    epsilon: tdCarWeb.white
  }
};
/**
 * this component represents modal when a record is going to be deleted
 * @param {boolean} isVisible Determines if the modal is visible or not.
 * @param {function} setIsVisible Function triggered when close the modal to hide it.
 * @param {function} onClickDeleteRecord remove a record from the list
 */

export default function DeleteImporter({
  isVisible,
  setIsVisible,
  onClickDeleteRecord,
  deleteOption,
  setDeleteOption,
  isFetching = false
}) {
  const {
    theme
  } = useTheme();
  const {
    t
  } = useTranslation();
  const {
    isMobile
  } = useMediaQuery();
  return jsx(Rodal, {
    visible: isVisible,
    showCloseButton: false,
    width: 882,
    height: 373,
    closeOnEsc: true,
    onClose: () => {},
    customStyles: (modalCustomStyle({
      isMobile,
      width: 882,
      height: 373
    }), {
      borderRadius: 10
    })
  }, jsx("div", {
    css: [styles, {
      padding: '33px 26px 31px 22px'
    }]
  }, jsx(DivRow, {
    className: "modals-row",
    containerStyle: {
      marginBottom: 25
    }
  }, jsx(DivColumn, {
    className: "modals-column"
  }, jsx("h1", {
    className: "modals-title",
    style: {
      fontSize: 20,
      marginTop: 'unset',
      color: themes[theme].alpha
    }
  }, t('Eliminar exepediente')), jsx("p", {
    className: "modals-description",
    style: {
      margin: '0 0 25px 0'
    }
  }, t('Puede eliminar solamente el expediente seleccionado o eliminar todos los asociados al Titular o Responsable Sanitario')))), jsx(DivRow, {
    containerStyle: {
      marginBottom: 60
    }
  }, jsx(DivColumn, null, jsx(FormLabel, {
    text: `${t('Seleccione una opción para eliminar')}:`,
    containerStyle: {
      color: '#0D1F2D',
      fontSize: 14,
      margin: 0
    }
  }), jsx(ButtonSelector, {
    data: [{
      value: 1,
      text: t('Solo este expediente')
    }, {
      value: 2,
      text: t('Todos los expedientes de este Titular')
    }, {
      value: 3,
      text: t('Todos los expedientes de este Responsable Sanitario')
    }],
    onChange: setDeleteOption,
    value: deleteOption,
    buttonStyle: {
      width: 236
    }
  }))), jsx("div", {
    className: "button-container"
  }, jsx(Button, {
    size: "md-icon",
    outline: true,
    className: "button blue",
    style: {
      color: themes[theme].gamma,
      border: `2px solid ${themes[theme].gamma}`,
      width: 150
    },
    disabled: isFetching,
    onClick: setIsVisible
  }, t('Salir')), jsx(Button, {
    size: "md-icon",
    className: "button red",
    style: {
      background: themes[theme].delta,
      color: themes[theme].epsilon,
      width: 167
    },
    disabled: isFetching,
    onClick: onClickDeleteRecord
  }, t('Eliminar'), isFetching ? jsx(SpinnerSmall, {
    style: {
      marginLeft: 10,
      width: 19,
      height: 19
    }
  }) : ''))));
}