import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '25',
  height: '32',
  viewBox: '0 0 25 32',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx(
      'g',
      null,
      jsx('path', {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M7.85938 4.02625C8.18854 1.71621 10.1666 0 12.5 0C14.8334 0 16.8115 1.71621 17.1406 4.02625L20.3125 4.65125V6.21375H21.875C23.6009 6.21375 25 7.61286 25 9.33875V28.0887C25 29.8146 23.6009 31.2137 21.875 31.2137H3.125C1.39911 31.2137 0 29.8146 0 28.0887V9.33875C0 7.62 1.40625 6.21375 3.125 6.21375H4.6875V4.65125L7.85938 4.02625ZM4.6875 9.33875H3.125V28.0888H21.875V9.33875H20.3125V10.9013H4.6875V9.33875ZM14.0625 4.65125C14.0625 5.5142 13.3629 6.21375 12.5 6.21375C11.6371 6.21375 10.9375 5.5142 10.9375 4.65125C10.9375 3.78831 11.6371 3.08875 12.5 3.08875C13.3629 3.08875 14.0625 3.78831 14.0625 4.65125Z',
        fill: '#fff',
      })
    )
  ),
  name: 'Clipboard',
})
