import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  css: css({
    path: {
      stroke: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M15.25 7.20147V14.7014C15.2499 14.7974 15.2233 14.8928 15.1712 14.9779C15.1189 15.0631 15.0423 15.1356 14.9474 15.1854L14.9467 15.1858C14.8654 15.2287 14.7728 15.2512 14.678 15.25L14.678 15.2499H14.6685H10.7471V11.4561C10.7471 11.0785 10.5928 10.7205 10.325 10.4598C10.0579 10.1998 9.69977 10.0571 9.33044 10.0571H6.66379C6.29446 10.0571 5.93634 10.1997 5.66921 10.4598C5.4014 10.7205 5.24713 11.0785 5.24713 11.4561V15.2499L1.33358 15.2499L1.33172 15.2499C1.24656 15.2501 1.16291 15.2326 1.08656 15.1991C1.01129 15.166 0.945554 15.1186 0.892912 15.061C0.797476 14.9524 0.748442 14.8172 0.750038 14.681H0.750089V14.6722V7.20112C0.750192 7.12767 0.765976 7.05446 0.797107 6.98595C0.828281 6.91735 0.874484 6.85431 0.933944 6.80156C0.933986 6.80152 0.934028 6.80149 0.93407 6.80145L7.5997 0.901067L7.59971 0.901071L7.60174 0.899259C7.70738 0.805055 7.8491 0.75001 7.99954 0.75C8.14998 0.74999 8.29171 0.805016 8.39737 0.899212L8.39937 0.900984L15.066 6.80139C15.0661 6.80148 15.0662 6.80158 15.0663 6.80167C15.1257 6.85441 15.1718 6.91741 15.203 6.98597C15.2341 7.05458 15.2499 7.12791 15.25 7.20147Z',
      fill: 'url(#paint0_linear_1514_12606)',
      stroke: 'white',
      strokeWidth: '1.5',
    })
  ),
  name: 'HomeLight',
})
