import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM9 16.4732C4.87366 16.4732 1.52679 13.1263 1.52679 9C1.52679 4.87366 4.87366 1.52679 9 1.52679C13.1263 1.52679 16.4732 4.87366 16.4732 9C16.4732 13.1263 13.1263 16.4732 9 16.4732Z',
      fill: 'white',
    }),
    jsx('path', {
      d: 'M12.9366 11.8342L10.2208 9.58183V4.17477C10.2208 4.07865 10.1522 4 10.0684 4H9.15236C9.06856 4 9 4.07865 9 4.17477V10.1892C9 10.246 9.02285 10.2984 9.06285 10.3312L12.2129 12.9659C12.2815 13.0227 12.3767 13.0074 12.4262 12.9287L12.9709 12.0767C13.0204 11.9981 13.0052 11.8888 12.9366 11.8342Z',
      fill: 'white',
    })
  ),
  name: 'Clock',
})
