const { api: esApi, web: esWeb, sql: esSQL } = require('./es')
const { api: esCRApi, web: esCRWeb, sql: esCRSQL } = require('./es-CR')
const { api: enApi, web: enWeb, sql: enSQL } = require('./en')
const { api: enUSApi, web: enUSWeb, sql: enUSSQL } = require('./en-US')

exports.sql = {
  es: esSQL,
  'es-CR': esCRSQL,
  en: enSQL,
  'en-US': enUSSQL,
}

exports.api = {
  es: esApi,
  'es-CR': esCRApi,
  en: enApi,
  'en-US': enUSApi,
}

exports.web = {
  home: {
    en: enWeb.home,
    'en-US': enUSWeb.home,
    es: esWeb.home,
    'es-CR': esCRWeb.home,
  },
  dgme: {
    en: enWeb.dgme,
    'en-US': enUSWeb.dgme,
    es: esWeb.dgme,
    'es-CR': esCRWeb.dgme,
  },
  operator: {
    en: enWeb.operator,
    'en-US': enUSWeb.operator,
    es: esWeb.operator,
    'es-CR': esCRWeb.operator,
  },
  sanitarianRegisters: {
    en: enWeb.sanitarianRegisters,
    'en-US': enUSWeb.sanitarianRegisters,
    es: esWeb.sanitarianRegisters,
    'es-CR': esCRWeb.sanitarianRegisters,
  },
  tdcar: {
    en: enWeb.tdcar,
    'en-US': enUSWeb.tdcar,
    es: esWeb.tdcar,
    'es-CR': esCRWeb.tdcar,
  },
}
