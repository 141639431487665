/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '19',
  height: '19',
  viewBox: '0 0 19 19',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M11.6878 9.5L17.8108 3.37695C18.1014 3.08686 18.2649 2.69322 18.2652 2.28261C18.2656 1.87201 18.1028 1.47808 17.8128 1.18748C17.5227 0.896879 17.129 0.733419 16.7184 0.733056C16.3078 0.732693 15.9139 0.895458 15.6233 1.18554L9.50024 7.30859L3.3772 1.18554C3.0866 0.894945 2.69246 0.731688 2.28149 0.731688C1.87052 0.731688 1.47639 0.894945 1.18579 1.18554C0.89519 1.47614 0.731934 1.87028 0.731934 2.28125C0.731934 2.69222 0.89519 3.08635 1.18579 3.37695L7.30884 9.5L1.18579 15.623C0.89519 15.9136 0.731934 16.3078 0.731934 16.7187C0.731934 17.1297 0.89519 17.5238 1.18579 17.8144C1.47639 18.105 1.87052 18.2683 2.28149 18.2683C2.69246 18.2683 3.0866 18.105 3.3772 17.8144L9.50024 11.6914L15.6233 17.8144C15.9139 18.105 16.308 18.2683 16.719 18.2683C17.13 18.2683 17.5241 18.105 17.8147 17.8144C18.1053 17.5238 18.2685 17.1297 18.2685 16.7187C18.2685 16.3078 18.1053 15.9136 17.8147 15.623L11.6878 9.5Z',
    fill: 'white',
  }),
  name: 'Close',
})
