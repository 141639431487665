import React from 'react'
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '28',
  height: '28',
  viewBox: '0 0 28 28',
  css: css({
    path: {
      stroke: 'currentcolor',
    },
  }),
  paths: jsx(
    React.Fragment,
    null,
    jsx('path', {
      d: 'M24.5 13.5C24.5 7.70312 19.7969 3 14 3C8.20312 3 3.5 7.70312 3.5 13.5C3.5 19.2969 8.20312 24 14 24C19.7969 24 24.5 19.2969 24.5 13.5Z',
      stroke: 'white',
      strokeWidth: '2.5',
      strokeMiterlimit: '10',
    }),
    jsx('path', {
      d: 'M17.5 17L10.5 10',
      stroke: 'white',
      strokeWidth: '2.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    }),
    jsx('path', {
      d: 'M10.5 17L17.5 10',
      stroke: 'white',
      strokeWidth: '2.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    })
  ),
  name: 'CircleX',
})
