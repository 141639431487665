import React from "react";

/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import Rodal from 'rodal';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '../../../hooks/use-media-query';
import { Button, Colors } from '../../../core';
import styles from './modalStyle';
import modalCustomStyle from './utils';
import { DivRow, DivColumn } from '../InputsUI';

var HelpInfo = function HelpInfo(props) {
  return jsx("svg", props, jsx("path", {
    d: "M64 32c0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32z",
    fill: "#0672AB"
  }), jsx("path", {
    d: "M30.155 47.777l-3.297-2.997A3 3 0 0 0 24.84 44H20a4 4 0 0 1-4-4V20a4 4 0 0 1 4-4h24a4 4 0 0 1 4 4v20a4 4 0 0 1-4 4h-5.84a3 3 0 0 0-2.018.78l-3.297 2.997a2 2 0 0 1-2.69 0z",
    fill: "#EBF1F4"
  }), jsx("path", {
    d: "M30 25.062a2.062 2.062 0 1 1 4.125 0 2.062 2.062 0 0 1-4.125 0zM32.063 38.47a2.062 2.062 0 0 0 2.062-2.063v-4.641a2.062 2.062 0 1 0-4.125 0v4.64a2.063 2.063 0 0 0 2.063 2.063z",
    fill: "#CE1625"
  }));
};

HelpInfo.defaultProps = {
  width: "64",
  height: "64",
  viewBox: "0 0 64 64",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
};
import roleExample from '../../../assets/img/roleExample.png';
const {
  tdCarWeb: colors
} = Colors;
/**
 * This component represents a modal for cancel a procedure.
 */

export default function VehicleInfo({
  isVisible,
  setIsVisible
}) {
  const {
    t
  } = useTranslation();
  const {
    isMobile
  } = useMediaQuery();
  return jsx(Rodal, {
    visible: isVisible,
    showCloseButton: false,
    width: 830,
    height: 526,
    closeOnEsc: true,
    onClose: () => {},
    customStyles: modalCustomStyle({
      isMobile,
      width: 830,
      height: 526,
      borderRadius: 20
    })
  }, jsx("div", {
    css: [styles, {
      padding: '25px 20px'
    }]
  }, jsx(DivRow, {
    className: "modals-row"
  }, jsx(DivColumn, null, jsx(HelpInfo, {
    style: {
      width: 64,
      height: 64
    }
  })), jsx(DivColumn, {
    containerStyle: {
      flexBasis: '80%'
    }
  }, jsx("h1", {
    className: "modals-title",
    style: {
      fontSize: 24,
      marginTop: 16,
      marginBottom: 10,
      color: colors.deepCerulean
    }
  }, t('Antes de comenzar')), jsx("p", null, t('Información importante para su perfil y empresas')), jsx("ol", {
    css: {
      margin: 0,
      padding: '0 16px',
      color: colors.blackPearl,
      fontSize: 14,
      marginBottom: 14
    }
  }, jsx("li", {
    css: {
      marginBottom: 15
    }
  }, t('Para crear el perfil de empresa el solicitante debe comprar la personería jurídica de manera exitosa [ext]')), jsx("li", null, t('Para realizar trámites a nombre de la empresa diríjase a la barra superior y cambie su rol por el de la empresa que registró previamente'))), jsx("img", {
    src: roleExample,
    alt: "Example",
    css: {
      width: 268,
      height: 165
    }
  }))), jsx("div", {
    className: "button-container",
    css: {
      justifyContent: 'space-between !important',
      alignItems: 'center',
      paddingLeft: 80
    }
  }, jsx("a", {
    // href={`${process.env.REACT_APP_CDN_URL}/public/tdcar/manual.pdf`}
    href: "https://tdcarprod-cdn-s3.s3.amazonaws.com/Preguntas+frecuentes+TDCAR+23082022.pdf",
    target: "_blank",
    rel: "noreferrer",
    css: {
      fontSize: 14,
      color: colors.cerulean,
      fontWeight: 500
    }
  }, t('Ver manual de instrucciones')), jsx("div", {
    css: {
      display: 'flex'
    }
  }, jsx(Button, {
    size: "md-icon",
    outline: true,
    className: "button blue",
    style: {
      color: colors.cerulean,
      border: `2px solid ${colors.cerulean}`,
      width: 210,
      height: 48,
      marginRight: 10
    },
    onClick: () => setIsVisible(true)
  }, t('No volver a mostrar')), jsx(Button, {
    size: "md-icon",
    className: "button red",
    style: {
      background: colors.fireEngineRed,
      color: colors.white,
      width: 138,
      height: 48
    },
    onClick: () => setIsVisible(false)
  }, t('Cerrar'))))));
}