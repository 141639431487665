function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import { useEffect, useState } from 'react';
import TimesPicker from 'rc-time-picker';
import moment from 'moment';
import { useTheme } from 'emotion-theming';
/** @jsxRuntime classic */

/** @jsx jsx */

import { jsx } from '@emotion/core';
import { ClockIcon, ErrorCircleIcon } from '../icon';
import 'rc-time-picker/assets/index.css';
import style, { input } from '../styles/timePicker';
import { migratoryWebCitizen, sanitarianRegistersWeb, tdCarWeb } from './Colors';
const themes = {
  migratoryWebCitizen: {
    alpha: migratoryWebCitizen.cerulean
  },
  sanitarianRegistersWeb: {
    alpha: sanitarianRegistersWeb.cerulean
  },
  tdCarWeb: {
    alpha: tdCarWeb.cerulean
  }
};
/**
 * This component represents a Time picker.
 * @param {Function} onChange What will be shonw inside the wrapper.
 * @param {JSON} parentContainerStyle Custom styles for the container.
 * @param {JSON} containerStyle Custom styles for the container.
 * @param {JSON} iconStyle Custom icon styles.
 * @param {*} value Time picker value.
 * @param {*} rest All other suported props.
 */

const TimePicker = ({
  onChange,
  parentContainerStyle,
  containerStyle,
  iconStyle,
  value = '',
  ...rest
}) => {
  const [selectedTime, setSelected] = useState('');
  const {
    theme
  } = useTheme();

  const onTimeChange = date => {
    const {
      hour12 = true
    } = rest;
    let time = '';

    if (date) {
      // eslint-disable-next-line no-underscore-dangle
      time = date._d.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        ...(!hour12 && {
          second: 'numeric'
        }),
        hour12
      });
      onChange(time);
      setSelected(date);
    }
  };

  useEffect(() => {
    setSelected(value ? moment(value, 'HH:mm A') : value);
  }, [value]);
  return jsx("div", {
    css: [style, input(containerStyle)],
    style: { ...parentContainerStyle
    }
  }, jsx(TimesPicker, _extends({
    showSecond: false,
    className: "timePicker",
    onChange: onTimeChange,
    format: "h:mm a",
    use12Hours: true,
    inputReadOnly: true,
    value: selectedTime,
    clearIcon: true
  }, rest)), selectedTime ? jsx(ErrorCircleIcon, {
    onClick: () => {
      onChange('');
      setSelected('');
    },
    css: {
      height: 20,
      width: 20,
      color: themes[theme].alpha,
      position: 'absolute',
      right: 5,
      top: '50%',
      transform: 'translate(0, -50%)',
      cursor: 'pointer',
      ...iconStyle
    }
  }) : jsx(ClockIcon, {
    css: {
      width: 20,
      height: 20,
      color: themes[theme].alpha,
      position: 'absolute',
      right: 5,
      top: '50%',
      transform: 'translate(0, -50%)',
      ...iconStyle
    }
  }));
};

export default TimePicker;