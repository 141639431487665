import { css } from '@emotion/core';
import { mediaQuery } from '../../utils';
export const list = percentage => css`
  list-style: none;
  padding: 0;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    left: 16px;
    top: 0;
    height: 100%;
    width: 2px;
    background-color: #0883a0;
    transition: 0.6s ease;
    z-index: 0;
  }

  ::after {
    content: '';
    position: absolute;
    left: 16px;
    height: ${percentage}%;
    width: 2px;
    background-color: #d33e43;
    transition: 0.6s ease;
    top: 0;
    z-index: 0;
  }
`;
export default css`
  display: flex;
  flex-direction: row;
  ${mediaQuery[0]} {
    flex-direction: column;
    position: relative;
    flex: 1;
  }

  .sidebar-container {
    background-color: #e9f1f2;
    width: 40%;
    position: relative;
    padding: 40px;
    box-sizing: border-box;
    ${mediaQuery[0]} {
      display: none;
    }
  }

  .sidebar-list-item {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-bottom: 40px;
    z-index: 1;
  }

  .sidebar-list-item.pointer {
    cursor: pointer;
  }

  .sidebar-list-item.disabled {
    cursor: not-allowed;
  }

  .sidebar-list-item-circle {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    background-color: #ebf1f4;
    border: 2px solid #0672ab;
    border-radius: 50%;
    transition: 1.2s ease;
  }

  .current {
    background-color: #f93545;
    border: 2px solid #ce1625;
  }

  .finished {
    background-color: #ce1625;
    border: 2px solid #ce1625;
  }

  .steps-content {
    padding: 80px 10px 0 80px;
    width: 100%;
    box-sizing: border-box;
    ${mediaQuery[0]} {
      padding: 21px 20px 54px 20px;
      position: relative;
      min-height: 100%;

      @supports (padding-top: constant(safe-area-inset-top)) {
        --safe-area-inset-top: constant(safe-area-inset-top);
        padding-top: calc(21px + var(--safe-area-inset-top));
      }

      @supports (padding-top: env(safe-area-inset-top)) {
        --safe-area-inset-top: env(safe-area-inset-top);
        padding-top: calc(21px + var(--safe-area-inset-top));
      }
    }
  }

  @media print {
    .ScrollbarsCustom {
      width: 100% !important;
      height: 100% !important;
      position: absolute !important;
      top: 0px !important;
      bottom: 0px !important;
      margin: auto !important;
      margin-top: 0px !important;
    }

    .ScrollbarsCustom-Wrapper {
      overflow: visible !important;
    }

    .ScrollbarsCustom-Scroller {
      overflow: visible !important;
    }
  }
`;