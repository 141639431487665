/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import svgToIcon from './svgToIcon'
export default svgToIcon({
  width: '31',
  height: '31',
  viewBox: '0 0 31 31',
  css: css({
    path: {
      fill: 'currentcolor',
    },
  }),
  paths: jsx('path', {
    d: 'M15.5 0C6.93958 0 0 6.93958 0 15.5C0 24.0604 6.93958 31 15.5 31C24.0604 31 31 24.0604 31 15.5C31 6.93958 24.0604 0 15.5 0ZM20.7147 4.84179C21.1692 4.84538 21.6103 5.01298 21.9436 5.34629L24.523 7.92471C25.236 8.63774 25.1872 9.83906 24.416 10.6121C23.6429 11.3851 22.4397 11.4311 21.7286 10.7201L19.1502 8.14163C18.4372 7.43057 18.487 6.22539 19.2582 5.45427C19.6679 5.04354 20.1998 4.83773 20.7147 4.84179ZM16.4648 8.24861L21.6227 13.4055L13.2406 21.7856L8.08273 16.6278L16.4648 8.24861ZM6.74395 17.6044L12.1844 23.0449L5.38163 24.4072L6.74395 17.6044Z',
    fill: 'white',
  }),
  name: 'PencilCircle',
})
